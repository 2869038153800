import gql from "graphql-tag";
const getRootIdToTitle = gql`
  mutation(
      $rootIds: [String]!
    ) {
    getRootIdToTitle(rootIds: $rootIds) {
      result {
        uuid
        rootId
        title
        type
      }
      response
      error
      scrollID
    }
  }
`;

export default getRootIdToTitle;
