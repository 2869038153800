import { gql } from "@apollo/client";

const GET_CLUSTERS = gql`
  query {
    getClusters {
      cluster_name
      cluster_languages
    }
  }
`;

export default GET_CLUSTERS;
