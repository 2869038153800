import ReactJson from "react-json-view";
import { Box, Typography, Button } from "@mui/material";

interface Props {
  src: any;
  setSrc: (src: any) => void;
}

const LinkContent = (props: Props) => {
  const { src, setSrc } = props;

  const onClickAdd = () => {
    const template = {
      relationType: null,
      id: null,
      uuid: null,
      source: null,
      uri: null,
      url: null,
    };
    setSrc([...src, template]);
  };

  const onEdit = (e: any) => {
    setSrc(e.updated_src);
  };

  const onAdd = (e: any) => {
    setSrc(e.updated_src);
  };

  const onDelete = (e: any) => {
    setSrc(e.updated_src);
  };

  return (
    <Box>
      <ReactJson
        src={src}
        theme="summerfruit" //summerfruit:inverted
        iconStyle="triangle"
        name={false}
        displayObjectSize={false}
        enableClipboard={false}
        displayDataTypes={false}
        quotesOnKeys={false}
        indentWidth={4}
        collapsed={2}
        onEdit={onEdit}
        onAdd={onAdd}
        onDelete={onDelete}
        style={{ minHeight: "500px", padding: "8px" }}
      />
      <Box mt={2}>
        <Box mb={2}>
          <Button variant="contained" size="small" onClick={onClickAdd}>
            Append link
          </Button>
        </Box>
        <Typography variant="overline">
          Pro tip: use{" "}
          <span
            style={{
              backgroundColor: "#ddd",
              fontWeight: 700,
              padding: "0px 4px",
            }}
          >
            ctrl + click
          </span>{" "}
          or{" "}
          <span
            style={{
              backgroundColor: "#ddd",
              fontWeight: 700,
              padding: "0px 2px",
            }}
          >
            ctrl + esc
          </span>{" "}
          to edit
        </Typography>
      </Box>
    </Box>
  );
};

export default LinkContent;
