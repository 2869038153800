import config from "./config.json";
import { Fragment, useState, useEffect, useContext } from "react";
import { CTX } from "./Context";
import { Span, Box, H3, OutlinedButton } from "./StyledComponents";
import ArticleItem from "./ArticleItem";
import Pagination from "./Pagination";
import { Lang, Article, ItemType } from "./TypeDefs";

const LANG = config.LANG;

interface Labels {
  articles: Lang | any;
  viewMore: Lang | any;
  results: Lang | any;
  result: Lang | any;
  noFound: Lang | any;
}

const labels: Labels = {
  articles: { en: "Articles", sv: "Artiklar" },
  viewMore: { en: "View more", sv: "Visa fler" },
  results: { en: "results", sv: "resultat" },
  result: { en: "result", sv: "resultat" },
  noFound: { en: "No articles found", sv: "Inga artiklar hittades" },
};

interface Props {
  articles: Article[];
  numberOfResult: number;
  overview?: boolean;
  onSubmit?: (e: React.FormEvent, initTab: number) => void;
  onOptionClick?: (type: ItemType, uuid: string) => void;
}
const ArticleResults = (props: Props) => {
  const {
    overview = false,
    articles,
    numberOfResult,
    onSubmit,
    onOptionClick,
  } = props;

  const { history } = useContext(CTX);

  const maxPages = Math.ceil(numberOfResult / 20);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = Number(urlParams.get("p") || 1);
    Number(page) > maxPages ? setPage(1) : setPage(Number(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfResult]);

  return (
    <Box py={2}>
      {overview && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <H3>{labels.articles[LANG]}</H3>
          <Span
            style={{ fontSize: "0.8rem", cursor: "pointer" }}
            onClick={(e: React.FormEvent) => onSubmit && onSubmit(e, 1)}
          >{`${numberOfResult} ${
            numberOfResult === 1 ? labels.result[LANG] : labels.results[LANG]
          } >`}</Span>
        </Box>
      )}
      {articles && articles.length > 0 ? (
        <Fragment>
          {articles.map((article: Article) => (
            <ArticleItem
              article={article}
              key={article.uuid}
              onClick={() => {
                onOptionClick && onOptionClick("article", article.uuid);
              }}
            />
          ))}

          {overview ? (
            <OutlinedButton
              fullWidth
              onClick={(e: React.FormEvent) => onSubmit && onSubmit(e, 1)}
            >
              {labels.viewMore[LANG]} ({numberOfResult})
            </OutlinedButton>
          ) : (
            <Pagination pages={maxPages} page={page} setPage={setPage} />
          )}
        </Fragment>
      ) : (
        <Span color="gray">{labels.noFound[LANG]}</Span>
      )}
    </Box>
  );
};

export default ArticleResults;
