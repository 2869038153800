import { gql } from "@apollo/client";

const GET_USER_PROFILES = gql`
  query ($customer: String) {
    getUserProfiles(customer: $customer) {
      id
      customer
      active
      description
      imgUrl
      interests
      location {
        name
        latLng
      }
    }
  }
`;

export default GET_USER_PROFILES;
