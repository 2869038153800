import { useState, Fragment, useEffect, useContext } from "react";
import { CTX } from "./Context";
import config from "./config.json";
import { Span, Box, ItemDiv, H3, OutlinedButton } from "./StyledComponents";
import { Page, ItemType, Lang } from "./TypeDefs";
import Pagination from "./Pagination";

const LANG = config.LANG;

interface Labels {
  pages: Lang | any;
  viewMore: Lang | any;
  results: Lang | any;
  result: Lang | any;
  noFound: Lang | any;
}

const labels: Labels = {
  pages: { en: "Pages", sv: "Sidor" },
  viewMore: { en: "View more", sv: "Visa fler" },
  results: { en: "results", sv: "resultat" },
  result: { en: "result", sv: "resultat" },
  noFound: { en: "No pages found", sv: "Inga sidor hittades" },
};

interface Props {
  overview?: boolean;
  pages: Page[];
  numberOfResult: number;
  onSubmit?: (e: React.FormEvent, initTab: number) => void;
  onOptionClick?: (type: ItemType, uuid: string) => void;
}

const PageResults = (props: Props) => {
  const {
    overview = false,
    pages,
    numberOfResult,
    onSubmit,
    onOptionClick,
  } = props;

  const { history } = useContext(CTX);

  const maxPages = Math.ceil(numberOfResult / 20);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = Number(urlParams.get("p") || 1);
    Number(page) > maxPages ? setPage(1) : setPage(Number(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfResult]);

  return (
    <Box py={2}>
      {overview && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <H3>{labels.pages[LANG]}</H3>
          <Span
            style={{ fontSize: "0.8rem", cursor: "pointer" }}
            onClick={(e: React.FormEvent) => onSubmit && onSubmit(e, 2)}
          >{`${numberOfResult} ${
            numberOfResult === 1 ? labels.result[LANG] : labels.results[LANG]
          } >`}</Span>
        </Box>
      )}

      {pages && pages.length > 0 ? (
        <Fragment>
          {pages.map((page: Page) => (
            <ItemDiv
              key={page.uuid}
              onClick={() => {
                onOptionClick && onOptionClick("page", page.uuid);
                //page.path && history.push("/article-search" + page.path);
              }}
            >
              <Box>
                <Box>{page.section_title}</Box>
                <Span fontWeight="normal">{page.section_desc}</Span>
              </Box>
            </ItemDiv>
          ))}

          {overview ? (
            <OutlinedButton
              fullWidth
              onClick={(e: React.FormEvent) => onSubmit && onSubmit(e, 2)}
            >
              {labels.viewMore[LANG]} ({numberOfResult})
            </OutlinedButton>
          ) : (
            <Pagination pages={maxPages} page={page} setPage={setPage} />
          )}
        </Fragment>
      ) : (
        <Span color="gray">{labels.noFound[LANG]}</Span>
      )}
    </Box>
  );
};

export default PageResults;
