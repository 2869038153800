import gql from "graphql-tag";
const editResource = gql`
  mutation(
    $cluster: String!
    $language: String! 
    $id: String!
    $title: String
    $source: String
    $homonyms: [String]
    $author: String
    $deleted: Boolean
    $feeling: Boolean
    $noun: Boolean
    $stopword: Boolean
    $stopwordAlways: Boolean
    $word: Boolean
    $genderedPronoun: Boolean
    $malePronoun: Boolean
    $femalePronoun: Boolean
    $genderNeutralPronoun: Boolean
    $placeNameSuffix: Boolean
    $organizationNameSuffix: Boolean
  ) {
    editResource(
        cluster: $cluster
        language: $language
        id: $id
        title: $title
        source: $source
        homonyms: $homonyms
        author: $author
        deleted: $deleted
        feeling: $feeling
        noun: $noun
        stopword: $stopword
        stopwordAlways: $stopwordAlways
        word: $word
        genderedPronoun: $genderedPronoun
        malePronoun: $malePronoun
        femalePronoun: $femalePronoun
        genderNeutralPronoun: $genderNeutralPronoun
        placeNameSuffix: $placeNameSuffix
        organizationNameSuffix: $organizationNameSuffix
    ) {
      id
      message
    }
  }
`;

export default editResource;
