import moment from "moment";
import { Box, Typography, Paper } from "@mui/material";
import { Article } from "./TypeDefs";

const imgStyle: any = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  aspectRatio: "3/2",
};

interface Props {
  article: Article;
  onClick?: any;
}

const ArticleItem = (props: Props) => {
  const { article, onClick } = props;
  return (
    <Paper
      elevation={4}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        my: 4,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box pr={3} width={[2 / 3, 4 / 5]}>
        <Typography variant="subtitle1">
          {moment(article.publication_timestamp).format("LL")}
        </Typography>
        <Typography variant="h6">{article.headline}</Typography>
        <Typography variant="body1">{article.preamble}</Typography>
      </Box>
      <Box width={[1 / 3, 1 / 5]}>
        {article.image && <img style={imgStyle} src={article.image} alt="" />}
      </Box>
    </Paper>
  );
};

export default ArticleItem;
