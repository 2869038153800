import { useState, useEffect } from "react";
import {
  TextField,
  IconButton,
  Chip,
  Tooltip,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";
import GET_SUBTYPES_QUERY from "../../queries/getSubtypesQuery";
import { ExpandLess } from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    inputRoot: {
      paddingRight: "12px !important",
    },
  })
);

const SubtypesComponent = (props: any) => {
  const classes = useStyles();
  const { subtypes, setSubtypes } = props;
  const [allSubtypes, setAllSubtypes] = useState([]);
  const [limitTags, setLimitTags] = useState(5);
  const [expanded, setExpanded] = useState(true);

  const { data } = useQuery(GET_SUBTYPES_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data) {
      setAllSubtypes(data.getSubtypes || []);
    }
  }, [data]);

  useEffect(() => {
    if (!expanded && limitTags === 5) {
      const el = document.getElementById("subtypes-input");
      if (el) {
        el.blur();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <Autocomplete
      classes={classes}
      value={subtypes}
      onChange={(event: any, newValue: any) => {
        setSubtypes(newValue);
      }}
      fullWidth
      multiple
      limitTags={limitTags}
      disableCloseOnSelect
      id="subtypes-input"
      options={allSubtypes}
      getOptionLabel={(option: string) => option}
      onFocus={() => {
        setLimitTags(-1);
        setExpanded(true);
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          style={{ display: "inline-block", whiteSpace: "nowrap" }}
          variant="outlined"
          label="Subtypes"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {limitTags === -1 && (
                  <Tooltip title="Expand less" placement="top">
                    <span>
                      <IconButton
                        //disabled={false}
                        size="small"
                        style={{ marginLeft: "0.3rem" }}
                        onClick={() => {
                          setLimitTags(5);
                          setExpanded(false);
                        }}
                      >
                        <ExpandLess />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      renderTags={(value: string[], getTagProps: any) =>
        value.map((option: string, index: number) => (
          <Chip
            size="small"
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
    />
  );
};

export default SubtypesComponent;
