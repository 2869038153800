import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { getTagIcon } from "src/utils/helpers";
import { useLazyQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import SEARCH_ARTICLE from "src/queries/searchArticleQuery";
import GET_NEWS_RECOMMENDATIONS from "src/queries/GET_NEWS_RECOMMENDATIONS";

interface IProps {
  user: any;
  strategy: any;
  selectedArticle: any;
  setSelectedArticle: (article: any) => void;
  articleAsContext: boolean;
  onChange: (option: boolean) => void;
  setModalArticle: (article: any) => void;
}

const ArticleContextComponent = (props: IProps) => {
  const {
    user,
    strategy,
    selectedArticle,
    setSelectedArticle,
    articleAsContext,
    onChange,
    setModalArticle,
  } = props;

  const searchTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [articleSuggestions, setArticleSuggestions] = useState<any[]>([]);
  const [searchByUuid, setSearchByUuid] = useState<boolean>(false);
  const [detailedArticle, setDetailedArticle] = useState<any>(null);

  const [searchArticles, { error, loading }] = useLazyQuery(SEARCH_ARTICLE, {
    onCompleted: (data) => {
      setArticleSuggestions(data.searchArticleQuery);
    },
    onError: () => {
      setArticleSuggestions([]);
    },
  });

  const [fetchArticle, { loading: loadingArticle, error: errorArticle }] =
    useLazyQuery(GET_NEWS_RECOMMENDATIONS, {
      onCompleted: (res) => {
        const data = res.getNewsRecommendations;
        const articles = data.recommendations;
        setDetailedArticle(articles.length > 0 ? articles[0] : null);
      },
      onError: (e) => {
        setDetailedArticle(null);
      },
    });

  useEffect(() => {
    if (!selectedArticle?.uuid) return;
    fetchArticle({
      variables: {
        query: {
          source: "bot",
          returnSize: 1,
          userID: user?.id ?? undefined,
          articleID: selectedArticle.uuid,
          signedIn: true,
          config: strategy,
          location: user?.location?.latLng,
          returnFields: [
            "articleID",
            "headline",
            "preamble",
            "featuredImageURL",
            "publicationTimestamp",
            "topConcept",
            "concepts",
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticle]);

  useEffect(() => {
    clearTimeout(searchTimeout.current);
    if (!searchTerm) {
      setArticleSuggestions([]);
      return;
    }
    searchTimeout.current = setTimeout(() => {
      searchArticles({
        variables: {
          uuid: searchByUuid,
          searchString: searchTerm,
          returnSize: 10,
        },
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchByUuid]);

  useEffect(() => {
    if (articleAsContext) {
      setSearchTerm("");
      setArticleSuggestions([]);
    }
  }, [articleAsContext]);

  return (
    <Box>
      <Paper elevation={2}>
        <Box
          sx={{
            px: 2,
            bgcolor: "rgba(0,0,0,0.075)",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <Typography variant="overline" sx={{ fontWeight: 700 }}>
            Recommendation context
          </Typography>
        </Box>
        <Accordion disableGutters expanded={articleAsContext}>
          <AccordionSummary
            expandIcon={null}
            sx={{
              margin: "0 0",
              bgcolor: "rgba(0, 0, 0, 0.04)",
            }}
          >
            <FormControlLabel
              sx={{ mb: 0, mr: 3 }}
              control={
                <Radio
                  edge="end"
                  onChange={() => {
                    onChange(false);
                  }}
                  checked={!articleAsContext}
                />
              }
              label={
                <Typography variant="overline" sx={{ ml: 1, fontWeight: 700 }}>
                  None (frontpage)
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ mb: 0, mr: 0 }}
              control={
                <Radio
                  edge="end"
                  onChange={() => {
                    onChange(true);
                  }}
                  checked={articleAsContext}
                />
              }
              label={
                <Typography variant="overline" sx={{ ml: 1, fontWeight: 700 }}>
                  Article as context
                </Typography>
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <LinearProgress
              sx={{
                visibility: loading || loadingArticle ? "visible" : "hidden",
              }}
            />
            <Box sx={{ position: "relative", px: 2, pb: 2 }}>
              <FormControlLabel
                disableTypography
                sx={{ mb: 0, mr: 3 }}
                control={
                  <Radio
                    edge="end"
                    onChange={() => {
                      setSearchByUuid(false);
                    }}
                    checked={!searchByUuid}
                  />
                }
                label={
                  <Typography
                    variant="overline"
                    sx={{ ml: 1, fontWeight: 700 }}
                  >
                    Freetext
                  </Typography>
                }
              />
              <FormControlLabel
                disableTypography
                sx={{ mb: 0, mr: 0 }}
                control={
                  <Radio
                    edge="end"
                    onChange={() => {
                      setSearchByUuid(true);
                    }}
                    checked={searchByUuid}
                  />
                }
                label={
                  <Typography
                    variant="overline"
                    sx={{ ml: 1, fontWeight: 700 }}
                  >
                    UUID
                  </Typography>
                }
              />
              <TextField
                label="Search article"
                size="small"
                autoComplete="off"
                fullWidth
                value={searchTerm}
                error={error ? true : false}
                helperText={error ? "Failed to fetch" : undefined}
                InputLabelProps={{
                  shrink: true,
                }}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setShowSuggestions(false)}
                InputProps={
                  !searchTerm
                    ? undefined
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={() => {
                                setSearchTerm("");
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                }
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                sx={{ mt: 2 }}
              />
              {showSuggestions && articleSuggestions.length > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    px: 2,
                    left: 0,
                    width: "100%",
                    zIndex: 1,
                  }}
                >
                  <Paper>
                    <List
                      dense
                      disablePadding
                      sx={{ maxHeight: "50vh", overflow: "auto" }}
                    >
                      {articleSuggestions.map((article, i: number) => (
                        <ListItemButton
                          key={i}
                          onMouseDown={() => {
                            setSelectedArticle(null);
                            setSelectedArticle(article);
                          }}
                          sx={{
                            bgcolor:
                              i % 2 === 0 ? "rgba(0,0,0,0.04)" : undefined,
                          }}
                        >
                          <ListItemText>{article.headline}</ListItemText>
                        </ListItemButton>
                      ))}
                    </List>
                  </Paper>
                </Box>
              )}
            </Box>
            {errorArticle && (
              <Typography color="secondary">Failed to load article</Typography>
            )}
            {selectedArticle && detailedArticle && (
              <ListItem
                onClick={() => setModalArticle(selectedArticle)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "rgba(87, 227, 158, 0.2)",
                  cursor: "pointer",
                }}
              >
                <ListItemText
                  primary={
                    <Box component="span">
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {detailedArticle?.topConcept?.title && (
                          <Typography
                            component="span"
                            sx={{
                              fontWeight: 700,
                              color: "#EF6F6C",
                              fontSize: "1rem",
                            }}
                          >
                            {detailedArticle.topConcept.title.toUpperCase()}
                          </Typography>
                        )}
                        <Typography component="span" variant="body2">
                          {moment(detailedArticle.publicationTimestamp).format(
                            "DD MMM"
                          )}
                          <Tooltip title="Unselect article" arrow>
                            <IconButton
                              size="small"
                              color="secondary"
                              edge="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedArticle(null);
                              }}
                              sx={{ ml: 0.5 }}
                            >
                              <Cancel />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>

                      <Typography
                        component="span"
                        sx={{
                          display: "block",
                          fontWeight: 700,
                          fontFamily: "Crete Round",
                          fontSize: "1.2rem",
                          mb: 0.5,
                        }}
                      >
                        {selectedArticle.headline}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box component="span">
                      <Box
                        component="span"
                        sx={{ fontFamily: "Crete Round", fontSize: "1rem" }}
                      >
                        {selectedArticle.preamble}
                      </Box>

                      <Grid component="span" container gap={1} sx={{ pt: 1 }}>
                        {selectedArticle.concepts.map(
                          (concept: any, i: number) => {
                            if (!getTagIcon(concept.type)) {
                              return null;
                            }
                            return (
                              <Chip
                                key={i}
                                variant="outlined"
                                color="primary"
                                label={
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box component="span" sx={{ mr: 0.5 }}>
                                      {getTagIcon(concept.type)}
                                    </Box>
                                    {concept.title}
                                  </Box>
                                }
                                sx={{ fontWeight: 700 }}
                              />
                            );
                          }
                        )}
                      </Grid>
                    </Box>
                  }
                  primaryTypographyProps={{ sx: { fontWeight: 700 } }}
                />
              </ListItem>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

export default ArticleContextComponent;
