import { gql } from "@apollo/client";

const GET_WIKIDATA_P = gql`
  mutation ($title: String, $pqValue: [WikidataPQInput]) {
    getWikidataValueP(title: $title, pqValue: $pqValue) {
      id
      label
      description
    }
  }
`;

export default GET_WIKIDATA_P;
