import { Fragment, useState, useEffect, useRef, useContext } from "react";
import "./ResourceManagement.css";
import { CTX } from "../CMContext";
import { useMutation } from "@apollo/client";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Search, Cancel } from "@mui/icons-material";
import SEARCH_RESOURCES from "../../../mutations/searchResources";
import Picker from "./Picker";
import ResourceList from "./ResourceList";
import Resource from "./Resource";

interface IErrorMessage {
  show: boolean;
  message: string;
}

const ResourceManagement = () => {
  const {
    languages,
    clusters,
    selectedLanguage,
    setSelectedLanguage,
    selectedCluster,
    setSelectedCluster,
  }: any = useContext(CTX);
  const [resourceList, setResourceList] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [loadingError, setLoadingError] = useState<IErrorMessage>({
    show: false,
    message: "",
  });
  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);
  const [searchResources] = useMutation(SEARCH_RESOURCES);

  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    //##### CLICKS TRIGGERS THE SEARCH AFTER CHANGING CLUSTER OR LANGAUGE #####
    const inputField = document.getElementById("resourceSearchInput");
    if (inputField) {
      inputField.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }, [selectedCluster, selectedLanguage]);

  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  useEffect(() => {
    if (loadingError.show) {
      errorTimeout.current && clearTimeout(errorTimeout.current);
      errorTimeout.current = setTimeout(() => {
        setLoadingError({
          show: false,
          message: "",
        });
      }, 4000);
    }
  }, [loadingError]);

  const SearchResourceInput = (
    <TextField
      autoFocus
      autoComplete="off"
      id="resourceSearchInput"
      className="new-tag-field"
      value={searchString}
      type="text"
      label="Search Resources"
      variant="outlined"
      size="small"
      fullWidth
      helperText={
        loadingError.show && (
          <Alert severity="error" sx={{ position: "absolute" }}>
            {loadingError.message}
          </Alert>
        )
      }
      FormHelperTextProps={{
        sx: { position: "absolute", bottom: 0, zIndex: 3 },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: searchString ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                setSearchString("");
                $("#resourceSearchInput") && $("#resourceSearchInput").focus();
              }}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onInput={(e: any) => {
        clearTimeouts(searchTagTimeouts);
        setLoadingError({ show: false, message: "" });
        let inputString = e.target.value;
        setSearchString(inputString);
        if (!inputString) {
          setResourceList([]);
          return;
        }
        let searchTagTimeout = setTimeout(() => {
          setSearchTagTimeouts([]);
          if (!inputString) {
            setResourceList([]);
          } else {
            searchResources({
              variables: {
                freetext: inputString.trim(),
                cluster: selectedCluster?.toUpperCase(),
                language: selectedLanguage,
              },
            })
              .then((data: any) => {
                let foundResources: any = [];
                data &&
                  data.data &&
                  data.data.searchResources &&
                  data.data.searchResources.result &&
                  data.data.searchResources.result.forEach(
                    (resource: Resource) => {
                      foundResources = [...foundResources, resource];
                    }
                  );
                setResourceList(foundResources);
              })
              .catch((err: Error) => {
                setResourceList([]);
                setLoadingError({ show: true, message: err.message });
              });
          }
        }, 750);
        setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      }}
    />
  );

  return (
    <Fragment>
      <Box
        width="100%"
        display="flex"
        bgcolor="#fafafa"
        borderBottom="1px solid rgba(0,0,0,.125)"
        className="button-panel w3-center w3-animate-opacity"
      >
        <Box flexGrow={1} pt={1} pb={1}>
          {SearchResourceInput}
        </Box>

        <Box pt={1} pb={1} width="50%" textAlign="end">
          <Box display="inline-block" mr={1}>
            <Picker
              label="Cluster"
              value={selectedCluster}
              onChange={setSelectedCluster}
              dataList={clusters}
            />
          </Box>
          <Box display="inline-block">
            <Picker
              label="Language"
              value={selectedLanguage}
              onChange={setSelectedLanguage}
              dataList={languages}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "1.7rem", marginRight: "auto", marginLeft: "auto" }}>
        {loadingError.show && (
          <Alert severity="error">
            <AlertTitle sx={{ fontWeight: 700 }}>
              Error loading resources
            </AlertTitle>
            Does {selectedLanguage}-resources exist on cluster{" "}
            {selectedCluster?.toUpperCase()}?
          </Alert>
        )}
        <ResourceList
          list={resourceList}
          listLabel="Resources"
          listType="resource"
          cluster={selectedCluster?.toUpperCase()}
          language={selectedLanguage}
        />
      </Box>
    </Fragment>
  );
};

export default ResourceManagement;
