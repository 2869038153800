import { useState, useMemo, createContext } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import GET_API_DOCS from "../../queries/GET_API_DOCS";
import SET_API_DOCS from "../../mutations/UPDATE_API_DOCS";
import NEW_API_DOC_PAGE from "../../mutations/NEW_API_DOC_PAGE";
import { IPage } from "./types";

export const CTX = createContext(null);

export default function ApiDocsContext(props: any) {
  const hasAdminPrivs: Boolean = Authenticator.hasAuthority(["ADMIN"]);
  const history = useHistory();

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [docPages, setDocPages] = useState<Array<IPage>>([]);
  const [activePage, setActivePage] = useState<any>(undefined);
  const [activePageBeforeChange, setActivePageBeforeChange] =
    useState<any>(undefined);

  const [windowHeight] = useState(window.innerHeight);

  const [getApiDocs, { loading, error }] = useLazyQuery(GET_API_DOCS, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      if (
        data &&
        Object.keys(data).length !== 0 &&
        data.constructor === Object
      ) {
        setDocPages(data.getApiDocs);
      }
    },
    onError: (err: Error) => {
      console.error(err);
    },
  });

  const [newApiDocPage] = useMutation(NEW_API_DOC_PAGE);
  const [updateApiDocs] = useMutation(SET_API_DOCS);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [pageSections, setPageSections] = useState([]);

  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);
  const [nextPath, setNextPath] = useState<string>("");

  const onSaveAlertModal = (nextPath: string) => {
    updateApiDocs({
      variables: {
        name: activePage.name,
        content: activePage.content,
        pubStatus: activePage.pubStatus,
        priv: activePage.priv,
      },
      onCompleted: () => {
        getApiDocs({
          onCompleted: (data: any) => {
            setDocPages(data.getApiDocs);
            setOpenAlertModal(false);
            $("#api-docs").scrollTop(0);
            history.push("/api-docs" + nextPath);
          },
        });
      },
      onError: (err: Error) => {
        console.error(err);
      },
    });
  };

  const onDiscardAlertModal = (nextPath: string) => {
    $("#api-docs").scrollTop(0);
    history.push("/api-docs" + nextPath);
    setOpenAlertModal(false);
  };

  const onCloseAlertModal = () => {
    setOpenAlertModal(false);
  };

  const docPrivs: Array<string> = useMemo(() => {
    return [
      ...new Set<string>(
        docPages
          .map((item: IPage) => item.priv)
          .filter((priv: string) => priv !== null)
      ),
    ];
  }, [docPages]);

  return (
    <CTX.Provider
      value={
        {
          history,
          hasAdminPrivs,
          isEditable,
          setIsEditable,
          docPages,
          setDocPages,
          activePage,
          setActivePage,
          activePageBeforeChange,
          setActivePageBeforeChange,
          windowHeight,
          getApiDocs,
          newApiDocPage,
          updateApiDocs,
          selectedTab,
          setSelectedTab,
          pageSections,
          setPageSections,

          docPrivs,

          loading,
          error,

          nextPath,
          setNextPath,
          openAlertModal,
          setOpenAlertModal,
          onSaveAlertModal,
          onDiscardAlertModal,
          onCloseAlertModal,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}
