import config from "./config.json";
import { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";

interface ILocation {
  name: string;
  latLng: string;
}

interface IInterest {
  conceptID: string;
  count: number;
}
interface IUserProfile {
  id?: string;
  customer?: string;
  active?: boolean;
  description?: string;
  imgUrl?: string;
  interests?: IInterest[];
  location: ILocation;
}

export const CTX = createContext<any>(null);

export default function ContextStore(props: any) {
  const history = useHistory();

  const [openProfileModal, setOpenProfileModal] = useState<boolean>(true);
  const [selectedUserProfile, setSelectedUserProfile] = useState<
    IUserProfile | undefined | null
  >(undefined);
  const [selectedArticle, setSelectedArticle] = useState<any>();
  const [selectedTopic, setSelectedTopic] = useState<string | null>();

  const updateFilterParamsToState = async (urlParams: URLSearchParams) => {
    setSelectedArticle({ uuid: urlParams.get("uuid") });
    setSelectedTopic(urlParams.get("uuid"));
  };

  const previewImage = (currentUrl: string, size?: number) => {
    if (!currentUrl) return undefined;
    const current = new URL(currentUrl);
    const currentParams = current.searchParams;
    const uuid = currentParams.get("uuid");

    const url = new URL(currentUrl.split("?")[0]);
    const params = url.searchParams;
    if (uuid) {
      params.set("uuid", uuid);
      params.set("type", "preview");
      size && params.set("width", size.toString());
    }
    return url?.toString() ?? undefined;
  };

  const navigate = (path: string) => {
    history.push(path);
  };

  const onArticleClick = (uuid: string) => {
    navigate(`${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${uuid}`);
  };

  const onTagClick = (uuid: string) => {
    navigate(`${config.BASE_URL + config.PATHS.TOPIC}?uuid=${uuid}`);
  };

  // path listener
  useEffect(() => {
    updateFilterParamsToState(new URLSearchParams(history.location.search));
    return history.listen((location: any) => {
      updateFilterParamsToState(new URLSearchParams(location.search));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <CTX.Provider
      value={
        {
          history,
          previewImage,

          openProfileModal,
          setOpenProfileModal,

          selectedArticle,
          setSelectedArticle,

          selectedUserProfile,
          setSelectedUserProfile,

          selectedTopic,

          onArticleClick,
          onTagClick,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}
