import config from "./config.json";
import { Fragment, useState, useEffect, useRef, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Paper,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import ArticleImageComponent from "./ArticleImageComponent";
import GET_NEWS_RECOMMENDATIONS from "../../queries/GET_NEWS_RECOMMENDATIONS";
import { CTX } from "./Context";

interface IRelatedArticle {
  uuid: string;
  headline: string;
  preamble: string;
  publicationTimestamp: string;
  imgSrc: string;
}

const LocationRecentNewsComponent = () => {
  const { history, selectedUserProfile } = useContext(CTX);

  const isInit = useRef<boolean>(false);

  const [recommendations, setRecommendations] = useState<
    Array<IRelatedArticle>
  >([]);

  const [getRecommendations] = useLazyQuery(GET_NEWS_RECOMMENDATIONS, {
    onCompleted: (res) => {
      const data = res.getNewsRecommendations;
      const recommendations = data.recommendations;
      setRecommendations(recommendations);
    },
    onError: (e) => {
      console.error("err", e);
    },
  });

  const handleClick = (article: any) => {
    if (window?.getSelection()?.isCollapsed) {
      history.push(
        `${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${article.articleID}`
      );
    }
  };

  const loadMore = () => {
    getRecommendations({
      variables: {
        query: {
          source: "bot",
          returnSize: recommendations.length + 3,
          userID: selectedUserProfile.id,
          signedIn: true,
          config: "imatrics_times_near_you_config",
          location: selectedUserProfile.location.latLng,
          returnFields: [
            "articleID",
            "headline",
            "preamble",
            "imageUrl",
            "publicationTimestamp",
            "topConcept",
            "concepts",
          ],
        },
      },
    });
  };

  useEffect(() => {
    if (!isInit.current) {
      setRecommendations([]);
      getRecommendations({
        variables: {
          query: {
            source: "bot",
            returnSize: 3,
            userID: selectedUserProfile.id,
            signedIn: true,
            config: "imatrics_times_near_you_config",
            location: selectedUserProfile.location.latLng,
            returnFields: [
              "articleID",
              "headline",
              "preamble",
              "imageUrl",
              "publicationTimestamp",
              "topConcept",
              "concepts",
            ],
          },
        },
      });
    }
    isInit.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{ borderLeft: "2px solid #586CBF", overflow: "hidden" }}
    >
      <Box sx={{ py: 1, mx: 2 }}>
        <Typography
          component="span"
          sx={{
            px: 0.5,
            color: "#FFF",
            bgcolor: "#586CBF",
            fontWeight: 700,
          }}
        >
          NEAR YOU
          {selectedUserProfile?.location?.name &&
            ` - ${selectedUserProfile.location.name.toUpperCase()}`}
        </Typography>
      </Box>
      <List disablePadding sx={{ overflow: "auto" }}>
        {recommendations?.length > 0 &&
          recommendations?.map((article: any, i: number) => (
            <Fragment key={i}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleClick(article)}
                  sx={{ px: 2, alignItems: "start" }}
                >
                  {i === 0 ? (
                    <Grid container>
                      <Grid item xs={8} sx={{ pr: 1 }}>
                        {article?.topConcept?.title && (
                          <Typography
                            sx={{
                              color: "#EF6F6C",
                              fontWeight: 700,
                            }}
                          >
                            {article.topConcept.title.toUpperCase()}
                          </Typography>
                        )}
                        <Typography
                          sx={{
                            fontFamily: "Crete Round",
                            fontSize: "1.2rem",
                            fontWeight: 700,
                            mb: 0.5,
                          }}
                        >
                          {article.headline}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Crete Round",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {article.preamble}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <ArticleImageComponent uuid={article.articleID} />
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: "Crete Round",
                        fontSize: "1.2rem",
                        fontWeight: 700,
                        mb: 0.5,
                      }}
                    >
                      {article?.topConcept?.title && (
                        <Typography
                          component="span"
                          sx={{
                            color: "#EF6F6C",
                            fontWeight: 700,
                            mr: 1,
                          }}
                        >
                          {article.topConcept.title.toUpperCase() + ":"}
                        </Typography>
                      )}
                      {article.headline}
                    </Typography>
                  )}
                </ListItemButton>
              </ListItem>
              {i !== recommendations.length - 1 && <Divider variant="middle" />}
            </Fragment>
          ))}
        <Box sx={{ py: 1, display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            size="small"
            startIcon={<ArrowDropDown fontSize="large" />}
            sx={{ textDecoration: "underline" }}
            onClick={loadMore}
          >
            View more
          </Button>
        </Box>
      </List>
    </Paper>
  );
};

export default LocationRecentNewsComponent;
