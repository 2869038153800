import { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import WikidataContent from "./WikidataContent";
import DuplicateCheckerContent from "./DuplicateCheckerContent";

interface Props {
  open: boolean;
  onClose: () => void;
}
const WikidataModal = ({ open, onClose }: Props) => {
  const [showCreator, setShowCreator] = useState(false);
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle style={{ padding: 0 }}>
        <Box
          px={2}
          pt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>Publish to Wikidata</Box>

          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {showCreator ? (
          <WikidataContent />
        ) : (
          <DuplicateCheckerContent setShowCreator={setShowCreator} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WikidataModal;
