import React, { Fragment, useState, useRef, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  Grow,
  MenuList,
  MenuItem,
  Tooltip,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  AccountBalance,
  LocationOn,
  EventNote,
  Link,
  LinkOff,
  Description,
  Grain,
  Cancel,
} from "@mui/icons-material";
import { TiTag, TiThLargeOutline } from "react-icons/ti";

import SEARCH_CONCEPTS from "../../mutations/searchConcepts";

interface BroaderConcept {
  uuid: string;
  type: string;
  title: string;
  broader: string;
}

interface Props {
  disabled: boolean;
  type: string;
  isEntity: boolean;
  broader: string | any;
  setBroader(broader: string): any;
  broaderConcept: BroaderConcept;
  setBroaderConcept(broaderConcept: BroaderConcept): any;
  viewOnly: boolean;
  superAdminMode: boolean;
  isInheriting: boolean;
  setIsInheriting?: (inherit: boolean) => void;
  handleToggleInheritance(broader: string): any;
  className?: any;
  merged?: string;
}

let searchTagTimeout: ReturnType<typeof setTimeout>;

const BroaderInput = (props: Props) => {
  const {
    disabled,
    type,
    isEntity,
    broader,
    setBroader,
    broaderConcept,
    setBroaderConcept,
    viewOnly,
    superAdminMode,
    isInheriting,
    setIsInheriting,
    handleToggleInheritance,
    className,
  } = props;

  const isInitRender = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);

  const [inputValue, setInputValue] = useState(
    broaderConcept?.title ?? broader ?? ""
  );
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [openSuggestionList, setOpenSuggestionList] = useState(false);
  const [focusSuggestionList] = useState(false);
  const [isLoadingBroader, setIsLoadingBroader] = useState<boolean>(false);

  const handleOpenSuggestionList = () => {
    setOpenSuggestionList(true);
  };

  const handleCloseSuggestionList = () => {
    setOpenSuggestionList(false);
  };

  const handleListKeyDownInput = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowDown" || event.key === "Tab") {
      event.preventDefault();
      $("#suggestion-list li:first").focus();
    }
  };

  const handleListKeyDownList = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      if ($("#suggestion-list li:focus").is(":last-child")) {
        $("#suggestion-list li:first").focus();
      } else $("#suggestion-list li:focus").next().focus();
    }
  };

  useEffect(() => {
    setInputValue(broaderConcept?.title ?? broader ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInheriting]);

  useEffect(() => {
    if (!isInitRender?.current || !isInheriting) {
      searchTagTimeout && clearTimeout(searchTagTimeout);
      if (inputValue === "") {
        setBroader("");
        setTagSuggestions([]);
      }
      if (inputValue !== broader) {
        setIsLoadingBroader(true);
        searchTagTimeout = setTimeout(async () => {
          let requests: any = [];
          if (type === "category" || type === "topic") {
            requests.push(
              searchConcepts({
                variables: {
                  title: inputValue,
                  type: "category",
                  draft: false,
                  size: 6,
                },
              })
            );
            if (type === "topic")
              requests.push(
                searchConcepts({
                  variables: {
                    title: inputValue,
                    type: "topic",
                    draft: false,
                    size: 6,
                  },
                })
              );
          } else {
            requests.push(
              searchConcepts({
                variables: {
                  title: inputValue,
                  type: "entity",
                  draft: false,
                  size: 10,
                },
              })
            );
          }
          let concepts: any = [];
          await Promise.all(requests)
            .then((res) => {
              res.forEach((data: any) => {
                data?.data?.searchConcepts?.result.forEach((concept: any) => {
                  concepts.push(concept);
                });
              });
            })
            .finally(() => setIsLoadingBroader(false));
          if (
            concepts.filter((tag: any) => tag.uuid === inputValue).length === 1
          ) {
            setTagSuggestions([]);
            setBroaderConcept(
              concepts.filter((tag: any) => tag.uuid === inputValue)[0]
            );
          } else {
            setTagSuggestions(concepts);
          }
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    isInitRender.current = false;
  }, []);

  return (
    <Box width="100%">
      <TextField
        id={"search-tag-input-" + type}
        autoComplete="off"
        disabled={disabled}
        type="text"
        ref={inputRef}
        onSelectCapture={handleOpenSuggestionList}
        onBlur={() =>
          setTimeout(() => {
            setOpenSuggestionList(false);
          }, 100)
        }
        label="Broader"
        variant="outlined"
        size="small"
        fullWidth
        value={inputValue}
        className={className}
        helperText={
          <Box
            id="broader-helper"
            component="span"
            fontWeight="bold"
            color="#777"
            onClick={() =>
              setTimeout(() => {
                setOpenSuggestionList(false);
              }, 100)
            }
          >
            {!broader || !broaderConcept ? null : isLoadingBroader ? (
              "Loading..."
            ) : broaderConcept?.uuid ? (
              <span>
                <span>{broaderConcept.uuid}</span>
                {broaderConcept.type && (
                  <Box component="span" ml={0.5}>
                    ({broaderConcept.type})
                  </Box>
                )}
              </span>
            ) : (
              "Invalid broader"
            )}
          </Box>
        }
        InputLabelProps={{
          shrink: true,
        }}
        aria-controls={openSuggestionList ? "suggestion-list" : undefined}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <Fragment>
              <InputAdornment position="end">
                {inputValue && !disabled && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setInputValue("");
                      setBroader("");
                      setTagSuggestions([]);
                      $("#search-broader-input-" + type) &&
                        $("#search-broader-input-" + type).focus();
                    }}
                  >
                    <Cancel />
                  </IconButton>
                )}
              </InputAdornment>
              {superAdminMode && !viewOnly && !isEntity && (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      isInheriting
                        ? "Deactivate inheritance"
                        : "Activate inheritance"
                    }
                    placement="top"
                  >
                    <IconButton
                      size="small"
                      color={isInheriting ? "success" : "primary"}
                      onClick={() => {
                        handleToggleInheritance("broader");
                      }}
                    >
                      {isInheriting ? <Link /> : <LinkOff />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            </Fragment>
          ),
        }}
        onClick={() => {
          handleOpenSuggestionList();
        }}
        onKeyDown={handleListKeyDownInput}
      />

      {!disabled && tagSuggestions.length > 0 && (
        <Box>
          <Popper
            id="suggestion-dropdown"
            style={{
              width: "calc(100% - 3.3rem)",
              marginBottom: "0.6rem",
            }}
            open={openSuggestionList}
            anchorEl={inputRef.current}
            transition
            placement="bottom-start"
            disablePortal={true}
            modifiers={[
              {
                name: "flip",
                enabled: false,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: false,
                options: {
                  altAxis: false,
                  altBoundary: false,
                  tether: false,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper className="popover-index dropdown-transition">
                  <ClickAwayListener onClickAway={handleCloseSuggestionList}>
                    {type === "category" || type === "topic" ? (
                      <Box display="flex">
                        <MenuList
                          dense
                          style={{
                            flexGrow: 1,
                            width: "100%",
                            borderLeft: "1px solid rgba(0,0,0,0.125)",
                            borderRight: "1px solid rgba(0,0,0,0.125)",
                          }}
                          id="suggestion-list-categories"
                          autoFocusItem={focusSuggestionList}
                          onKeyDown={handleListKeyDownList}
                        >
                          <MenuItem
                            disabled
                            style={{
                              borderBottom: "1px solid rgba(0,0,0,0.125)",
                              maxHeight: "32.5px",
                            }}
                          >
                            <Typography variant="overline">
                              Categories
                            </Typography>
                          </MenuItem>

                          {tagSuggestions
                            .filter(
                              (concept: any) => concept.type === "category"
                            )
                            .map((item: any) => (
                              <Tooltip
                                title={
                                  <Box minWidth="140px">
                                    <Box fontWeight="bold">
                                      {item.title} ({item.type})
                                    </Box>
                                    {item.shortDescription ? (
                                      <Box mt={0.5}>
                                        {item.shortDescription}
                                      </Box>
                                    ) : null}

                                    {item.uuid ? (
                                      <Box mt={0.75}>{item.uuid}</Box>
                                    ) : null}
                                  </Box>
                                }
                                placement="bottom-start"
                                key={item.uuid}
                                arrow
                              >
                                <MenuItem
                                  dense
                                  style={{
                                    borderBottom: "1px solid rgba(0,0,0,0.125)",
                                    maxHeight: "32.5px",
                                  }}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    setIsInheriting && setIsInheriting(false);
                                    setTagSuggestions([]);
                                    setInputValue(item.title || "");
                                    setBroader(item.uuid || "");
                                    setBroaderConcept({
                                      uuid: item.uuid || "",
                                      type: item.type || "",
                                      title: item.title || "",
                                      broader: item.broader || "",
                                    });
                                  }}
                                >
                                  <Box>
                                    <Box className="font-weight-bold">
                                      <Typography variant="inherit">
                                        <Description
                                          fontSize="small"
                                          className="mr-1"
                                        />
                                        {item.title}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </MenuItem>
                              </Tooltip>
                            ))}
                        </MenuList>

                        {type === "topic" && (
                          <MenuList
                            id="suggestion-list-topic"
                            dense
                            style={{ flexGrow: 1, width: "100%" }}
                            autoFocusItem={focusSuggestionList}
                            onKeyDown={handleListKeyDownList}
                          >
                            <MenuItem
                              disabled
                              style={{
                                borderBottom: "1px solid rgba(0,0,0,0.125)",
                                maxHeight: "32.5px",
                              }}
                            >
                              <Typography variant="overline">Topics</Typography>
                            </MenuItem>
                            {tagSuggestions
                              .filter(
                                (concept: any) => concept.type === "topic"
                              )
                              .map((item: any) => (
                                <Tooltip
                                  title={
                                    <Box minWidth="140px">
                                      <Box fontWeight="bold">
                                        {item.title} ({item.type})
                                      </Box>
                                      {item.shortDescription ? (
                                        <Box mt={0.5}>
                                          {item.shortDescription}
                                        </Box>
                                      ) : null}
                                      {item.uuid ? (
                                        <Box mt={0.75}>{item.uuid}</Box>
                                      ) : null}
                                    </Box>
                                  }
                                  placement="bottom-start"
                                  key={item.uuid}
                                  arrow
                                >
                                  <MenuItem
                                    dense
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0,0,0,0.125)",
                                      maxHeight: "32.5px",
                                    }}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setTagSuggestions([]);
                                      setInputValue(item.title || "");
                                      setBroader(item.uuid || "");
                                      setBroaderConcept(
                                        {
                                          uuid: item.uuid,
                                          type: item.type,
                                          title: item.title,
                                          broader: item.broader,
                                        } || {}
                                      );
                                    }}
                                  >
                                    <Box>
                                      <Box className="font-weight-bold">
                                        <Typography variant="inherit">
                                          {item.type === "x-im/category" ||
                                          item.type === "category" ? (
                                            <Description
                                              fontSize="small"
                                              className="mr-1"
                                            />
                                          ) : item.type === "x-im/topic" ||
                                            item.type === "topic" ? (
                                            <Grain
                                              fontSize="small"
                                              className="mr-1"
                                            />
                                          ) : (
                                            <TiTag
                                              fontSize="small"
                                              className="mr-1"
                                            />
                                          )}
                                          {item.title}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </MenuItem>
                                </Tooltip>
                              ))}
                          </MenuList>
                        )}
                      </Box>
                    ) : (
                      <MenuList
                        dense
                        style={{
                          flexGrow: 1,
                          width: "100%",
                          borderLeft: "1px solid rgba(0,0,0,0.125)",
                          borderRight: "1px solid rgba(0,0,0,0.125)",
                        }}
                        id="suggestion-list-entities"
                        autoFocusItem={focusSuggestionList}
                        onKeyDown={handleListKeyDownList}
                      >
                        <MenuItem
                          disabled
                          style={{
                            borderBottom: "1px solid rgba(0,0,0,0.125)",
                            maxHeight: "32.5px",
                          }}
                        >
                          <Typography variant="overline">Entities</Typography>
                        </MenuItem>

                        {tagSuggestions.map((item: any) => (
                          <Tooltip
                            title={
                              <Box minWidth="140px">
                                <Box fontWeight="bold">
                                  {item.title} ({item.type})
                                </Box>
                                {item.shortDescription ? (
                                  <Box mt={0.5}>{item.shortDescription}</Box>
                                ) : null}
                                {item.uuid ? (
                                  <Box mt={0.75}>{item.uuid}</Box>
                                ) : null}
                              </Box>
                            }
                            placement="bottom-start"
                            key={item.uuid}
                            arrow
                          >
                            <MenuItem
                              dense
                              style={{
                                borderBottom: "1px solid rgba(0,0,0,0.125)",
                                maxHeight: "32.5px",
                              }}
                              onClick={(e: any) => {
                                e.preventDefault();
                                setTagSuggestions([]);
                                setInputValue(item.title || "");
                                setBroader(item.uuid || "");
                                setBroaderConcept({
                                  uuid: item.uuid || "",
                                  type: item.type || "",
                                  title: item.title || "",
                                  broader: item.broader || "",
                                });
                              }}
                            >
                              <Box>
                                <Box fontWeight="bold">
                                  <Typography variant="inherit">
                                    {item.type === "x-im/person" ||
                                    item.type === "person" ? (
                                      <PersonOutlineOutlined
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    ) : item.type === "x-im/organisation" ||
                                      item.type === "organisation" ? (
                                      <AccountBalance
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    ) : item.type === "x-im/place" ||
                                      item.type === "place" ? (
                                      <LocationOn
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    ) : item.type === "event" ? (
                                      <EventNote
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    ) : item.type === "object" ? (
                                      <TiThLargeOutline
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    ) : (
                                      <TiTag
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                    )}
                                    {item.title}
                                  </Typography>
                                </Box>
                              </Box>
                            </MenuItem>
                          </Tooltip>
                        ))}
                      </MenuList>
                    )}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};

export default BroaderInput;
