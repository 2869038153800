import ReactCountryFlag from "react-country-flag";
import { useContext } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { PRIVILEGES } from "../PrivateRoute/PrivateRoute";
import { CTX as CMCTX } from "./CMContext";
import { Box, IconButton, Tooltip, Badge } from "@mui/material";
import { withStyles } from "@mui/styles";
import { FaWikipediaW, FaDatabase, FaMapMarkedAlt } from "react-icons/fa";

import WikidataModal from "./WikidataCreator/WikidataModal";

const styles = () => ({
  customBadge: {
    backgroundColor: (props: any) => props.color,
    color: "white",
    fontSize: "1.25rem",
    fontWeight: 700,
  },
});

const SimpleBadge = (props: any) => {
  const { classes, children, badgeContent, anchorOrigin } = props;
  return (
    <Badge
      classes={{ badge: classes.customBadge }}
      badgeContent={badgeContent}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </Badge>
  );
};
const StyledBadge = withStyles(styles)(SimpleBadge);

/** Method to convert country code to the actual code in order to render the country flag */
const convertCountryCode: any = (country: string) => {
  if (!country) return "";
  if (country === "sv") country = "se";
  if (country === "en") country = "us";
  if (country === "da") country = "dk";
  return country.toUpperCase();
};

const availableLanguages = [
  "sv",
  "en",
  "da",
  "no",
  "de",
  "nl",
  "fi",
  "fr",
  "es",
  "pt",
];

const flagStyle = {
  width: "1.5em",
  height: "1.5em",
  objectFit: "cover",
  borderRadius: "50%",
};

const buttonStyle = {
  fontSize: "1.75em",
  color: "rgb(55, 55, 55)",
  backgroundColor: "#fff",
  border: "0.4px solid rgba(0,0,0,0.25)",
  marginLeft: "0.25em",
  padding: "0.2em",
};

interface Props {
  language: string;
  global: string;
  wikipedia: string;
  wikidata: string;
  osm: string;
  concept?: any;
  createConceptMode?: boolean;
}

const LinkButtons = (props: Props) => {
  const {
    language,
    global,
    wikipedia,
    wikidata,
    osm,
    concept,
    createConceptMode,
  } = props;
  const countryCode: string = convertCountryCode(language);
  const { wikiMode, setWikiMode, setWikiConcept }: any = useContext(CMCTX);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {wikiMode && (
        <WikidataModal open={wikiMode} onClose={() => setWikiMode(false)} />
      )}
      <Box>
        {wikipedia && (
          <Tooltip title={"Visit Wikipedia"} placement="top">
            <IconButton
              color="primary"
              className="interactive mr-1"
              size="small"
              style={buttonStyle}
              href={
                wikipedia.startsWith("https://")
                  ? wikipedia
                  : "https://" + wikipedia
              }
              target="_blank"
            >
              <FaWikipediaW />
            </IconButton>
          </Tooltip>
        )}
        {wikidata && (
          <Tooltip title="Visit Wikidata" placement="top">
            <IconButton
              className="interactive mr-1"
              size="small"
              style={buttonStyle}
              href={wikidata}
              target="_blank"
            >
              <FaDatabase />
            </IconButton>
          </Tooltip>
        )}
        {osm && (
          <Tooltip title={"Visit OpenStreetMap"} placement="top">
            <IconButton
              className="interactive mr-1"
              size="small"
              style={buttonStyle}
              href={osm}
              target="_blank"
            >
              <FaMapMarkedAlt />
            </IconButton>
          </Tooltip>
        )}
        {Authenticator.hasAuthority([PRIVILEGES.CREATE_WIKIDATA]) &&
          !createConceptMode &&
          !wikidata &&
          concept.type !== "category" &&
          concept.type !== "topic" && (
            <Tooltip title={"Publish to Wikidata"} placement="top">
              <IconButton
                className="interactive mr-1"
                size="medium"
                style={{ backgroundColor: "inherit", color: "#000" }}
                onClick={() => {
                  setWikiConcept(concept);
                  setWikiMode && setWikiMode(true);
                }}
              >
                <StyledBadge
                  color="#56E39F"
                  badgeContent="+"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <FaDatabase fontSize="1.75rem" />
                </StyledBadge>
              </IconButton>
            </Tooltip>
          )}
      </Box>

      <Box display="flex" alignItems="center">
        {availableLanguages.includes(language) && (
          <Box component="a" mr={1}>
            <ReactCountryFlag
              id="country-flag"
              countryCode={countryCode}
              svg
              style={flagStyle}
              title={countryCode}
            />
          </Box>
        )}
        {language && (
          <Box component="a" fontWeight="bold">
            {language.toUpperCase()}
          </Box>
        )}
        {global && (
          <Box component="a" ml={0.75}>
            (global)
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LinkButtons;
