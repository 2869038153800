import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Cancel, Edit, Search } from "@mui/icons-material";

import GET_USER_PROFILES from "../../queries/GET_USER_PROFILES";
//import GET_PROFILE_INTERESTS from "../../queries/GET_PROFILE_INTERESTS";

import CreateProfileModal from "./CreateProfileModal";
import EditProfileModal from "./EditProfileModal";

interface ILocation {
  name: string;
  latLng: string;
}

interface IUserProfile {
  id?: string;
  customer?: string;
  active?: boolean;
  description?: string;
  imgUrl?: string;
  interests?: string[];
  location: ILocation;
}

interface IProps {
  endpoint: any | null;
  userProfile: any | null;
  onChange: (user: any | null) => void;
}

const UserComponent = (props: IProps) => {
  const { endpoint, userProfile, onChange } = props;

  const [userProfiles, setUserProfiles] = useState<IUserProfile[]>([]);
  const [searchByUuid, setSearchByUuid] = useState<boolean>(false);
  const [searchId, setSearchId] = useState<string>("");
  const [openCreateProfileModal, setOpenCreateProfileModal] =
    useState<boolean>(false);
  const [modalProfile, setModalProfile] = useState<IUserProfile | null>(null);

  const { loading, error } = useQuery(GET_USER_PROFILES, {
    variables: { customer: endpoint?.settingsName },
    onCompleted: (data) => {
      if (data?.getUserProfiles?.length > 0) {
        setUserProfiles(data.getUserProfiles);
      } else {
        onChange(null);
        setUserProfiles([]);
      }
    },
    onError: () => {
      onChange(null);
      setUserProfiles([]);
    },
    skip: !endpoint?.settingsName,
  });

  /*
  useQuery(GET_PROFILE_INTERESTS, {
    variables: { userID: userProfile?.id },
    onCompleted: (data) => {
      setSelectedUserProfile((prev: IUserProfile | any) => {
        return {
          ...prev,
          interests: data?.getProfileInterests?.interests ?? [],
        };
      });
    },
    onError: (e) => {},
    skip: !userProfile?.id,
  });
  */

  useEffect(() => {
    if (searchByUuid) {
      setSearchId("");
    } else {
      onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByUuid]);

  return (
    <Box>
      <LinearProgress sx={{ visibility: !loading ? "hidden" : "visible" }} />
      <Paper elevation={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            bgcolor: "rgba(0,0,0,0.075)",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <Typography variant="overline" sx={{ fontWeight: 700 }}>
            User profile ({userProfiles.length})
          </Typography>
          <Tooltip title="Add new profile" arrow>
            <IconButton
              size="small"
              edge="end"
              onClick={() => {
                setOpenCreateProfileModal(true);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Box>

        <CreateProfileModal
          open={openCreateProfileModal}
          handleClose={() => {
            setOpenCreateProfileModal(false);
          }}
        />
        <EditProfileModal
          profile={modalProfile}
          open={modalProfile ? true : false}
          handleClose={() => {
            setModalProfile(null);
          }}
        />

        <Accordion disableGutters expanded={!searchByUuid}>
          <AccordionSummary
            expandIcon={null}
            sx={{
              margin: "0 0",
              bgcolor: "rgba(0, 0, 0, 0.04)",
            }}
          >
            <FormControlLabel
              sx={{ mb: 0, mr: 3 }}
              control={
                <Radio
                  edge="end"
                  onChange={() => {
                    setSearchByUuid(false);
                  }}
                  checked={!searchByUuid}
                />
              }
              label={
                <Typography variant="overline" sx={{ ml: 1, fontWeight: 700 }}>
                  List
                </Typography>
              }
            />
            <FormControlLabel
              sx={{ mb: 0, mr: 0 }}
              control={
                <Radio
                  edge="end"
                  onChange={() => {
                    setSearchByUuid(true);
                  }}
                  checked={searchByUuid}
                />
              }
              label={
                <Typography variant="overline" sx={{ ml: 1, fontWeight: 700 }}>
                  Profile ID
                </Typography>
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List
              disablePadding
              sx={{ maxHeight: "calc(100vh - 294px)", overflow: "auto" }}
            >
              <ListItem
                dense
                onClick={() => {
                  onChange(null);
                }}
              >
                <Radio edge="start" checked={!userProfile} />
                <ListItemAvatar>
                  <Avatar alt={""} src={undefined} variant="square" />
                </ListItemAvatar>
                <ListItemText
                  primary="NONE"
                  primaryTypographyProps={{
                    sx: {
                      overflowWrap: "break-word",
                      fontWeight: 700,
                    },
                  }}
                />
              </ListItem>
              {error ? (
                <Alert severity="error">Failed to fetch profiles</Alert>
              ) : userProfiles.length < 1 ? (
                <Alert severity="info">No profiles found</Alert>
              ) : (
                userProfiles.map((user) => (
                  <ListItem
                    key={user.id}
                    secondaryAction={
                      <IconButton
                        size="small"
                        color="primary"
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModalProfile(user);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    }
                    onClick={() => {
                      onChange(user);
                    }}
                  >
                    <Radio
                      edge="start"
                      checked={userProfile?.id === user.id}
                      inputProps={{ "aria-labelledby": user.id }}
                    />
                    <ListItemAvatar>
                      <Avatar
                        alt={user.id}
                        src={user.imgUrl}
                        variant="square"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.id}
                      primaryTypographyProps={{
                        sx: {
                          overflowWrap: "break-word",
                          fontWeight: 700,
                        },
                      }}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </AccordionDetails>
        </Accordion>
        {searchByUuid && (
          <Box>
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                searchId && onChange({ id: searchId });
              }}
              style={{ padding: 16 }}
            >
              <TextField
                autoComplete="off"
                size="small"
                label="Profile ID"
                value={searchId}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton size="small" edge="start" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchId && (
                        <IconButton
                          size="small"
                          edge="end"
                          onClick={() => {
                            setSearchId("");
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearchId(e.target.value);
                }}
              />
            </form>
            {userProfile && (
              <ListItem
                onClick={() => {}}
                secondaryAction={
                  <Tooltip title="Unselect profuke" arrow>
                    <IconButton
                      size="small"
                      color="secondary"
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(null);
                      }}
                      sx={{ ml: 0.5 }}
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                }
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "rgba(87, 227, 158, 0.2)",
                  cursor: "pointer",
                }}
              >
                <ListItemText
                  primary={userProfile.id}
                  primaryTypographyProps={{ fontWeight: 700 }}
                />
              </ListItem>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default UserComponent;
