import CONFIG from "./config.json";
import { useState, useEffect, useContext } from "react";
import { CTX } from "./Context";
import { useQuery, useMutation } from "@apollo/client";
import { FetchResult } from "./TypeDefs";
import { Box, Paper, Typography, Skeleton, Divider } from "@mui/material";

import GET_POPULAR_ARTICLES from "../../queries/GET_POPULAR_ARTICLES";
import GET_CONCEPT from "../../mutations/getConcept";

import ArticleItem from "./ArticleItem";

const TopicPage = () => {
  const { onArticleClick, selectedTopic } = useContext(CTX);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [concept, setConcept] = useState<any>();
  const [raw, setRaw] = useState<FetchResult>();

  const { loading, error } = useQuery(GET_POPULAR_ARTICLES, {
    variables: {
      query: {
        freetext: "",
        //page_size: -1,
        channels: [CONFIG.CHANNEL],
        concept_filter: [selectedTopic],
        scope: CONFIG.SCOPE.ID,
        offset: 0,
        include_extra_fields: true,
        min_pub_date: null,
        max_pub_date: null,
      },
    },
    skip: !selectedTopic,
    onCompleted: (res) => {
      const data = res.getPopularArticles;
      setRaw(data);
    },
    onError: (e) => {
      console.error("err", e);
    },
  });

  const [getConcept] = useMutation(GET_CONCEPT, {
    onCompleted: (res) => {
      setIsLoading(false);
      let data;
      try {
        data = res.getConcept.result[0];
      } catch {}
      setConcept(data);
    },
    onError: () => setIsLoading(false),
  });

  useEffect(() => {
    setIsLoading(true);
    getConcept({
      variables: {
        uuid: selectedTopic,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopic]);

  if (isLoading || loading) {
    return (
      <Paper
        sx={{
          flexGrow: 1,
          p: 6,
        }}
      >
        <Typography variant="h4">
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="90%" />
          <Skeleton width="90%" />
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: 6,
      }}
    >
      {!concept && error ? (
        <Typography variant="h4" color="red">
          Topic not found
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" color="error">
            We apologize for the inconvenience. The topic page feature is
            currently being developed and is not supported yet.
          </Typography>
          <Typography variant="h4">{concept?.title}</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {concept?.longDescription || concept?.shortDescription}
          </Typography>
          <Divider sx={{ my: 4 }} />

          <Box>
            <Typography variant="h5">Articles</Typography>
            <Typography variant="subtitle2">
              {raw?.total_result_count} results
            </Typography>

            {raw?.result.map((article) => (
              <ArticleItem
                article={article}
                key={article.uuid}
                onClick={() => {
                  onArticleClick && onArticleClick(article.uuid);
                }}
              />
            ))}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default TopicPage;
