import { useState, useEffect, useRef, useContext } from "react";
import { useMutation } from "@apollo/client";
import { CTX } from "src/utils/ContextStore";
import ReactJson from "react-json-view";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import InterestChip from "../iMatricsTimes/InterestChip";

import SEARCH_CONCEPTS from "src/mutations/searchConcepts";
import CREATE_PROFILE from "src/mutations/CREATE_PROFILE";

interface ILocation {
  name: string;
  latLng: string;
}

interface IUserProfile {
  id: string;
  customer: string;
  active?: boolean;
  description?: string;
  imgUrl?: string;
  interests: string[];
  location: ILocation;
}

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const CreateProfileModal = (props: IProps) => {
  const { open, handleClose } = props;
  const { setShowSnackbar, setSnackbarMessage, setSnackbarError }: any =
    useContext(CTX);

  const searchTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [newProfile, setNewProfile] = useState<IUserProfile>({
    id: "",
    customer: "",
    active: false,
    description: "",
    imgUrl: "",
    interests: [],
    location: {
      name: "",
      latLng: "",
    },
  });
  const [noNameError, setNoNameError] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [
    searchConcepts,
    { error: errorSuggestions, loading: loadingSuggestions },
  ] = useMutation(SEARCH_CONCEPTS, {
    onCompleted: (data) => {
      setSearchResults(data.searchConcepts.result);
    },
    onError: (error) => {
      setSearchResults([]);
    },
  });

  const [createProfile] = useMutation(CREATE_PROFILE, {
    variables: { query: JSON.stringify(newProfile) },
    onCompleted: (data) => {
      setShowSnackbar(true);
      setSnackbarError(false);
      setSnackbarMessage("Profile created successfully");
      handleClose();
    },
    onError: (err) => {
      setShowSnackbar(true);
      setSnackbarError(true);
      setSnackbarMessage("Failed to create profile");
    },
  });

  const onEdit = (e: any) => {
    setNewProfile({ ...newProfile, location: e.updated_src });
  };

  const onAdd = (e: any) => {
    setNewProfile({ ...newProfile, location: e.updated_src });
  };

  const onDelete = (e: any) => {
    setNewProfile({ ...newProfile, location: e.updated_src });
  };

  const onSubmit = () => {
    if (!newProfile.id) {
      setNoNameError(true);
      setShowSnackbar(true);
      setSnackbarMessage("Failed to create profile");
      setSnackbarError(true);
      return;
    }
    setNoNameError(false);
    createProfile();
    handleClose();
  };

  useEffect(() => {
    clearTimeout(searchTimeout.current);
    if (!searchString) {
      setSearchResults([]);
      return;
    }
    searchTimeout.current = setTimeout(() => {
      searchConcepts({
        variables: {
          title: searchString,
          type: "topic",
          draft: false,
          size: 10,
        },
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    if (!open) {
      setNewProfile({
        id: "",
        customer: "",
        active: false,
        description: "",
        imgUrl: "",
        interests: [],
        location: {
          name: "",
          latLng: "",
        },
      });
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 12, right: 12 }}
      >
        <Close />
      </IconButton>
      <DialogTitle>Create new profile</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              autoComplete="off"
              autoFocus
              label="ID"
              size="small"
              fullWidth
              error={noNameError}
              value={newProfile.id}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setNoNameError(false);
                setNewProfile({
                  ...newProfile,
                  id: e.target.value,
                });
              }}
            />

            <TextField
              autoComplete="off"
              label="Active"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              value={newProfile.active ? "On" : "Off"}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={newProfile.active ? "Toggle off" : "Toggle on"}
                      placement="top"
                    >
                      <Switch
                        edge="end"
                        checked={newProfile.active}
                        onChange={() => {
                          setNewProfile({
                            ...newProfile,
                            active: !newProfile.active,
                          });
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Autocomplete
              multiple
              options={searchResults}
              getOptionLabel={(option: any) => option.title}
              loading={loadingSuggestions}
              value={newProfile.interests}
              freeSolo
              size="small"
              limitTags={2}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((interest: any, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <InterestChip key={index} uuid={interest} {...tagProps} />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  error={errorSuggestions ? true : false}
                  label="Interests"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              )}
              onChange={(e, newValue) => {
                setNewProfile({
                  ...newProfile,
                  interests: newValue.map(
                    (interest: any) => interest.uuid ?? interest
                  ),
                });
                setSearchString("");
                setSearchResults([]);
              }}
            />
            <Box>
              <Typography sx={{ fontWeight: 700, fontSize: 12 }}>
                Location
              </Typography>
              <ReactJson
                src={newProfile.location}
                theme="summerfruit"
                iconStyle="triangle"
                name={false}
                displayObjectSize={false}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                indentWidth={4}
                collapsed={2}
                onEdit={onEdit}
                onAdd={onAdd}
                onDelete={onDelete}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <TextField
              autoComplete="off"
              label="Description"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={newProfile.description}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setNewProfile({ ...newProfile, description: e.target.value });
              }}
            />
            <TextField
              autoComplete="off"
              label="Image URL"
              size="small"
              fullWidth
              value={newProfile.imgUrl}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setNewProfile({ ...newProfile, imgUrl: e.target.value });
              }}
            />

            <Box sx={{ display: "flex", gap: 1 }}>
              <Box
                sx={{
                  width: "50%",
                  borderRadius: "4px",
                  overflow: "hidden",
                  border: "1px solid rgba(0,0,0,0.125)",
                }}
              >
                {newProfile?.imgUrl && (
                  <img
                    alt=""
                    src={newProfile?.imgUrl}
                    style={{
                      width: "100%",
                      aspectRatio: 16 / 10,
                      objectFit: "cover",
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  gap: 1,
                }}
              >
                <Button
                  disabled
                  variant="contained"
                  size="small"
                  fullWidth
                  onClick={() => {
                    newProfile && setNewProfile({ ...newProfile, imgUrl: "" });
                  }}
                >
                  Upload image
                </Button>
                <Button
                  disabled={!newProfile?.imgUrl}
                  variant="contained"
                  color="secondary"
                  size="small"
                  fullWidth
                  onClick={() => {
                    newProfile && setNewProfile({ ...newProfile, imgUrl: "" });
                  }}
                >
                  Remove image
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled variant="contained" onClick={onSubmit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProfileModal;
