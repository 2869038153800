import { gql } from "@apollo/client";

const GET_WIKIDATA_Q = gql`
  mutation ($title: String) {
    getWikidataValueQ(title: $title) {
      id
      label
      description
    }
  }
`;

export default GET_WIKIDATA_Q;
