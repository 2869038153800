import gql from "graphql-tag";
const SEARCH_ARTICLE = gql`
  query ($searchString: String!, $returnSize: Int, $uuid: Boolean) {
    searchArticleQuery(
      query: $searchString
      returnSize: $returnSize
      uuid: $uuid
    ) {
      preamble
      dateline
      concepts {
        title
        type
        uuid
      }
      body
      groupAndGroupCluster
      uuid
      headline
      newspaper
      channel
      minuteRead
      published
      language
      authors
      publicationTimestamp
    }
  }
`;

export default SEARCH_ARTICLE;
