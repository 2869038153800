import { useEffect, useMemo, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";
import ReactJson from "react-json-view";
import { Box, Tooltip, IconButton, Alert, LinearProgress } from "@mui/material";
import { Refresh } from "@mui/icons-material";

import GET_CUSTOMER_SETTINGS from "src/queries/GET_CUSTOMER_SETTINGS";

import DatasetPicker from "../PanelComponents/DatasetPicker";

const CustomerSettingsComponent = () => {
  const { selectedEndpoints }: any = useContext(CTX);

  const selectedCustomerSettings: Array<string> = useMemo(() => {
    if (selectedEndpoints && selectedEndpoints.length > 0) {
      return selectedEndpoints.map((endpoint: any) => {
        return endpoint.settingsName;
      });
    }
  }, [selectedEndpoints]);

  const [getCustomerSettings, { data, loading, error }] = useLazyQuery(
    GET_CUSTOMER_SETTINGS,
    {
      fetchPolicy: "cache-and-network",
      variables: { settingNames: selectedCustomerSettings },
      onCompleted: (data) => {},
      onError: () => {},
    }
  );

  useEffect(() => {
    getCustomerSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box
        sx={{
          p: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 2,
          }}
        >
          <DatasetPicker multiple sx={{ maxWidth: 300 }} />

          <Box sx={{ display: "flex", ml: "auto", gap: 1 }}>
            <Tooltip title="Refresh table">
              <IconButton
                onClick={() => {
                  getCustomerSettings();
                }}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box sx={{ height: "100%", overflow: "auto" }}>
        {error ? (
          <Alert severity="error" sx={{ width: "fit-content", m: 2 }}>
            {error.message || "Failed to fetch customer settings"}
          </Alert>
        ) : (
          <Box sx={{ position: "relative" }}>
            <LinearProgress
              color="primary"
              variant={loading ? "indeterminate" : "determinate"}
              value={0}
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                visibility: loading ? "visible" : "hidden",
                zIndex: 1,
              }}
            />
            {data?.getCustomerSettings && (
              <ReactJson
                src={JSON.parse(data.getCustomerSettings)}
                theme="summerfruit" //summerfruit:inverted
                iconStyle="triangle"
                name={false}
                displayObjectSize={false}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                indentWidth={4}
                collapsed={2}
                //onEdit={onEdit}
                //onAdd={onAdd}
                //onDelete={onDelete}
                style={{ minHeight: "500px", padding: "8px" }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomerSettingsComponent;
