const IMATRICS_WEBDEMO_USER_KEY = "imatrics-webdemo-signed-in-user";
const IMATRICS_WEBDEMO_USER_LAST_ACTIVITY = "imatrics-webdemo-last-activity";

export const removeSignedInUser = () => {
  window.localStorage.removeItem(IMATRICS_WEBDEMO_USER_KEY);
};

export const setSignedInUser = (user: any) => {
  if (user == null) {
    removeSignedInUser();
    return;
  }
  const userReduced = {
    username: user.data.login.username,
    token: user.data.login.token,
    privileges: user.data.login.privileges,
    expiresAt: user.data.login.expiresAt,
  };
  window.localStorage.setItem(
    IMATRICS_WEBDEMO_USER_KEY,
    JSON.stringify(userReduced)
  );
};

export const setSignedUserExplicit = (user: any) => {
  window.localStorage.setItem(IMATRICS_WEBDEMO_USER_KEY, JSON.stringify(user));
};

export const getSignedInUser = () => {
  const userString = window.localStorage.getItem(IMATRICS_WEBDEMO_USER_KEY);
  if (userString && userString.length > 0) {
    return JSON.parse(userString);
  }
  return null;
};

export const setLastAuthenticationActivity = (moment: string) => {
  window.localStorage.setItem(IMATRICS_WEBDEMO_USER_LAST_ACTIVITY, moment);
};

export const getLastAuthenticationActivity = () => {
  return window.localStorage.getItem(IMATRICS_WEBDEMO_USER_LAST_ACTIVITY);
};
