import { Fragment } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Done, Save } from "@mui/icons-material";

interface Props {
  disabled?: boolean;
  isLoading?: boolean;
  confirmed?: boolean;
  onClick?: any;
}

const ConfirmButton = (props: Props) => {
  const { disabled, isLoading, confirmed, onClick } = props;
  return (
    <Fragment>
      {!confirmed ? (
        <Button
          disabled={disabled || isLoading}
          fullWidth
          size="small"
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Box mr={1}>Saving</Box>
              <CircularProgress color="inherit" size="1rem" />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Box mr={1}>Save</Box>
              <Save fontSize="small" />
            </Box>
          )}
        </Button>
      ) : (
        <Button
          disabled
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box mr={1}>Saved</Box>
            <Done fontSize="medium" />
          </Box>
        </Button>
      )}
    </Fragment>
  );
};

export default ConfirmButton;
