import gql from "graphql-tag";
const editConcept = gql`
  mutation (
    $superAdmin: Boolean
    $extendedAdmin: Boolean
    $uuid: String!
    $title: String
    $type: String
    $gender: String
    $pubStatus: Boolean
    $shortDescription: String
    $longDescription: String
    $aliases: [String]
    $broader: String
    $author: String
    $ignore: Boolean
    $subtypes: [String]
    $geoJSON: String
    $rootId: String
    $keywords: [String]
    $mustConnectWords: [[String]]
    $mustNotConnectWords: [[String]]
    $mustBeMentioned: Boolean
    $mustBeMentionedWords: [String]
    $mustNotBeMentionedWords: [String]
    $correctionWeight: Float
    $minimumWeight: Int
    $links: [ConceptLinkInput]
    $global: Boolean
    $language: String
  ) {
    editConcept(
      superAdmin: $superAdmin
      extendedAdmin: $extendedAdmin
      uuid: $uuid
      title: $title
      type: $type
      gender: $gender
      pubStatus: $pubStatus
      shortDescription: $shortDescription
      longDescription: $longDescription
      aliases: $aliases
      broader: $broader
      author: $author
      ignore: $ignore
      subtypes: $subtypes
      geoJSON: $geoJSON
      rootId: $rootId
      keywords: $keywords
      mustConnectWords: $mustConnectWords
      mustNotConnectWords: $mustNotConnectWords
      mustBeMentioned: $mustBeMentioned
      mustBeMentionedWords: $mustBeMentionedWords
      mustNotBeMentionedWords: $mustNotBeMentionedWords
      correctionWeight: $correctionWeight
      minimumWeight: $minimumWeight
      links: $links
      global: $global
      language: $language
    ) {
      response
      error
    }
  }
`;

export default editConcept;
