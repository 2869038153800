import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CTX } from "./ApiDocsContext";
import { IPage } from "./types";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

const route = "/api-docs";

interface IProps {
  pages: Array<IPage>;
}

const PageList = (props: IProps) => {
  const { pages } = props;
  const history = useHistory();
  const {
    hasAdminPrivs,
    activePage,
    activePageBeforeChange,
    setNextPath,
    isEditable,
    setOpenAlertModal,
  }: any = useContext(CTX);

  const onPageChange = (newPath: string) => {
    if (
      isEditable &&
      (activePage?.content !== activePageBeforeChange?.content ||
        activePage.priv !== activePageBeforeChange.priv)
    ) {
      setNextPath(newPath);
      setOpenAlertModal(true);
    } else {
      $("#api-docs").scrollTop(0);
      history.push(route + newPath);
    }
  };

  return (
    <List
      dense
      disablePadding
      sx={{
        borderRight: "1px solid rgba(0,0,0,0.125)",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {pages.map((page: IPage) => (
        <ListItem
          key={page.name}
          selected={activePage?.path === page.path}
          disablePadding
          onClick={() => onPageChange(page.path)}
        >
          <ListItemButton>
            <ListItemIcon>
              <ChevronRight
                color={
                  !hasAdminPrivs
                    ? undefined
                    : page.pubStatus
                    ? "success"
                    : "error"
                }
              />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={page.name}
              style={{ fontSize: "14px", fontWeight: 700 }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default PageList;
