import {
  getLastAuthenticationActivity,
  getSignedInUser,
  removeSignedInUser,
  setLastAuthenticationActivity,
  setSignedInUser,
  setSignedUserExplicit,
} from "./ppen-localstorage";
import moment from "moment";
import refreshTokenMutation from "../mutations/refreshTokenMutation";
import configData from "../app_config.json";
const GRAPHQL_URL = configData.GRAPHQL_URL;

export const Authenticator = {
  isAuthenticated: () => {
    const user = getSignedInUser();
    if (!user) {
      return false;
    }
    const now = moment().format();
    setLastAuthenticationActivity(now);
    return true;
  },
  hasAuthority: (neededPrivs: string[] = []) => {
    if (!Authenticator.isAuthenticated()) {
      return false;
    }
    if (neededPrivs === null) {
      return true;
    }
    const userPrivs = getSignedInUser().privileges;
    if (userPrivs.includes("ADMIN")) {
      return true;
    }
    //Loop through the needed privs, because you need all of them. If any needed priv is not there then deny
    for (let i = 0; i < neededPrivs.length; i++) {
      let neededPriv = neededPrivs[i];
      if (!userPrivs.includes(neededPriv)) {
        return false;
      }
    }
    return true;
  },
  getUsername: () => {
    const user = getSignedInUser();
    if (!user) {
      return null;
    }
    return user.username;
  },
  authenticate: (userData: any, cb: (...args: any[]) => void = () => {}) => {
    const sessionsExpiresAt = userData.data.login.expiresAt;
    const expiresAt = moment(sessionsExpiresAt);
    const minutesLeftToExpire = expiresAt.diff(moment(), "minutes");
    Authenticator.checkUserTokenEveryInterval(minutesLeftToExpire / 24); //60 min => check every 10 min
    setSignedInUser(userData);
    cb();
  },
  signout: (cb: (...args: any[]) => void = () => {}) => {
    removeSignedInUser();
    cb();
  },
  getSignedInUser: () => {
    return getSignedInUser();
  },
  checkUserTokenEveryInterval: (minutes: number) => {
    const checkToken = setInterval(() => {
      const user = getSignedInUser();
      if (!user) {
        Authenticator.signout(() => {});
        clearInterval(checkToken);
        return;
      }
      const sessionsExpiresAt = user.expiresAt;
      const expiresAt = moment(sessionsExpiresAt);
      const minutesLeftToExpire = expiresAt.diff(moment(), "minutes");
      if (minutesLeftToExpire > 0) {
        const lastActivity = getLastAuthenticationActivity();
        if (lastActivity === null) {
          //should never happen
          return;
        }
        const minutesSinceLastActivity = moment().diff(
          moment(lastActivity),
          "minutes"
        );
        if (minutesSinceLastActivity > minutes * 2) {
          //we don't refresh inactive users token...
          return;
        }
        Authenticator.reNegotiateToken(checkToken);
        return;
      }
      if (minutesLeftToExpire === 0) {
        Authenticator.signout(() => {});
        clearInterval(checkToken);
        return;
      }
    }, minutes * 60000);
  },
  reNegotiateToken: async (caller: any, cb: any = null) => {
    if (getSignedInUser() === null || getSignedInUser() === undefined) {
      Authenticator.signout(() => {});
      return;
    }
    await fetch(GRAPHQL_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: refreshTokenMutation,
        variables: { token: getSignedInUser().token },
      }),
    })
      .then(async (response) => {
        let tokenResponse = await response.json();
        const newToken = tokenResponse.data.refreshToken;
        const currentUser = Authenticator.getSignedInUser();
        const userWithNewToken = {
          username: currentUser.username,
          token: newToken.token,
          expiresAt: newToken.expiresAt,
          privileges: currentUser.privileges,
        };
        setSignedUserExplicit(userWithNewToken);
        if (cb !== null) {
          cb();
        }
      })
      .catch((err) => {
        //token invalid or already expired (should never happen)
        if (caller !== null) {
          clearInterval(caller);
        }
        Authenticator.signout(() => {});
        return;
      });
  },
};

window.onload = () => {
  const currentUser = Authenticator.getSignedInUser();
  if (!currentUser) {
    Authenticator.signout(() => {});
    return;
  }
  Authenticator.reNegotiateToken(null, () => {
    const userWithNewToken = Authenticator.getSignedInUser();
    const sessionExpireAt = userWithNewToken.expiresAt;
    const expiresAt = moment(sessionExpireAt);
    const minutesLeftToExpire = expiresAt.diff(moment(), "minutes");
    Authenticator.checkUserTokenEveryInterval(minutesLeftToExpire / 24);
  });
};
