import { useState, useEffect } from "react";
import "./App.css";
import { Authenticator } from "./utils/Authenticator";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import ContextStore from "./utils/ContextStore";
import ApiDocsContext from "./components/ApiDocs/ApiDocsContext";
import AppContainer from "./components/AppContainer";
import configData from "./app_config.json";
import Theme from "./Theme";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";

const URI = configData.GRAPHQL_URL;

const getJWTToken = () => {
  if (Authenticator.isAuthenticated()) {
    const user = Authenticator.getSignedInUser();
    return user.token;
  }
  return "";
};

const cacheOptions: any = {
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        getCustomRules: {
          merge(existing: any, incoming: any) {
            return incoming;
          },
          getArticleCountInChannels: {
            merge(existing: any, incoming: any) {
              return incoming;
            },
          },
        },
      },
    },
  },
};

const App = () => {
  const checkAuth = Authenticator.isAuthenticated();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(checkAuth);

  const client = new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: new HttpLink({
      uri: URI,
      headers: {
        authorization: `Bearer ${getJWTToken()}`,
      },
    }),
  });

  useEffect(() => {
    const currentUser = Authenticator.getSignedInUser();
    if (currentUser === null || !currentUser) {
      Authenticator.signout(() => {});
      return;
    }
  }, [isAuthorized]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <ContextStore
            isAuthorized={isAuthorized}
            setIsAuthorized={setIsAuthorized}
          >
            <ApiDocsContext>
              <AppContainer
                isAuthorized={isAuthorized}
                setIsAuthorized={setIsAuthorized}
              />
            </ApiDocsContext>
          </ContextStore>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
