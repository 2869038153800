import { useContext } from "react";
import { CTX } from "../CMContext";
import { Grid, Button } from "@mui/material";
import { makeStyles, createStyles} from "@mui/styles"

const useStyles = makeStyles(() =>
  createStyles({
    keepButton: {
      color: "green",
      border: "1px solid green",
    },
    discardButton: {
      color: "red",
      border: "1px solid red",
    },
  })
);

const ActionButtons = ({ variable }: any) => {
  const classes = useStyles();
  const {
    currentConcept,
    selectedSuggestion,
    changedConcept,
    setChangedConcept,
    keepChanges,
    setKeepChanges,
  }: any = useContext(CTX);

  const acceptChanges = (variable: string) => {
    setChangedConcept({
      ...changedConcept,
      [variable]: selectedSuggestion.concept[variable],
    });
  };

  const discardChanges = (variable: string) => {
    setChangedConcept({
      ...changedConcept,
      [variable]: currentConcept[variable],
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item xs>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            style={
              keepChanges[variable]
                ? { backgroundColor: "rgba(87, 227, 158, 0.5)" }
                : undefined
            }
            className={classes.keepButton}
            onClick={() => {
              acceptChanges(variable);
              setKeepChanges({ ...keepChanges, [variable]: true });
            }}
          >
            Keep
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="small"
            style={
              !keepChanges[variable]
                ? { backgroundColor: "rgba(239, 111, 108, 0.4)" }
                : undefined
            }
            className={classes.discardButton}
            onClick={() => {
              discardChanges(variable);
              setKeepChanges({ ...keepChanges, [variable]: false });
            }}
          >
            Discard
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
