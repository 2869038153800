import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useCurrentHeight } from "../../../utils/helpers";
import { CTX } from "src/utils/ContextStore";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
  Menu,
  FormControlLabel,
  FormGroup,
  Divider,
  LinearProgress,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { visuallyHidden } from "@mui/utils";
import {
  Add,
  Check,
  Close,
  Edit,
  Delete,
  FilterList,
  Search,
  Clear,
} from "@mui/icons-material";

import GET_CUSTOM_RULES from "src/queries/GET_CUSTOM_RULES";
import ADD_CUSTOM_RULE from "src/mutations/ADD_CUSTOM_RULE";
import UPDATE_CUSTOM_RULE from "src/mutations/UPDATE_CUSTOM_RULE";
import DELETE_CUSTOM_RULE from "src/mutations/DELETE_CUSTOM_RULE";

import RulePickerComponent from "./RulePickerComponent";
import DatasetPicker from "src/components/PanelComponents/DatasetPicker";
import { InputAdornment } from "@mui/material";

const ruleOptions = [
  { id: 0, label: "Replace word", value: "REPLACE_TEXT" },
  { id: 1, label: "Exclude text", value: "EXCLUDE_TEXT" },
  {
    id: 2,
    label: "Exclude paragraph",
    value: "EXCLUDE_PARAGRAPH",
  },
];

interface IData {
  ruleName: string;
  operation: string;
  textToManipulate: string;
  replacingText: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof IData;
  label: string;
  width: string | number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "textToManipulate",
    label: "Primary text",
    width: "33%",
  },
  {
    id: "operation",
    label: "Rule",
    width: "10%",
  },
  {
    id: "replacingText",
    label: "Replacing text",
    width: "33%",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof IData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontWeight: 700 }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center" sx={{ fontWeight: 700 }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

interface IFilter {
  label: string;
  value: string;
  checked: boolean;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  filters: IFilter[];
  searchTerm: string;
  clearSearch: () => void;
  loading?: boolean;
  onAddNewRule?: (e: any) => any;
  onDelete?: (e: any) => any;
  onFilter?: (e: any) => any;
  onSearch?: (e: any) => any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    numSelected,
    filters,
    loading,
    onAddNewRule,
    onDelete,
    onFilter,
    onSearch,
    searchTerm,
    clearSearch,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorEl);

  const handleClickFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        borderBottom: "1px solid rgba(0,0,0,.125)",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          px: 2,
          gap: 2,
        }}
      >
        <Tooltip title="Insert new rule">
          <Button disabled={loading} variant="contained" onClick={onAddNewRule}>
            <Add sx={{ mr: 0.5 }} />
            Add rule
          </Button>
        </Tooltip>
        {numSelected > 0 ? (
          <Typography variant="subtitle1" component="span">
            {numSelected} selected
          </Typography>
        ) : (
          <TextField
            size="small"
            variant="outlined"
            label={
              <span>
                <Search sx={{ mr: 0.25 }} />
                Search
              </span>
            }
            value={searchTerm}
            onChange={onSearch}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      clearSearch && clearSearch();
                    }}
                    edge="end"
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: "25%", minWidth: "250px" }}
          />
        )}
      </Box>
      <Box>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton color="secondary" onClick={onDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Filter list">
              <IconButton
                id="filter-button"
                aria-haspopup="true"
                aria-controls={openFilter ? "filter-menu" : undefined}
                aria-expanded={openFilter ? "true" : undefined}
                onClick={handleClickFilter}
              >
                <FilterList />
              </IconButton>
            </Tooltip>
            <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              open={openFilter}
              onClose={handleCloseFilter}
              MenuListProps={{
                "aria-labelledby": "filter-button",
              }}
              sx={{ minWidth: "100%" }}
            >
              <Box sx={{ px: 2 }}>
                <Typography
                  sx={{
                    pb: 1,
                    fontWeight: 700,
                  }}
                >
                  Filter by rule
                </Typography>
                <Divider />
                <FormGroup>
                  {filters.map((filter, i) => (
                    <FormControlLabel
                      key={i}
                      control={<Checkbox value={filter.value} />}
                      label={filter.label}
                      checked={filter.checked}
                      onChange={(e) => {
                        let newFilters = [...filters];
                        const i = newFilters.findIndex(
                          (f) => f.label === filter.label
                        );
                        newFilters[i].checked = !newFilters[i].checked;
                        onFilter && onFilter(newFilters);
                      }}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Menu>
          </>
        )}
      </Box>
    </Toolbar>
  );
};

const useStyles = makeStyles({
  paginationLabel: { marginBottom: 0 },
});

const CustomAnalysisRulesComponent = () => {
  const classes = useStyles();
  const {
    activeEndpoint,
    setShowSnackbar,
    setSnackbarMessage,
    setSnackbarError,
  }: any = useContext(CTX);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IData>("textToManipulate");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [customRules, setCustomRules] = useState<Array<IData>>([]);
  const [editingRules, setEditingRules] = useState<Array<IData>>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filters, setFilters] = useState<Array<IFilter>>([
    {
      label: "Replace text",
      value: "REPLACE_TEXT",
      checked: true,
    },
    {
      label: "Exclude text",
      value: "EXCLUDE_TEXT",
      checked: true,
    },
    {
      label: "Exclude paragraph",
      value: "EXCLUDE_PARAGRAPH",
      checked: true,
    },
  ]);

  const rows = useMemo(() => {
    let allowedRules: string[] = [];
    filters.forEach((filter: IFilter) => {
      if (filter.checked) {
        allowedRules.push(filter.value);
      }
    });
    let res = customRules.filter((rule) => {
      const term = searchTerm?.toLowerCase();
      return (
        allowedRules.includes(rule.operation) &&
        (rule.textToManipulate?.toLowerCase().includes(term) ||
          rule.replacingText?.toLowerCase().includes(term))
      );
    });

    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customRules, searchTerm, filters]);

  const generateID = (customer = null) => {
    let id;
    if (customRules.length > 0) {
      // Take the lowest existing sort key and decrease it to make it lower to avoid conflicts
      let keyList = customRules[0].ruleName.split("_");
      let key;
      try {
        key = keyList[1];
      } catch (e) {
        key = keyList[0];
      }
      id = parseInt(key) - 1;
    } else {
      id = Math.random().toString(36).substr(2, 9);
    }
    return `${(customer ? customer + "_" : "") + id}`;
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: Array<string> = rows.map((n) => n.ruleName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClickFilter = (
    event: React.MouseEvent<unknown>,
    ruleName: string
  ) => {
    const selectedIndex = selected.indexOf(ruleName);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ruleName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (ruleName: string) => selected.indexOf(ruleName) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const latestSortedRows = useRef<Array<IData>>([]);

  const visibleRows = useMemo(
    () => {
      if (editingRules.length > 0) {
        let included = latestSortedRows.current.map((r) => {
          return rows.find((row) => row.ruleName === r.ruleName) || r;
        });
        return rows.map((r) => {
          return included.find((row) => row.ruleName === r.ruleName) || r;
        });
      }
      let sorted = stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      latestSortedRows.current = sorted;
      return latestSortedRows.current;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, customRules, searchTerm, filters]
  );

  const onAddNewRule = () => {
    const customerID = activeEndpoint?.settingsName ?? "";
    const newId = generateID(customerID);
    const newRule: IData = {
      ruleName: newId,
      operation: ruleOptions[0].value,
      textToManipulate: "",
      replacingText: "",
    };
    addCustomRule({
      variables: { query: newRule },
      onCompleted: (data) => {
        getCustomRules();
      },
      onError: (err) => {},
    });
    setEditingRules([newRule, ...editingRules]);
  };

  const handleEditRule = (data: IData) => {
    setEditingRules([...editingRules, data]);
  };

  const handleCancelRule = async (data: IData) => {
    await getCustomRules();
    setEditingRules(editingRules.filter((r) => r.ruleName !== data.ruleName));
  };

  const handleUpdateRule = async (data: IData) => {
    const itemIndex = customRules.findIndex(
      (rule) => rule.ruleName === data.ruleName
    );
    let newList = [...customRules];
    if (data.operation !== "REPLACE_TEXT") {
      data.replacingText = "";
    }
    newList[itemIndex] = data;
    await updateCustomRule({
      variables: { query: { ...data } },
      onCompleted: (data) => {
        setSnackbarMessage("Rule updated successfully");
        setSnackbarError(false);
        setShowSnackbar(true);
        getCustomRules();
      },
      onError: (err) => {
        setSnackbarMessage("Failed to update rule");
        setSnackbarError(true);
        setShowSnackbar(true);
      },
    });
    //setCustomRules(newList);
    setEditingRules(editingRules.filter((r) => r.ruleName !== data.ruleName));
  };

  const handleOnDelete = () => {
    setCustomRules(
      customRules.filter((rule) => !selected.includes(rule.ruleName))
    );
    setSelected([]);

    const reqs = selected.map((ruleName) => {
      return deleteCustomRule({
        variables: { query: { ruleName } },
      });
    });

    Promise.allSettled(reqs)
      .then((data: any) => {
        setSnackbarMessage("Rule deleted");
        setSnackbarError(false);
        setShowSnackbar(true);
        getCustomRules();
      })
      .catch((err) => {
        setSnackbarMessage("Failed to delete rule");
        setSnackbarError(true);
        setShowSnackbar(true);
      });
  };

  const handleOnFilter = (ruleFilters: IFilter[]) => {
    setFilters(ruleFilters);
  };

  const handleOnSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const [getCustomRules, { loading, error }] = useLazyQuery(GET_CUSTOM_RULES, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const customRules = data.getCustomRules;
      setCustomRules(
        customRules.map((rule: any) => {
          return {
            ruleName: rule.ruleName,
            operation: rule.operation,
            textToManipulate: rule.textToManipulate,
            replacingText: rule.replacingText,
          };
        })
      );
    },
    onError: () => {},
  });

  const [addCustomRule] = useMutation(ADD_CUSTOM_RULE);
  const [updateCustomRule] = useMutation(UPDATE_CUSTOM_RULE);
  const [deleteCustomRule] = useMutation(DELETE_CUSTOM_RULE);

  useEffect(() => {
    getCustomRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  return (
    <Box>
      <Grid
        container
        sx={{
          p: 1.5,
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          zIndex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="ruleTableTitle"
            component="div"
          >
            Custom analysis rules
          </Typography>
        </Grid>
        <Grid item>
          <DatasetPicker />
        </Grid>
      </Grid>

      <Box sx={{ p: 2 }}>
        <Paper sx={{ width: "100%" }}>
          <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />
          <EnhancedTableToolbar
            numSelected={selected.length}
            filters={filters}
            loading={loading}
            searchTerm={searchTerm}
            clearSearch={() => setSearchTerm("")}
            onAddNewRule={onAddNewRule}
            onDelete={handleOnDelete}
            onFilter={handleOnFilter}
            onSearch={handleOnSearch}
          />
          <TableContainer
            sx={{
              height: `${useCurrentHeight() - 275}px`,
            }}
          >
            {error ? (
              <Alert severity="error">{error.message}</Alert>
            ) : rows.length === 0 ? (
              <Alert severity="info">
                No rules found for the selected dataset
              </Alert>
            ) : (
              <Table
                aria-labelledby="ruleTableTitle"
                stickyHeader
                size="small"
                sx={{
                  minWidth: 500,
                }}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {visibleRows.map((row) => {
                    const isItemSelected = isSelected(row.ruleName);
                    const labelId = `enhanced-table-checkbox-${row.ruleName}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.ruleName}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{ borderBottom: "none" }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) =>
                              handleClickFilter(event, row.ruleName)
                            }
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          <TextField
                            disabled={
                              !editingRules.find(
                                (r) => r.ruleName === row.ruleName
                              )
                            }
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={row.textToManipulate}
                            autoComplete="off"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              let newList = [...customRules];
                              const index = newList.findIndex(
                                (rule) => rule.ruleName === row.ruleName
                              );
                              newList[index].textToManipulate = e.target.value;
                              setCustomRules(newList);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          <RulePickerComponent
                            disabled={
                              !editingRules.find(
                                (r) => r.ruleName === row.ruleName
                              )
                            }
                            fullWidth
                            ruleOptions={ruleOptions}
                            value={row.operation}
                            label=""
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              let newList = [...customRules];
                              const index = newList.findIndex(
                                (rule) => rule.ruleName === row.ruleName
                              );
                              newList[index].operation = e.target.value;
                              setCustomRules(newList);
                            }}
                            sx={{ width: "15em" }}
                          />
                        </TableCell>

                        <TableCell align="center" sx={{ borderBottom: "none" }}>
                          {row.operation === "REPLACE_TEXT" && (
                            <TextField
                              disabled={
                                !editingRules.find(
                                  (r) => r.ruleName === row.ruleName
                                )
                              }
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={row.replacingText}
                              autoComplete="off"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                let newList = [...customRules];
                                const index = newList.findIndex(
                                  (rule) => rule.ruleName === row.ruleName
                                );
                                newList[index].replacingText = e.target.value;
                                setCustomRules(newList);
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            borderBottom: "none",
                          }}
                        >
                          {!editingRules.find(
                            (r) => r.ruleName === row.ruleName
                          ) ? (
                            <Box sx={{ display: "flex", gap: 0.5 }}>
                              <Tooltip title="Edit rule">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    handleEditRule(row);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                              }}
                            >
                              <Tooltip title="Cancel edit">
                                <IconButton
                                  color="secondary"
                                  onClick={() => {
                                    handleCancelRule(row);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Update rule">
                                <IconButton
                                  color="success"
                                  onClick={() => {
                                    handleUpdateRule(row);
                                  }}
                                >
                                  <Check />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              displayedRows: classes.paginationLabel,
              selectLabel: classes.paginationLabel,
            }}
            sx={{ borderTop: "1px solid rgba(0,0,0,.125)" }}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default CustomAnalysisRulesComponent;
