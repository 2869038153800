import { useState, useEffect } from "react";
//import { CTX } from "./Context";
import { Box, Paper, Typography, Skeleton, Divider } from "@mui/material";

const AuthorPage = () => {
  //const { history } = useContext(CTX);

  const [author] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    //const urlParams = new URLSearchParams(history.location.search);
    //const authorUuid: string = urlParams.get("uuid") || "";
    //setAuthor(authorUuid);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Paper
        sx={{
          flexGrow: 1,
          p: 6,
        }}
      >
        <Typography variant="h4">
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="h6">
          <Skeleton width="90%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: 6,
      }}
    >
      {!author ? (
        <Box>
          <Typography variant="h6" color="error">
            We apologize for the inconvenience. The author feature is currently
            being developed and is not supported yet.
          </Typography>
          <Divider sx={{ my: 4 }} />
        </Box>
      ) : (
        <Box>
          <Typography variant="h4">To be launched</Typography>
          <Typography variant="h6">This is under constructions.</Typography>
          <Divider sx={{ my: 4 }} />
        </Box>
      )}
    </Paper>
  );
};

export default AuthorPage;
