import gql from "graphql-tag";

const GET_TOPIC_PAGES = gql`
  query {
    getTopicPages {
      title
      description
      imgUrl
      published
      tags {
        uuid
        title
        type
      }
      tagCondition
      slug
      url
      createdTimestamp
      createdBy
      lastEditedTimestamp
      lastEditedBy
    }
  }
`;

export default GET_TOPIC_PAGES;
