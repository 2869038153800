import gql from "graphql-tag";

const UPDATE_CUSTOM_RULE = gql`
  mutation ($query: CustomRuleInput) {
    updateCustomRule(query: $query) {
      ruleName
      operation
      textToManipulate
      replacingText
    }
  }
`;

export default UPDATE_CUSTOM_RULE;
