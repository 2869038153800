import gql from "graphql-tag";

const deleteConcept = gql`
  mutation (
    $superAdmin: Boolean
    $extendedAdmin: Boolean
    $uuid: String!
    $global: Boolean
    $language: String
  ) {
    deleteConcept(
      superAdmin: $superAdmin
      extendedAdmin: $extendedAdmin
      uuid: $uuid
      global: $global
      language: $language
    ) {
      response
      error
    }
  }
`;

export default deleteConcept;
