import { MenuItem, TextField } from "@mui/material";

const RulePickerComponent = ({ ruleOptions, ...rest }: any) => {
  return (
    <TextField select label="Rule" size="small" {...rest}>
      {ruleOptions.map((option: any) => (
        <MenuItem key={option.id} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default RulePickerComponent;
