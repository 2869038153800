import gql from "graphql-tag";

const GET_USERS = gql`
  query ($datasets: [String]) {
    getUsers(datasets: $datasets) {
      username
      password
      created
      last_login
      privs
      privsString
      docPrivs
      webdemo {
        dataset
        expires
        api_calls
        max_api_calls
      }
      queue
      dataset
      apiCalls
      expires
      confirmed
      confirmed_at
      demo_user
    }
  }
`;

export default GET_USERS;
