import { useState, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { CTX as mainCTX } from "../../../utils/ContextStore";
import { CTX } from "../CMContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: "1px solid rgba(0,0,0,0.125)",
      padding: theme.spacing(2),
    },
  })
);

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const ConfirmModal = (props: ModalProps) => {
  const { onClose, open } = props;
  const { forceUpdateListener, setForceUpdateListener }: any =
    useContext(mainCTX);
  const {
    updateConceptChangeSuggestions,
    selectedSuggestion,
    setSelectedSuggestion,
    setCurrentConcept,
  }: any = useContext(CTX);
  return (
    <Dialog onClose={onClose} aria-labelledby="confirm-discard" open={open}>
      <DialogTitle id="confirm-discard">
        Are you sure you don't want to commit any changes and mark this
        suggestion as{" "}
        <Box component="span" color="#3f51b5">
          handled
        </Box>
        ?
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box p={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (selectedSuggestion?.id) {
                  updateConceptChangeSuggestions({
                    variables: {
                      id: selectedSuggestion.id,
                      handled: true,
                    },
                  })
                    .then(() => {
                      setTimeout(() => {
                        setForceUpdateListener(
                          forceUpdateListener === 0 ? 1 : 0
                        );
                        //setPageNumber();
                      }, 250);
                    })
                    .catch((e: Error) => {
                      console.error(e);
                    })
                    .finally(() => {
                      onClose();
                      setCurrentConcept(null);
                      setSelectedSuggestion(null);
                    });
                }
              }}
            >
              Confirm
            </Button>
          </Box>
          <Box p={1}>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const ConfirmBox = () => {
  const classes = useStyles();
  const {
    currentConcept,
    setChangedConcept,
    setKeepChanges,
    setShowHistoryLog,
    setShowConceptModal,
    selectedSuggestion,
    setShowCreateConceptModal,
  }: any = useContext(CTX);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const discardAll = () => {
    currentConcept && setChangedConcept(currentConcept);
    setKeepChanges({
      title: false,
      type: false,
      gender: false,
      shortDescription: false,
      longDescription: false,
      aliases: false,
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" className={classes.root}>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />

      <Box whiteSpace="nowrap">
        <Button
          disabled={selectedSuggestion.createNewRequested}
          color="primary"
          variant="contained"
          onClick={() => {
            setShowHistoryLog(true);
          }}
        >
          History log
        </Button>
      </Box>
      <Box whiteSpace="nowrap">
        <Button
          disabled={selectedSuggestion.handled}
          color="primary"
          variant="contained"
          style={{ marginRight: "1rem" }}
          onClick={() => {
            discardAll();
            setShowConfirmModal(true);
          }}
        >
          Discard request
        </Button>

        <Button
          disabled={selectedSuggestion.handled}
          color="primary"
          variant="contained"
          onClick={() => {
            if (selectedSuggestion.createNewRequested) {
              setShowCreateConceptModal(true);
            } else {
              setShowConceptModal(true);
            }
          }}
        >
          {selectedSuggestion.createNewRequested
            ? "Continue to Create Concept"
            : selectedSuggestion.deleteRequested
            ? "Continue to Delete Concept"
            : "Continue to Commit Changes"}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmBox;
