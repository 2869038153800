import { useContext } from "react";
import { CTX } from "../CMContext";
import { Chip, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import Picker from "../ResourceManagement/Picker";

const styles = () => ({
  cssFocused: {},
  rootKeep: {
    "&:hover:not($focusedKeep) $notchedOutlineKeep": {
      borderColor: "rgb(87, 227, 158)",
    },
    "&$cssFocused $notchedOutlineKeep": {
      borderColor: "rgb(87, 227, 158)",
      borderWidth: 3.6,
    },
  },
  focusedKeep: { borderColor: "rgb(87, 227, 158)", borderWidth: 3 },
  notchedOutlineKeep: { borderColor: "rgb(87, 227, 158)", borderWidth: 3 },
  rootDiscard: {
    "&:hover:not($focusedDiscard) $notchedOutlineDiscard": {
      borderColor: "rgb(239, 111, 108)",
    },
    "&$cssFocused $notchedOutlineDiscard": {
      borderColor: "rgb(239, 111, 108)",
      borderWidth: 3.6,
    },
  },
  focusedDiscard: { borderColor: "rgb(239, 111, 108)", borderWidth: 3 },
  notchedOutlineDiscard: {
    borderColor: "rgb(239, 111, 108)",
    borderWidth: 3,
  },
});

const SectionContent = (props: any) => {
  const {
    label,
    content,
    highlight,
    active,
    disabled,
    classes,
    createConcept,
  } = props;

  const { setSelectedSuggestion }: any = useContext(CTX);

  const setConceptType = (value: string) => {
    setSelectedSuggestion((prevState: any) => ({
      ...prevState,
      concept: {
        ...prevState.concept,
        type: value,
      },
    }));
  };

  if (label === "Short description" || label === "Long description") {
    return (
      <TextField
        disabled={disabled}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={4}
        value={content}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: true,
          classes: {
            root:
              highlight && active
                ? classes.rootKeep
                : highlight
                ? classes.rootDiscard
                : undefined,
            notchedOutline:
              highlight && active
                ? classes.notchedOutlineKeep
                : highlight
                ? classes.notchedOutlineDiscard
                : undefined,
            focused: classes.cssFocused,
          },
        }}
      />
    );
  } else if (label === "Aliases") {
    return (
      <TextField
        disabled={disabled}
        size="small"
        variant="outlined"
        label={label}
        fullWidth
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
          classes: {
            root:
              highlight && active
                ? props.classes.rootKeep
                : highlight
                ? props.classes.rootDiscard
                : undefined,
            notchedOutline:
              highlight && active
                ? props.classes.notchedOutlineKeep
                : highlight
                ? props.classes.notchedOutlineDiscard
                : undefined,
            focused:
              highlight && active
                ? props.classes.focusedKeep
                : highlight
                ? props.classes.focusedDiscard
                : props.classes.cssFocused,
          },
          inputComponent: ({ inputRef, ...other }: any) => (
            <div {...other} style={{ minHeight: 24 }} />
          ),
        }}
        inputProps={{
          children:
            content.aliases &&
            content.aliases.map((alias: string, index: any) => {
              return index !== content.length ? (
                <Chip
                  key={index}
                  variant="outlined"
                  size="small"
                  label={alias}
                  style={{ marginRight: "0.3rem" }}
                />
              ) : (
                <Chip variant="outlined" size="small" label={alias} />
              );
            }),
        }}
      />
    );
  } else if (label === "Type" && createConcept) {
    return (
      <Picker
        fullWidth={true}
        disabled={disabled}
        value={content}
        label={label}
        dataList={[
          "category",
          "topic",
          "person",
          "place",
          "organisation",
          "event",
          "object",
        ]}
        onChange={setConceptType}
      />
    );
  } else
    return (
      <TextField
        disabled={disabled}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        value={content || ""}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: true,
          classes: {
            root:
              highlight && active
                ? props.classes.rootKeep
                : highlight
                ? props.classes.rootDiscard
                : undefined,
            notchedOutline:
              highlight && active
                ? props.classes.notchedOutlineKeep
                : highlight
                ? props.classes.notchedOutlineDiscard
                : undefined,
            focused:
              highlight && active
                ? props.classes.focusedKeep
                : highlight
                ? props.classes.focusedDiscard
                : undefined,
          },
        }}
      />
    );
};

export default withStyles(styles)(SectionContent);
