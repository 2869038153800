import { useContext } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import ReactMde from "react-mde";
import Showdown from "showdown";
import { CTX } from "./ApiDocsContext";
import { IPage } from "./types";
import { CircularProgress } from "@mui/material";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const MDEditor = () => {
  const {
    isEditable,
    activePage,
    setActivePage,
    windowHeight,
    selectedTab,
    setSelectedTab,
  }: any = useContext(CTX);

  /** ##### FORCE HIDE BORDER IN PREVIEW MODE ##### */
  isEditable
    ? $(".react-mde").css("border", "1px solid #c8ccd0")
    : $(".react-mde").css("border", "none");

  /** ##### ENABLE INSERT INDENT BY TABBING ##### */
  $('textarea[data-testid="text-area"]').attr("id", "md-editor");
  isEditable &&
    $("#md-editor")
      .off()
      .on("keydown", (e: any) => {
        if (e.keyCode === 9) {
          document.execCommand("insertText", false, "\t");
          e.preventDefault();
          return false;
        }
      });

  if (!activePage) return null;

  return (
    <ReactMde
      //minPreviewHeight={windowHeight}
      minEditorHeight={windowHeight - 280}
      maxEditorHeight={windowHeight - 280}
      toolbarCommands={isEditable ? undefined : []}
      value={activePage.content}
      onChange={(value) =>
        setActivePage((prev: IPage) => {
          return { ...prev, content: value };
        })
      }
      selectedTab={isEditable ? selectedTab : "preview"}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={(markdown) =>
        Promise.resolve(converter.makeHtml(markdown))
      }
      childProps={{
        writeButton: {
          tabIndex: -1,
          outline: "none",
        },
      }}
      loadingPreview={<CircularProgress size="2em" />}
    />
  );
};

export default MDEditor;
