import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
} from "@mui/material";

import GET_SUB_CONCEPTS_MUTATION from "../../../mutations/getSubConceptsMutation";

const SubConceptList = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getSubConcepts] = useMutation(GET_SUB_CONCEPTS_MUTATION);
  const chosenConcept = props.chosenConcept;
  const subConcepts = props.subConcepts || [];
  const setSubConcepts = props.setSubConcepts;
  const setChosenConcept = props.setChosenConcept;
  const conceptHistory = props.conceptHistory;
  const setConceptHistory = props.setConceptHistory;

  const conceptClickHandler = (chosenConcept: any, childData: any) => {
    if (childData?.data?.getStatisticMutation?.result) {
      setChosenConcept(chosenConcept);
      setSubConcepts(childData.data.getStatisticMutation.result);
      setConceptHistory([
        {
          concept: chosenConcept,
          subconcepts: childData.data.getStatisticMutation.result,
        },
        ...conceptHistory,
      ]);
    }
  };

  return (
    <List
      style={{
        overflowX: "hidden",
        overflowY: "auto",
        height: 420,
      }}
    >
      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          m={1}
          width="100%"
        >
          <CircularProgress size="2em" />
        </Box>
      )}
      {!isLoading && subConcepts.length === 0 && (
        <Typography variant="body1" color={"secondary"}>
          <span className="font-weight-bold">{chosenConcept.title}</span> does
          not contain any subconcept
        </Typography>
      )}
      {!isLoading && subConcepts
        ? subConcepts.map((concept: any, index: any) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                setIsLoading(true);
                getSubConcepts({
                  variables: {
                    statisticOperation: "conceptFrequencyByParent",
                    conceptType: concept.type,
                    parent: concept.uuid,
                    startDate: props.startDate,
                    endDate: props.endDate,
                    newspaper: props.newspaper,
                    channel: props.channel,
                  },
                })
                  .then((data: any) => {
                    setIsLoading(false);
                    conceptClickHandler(concept, data);
                  })
                  .catch((err: Error) => {
                    setIsLoading(false);
                  });
              }}
            >
              <ListItemText
                primary={
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span className="overflow-ellipsis">{concept.title}</span>
                    <span
                      className="overflow-ellipsis"
                      style={{
                        float: "right",
                        marginLeft: "auto",
                        color: "#aaa",
                      }}
                    >
                      {concept.frequency}
                    </span>
                  </div>
                }
              />
            </ListItem>
          ))
        : null}
    </List>
  );
};

export default SubConceptList;
