import { useState, useEffect } from "react";
import moment from "moment";
import { TextField } from "@mui/material";

interface IProps {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}

const DatePicker = (props: IProps) => {
  const { startDate, endDate, setStartDate, setEndDate } = props;

  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);

  useEffect(() => {
    if (startDateError) {
      setTimeout(() => {
        setStartDateError(false);
      }, 2000);
    }
    if (endDateError) {
      setTimeout(() => {
        setEndDateError(false);
      }, 2000);
    }
  }, [startDateError, endDateError]);

  useEffect(() => {
    let inputFrom = document.getElementById("date-from") as HTMLInputElement;
    let inputTo = document.getElementById("date-to") as HTMLInputElement;
    if (inputFrom) {
      inputFrom.max = moment().format("YYYY-MM-DD");
    }
    if (inputTo) {
      inputTo.max = moment().format("YYYY-MM-DD");
    }
  }, []);

  return (
    <form
      noValidate
      style={{
        display: "flex",
        flexWrap: "wrap",
        float: "right",
        gap: 8,
      }}
    >
      <TextField
        id="date-from"
        label="From"
        type="date"
        variant="outlined"
        size="small"
        value={startDate}
        error={startDateError}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => {
          const dateInput = startDate;
          const value = e.target.value;
          const monthInput = new Date(value).getMonth();
          const monthState = new Date(dateInput).getMonth();
          const dateOnInput = new Date(value).getDate();
          const dateState = new Date(dateInput).getDate();

          const start = moment(value);

          if (dateOnInput === dateState) {
            if (Math.sign(monthInput - monthState) === -1) {
              // onArrowUp
              setStartDate(start.format("YYYY-MM-DD"));
              setEndDate(start.add(1, "month").format("YYYY-MM-DD"));
              return;
            } else {
              // onArrowDown
              setStartDate(start.format("YYYY-MM-DD"));
              // check if next month is within the range otherwise set to today
              const now = moment().format("YYYY-MM-DD");
              const nextMonth = start.add(1, "month").format("YYYY-MM-DD");
              setEndDate(nextMonth > now ? now : nextMonth);
              return;
            }
          }

          const diff = moment(endDate).diff(start, "days");
          setStartDate(start.format("YYYY-MM-DD"));
          if (diff < 0) {
            setEndDate(start.format("YYYY-MM-DD"));
            setEndDateError(true);
          } else if (diff > 31) {
            const now = moment().format("YYYY-MM-DD");
            const nextMonth = start.add(1, "month").format("YYYY-MM-DD");
            setEndDate(nextMonth > now ? now : nextMonth);
            setEndDateError(true);
          }
          /*else {
            const now = moment().format("YYYY-MM-DD");
            const nextMonth = start.add(1, "month").format("YYYY-MM-DD");
            setEndDate(nextMonth > now ? now : nextMonth);
          }*/
        }}
        sx={{ width: 170 }}
      />

      <TextField
        id="date-to"
        label="To"
        type="date"
        variant="outlined"
        size="small"
        value={endDate}
        error={endDateError}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => {
          const dateInput = endDate;
          const value = e.target.value;
          const monthInput = new Date(value).getMonth();
          const monthState = new Date(dateInput).getMonth();
          const dateOnInput = new Date(value).getDate();
          const dateState = new Date(dateInput).getDate();

          const end = moment(value);

          if (dateOnInput === dateState) {
            if (Math.sign(monthInput - monthState) === -1) {
              // onArrowUp
              setEndDate(end.format("YYYY-MM-DD"));
              setStartDate(end.subtract(1, "month").format("YYYY-MM-DD"));
              return;
            } else {
              // onArrowDown
              setEndDate(end.format("YYYY-MM-DD"));
              setStartDate(end.subtract(1, "month").format("YYYY-MM-DD"));
              return;
            }
          }

          const diff = end.diff(moment(startDate), "days");

          setEndDate(end.format("YYYY-MM-DD"));
          if (diff < 0) {
            setStartDate(end.format("YYYY-MM-DD"));
            setStartDateError(true);
          }
          if (diff > 31) {
            setStartDate(end.subtract(31, "days").format("YYYY-MM-DD"));
            setStartDateError(true);
          }
        }}
        sx={{ width: 170 }}
      />
    </form>
  );
};

export default DatePicker;
