import { TextField, MenuItem } from "@mui/material";

interface ILocation {
  name: string;
  latLng: string;
}

interface IInterest {
  conceptID: string;
  count: number;
}
interface IUserProfile {
  id?: string;
  customer?: string;
  active?: boolean;
  description?: string;
  imgUrl?: string;
  interests?: IInterest[];
  location: ILocation;
}

interface IProps {
  selectedUserProfile: IUserProfile | undefined | null;
  setSelectedUserProfile: (profile: any) => void;
  userProfileOptions: IUserProfile[];
  cb: any;
}

const UserPickerComponent = (props: IProps) => {
  const {
    userProfileOptions,
    selectedUserProfile,
    setSelectedUserProfile,
    cb,
  } = props;

  let inputProp = { ...props };
  delete inputProp.cb;

  return (
    <TextField
      id="times-user-profile-select"
      select
      fullWidth
      label="User profile"
      variant="outlined"
      size="small"
      InputProps={{ style: { fontWeight: 700 } }}
      value={selectedUserProfile?.id || ""}
      SelectProps={{
        displayEmpty: true,
        sx: { color: !selectedUserProfile?.id ? "#999" : "#000" },
        renderValue: (value: any) => {
          return value || "Other profiles...";
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{ bgcolor: "transparent" }}
      {...inputProp}
    >
      <MenuItem disabled value="">
        Other profiles...
      </MenuItem>
      {userProfileOptions?.map((option: IUserProfile, i: number) => (
        <MenuItem
          key={option?.id}
          value={option?.id}
          onClick={() => {
            if (
              !selectedUserProfile ||
              option?.id !== selectedUserProfile?.id
            ) {
              setSelectedUserProfile(option);
            }
            cb && cb();
          }}
          sx={{ display: i === 0 ? "none" : "block" }}
        >
          {option?.id}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default UserPickerComponent;
