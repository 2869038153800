import moment from "moment";
import { Headline, Preamble, Dateline, Image, Box } from "./StyledComponents";
import { Article, ItemType } from "./TypeDefs";

interface Props {
  article: Article;
  onClick?: (type: ItemType, uuid: string) => void;
}

const previewImage = (currentUrl: string) => {
  if (!currentUrl) return undefined;
  const current = new URL(currentUrl);
  const currentParams = current.searchParams;
  const uuid = currentParams.get("uuid");

  const url = new URL(currentUrl.split("?")[0]);
  const params = url.searchParams;
  if (uuid) {
    params.set("uuid", uuid);
    params.set("type", "preview");
  }
  return url?.toString() ?? undefined;
};

const ArticleItem = (props: Props) => {
  const { article, onClick } = props;
  return (
    <Box
      mb={3}
      display="flex"
      onClick={() => onClick && onClick("article", article.uuid)}
      style={{ cursor: "pointer" }}
    >
      <Box pr={3} width={[2 / 3, 5 / 6]}>
        <Dateline>
          {moment(article.publication_timestamp).format("ll")}
        </Dateline>
        <Headline>{article.headline}</Headline>
        <Preamble>{article.preamble}</Preamble>
      </Box>
      <Box width={[1 / 3, 1 / 6]}>
        {article.image && <Image src={previewImage(article.image)} />}
      </Box>
    </Box>
  );
};

export default ArticleItem;
