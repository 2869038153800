import gql from "graphql-tag";
const GET_ARTICLE_BY_UUID = gql`
  query ($uuid: String) {
    getArticleByUuid(uuid: $uuid) {
      uuid
      headline
      preamble
      dateline
      body
      image
      newspaper
      minuteRead
      authors
      publicationTimestamp
      concepts {
        title
        type
        uuid
      }
    }
  }
`;

export default GET_ARTICLE_BY_UUID;
