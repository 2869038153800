import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Alert,
  Badge,
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Edit } from "@mui/icons-material";

import GET_STRATEGIES from "src/queries/GET_STRATEGIES";
import EditStrategyModal from "./EditStrategyModal";
import CreateStrategyModal from "./CreateStrategyModal";

interface IStrategy {
  name: string;
  description: string;
  isABTest: boolean;
}

interface IProps {
  endpoint: any | null;
  selected: string | null;
  onChange: (username: string) => void;
}

const StrategyComponent = (props: IProps) => {
  const { endpoint, selected, onChange } = props;

  const [openCreateStrategyModal, setOpenCreateStrategyModal] =
    useState<boolean>(false);
  const [modalStrategy, setModalStrategy] = useState<IStrategy | null>(null);
  const [strategyOptions, setStrategyOptions] = useState<IStrategy[] | null>(
    null
  );

  const [getStrategies, { loading, error }] = useLazyQuery(GET_STRATEGIES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.getStrategies) {
        const strategies = data.getStrategies;
        setStrategyOptions(strategies);
        if (strategies.length > 0) {
          onChange(strategies[0].name);
        }
      }
    },
    onError: () => {
      setStrategyOptions([]);
    },
  });

  useEffect(() => {
    setStrategyOptions([]);
    if (endpoint) {
      getStrategies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return (
    <Box>
      <LinearProgress sx={{ visibility: !loading ? "hidden" : "visible" }} />
      <Paper elevation={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            bgcolor: "rgba(0,0,0,0.075)",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <Typography variant="overline" sx={{ fontWeight: 700 }}>
            Strategy ({strategyOptions?.length ?? 0})
          </Typography>
          <Tooltip title="Add new strategy" arrow>
            <IconButton
              size="small"
              edge="end"
              onClick={() => {
                setOpenCreateStrategyModal(true);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </Box>
        <CreateStrategyModal
          open={openCreateStrategyModal}
          handleClose={() => {
            setOpenCreateStrategyModal(false);
          }}
        />
        <EditStrategyModal
          strategy={modalStrategy}
          open={modalStrategy ? true : false}
          handleClose={() => {
            setModalStrategy(null);
          }}
        />
        <List
          dense
          disablePadding
          sx={{ maxHeight: "calc(100vh - 294px)", overflow: "auto" }}
        >
          {error ? (
            <Alert severity="error">Failed to fetch strategies</Alert>
          ) : strategyOptions && strategyOptions.length < 1 ? (
            <Alert severity="info">No strategies found</Alert>
          ) : (
            strategyOptions?.map((strategy, i) => (
              <ListItem
                key={i}
                secondaryAction={
                  <IconButton
                    size="small"
                    color="primary"
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalStrategy(strategy);
                    }}
                  >
                    <Edit />
                  </IconButton>
                }
                onClick={() => {
                  onChange(strategy.name);
                }}
              >
                <Radio
                  edge="start"
                  checked={selected === strategy.name}
                  inputProps={{ "aria-labelledby": strategy.name }}
                />

                <ListItemText
                  primary={
                    <Badge
                      badgeContent={
                        <Tooltip
                          title="A/B testing enabled for strategy"
                          placement="top"
                          arrow
                        >
                          <>A/B</>
                        </Tooltip>
                      }
                      invisible={!strategy?.isABTest}
                      sx={{
                        "& .MuiBadge-badge": {
                          right: -16,
                          top: 0,
                          px: 0.5,
                          py: 0.25,
                          height: "auto",
                          fontSize: "0.7em",
                          fontWeight: 700,
                          color: "#FFF",
                          bgcolor: "#56E39F",
                          cursor: "help",
                        },
                      }}
                    >
                      {strategy.name}
                    </Badge>
                  }
                  secondary={
                    <Tooltip
                      title={strategy.description}
                      //placement="bottom-start"
                      arrow
                    >
                      <Box
                        component="span"
                        sx={{
                          display: "block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {strategy.description}
                      </Box>
                    </Tooltip>
                  }
                  primaryTypographyProps={{
                    sx: {
                      overflowWrap: "break-word",
                      fontWeight: 700,
                    },
                  }}
                />
              </ListItem>
            ))
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default StrategyComponent;
