import { useState } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Alert,
  Popover,
  Typography,
  Paper,
  LinearProgress,
} from "@mui/material";
import GET_CONCEPT_USAGE_OVER_TIME from "../../queries/GET_CONCEPT_USAGE_OVER_TIME";

import ConceptsOverTimeBarChart from "../ProductionInsights/Graphs/ConceptsOverTimeBarChart";
import DatePicker from "react-datepicker";

interface Props {
  uuid: string;
  show: boolean;
}

const StatsField = (props: Props) => {
  const { uuid, show } = props;

  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(1, "months").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [usage, setUsage] = useState<number>(0);

  const { loading, error } = useQuery(GET_CONCEPT_USAGE_OVER_TIME, {
    variables: {
      query: {
        uuid: uuid,
        startDate: startDate,
        endDate: endDate,
      },
    },
    onCompleted: (data) => {
      data = data.getConceptUsageOverTime;
      const { usage } = data;
      setUsage(usage);
      /*
      const conceptUsageOverTime = data.result?.map((d: any) => ({
        date: d.date,
        [title]: d.usage ?? 0,
      }));
      setData({ uuid, title, type, usage, conceptUsageOverTime });
      */
    },
    onError: (err) => {
      setUsage(0);
    },
    skip: !uuid,
  });

  const [showInsights, setShowInsights] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowInsights(false);
    setAnchorEl(null);
  };

  if (!show) return null;

  return (
    <Box sx={{ width: "100%" }}>
      <Alert
        severity={error ? "error" : "info"}
        action={
          <Button
            variant="outlined"
            size="small"
            onClick={(e) => {
              setShowInsights(!showInsights);
              handleClick(e);
            }}
          >
            {showInsights ? "Hide insights" : "Show insights"}
          </Button>
        }
        sx={{ display: "flex", width: "100%", alignItems: "center" }}
      >
        {error ? (
          <b>Statistics not found</b>
        ) : loading ? (
          <LinearProgress color="primary" />
        ) : (
          <span>
            Used <b>{usage}</b> times in the past{" "}
            {moment(endDate).diff(startDate, "days")} days
          </span>
        )}
      </Alert>
      <Popover
        open={showInsights}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ height: "75vh", width: "80vw" }}>
          <Box sx={{ px: 4, py: 2 }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Box>
                From
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => {
                    setStartDate(date);
                  }}
                  dateFormat="MMM d, yyyy"
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                />
              </Box>
              <Box ml={2} mr={2} pt={3}>
                -
              </Box>
              <Box>
                To
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date) => {
                    setEndDate(date);
                  }}
                  dateFormat="MMM d, yyyy"
                  popperModifiers={[
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                />
              </Box>
            </Box>
            <Typography>
              Used <b>{usage}</b> times in the past{" "}
              {moment(endDate).diff(startDate, "days")} days
            </Typography>
          </Box>

          <Paper sx={{ height: "75%", mx: 4 }}>
            <ConceptsOverTimeBarChart
              uuid={uuid}
              startDate={startDate}
              endDate={endDate}
            />
          </Paper>
        </Box>
      </Popover>
    </Box>
  );
};

export default StatsField;
