import { Fragment, useState, useEffect, ChangeEvent, useRef } from "react";
import "../../App.css";
import { getTagIcon } from "src/utils/helpers";
import {
  Box,
  Paper,
  IconButton,
  Badge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Checkbox,
  ToggleButton,
  Button,
  ButtonGroup,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles, createStyles } from "@mui/styles";
import {
  Add,
  Public,
  VisibilityOff,
  Checklist,
  Clear,
  Edit,
} from "@mui/icons-material";
import { useCurrentHeight } from "../../utils/helpers";

import CMConceptModal from "./CMConceptModal";
import BulkEditModal from "./BulkEditModal";

interface IColumn {
  id: "title" | "description";
  label: string;
  type: string;
  align?: "left";
  format?: (value: number) => string;
}

interface Concept {
  uuid: string;
  title: string;
  type: string;
  gender?: string;
  shortDescription?: string;
  longDescription?: string;
  pubStatus?: boolean;
  ignore?: boolean;
  aliases?: string[];
  broader?: string;
  broaderConcept?: any;
  author?: string;
  source?: string;
  geoJSON?: string;
  subtypes?: string[];
  rootId?: string;
  correctionWeight?: number;
  minimumWeight?: number;
  mustNotConnectWords?: string[];
  mustConnectWords?: string[];
  keywords?: string[];
  mustBeMentionedWords?: string[];
  mustBeMentioned?: boolean;
  mustNotBeMentionedWords?: string[];
  links?: string[];
  global?: string;
  language?: string;
  wikipedia?: string;
  wikidata?: string;
  openStreetMap?: string;
  createdTimestamp?: string;
  latestVersionTimestamp?: string;
}

const useStyles = makeStyles({
  paginationLabel: { marginBottom: 0 },
});

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      minWidth: "16px",
      height: "12px",
      right: "-4px",
      top: "14px",
      padding: 0,
      margin: 0,
      border: "none",
      color: "#000",
      fontSize: 9,
      fontWeight: "bold",
      backgroundColor: "#fff",
    },
  })
)(Badge);

const stringContainsNumber = (string: string) => {
  return /\d/.test(string);
};

const CMConceptList = ({ list, listLabel, listType }: any) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [chosenConcept, setChosenConcept] = useState<Concept | null>(null);

  const [editModalShow, setEditModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);

  const cols: IColumn[] = [
    {
      id: "title",
      label: listLabel,
      type: listType,
    },
  ];
  const [rows, setRows] = useState<any[]>([]);
  const [enableBulk, setEnableBulk] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [openEditSelected, setOpenEditSelected] = useState<boolean>(false);
  const [dragChecked, setDragChecked] = useState<boolean>(false);

  const handleChangePage: any = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: any = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //##### RESETS PAGE TO 0 EACH NEW SEARCH #####
  useEffect(() => {
    setPage(0);
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (selected.length < 1) setOpenEditSelected(false);
  }, [selected]);

  const mouseDown = useRef<number>(0);

  return (
    <Box>
      <CMConceptModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        concept={chosenConcept}
      />
      <CMConceptModal
        show={createModalShow}
        onHide={() => setCreateModalShow(false)}
        concept={chosenConcept}
        createConcept
      />
      <Paper sx={{ display: "block", width: "100%", overflowX: "hidden" }}>
        <TableContainer>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  display: "block",
                  width: "100%",
                }}
              >
                {cols.map((column, index: number) => (
                  <TableCell
                    key={index}
                    sx={{
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {getTagIcon(column.type, "medium")}
                        <Typography
                          component="span"
                          sx={{
                            fontWeight: 700,
                          }}
                        >
                          {column.label}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", gap: 0.5 }}>
                        {enableBulk && selected.length > 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {openEditSelected && (
                              <BulkEditModal
                                open={openEditSelected}
                                concepts={selected}
                                onClose={() => {
                                  setOpenEditSelected(false);
                                }}
                                onRemove={(concept) => {
                                  setSelected(
                                    selected.filter(
                                      (s) => s.uuid !== concept.uuid
                                    )
                                  );
                                }}
                                onClear={() => {
                                  setSelected([]);
                                  setOpenEditSelected(false);
                                }}
                              />
                            )}
                            <ButtonGroup
                              variant="contained"
                              size="small"
                              sx={{
                                overflow: "hidden",
                              }}
                            >
                              <Tooltip
                                arrow
                                title="Edit selected"
                                placement="bottom"
                              >
                                <Button
                                  onClick={() => {
                                    setOpenEditSelected(true);
                                  }}
                                  sx={{ gap: 0.5 }}
                                >
                                  {selected.length} selected
                                  <Edit fontSize="small" />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title="Clear selected"
                                placement="bottom"
                              >
                                <Button
                                  color="secondary"
                                  onClick={() => {
                                    setSelected([]);
                                    setOpenEditSelected(true);
                                  }}
                                >
                                  <Clear fontSize="small" />
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          </Box>
                        )}

                        <Tooltip
                          arrow
                          title={
                            enableBulk
                              ? "Disable bulk edit"
                              : "Enable bulk edit"
                          }
                          placement="bottom"
                        >
                          <ToggleButton
                            size="small"
                            value={enableBulk}
                            selected={enableBulk}
                            onChange={() => {
                              setEnableBulk(!enableBulk);
                            }}
                            sx={{ border: "none" }}
                          >
                            <Checklist />
                          </ToggleButton>
                        </Tooltip>

                        <Tooltip
                          arrow
                          title="Create new concept"
                          placement="bottom"
                        >
                          <IconButton
                            className="float-right"
                            size="small"
                            onClick={() => {
                              setChosenConcept({
                                uuid: "",
                                title: "",
                                type:
                                  column.type === "entity"
                                    ? "person"
                                    : column.type,
                                pubStatus: true,
                                ignore: false,
                                shortDescription: "",
                                longDescription: "",
                                aliases: [],
                                broader: "",
                                author: "",
                              });
                              setCreateModalShow(true);
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflowX: "hidden",
                overflowY: "auto",
                minHeight: "33.3vh",
                height: `${useCurrentHeight() - 292}px`,
              }}
            >
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover={
                        (row.type === "category" || row.type === "topic") &&
                        !stringContainsNumber(row.source)
                          ? false
                          : true
                      }
                      key={`${row.uuid}-${row.language}-${
                        row.global ? "global" : "customer"
                      }-${index}`}
                      sx={{
                        display: "block",
                        width: "100%",
                        opacity: row.hidden
                          ? "inherit"
                          : (row.type === "category" || row.type === "topic") &&
                            !stringContainsNumber(row.source)
                          ? "0.6"
                          : "inherit",
                      }}
                      onClick={() => {
                        setChosenConcept(row);
                        if (
                          (row.type === "category" || row.type === "topic") &&
                          !stringContainsNumber(row.source)
                        ) {
                          setCreateModalShow(true);
                          return;
                        }
                        setEditModalShow(true);
                      }}
                    >
                      {cols.map((column, index: number) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={index}>
                            <TableCell
                              className="interactive-cell"
                              sx={{
                                display: "block",
                                width: "100%",
                              }}
                              onMouseDown={(e) => {
                                if (e.button === 0) {
                                  mouseDown.current = ++mouseDown.current;
                                }
                              }}
                              onMouseUp={(e) => {
                                mouseDown.current = 0;
                              }}
                              onMouseEnter={(e) => {
                                if (enableBulk && mouseDown.current > 0) {
                                  if (dragChecked) {
                                    !selected.some(
                                      (s) => s.uuid === row.uuid
                                    ) && setSelected([...selected, row]);
                                  } else {
                                    setSelected(
                                      selected.filter(
                                        (s) => s.uuid !== row.uuid
                                      )
                                    );
                                  }
                                }
                              }}
                            >
                              {(row.hidden ||
                                !stringContainsNumber(row.source)) &&
                              (row.type === "topic" ||
                                row.type === "category") ? (
                                <Tooltip
                                  arrow
                                  title={
                                    row.hidden
                                      ? "Hidden concept"
                                      : "Click to create and enable this concept"
                                  }
                                  placement="bottom"
                                >
                                  <Box
                                    sx={{
                                      display: "block",
                                      width: "100%",
                                      padding: "0 0.2em",
                                    }}
                                  >
                                    <Box display="flex">
                                      <Box flexGrow={0}>
                                        {getTagIcon(row.type)}
                                      </Box>

                                      <Box
                                        flexGrow={1}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          margin: "0 0.6em",
                                        }}
                                      >
                                        <Box component="span" fontWeight="bold">
                                          {value}
                                        </Box>
                                        {row.shortDescription ? (
                                          <Box
                                            component="span"
                                            className="font-weight-lighter"
                                          >
                                            {` — ${row.shortDescription}`}
                                          </Box>
                                        ) : null}
                                      </Box>

                                      {row.hidden ? (
                                        <Badge>
                                          <VisibilityOff fontSize="small" />
                                        </Badge>
                                      ) : row.global !== undefined &&
                                        row.global ? (
                                        <Box flexGrow={0} marginRight="4px">
                                          <StyledBadge
                                            badgeContent={row.language?.toUpperCase()}
                                          >
                                            <Public fontSize="small" />
                                          </StyledBadge>
                                        </Box>
                                      ) : !stringContainsNumber(row.source) ? (
                                        <Box flexGrow={0} marginRight="12px">
                                          <StyledBadge
                                            badgeContent={(
                                              row.source || "iMatrics"
                                            ).toUpperCase()}
                                          >
                                            <Public fontSize="small" />
                                          </StyledBadge>
                                        </Box>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </Tooltip>
                              ) : (
                                <Box
                                  sx={{
                                    display: "block",
                                    width: "100%",
                                    padding: "0 0.2em",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexGrow: 0,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      {enableBulk ? (
                                        <Checkbox
                                          size="small"
                                          checked={selected.some(
                                            (s) => s.uuid === row.uuid
                                          )}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          onMouseDown={(e) => {
                                            if (e.button !== 0) return;
                                            if (
                                              selected.some(
                                                (s) => s.uuid === row.uuid
                                              )
                                            ) {
                                              setSelected(
                                                selected.filter(
                                                  (s) => s.uuid !== row.uuid
                                                )
                                              );
                                              setDragChecked(false);
                                            } else {
                                              setSelected([...selected, row]);
                                              setDragChecked(true);
                                            }
                                          }}
                                          sx={{ p: 0 }}
                                        />
                                      ) : (
                                        getTagIcon(row.type)
                                      )}
                                    </Box>

                                    <Box
                                      flexGrow={1}
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        margin: "0 0.6em",
                                      }}
                                    >
                                      <Box component="span" fontWeight="bold">
                                        {value}
                                      </Box>
                                      {row.shortDescription ? (
                                        <Box
                                          component="span"
                                          className="font-weight-lighter"
                                        >
                                          {` — ${row.shortDescription}`}
                                        </Box>
                                      ) : null}
                                    </Box>

                                    {row.global !== undefined && row.global ? (
                                      <Box flexGrow={0} marginRight="4px">
                                        <StyledBadge
                                          badgeContent={row.language?.toUpperCase()}
                                        >
                                          <Public fontSize="small" />
                                        </StyledBadge>
                                      </Box>
                                    ) : !stringContainsNumber(row.source) ? (
                                      <Box flexGrow={0} marginRight="12px">
                                        <StyledBadge
                                          badgeContent={(
                                            row.source || "iMatrics"
                                          ).toUpperCase()}
                                        >
                                          <Public fontSize="small" />
                                        </StyledBadge>
                                      </Box>
                                    ) : null}
                                  </Box>
                                </Box>
                              )}
                            </TableCell>
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[25, 50, 100]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          sx={{
            width: "100%",
            overflowX: "hidden",
            borderTop: "1px solid rgba(0, 0, 0, 0.125)",
          }}
          classes={{
            displayedRows: classes.paginationLabel,
            selectLabel: classes.paginationLabel,
          }}
        />
      </Paper>
    </Box>
  );
};

export default CMConceptList;
