import styled from "styled-components";
import {
  space,
  layout,
  color,
  flexbox,
  border,
  position,
  typography,
} from "styled-system";

/** TYPES */
interface Size {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

/** FUNCTIONS */
const getWidthString = (size: number) => {
  if (!size) return;
  let width = (size / 12) * 100;
  return `width: ${width}%`;
};

/** COMPONENTS */
export const Row = styled.div<any>`
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const Col = styled.div<any>`
  float: left;
  ${space}
  ${({ xs }: Size) => (xs ? getWidthString(xs) : "width: 100%")};
  &:first-child {
  }
  &:last-child {
  }
  @media only screen and (min-width: 768px) {
    ${({ sm }: Size) => sm && getWidthString(sm)};
  }
  @media only screen and (min-width: 992px) {
    ${({ md }: Size) => md && getWidthString(md)};
  }
  @media only screen and (min-width: 1200px) {
    ${({ lg }: Size) => lg && getWidthString(lg)};
  }
`;

export const Container = styled.div<any>`
  position: relative;
  width: 100%;
  height: 100%;
  ${layout}
  ${space}
`;

export const Box = styled.div<any>`
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${flexbox}
  ${position}
`;

export const Span = styled.span<any>`
  ${space}
  ${layout}
  ${color}
  ${border}
  ${flexbox}
  ${typography}
`;

export const IconImage = styled.img<any>`
  ${space}
  image-rendering: optimizeSpeed;
  filter: grayscale(100%) brightness(20%) sepia(100%) hue-rotate(-180deg)
    saturate(700%) contrast(0.8);
`;

export const QuickLink = styled.div<any>`
  ${space}
  height: 72px;
  padding: 0.5rem;
  font-size: 9px;
  text-align: center;
  overflow-wrap: break-word;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #0b2444;
  background-color: #e0eaef;
  &:hover {
    font-weight: 700;
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) {
    height: 124px;
    font-size: 1rem;
  }
`;

export const H1 = styled.h1<any>`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const H2 = styled.h2<any>`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const H3 = styled.h3<any>`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 700;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SearchForm = styled.form<any>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 4px 8px;
`;

export const SearchWrapper = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 4px 8px;
`;

export const SearchInput = styled.input<any>`
  font-size: 120% !important;
  font-weight: normal !important;
  width: 100%;
  border: none;
  height: 48px;
  padding: 0 8px;
`;

export const FilterInput = styled.input<any>`
  font-weight: normal !important;
  width: 100%;
  border: none;
  padding: 0 4px;
`;

/*
export const Card = styled.div`
  position: relative;
  display: block;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  border-radius: 0 0 0.25rem 0.25rem;
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${flexbox}
  ${position}
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  }
`;
*/

export const IconButton = styled.button<any>`
  cursor: pointer;
  background-color: inherit;
  border: none;
  border-radius: 50%;
  ${space}
  ${border}
  ${layout}
  ${color}
  ${typography}
`;

export const Dropdown = styled.div<any>`
  position: absolute;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 0 0 0.25rem 0.25rem;
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${flexbox}
  ${position}
`;

export const DropdownList = styled.ul<any>`
  padding: 0;
  margin: 0;
  width: 100%;
  border-radius: 0.25rem;
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${flexbox}
  ${position}
`;

export const ListItem = styled.li<any>`
  padding: 0.25rem 0.5rem;
  list-style-type: none;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    font-weight: 700;
    cursor: pointer;
  }
`;

export const Chip = styled.span<any>`
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-size: 0.75rem;
  background-color: #eeeeee;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  margin-top: 0.4rem;
  &:last-child {
    margin-right: 0;
  }
  ${color}
`;

export const ImgIcon = styled.img<any>`
  ${space}
  cursor: ${({ noCursor }: any) => (noCursor ? "auto" : "pointer")};
  &:hover {
    opacity: ${({ noCursor }: any) => (noCursor ? 1 : 0.8)};
  }
`;

/** ############### OVERLAYS ############### */

export const Overlay = styled.section<any>`
  ${({ show }: any) => (show ? "visibility: visible" : "visibility: hidden")};
  ${({ show }: any) => (show ? "opacity: 1" : "opacity: 0")};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  z-index: 0;
`;

export const FilterOverlay = styled.div<any>`
  ${({ show }: any) => (show ? "top: 0" : "top: 100%")};
  ${({ show }: any) => (show ? "visiblity: visible" : "visibility: hidden")};
  position: absolute;
  width: 100%;
  height: 100%;
  left: auto;
  background-color: #fff;
  z-index: 1;
  transition: all 0.3s ease-out;
`;

/*
export const FilterOverlay = styled.div`
  ${({ show }: any) => (show ? "display: block" : "display: none")};
  position: absolute;
  width: 100%;
  height: 100%;
  top: auto;
  left: auto;
  background-color: #fff;
  z-index: 1;
`;
*/

/** ############### FILTER CONTENT ############### */
export const CollapsibleButton = styled.div<any>`
  width: 100%;
  padding: 0.4rem;
  background-color: #dedede;
  border-radius: 0.25rem;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const CollapsibleContent = styled.div<any>`
  opacity: ${({ open }: any) => (open ? "1" : "0")};
  max-height: ${({ open }: any) => (open ? "100%" : "0")};
  visibility: ${({ open }: any) => (open ? "visible" : "hidden")};
  width: 100%;
  background-color: inherit;
  transition: all 0.3s ease-out;
`;

/** ############### ARTICLE RESULTS ############### */

export const Button = styled.button<any>`
  ${({ fullWidth }: any) => (fullWidth ? "width: 100%" : "width: auto")};
  cursor: pointer;
  padding: 0.3rem;
  background-color: inherit;
  border: ${({ outlined }: any) =>
    outlined ? "1px solid rgba(0, 0, 0, 0.125)" : "none"};
  border-radius: 0.25rem;
  ${space}
  ${border}
  ${layout}
  ${color}
  &:hover {
    opacity: 0.85;
  }
`;

export const OutlinedButton = styled.button<any>`
  ${({ fullWidth }: any) => (fullWidth ? "width: 100%" : "width: auto")};
  padding: 0.25rem;
  background-color: inherit;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${space}
  ${border}
  ${layout}
  ${color}
  &:hover {
    opacity: 0.8;
  }
`;

export const PaginationButton = styled.button<any>`
  background-color: ${({ active }: any) => (active ? "#e0eaef" : "inherit")};
  display: inline-block;
  width: 30px;
  padding: 0.25rem;
  margin: 0.25rem;
  cursor: pointer;
  border: none;
  border-radius: 0.25rem;
`;

export const Select = styled.select<any>`
  cursor: pointer;
  border: none;
  background-color: inherit;
  cursor: pointer;
  &:active {
    border: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

export const Headline = styled.h4<any>`
font-family: Crete Round;
margin-top: 0;
@media only screen and (max-width: 768px) {
  font-size: 1.05rem
`;

export const Preamble = styled.p<any>`
  font-family: Crete Round;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media only screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const Dateline = styled.span<any>`
  font-size: 0.9rem;
  @media only screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

export const Tab = styled.button<any>`
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  cursor: pointer;
  background-color: inherit;
  ${({ active }: any) => (active ? `border-bottom: 2px solid #586cbf;` : ``)}
`;

export const Image = styled.img<any>`
  width: 100%;
  height: auto;
`;

export const ItemDiv = styled.div<any>`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(219, 219, 222, 0.6);
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.7rem;
`;

export const AuthorAvatar = styled.img<any>`
  vertical-align: middle;
  width: 40px;
  height: 40px;
  margin-right: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50%;
  object-fit: cover;
`;

export const AuthorNameDiv = styled.div<any>`
  dislay: block;
`;

export const Badge = styled.span<any>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  font-weight: normal;
  color: #fff;
  background-color: #56e39f;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50%;
`;

export const Loader = styled.div<any>`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #586cbf;
  width: ${({ size }: any) => size ?? "100px"};
  height: ${({ size }: any) => size ?? "100px"};
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
