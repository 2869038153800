import { useState, useEffect, useContext } from "react";
import { CTX } from "../../utils/ContextStore";
import { useLazyQuery } from "@apollo/client";
import { Box, MenuItem, TextField, CircularProgress } from "@mui/material";

import GET_ARTICLE_COUNT_IN_CHANNELS from "../../queries/GET_ARTICLE_COUNT_IN_CHANNELS";
import { InputAdornment } from "@mui/material";

interface IChannelData {
  uuid: string;
  title: string;
  count: number;
}

interface IProps {
  startDate: string;
  endDate: string;
  channel: string;
  setChannel?: (channel: string) => void;
  includeConcepts: string[];
  sx?: any;
}

const ChannelInput = (props: IProps) => {
  const { startDate, endDate, channel, setChannel, includeConcepts, sx } =
    props;
  const { activeEndpoint }: any = useContext(CTX);

  const [channels, setChannels] = useState<Array<string>>([]);

  const [getArticleCountInChannels, { loading, error }] = useLazyQuery(
    GET_ARTICLE_COUNT_IN_CHANNELS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        query: {
          startDate: startDate,
          endDate: endDate,
          concepts: includeConcepts,
        },
      },
      onCompleted: (data) => {
        setChannels(
          data.getArticleCountInChannels.map((data: IChannelData) => data.title)
        );
      },
      onError: (err) => {
        setChannels([]);
      },
    }
  );

  useEffect(() => {
    getArticleCountInChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", float: "right", width: 150 }}>
      <TextField
        id="channel-input"
        disabled={loading}
        fullWidth
        variant="outlined"
        size="small"
        select
        label="Channel"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          startAdornment: loading ? (
            <InputAdornment position="start">
              <CircularProgress size="1em" />
            </InputAdornment>
          ) : undefined,
          sx: { fontWeight: "bold" },
        }}
        value={channel}
        error={error ? true : false}
        SelectProps={{
          MenuProps: {
            MenuListProps: {
              dense: true,
              disablePadding: true,
            },
          },
        }}
        sx={sx}
      >
        <MenuItem
          value=""
          onClick={() => {
            setChannel && setChannel("");
          }}
        />
        {channels.map((channel: string) => (
          <MenuItem
            key={channel}
            value={channel}
            onClick={() => {
              setChannel && setChannel(channel);
            }}
          >
            {channel}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default ChannelInput;
