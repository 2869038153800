import { gql } from "@apollo/client";

const GET_MISSING_DATA = gql`
  query ($cluster: String, $indexPrefix: String) {
    getMissingData(cluster: $cluster, indexPrefix: $indexPrefix) {
      concepts {
        uuid
        type
        title
        keywords
        rootId
        broader
        shortDescription
        longDescription
        author
        source
        pubStatus
        index
        missingField
        hiddenInDetector
      }
      totalNumberOfConcepts
    }
  }
`;

export default GET_MISSING_DATA;
