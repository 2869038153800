import { useState, useEffect } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import {
  Paper,
  Grid,
  Box,
  Typography,
  LinearProgress,
  Alert,
} from "@mui/material";

import GET_ARTICLE_COUNT_OVER_TIME from "src/queries/GET_ARTICLE_COUNT_OVER_TIME";

import ProductionOverviewChart from "./ProductionOverviewChart";
import ProductionOverviewList from "./ProductionOverviewList";

interface IProps {
  endpoint: any;
  startDate: string;
  endDate: string;
  channel: string;
  newspaper: string;
  setChannel: (channel: string) => void;
  setNewspaper: (newspaper: string) => void;
  excludeAuthors: string[];
  includeConcepts: string[];
}

const ProductionOverview = (props: IProps) => {
  const {
    endpoint,
    startDate,
    endDate,
    channel,
    setChannel,
    includeConcepts,
    excludeAuthors,
  } = props;
  const [articlePublications, setArticlePublications] = useState<any>(null);
  const [plotData, setPlotData] = useState<any>([]);

  const [getArticleCountOverTime, { loading, error }] = useLazyQuery(
    GET_ARTICLE_COUNT_OVER_TIME,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        query: {
          startDate: startDate,
          endDate: endDate,
          channels: [channel],
          concepts: includeConcepts,
          excludeAuthors: excludeAuthors,
        },
      },
      onCompleted: (data) => {
        data = data.getArticleCountOverTime;
        const dataList: any =
          data?.map((data: any) => {
            return {
              date: moment(data.date).format("YYYY-MM-DD"),
              articles: data.count,
            };
          }) ?? [];
        setPlotData(dataList);
        const totalCount = dataList?.reduce((acc: number, obj: any) => {
          return acc + obj.articles;
        }, 0);
        setArticlePublications(totalCount);
      },
      onError: (err) => {
        setPlotData([]);
      },
    }
  );

  useEffect(() => {
    setPlotData([]);
    setArticlePublications(null);

    if (startDate && endDate) getArticleCountOverTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, channel, startDate, endDate, excludeAuthors]);

  return (
    <Paper sx={{ mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          borderBottom: "1px solid #e0e0e0",
          bgcolor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <Typography component="span" variant="overline" fontWeight="bold">
          Publication overview
        </Typography>
        {!loading && (
          <Typography component="span" variant="overline" fontWeight="bold">
            {(articlePublications ?? 0) + " articles"}
          </Typography>
        )}
      </Box>
      {loading && (
        <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />
      )}
      <Grid container>
        <Grid item md={9} sx={{ minHeight: "320px", height: "30vh", p: 2 }}>
          {error ? (
            <Alert severity="error">
              No data found. Try to reload the page. If problem persist, please
              contact iMatrics.
            </Alert>
          ) : loading ? null : plotData?.length === 0 ? (
            <Alert severity="error" sx={{ display: "inline-flex" }}>
              No publication data found for the selected dataset.
            </Alert>
          ) : (
            <ProductionOverviewChart data={plotData} />
          )}
        </Grid>

        <Grid item md={3}>
          <ProductionOverviewList
            endpoint={endpoint}
            startDate={startDate}
            endDate={endDate}
            channel={channel}
            setChannel={setChannel}
            includeConcepts={includeConcepts}
            excludeAuthors={excludeAuthors}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductionOverview;
