import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";

interface Props {
  identifiers: any[];
  setIdentifiers: (newIdentifiers: any[]) => void;
}

const IdentifierContent = ({ identifiers, setIdentifiers }: Props) => {
  const editIdentifier = (i: number, newValue: string, field: string) => {
    let temp: any[] = [...identifiers];
    temp[i][field] = newValue;
    setIdentifiers(temp);
  };

  const removeIdentifier = (i: number) => {
    let temp: any[] = [...identifiers];
    temp.splice(i, 1);
    setIdentifiers(temp);
  };

  const addNewIdentifier = () => {
    setIdentifiers([...identifiers, { identifier: "", URL: "" }]);
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "1rem" }}>Identifiers</Typography>

      {identifiers.map((identifier: any, i: number) => (
        <Grid key={i} container spacing={2} style={{ marginBottom: "0.5rem" }}>
          <Grid item xs={4}>
            <TextField
              label="Identifier"
              variant="outlined"
              size="small"
              fullWidth
              value={identifier.identifier}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                editIdentifier(i, e.target.value, "identifier")
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <TextField
                label="URL"
                variant="outlined"
                size="small"
                fullWidth
                value={identifier.URL}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  editIdentifier(i, e.target.value, "URL")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box marginLeft="1rem">
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => {
                    removeIdentifier(i);
                  }}
                >
                  <Delete fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Box my={2}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            addNewIdentifier();
          }}
        >
          <Box display="flex" alignItems="center">
            <AddCircle style={{ marginRight: "1rem" }} />
            <span>Insert additional identifier</span>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default IdentifierContent;
