import gql from "graphql-tag";

const GET_STRATEGIES = gql`
  query {
    getStrategies {
      name
      description
      isABTest
    }
  }
`;

export default GET_STRATEGIES;
