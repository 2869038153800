import gql from "graphql-tag";

const UPDATE_API_DOCS = gql`
  mutation (
    $name: String
    $content: String
    $pubStatus: Boolean
    $priv: String
  ) {
    setApiDocs(
      name: $name
      content: $content
      pubStatus: $pubStatus
      priv: $priv
    )
  }
`;

export default UPDATE_API_DOCS;
