import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { CTX as CMCTX } from "../CMContext";
import { CTX } from "../../../utils/ContextStore";

import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import ReactCountryFlag from "react-country-flag";

import StatementContent from "./StatementContent";
import IdentifierContent from "./IdentifierContent";
import AliasInput from "../AliasInput";

import EDIT_CONCEPT from "../../../mutations/editConcept";
import PUBLISH_TO_WIKIDATA from "../../../mutations/PUBLISH_TO_WIKIDATA";

/** Method to convert country code to the actual code in order to render the country flag */
const convertCountryCode: any = (country: string) => {
  if (!country) return "";
  if (country === "sv") country = "se";
  if (country === "en") country = "us";
  if (country === "da") country = "dk";
  return country.toUpperCase();
};

const availableLanguages = ["sv", "en", "da", "no", "de", "nl"].sort();

const flagStyle = {
  width: "1.4rem",
  height: "1.4rem",
  objectFit: "cover",
  borderRadius: "50%",
  marginRight: "0.5rem",
};

interface Concept {
  label: string;
  description: string;
  aka: string[];
  language: string;
}

const WikidataContent = () => {
  const { setWikiMode, wikiConceptConverted, wikiConcept }: any =
    useContext(CMCTX);
  const { setShowSnackbar, setSnackbarMessage, setSnackbarError }: any =
    useContext(CTX);

  const [languages, setLanguages] = useState<Concept[]>(
    wikiConceptConverted.languageFields
  );
  const [statements, setStatements] = useState<any[]>(
    wikiConceptConverted.claims.map((claim: any) => {
      return {
        P: claim.P,
        Q: claim.Q,
        termP: `${claim.P.label} (${claim.P.id})`,
        termQ: `${claim.Q.label} (${claim.Q.id})`,
        suggestedP: [],
        suggestedQ: [],
        showSuggestedP: false,
        showSuggestedQ: false,
        isLoadingP: false,
        isLoadingQ: false,
      };
    })
  );
  const [identifiers, setIdentifiers] = useState<any[]>([]);

  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const [publishToWikidata] = useMutation(PUBLISH_TO_WIKIDATA);
  const [focusIndex, setFocusIndex] = useState<number>(-1);

  const [updateConcept] = useMutation(EDIT_CONCEPT);

  const editConcept = (i: number, newValue: any, field: string) => {
    let temp: any[] = [...languages];
    temp[i][field] = newValue;
    setLanguages(temp);
  };

  const removeConcept = (i: number) => {
    let temp: any[] = [...languages];
    temp.splice(i, 1);
    setLanguages(temp);
  };

  const addNewLanguage = () => {
    setLanguages([
      ...languages,
      { label: "", aka: [], description: "", language: "" },
    ]);
  };

  const handlePublish = () => {
    let claims: any = [];
    statements.forEach((statement: any) => {
      if (statement.P.id && statement.Q.id) {
        claims.push({ P: statement.P, Q: statement.Q });
      }
    });
    setIsLoadingPublish(true);
    setShowSnackbar(false);
    setSnackbarError(false);
    setUpdateError(false);
    publishToWikidata({
      variables: {
        wikiConcept: {
          claims: claims,
          languageFields: languages,
        },
      },
    })
      .then((data: any) => {
        if (
          data.data.publishToWikidata.message.includes("failed") ||
          data.data.publishToWikidata.message.includes("Failed")
        )
          setSnackbarError(true);
        else {
          if (data.data.publishToWikidata.id !== 0)
            updateConcept({
              variables: { ...wikiConcept, id: data.data.publishToWikidata.id },
            })
              .then(() => setWikiMode(false))
              .catch((err: Error) => {
                setUpdateError(true);
                console.error(err);
              });
        }
        setSnackbarMessage(data.data.publishToWikidata.message);
      })
      .catch(() => {
        setSnackbarError(true);
        setSnackbarMessage("Failed to publish");
      })
      .finally(() => {
        setIsLoadingPublish(false);
        setShowSnackbar(true);
      });
  };

  const handleCancel = () => {
    setWikiMode(false);
  };

  return (
    <Box py={1}>
      <Typography style={{ marginBottom: "1rem" }}>Languages</Typography>
      {languages.map((concept: any, i: number) => (
        <Grid key={i} container spacing={2} style={{ marginBottom: "0.5rem" }}>
          <Grid item xs={4}>
            <Box display="flex">
              <TextField
                select
                label="Language"
                value={concept.language}
                variant="outlined"
                size="small"
                style={{
                  marginRight: "1rem",
                  width: "fit-content",
                  minWidth: "110px",
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editConcept(i, e.target.value, "language");
                }}
                SelectProps={{ style: { maxHeight: "38.79px" } }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!concept.language}
                helperText={!concept.language ? "Required" : null}
              >
                {availableLanguages.map((language) => (
                  <MenuItem
                    key={language}
                    value={language}
                    disabled={languages.some(
                      (c: Concept) =>
                        c.language === language && concept.language !== language
                    )}
                  >
                    <Box display="flex" alignItems="center">
                      <ReactCountryFlag
                        id="country-flag"
                        countryCode={convertCountryCode(language)}
                        svg
                        style={flagStyle}
                        title={convertCountryCode(language)}
                      />
                      <Typography component="span" style={{ fontWeight: 700 }}>
                        {language.toUpperCase()}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Label"
                variant="outlined"
                size="small"
                fullWidth
                value={concept.label}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  editConcept(i, e.target.value, "label")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={3}>
            <AliasInput
              id={"aka-input-" + i}
              label="Also known as"
              aliases={concept.aliases}
              setAliases={(newValue: any[]) =>
                editConcept(i, newValue, "aliases")
              }
              compact={true}
            />
          </Grid>

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <TextField
                multiline
                maxRows={focusIndex === i ? 3 : 1}
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
                value={concept.description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  editConcept(i, e.target.value, "description")
                }
                onFocus={() => {
                  setFocusIndex(i);
                }}
                onBlur={() => {
                  setFocusIndex(-1);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box marginLeft="1rem">
                <IconButton
                  size="small"
                  onClick={() => removeConcept(i)}
                  disabled={i === 0}
                  color="secondary"
                >
                  <Delete fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Box my={2}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            addNewLanguage();
          }}
        >
          <Box display="flex" alignItems="center">
            <AddCircle style={{ marginRight: "1rem" }} />
            <span>Insert additional language</span>
          </Box>
        </Button>
      </Box>
      <hr />

      <StatementContent statements={statements} setStatements={setStatements} />
      <hr />
      <IdentifierContent
        identifiers={identifiers}
        setIdentifiers={setIdentifiers}
      />
      <hr />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          {updateError && (
            <Alert severity="error">Failed to update EntityDB concept</Alert>
          )}
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {isLoadingPublish && (
              <Grid item>
                <CircularProgress color="primary" size="1.5rem" />
              </Grid>
            )}
            <Grid item>
              <Button
                size="medium"
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isLoadingPublish}
                size="medium"
                variant="outlined"
                color="primary"
                onClick={handlePublish}
              >
                Publish
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WikidataContent;
