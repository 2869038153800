import { Switch, Route } from "react-router-dom";
import { Box } from "@mui/material";

import Dashboard from "../Dashboard/Dashboard";
import UserTableComponent from "./UserTableComponent";
import CustomerSettingsComponent from "./CustomerSettingsComponent";

interface IOption {
  id: string;
  privs?: string[];
  path: string;
  label?: string;
  description?: string;
  image?: string;
}

const options: Array<IOption> = [
  {
    id: "user-panel",
    label: "Users",
    description: "Manage webportal users",
    path: "/admin-panel/users",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/manage-users_small.jpg",
  },

  {
    id: "customer-settings-panel",
    label: "Customer settings",
    privs: ["ADMIN"],
    path: "/admin-panel/customer-settings",
    description: "Manage customer settings",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/customer-settings_small.jpg",
  },
];

const AdminPanelComponent = () => {
  return (
    <Box sx={{ height: "100%" }}>
      <Switch>
        <Route
          path="/admin-panel"
          exact={true}
          component={() => <Dashboard options={options} />}
        />
        <Route
          path="/admin-panel/users"
          exact={true}
          component={UserTableComponent}
        />
        <Route
          path="/admin-panel/customer-settings"
          exact={true}
          component={CustomerSettingsComponent}
        />
      </Switch>
    </Box>
  );
};

export default AdminPanelComponent;
