import config from "./config.json";
import { useState, Fragment, useEffect, useContext } from "react";
import { CTX } from "./Context";
import { Box, Span, H3, OutlinedButton } from "./StyledComponents";
import { Author, ItemType, Lang } from "./TypeDefs";

import AuthorItem from "./AuthorItem";
import Pagination from "./Pagination";

const LANG = config.LANG;

interface Labels {
  authors: Lang | any;
  viewMore: Lang | any;
  results: Lang | any;
  result: Lang | any;
  noFound: Lang | any;
}

const labels: Labels = {
  authors: { en: "Authors", sv: "Reportrar" },
  viewMore: { en: "View more", sv: "Visa fler" },
  results: { en: "results", sv: "resultat" },
  result: { en: "result", sv: "resultat" },
  noFound: { en: "No authors found", sv: "Inga reportrar hittades" },
};

interface Props {
  overview?: boolean;
  authors: Author[];
  numberOfResult: number;
  onSubmit?: (e: React.FormEvent, initTab: number) => void;
  onOptionClick?: (type: ItemType, uuid: string) => void;
}

const AuthorResults = (props: Props) => {
  const {
    overview = false,
    authors,
    numberOfResult,
    onSubmit,
    onOptionClick,
  } = props;

  const { history } = useContext(CTX);

  const maxPages = Math.ceil(numberOfResult / 20);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const page = Number(urlParams.get("p") || 1);
    Number(page) > maxPages ? setPage(1) : setPage(Number(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfResult]);

  return (
    <Box py={2}>
      {overview && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <H3>{labels.authors[LANG]}</H3>
          <Span
            style={{ fontSize: "0.8rem", cursor: "pointer" }}
            onClick={(e: React.FormEvent) => onSubmit && onSubmit(e, 3)}
          >{`View ${labels.results[LANG]} >`}</Span>
        </Box>
      )}

      {authors && authors.length > 0 ? (
        <Fragment>
          {authors.map((author: Author) => (
            <AuthorItem
              author={author.title}
              key={author.uuid}
              onClick={() => {
                onOptionClick && onOptionClick("author", author.uuid);
              }}
            />
          ))}

          {overview ? (
            <OutlinedButton
              fullWidth
              onClick={(e: React.FormEvent) => onSubmit && onSubmit(e, 3)}
            >
              {labels.viewMore[LANG]}
            </OutlinedButton>
          ) : (
            <Pagination pages={maxPages} page={page} setPage={setPage} />
          )}
        </Fragment>
      ) : (
        <Span color="gray">{labels.noFound[LANG]}</Span>
      )}
    </Box>
  );
};

export default AuthorResults;
