import gql from "graphql-tag";
const getTagsQuery = gql`
  mutation($articleText: String) {
    tagArticle(query: $articleText) {
      weight
      title
      type
      uuid
      shortDescription
      wikipedia
      wikidata
      openStreetMap
      source
      longDescription
      pubStatus
      aliases
      author
      createdTimestamp
      latestVersionTimestamp
      broader
      geoJSON
      global
      language
    }
  }
`;

export default getTagsQuery;
