import gql from "graphql-tag";

const ADD_USER = gql`
  mutation ($userdata: UserInput) {
    addUser(userdata: $userdata) {
      username
      password
      created
      privs
      docPrivs
      queue
      webdemo {
        dataset
        expires
        api_calls
        max_api_calls
      }
    }
  }
`;

export default ADD_USER;
