import { useState, Fragment } from "react";
import {
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import CMMap from "./CMMap";
import { Close } from "@mui/icons-material";

const MapModal = (props: any) => {
  const {
    pointSelectedCallback,
    selectedGeoJSON,
    pointSelectedConfirm,
    concept,
    open,
    onHide,
    ...rest
  } = props;

  const [latLng, setLatLng] = useState<string>("");
  const [inputError, setInputError] = useState<boolean>(false);

  return (
    <Dialog {...rest} fullWidth maxWidth="md" open={open}>
      <Box style={{ backgroundColor: "#fefefe" }}>
        <Box
          py={0.5}
          pl={2}
          pr={0.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid rgba(0,0,0,0.2)"
        >
          <Typography color="primary">
            <Box component="span" fontWeight={700}>
              Right-click
            </Box>
            <Box component="span" color="#000">
              {" "}
              to set new location
            </Box>
          </Typography>
          <IconButton onClick={onHide}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <CMMap
          concept={concept}
          latLng={latLng || ""}
          setLatLng={setLatLng}
          pointSelectedCallback={pointSelectedCallback}
          setError={setInputError}
        />
        <Box
          py={1}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderTop="1px solid rgba(0,0,0,0.2)"
        >
          <TextField
            autoComplete="off"
            name="latlng-input"
            id="latlng-input"
            label="Latitude, Longitude"
            variant="outlined"
            size="small"
            error={inputError}
            value={latLng}
            inputProps={{
              style: {
                padding: "6.25px 14px",
              },
            }}
            style={{ width: "40%" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setLatLng(e.target.value);
            }}
            helperText={
              inputError ? (
                <Fragment>
                  <span>Invalid input. E.g. </span>
                  <span style={{ fontStyle: "italic", fontWeight: 500 }}>
                    51.505, -0.09
                  </span>
                </Fragment>
              ) : null
            }
          />
          <Box>
            <Button
              disabled={!selectedGeoJSON}
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginRight: "1em" }}
              onClick={() => {
                pointSelectedConfirm();
                onHide();
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={onHide}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MapModal;
