import gql from "graphql-tag";
const GET_STATISTIC = gql`
  query (
    $statisticOperation: String
    $returnSize: Int
    $conceptTitle: String
    $conceptType: String
    $newspaper: String
    $channel: String
    $startDate: String
    $endDate: String
    $conceptArray: [String]
    $parent: String
  ) {
    getStatistic(
      statisticOperation: $statisticOperation
      returnSize: $returnSize
      conceptTitle: $conceptTitle
      conceptType: $conceptType
      newspaper: $newspaper
      channel: $channel
      startDate: $startDate
      endDate: $endDate
      conceptArray: $conceptArray
      parent: $parent
    ) {
      result {
        key
        channel
        newspaper
        frequency
        title
        timestamp
        uuid
        headline
        concepts {
          title
          type
          uuid
        }
        title
        type
        frequency_last_period
        frequency_current_period_last_year
        timestamps {
          timestamp
          frequency
        }
      }
    }
  }
`;

export default GET_STATISTIC;
