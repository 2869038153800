import { useState, useEffect, useContext } from "react";
import { CTX, Endpoint } from "../../utils/ContextStore";
import { TextField, MenuItem, Autocomplete } from "@mui/material";

interface Props {
  disabled?: boolean;
  multiple?: boolean;
  sx?: any;
}

const DatasetPicker = (props: Props) => {
  const { disabled, multiple, sx } = props;
  const {
    activeEndpoint,
    setActiveEndpoint,
    setPrevEndpoint,
    displayAltDatasetName,
    endpointList,
    updateEndpoint,
    selectedEndpoints,
    setSelectedEndpoints,
  }: any = useContext(CTX);

  const [render, setRender] = useState(false);

  useEffect(() => {
    setRender(true);
  }, []);

  if (!render) return null;

  if (multiple) {
    return (
      <Autocomplete
        multiple
        fullWidth
        limitTags={1}
        id="endpoint-input-multiple"
        size="small"
        options={endpointList
          .sort((a: Endpoint, b: Endpoint) => a.name.localeCompare(b.name))
          .map((option: Endpoint) => option.name)}
        getOptionLabel={(option) => option}
        value={selectedEndpoints.map((option: Endpoint) => option.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Datasets"
            InputLabelProps={{ shrink: true }}
          />
        )}
        onChange={(e, value) => {
          setSelectedEndpoints(
            endpointList.filter((endpoint: Endpoint) =>
              value.includes(endpoint.name)
            )
          );
        }}
        sx={sx}
      />
    );
  }

  return (
    <TextField
      disabled={disabled}
      id="endpoint-input"
      fullWidth
      variant="outlined"
      size="small"
      select
      label="Dataset"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ sx: { fontWeight: "bold" } }}
      value={
        activeEndpoint
          ? displayAltDatasetName
            ? activeEndpoint.alternativeName
            : activeEndpoint.name
          : ""
      }
      SelectProps={{
        MenuProps: {
          MenuListProps: {
            dense: true,
            disablePadding: true,
          },
        },
      }}
      sx={
        sx
          ? sx
          : {
              width: 213,
              float: "right",
              textAlign: "left",
              backgroundColor: "#fff",
            }
      }
    >
      {endpointList.map((endpoint: Endpoint, index: number) => (
        <MenuItem
          key={index}
          value={
            displayAltDatasetName ? endpoint.alternativeName : endpoint.name
          }
          onClick={() => {
            updateEndpoint({
              variables: endpoint,
              onCompleted: (res: any) => {
                if (res?.setEndpoint) {
                  setPrevEndpoint(activeEndpoint);
                  setActiveEndpoint(res.setEndpoint);
                } else {
                  console.error("Failed to update dataset");
                }
              },
              onError: (err: Error) => {
                console.error(err.message);
              },
            });
          }}
        >
          {displayAltDatasetName ? endpoint.alternativeName : endpoint.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default DatasetPicker;
