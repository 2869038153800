import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Typography, Paper, Grid, LinearProgress } from "@mui/material";

import GET_CONCEPT_USAGE_OVER_TIME from "../../queries/GET_CONCEPT_USAGE_OVER_TIME";

import ConceptOverviewList from "./ConceptOverviewList";
import ConceptSearchField from "./ConceptSearchField";
import ConceptOvertimeBarChart from "./ConceptOvertimeBarChart";

interface IConcept {
  uuid: string;
  title: string;
  type: string;
  usage: number;
}

interface IProps {
  endpoint: any;
  filterConcepts: IConcept[];
  setFilterConcepts: any;
  startDate: string;
  endDate: string;
  newspaper: string;
  channel: string;
  excludeAuthors: string[];
  addToConceptList: any;
  removeFromConceptList: any;
  clearConceptList: () => void;
}

const ConceptOverview = (props: IProps) => {
  const {
    endpoint,
    filterConcepts,
    startDate,
    endDate,
    newspaper,
    channel,
    excludeAuthors,
    addToConceptList,
    removeFromConceptList,
    clearConceptList,
  } = props;

  const client = useApolloClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [plotData, setPlotData] = useState<Array<any>>([]);
  const [listData, setListData] = useState<Array<any>>([]);

  const formatPlotData = (data: any) => {
    let formattedData: any[] = [];
    data.forEach((concept: any) => {
      const dataList = concept.result.map((plot: any) => {
        return {
          x: plot.date,
          y: plot.usage,
        };
      });

      const newData = {
        id: concept.title,
        data: dataList,
      };
      formattedData.push(newData);
    });

    return formattedData;
  };

  useEffect(() => {
    setPlotData([]);
    setListData([]);
    clearConceptList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, startDate, endDate, channel, newspaper, excludeAuthors]);

  useEffect(() => {
    // Remove concept from plotdata if it doesn't exist in filterConcepts
    setPlotData(
      plotData.filter((plot: any) =>
        filterConcepts.some((concept: any) => plot.id === concept.title)
      )
    );

    setListData(
      listData.filter((concept: any) =>
        filterConcepts.some(
          (filterConcept: any) => filterConcept.uuid === concept.uuid
        )
      )
    );

    if (filterConcepts.length < 1) return;

    const queryConcepts = filterConcepts.filter(
      (concept: any) => !plotData.some((plot: any) => plot.id === concept.title)
    );
    const queries = queryConcepts.map((concept: any) => {
      const variables = {
        query: {
          uuid: concept.uuid,
          startDate,
          endDate,
          channels: channel ? [channel] : [],
          excludeAuthors: excludeAuthors,
        },
      };
      return client.query({
        query: GET_CONCEPT_USAGE_OVER_TIME,
        variables: variables,
      });
    });
    if (queries.length === 0) return;
    setIsLoading(true);
    Promise.all(queries)
      .then((results) => {
        const data: any = results.map(
          (result) => result.data.getConceptUsageOverTime
        );

        // replace the title in data with the title matching uuid in filterConcepts
        let newData = data.map((concept: any) => {
          const title = filterConcepts.find(
            (filterConcept) => filterConcept.uuid === concept.uuid
          )?.title;
          const type = filterConcepts.find(
            (filterConcept) => filterConcept.uuid === concept.uuid
          )?.type;
          return { ...concept, title, type };
        });

        let formattedPlotData = formatPlotData(newData);

        setPlotData([...plotData, ...formattedPlotData]);
        setListData([...listData, ...newData]);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConcepts]);

  return (
    <Paper sx={{ width: "100%" }}>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          bgcolor: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <Grid item xs={9}>
          <Typography variant="overline" sx={{ px: 2, fontWeight: "bold" }}>
            Concept overview
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <ConceptSearchField
            endpoint={endpoint}
            addToConceptList={addToConceptList}
          />
        </Grid>
      </Grid>

      {isLoading && (
        <LinearProgress sx={{ visibility: isLoading ? "visible" : "hidden" }} />
      )}

      <Grid container>
        <Grid item md={9}>
          <ConceptOvertimeBarChart plotData={plotData} />
        </Grid>
        <Grid item md={3} sx={{ borderLeft: "1px solid #e0e0e0" }}>
          <ConceptOverviewList
            data={listData}
            endpoint={endpoint}
            startDate={startDate}
            endDate={endDate}
            newspaper={newspaper}
            channel={channel}
            excludeAuthors={excludeAuthors}
            removeFromConceptList={removeFromConceptList}
            clearConceptList={clearConceptList}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConceptOverview;
