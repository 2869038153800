import { gql } from "@apollo/client";

const GET_PUBLISHED_ARTICLES = gql`
  query ($query: GetPublishedArticleInput) {
    getPublishedArticles(query: $query) {
      uuid
      headline
      preamble
      body
      publicationTimestamp
      concepts {
        uuid
        title
        type
      }
      evaluation {
        correct {
          uuid
          title
          type
        }
        incorrect {
          uuid
          title
          type
        }
        missed {
          uuid
          title
          type
        }
      }
    }
  }
`;

export default GET_PUBLISHED_ARTICLES;
