import { gql } from "@apollo/client";

const GET_QE_FILES = gql`
  query {
    getQEFiles {
      name
      size
    }
  }
`;

export default GET_QE_FILES;
