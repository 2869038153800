import { useMutation } from "@apollo/client";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";

import GET_WIKIDATA_P from "../../../mutations/GET_WIKIDATA_P";
import GET_WIKIDATA_Q from "../../../mutations/GET_WIKIDATA_Q";

let timeoutP: ReturnType<typeof setTimeout>;
let timeoutQ: ReturnType<typeof setTimeout>;

interface PQValue {
  id: string;
  label: string;
  description: string;
}

interface Statement {
  P: PQValue;
  Q: PQValue;
  termP: string;
  termQ: string;
  suggestedP: [PQValue];
  suggestedQ: [PQValue];
  showSuggestedP: boolean;
  showSuggestedQ: boolean;
  isLoadingP: boolean;
  isLoadingQ: boolean;
}

interface Props {
  statements: Statement[];
  setStatements: (newStatements: Statement[] | any[]) => void;
}

const StatementContent = ({ statements, setStatements }: Props) => {
  const editStatement = (i: number, newValue: any, field: string) => {
    let temp: any[] = [...statements];
    temp[i][field] = newValue;
    setStatements(temp);
  };

  const removeStatement = (i: number) => {
    let temp: any[] = [...statements];
    temp.splice(i, 1);
    setStatements(temp);
  };

  const addNewStatement = () => {
    setStatements([
      ...statements,
      {
        Q: { id: "", label: "", description: "" },
        P: { id: "", label: "", description: "" },
        termP: "",
        termQ: "",
        suggestedP: [],
        suggestedQ: [],
        showSuggestedQ: false,
        showSuggestedP: false,
        isLoadingP: false,
        isLoadingQ: false,
      },
    ]);
  };

  const [getValueP] = useMutation(GET_WIKIDATA_P);
  const [getValueQ] = useMutation(GET_WIKIDATA_Q);

  const handleChangeP = (value: string, i: number) => {
    timeoutP && clearTimeout(timeoutP);
    editStatement(i, value, "termP");
    if (!value) {
      editStatement(i, { id: "", label: "", description: "" }, "P");
      editStatement(i, [], "suggestedP");
      editStatement(i, false, "isLoadingQ");
    } else if (value.length > 1) {
      timeoutP = setTimeout(() => {
        editStatement(i, true, "isLoadingP");
        let claims: any = [];
        statements.forEach((statement: any) => {
          if (statement.P.id && statement.Q.id)
            claims.push({ P: statement.P, Q: statement.Q });
        });
        getValueP({
          variables: {
            title: value,
            pqValue: claims,
          },
        })
          .then((data: any) => {
            editStatement(i, data.data.getWikidataValueP || [], "suggestedP");
          })
          .finally(() => editStatement(i, false, "isLoadingP"));
      }, 500);
    }
  };

  const handleChangeQ = (value: string, i: number) => {
    timeoutQ && clearTimeout(timeoutQ);
    editStatement(i, value, "termQ");
    if (!value) {
      editStatement(i, { id: "", label: "", description: "" }, "Q");
      editStatement(i, [], "suggestedQ");
      editStatement(i, false, "isLoadingQ");
    } else if (value.length > 1) {
      timeoutQ = setTimeout(() => {
        editStatement(i, true, "isLoadingQ");
        getValueQ({
          variables: {
            title: value,
          },
        })
          .then((data: any) => {
            editStatement(i, data.data.getWikidataValueQ || [], "suggestedQ");
          })
          .finally(() => editStatement(i, false, "isLoadingQ"));
      }, 500);
    }
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "1rem" }}>Statements</Typography>
      {statements?.map((statement: Statement, i: number) => (
        <Grid key={i} container spacing={2} style={{ marginBottom: "0.5rem" }}>
          <Grid item xs={4}>
            <Box width="100%" position="relative">
              <TextField
                label="P-value"
                variant="outlined"
                size="small"
                fullWidth
                value={statement.termP}
                error={!statement.P.id}
                helperText={
                  !statement.P.id ? (
                    <Box position="absolute" component="span" color="red">
                      Select P-value
                    </Box>
                  ) : null
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeP(e.target.value, i)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onFocus={() => editStatement(i, true, "showSuggestedP")}
                onBlur={() =>
                  setTimeout(
                    () => editStatement(i, false, "showSuggestedP"),
                    100
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {statement?.isLoadingP && (
                        <CircularProgress size="1rem" color="primary" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                display={
                  statement?.suggestedP && statement.suggestedP?.length > 0
                    ? "block"
                    : "none"
                }
                position="absolute"
                bgcolor="#fff"
                border="1px solid rgba(0,0,0,0.125)"
                borderRadius="0.25rem"
                width="100%"
                zIndex={1}
                maxHeight="200px"
                overflow="auto"
              >
                {statement?.showSuggestedP &&
                  statement?.suggestedP?.map((P: PQValue) => (
                    <ListItem
                      key={P.id}
                      button
                      dense
                      onClick={() => {
                        editStatement(i, P, "P");
                        editStatement(i, `${P.label} (${P.id})`, "termP");
                        editStatement(i, [], "suggestedP");
                        editStatement(i, false, "showSuggestedP");
                      }}
                    >
                      <ListItemText
                        primary={`${P.label} (${P.id})`}
                        secondary={P.description}
                      />
                    </ListItem>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <Box width="100%" position="relative">
                <TextField
                  label="Q-value"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={statement.termQ}
                  error={!statement.Q.id}
                  helperText={
                    !statement.Q.id ? (
                      <Box position="absolute" component="span" color="red">
                        Select Q-value
                      </Box>
                    ) : null
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeQ(e.target.value, i)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onFocus={() => editStatement(i, true, "showSuggestedQ")}
                  onBlur={() =>
                    setTimeout(
                      () => editStatement(i, false, "showSuggestedQ"),
                      100
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {statement?.isLoadingQ && (
                          <CircularProgress size="1rem" color="primary" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  display={
                    statement?.showSuggestedQ &&
                    statement.suggestedQ?.length > 0
                      ? "block"
                      : "none"
                  }
                  position="absolute"
                  bgcolor="#fff"
                  border="1px solid rgba(0,0,0,0.125)"
                  borderRadius="0.25rem"
                  width="100%"
                  zIndex={1}
                  maxHeight="200px"
                  overflow="auto"
                >
                  {statement?.suggestedQ?.map((Q: PQValue) => (
                    <ListItem
                      key={Q.id}
                      button
                      dense
                      onClick={() => {
                        editStatement(i, Q, "Q");
                        editStatement(i, `${Q.label} (${Q.id})`, "termQ");
                        editStatement(i, [], "suggestedQ");
                        editStatement(i, false, "showSuggestedQ");
                      }}
                    >
                      <ListItemText
                        primary={`${Q.label} (${Q.id})`}
                        secondary={Q.description}
                      />
                    </ListItem>
                  ))}
                </Box>
              </Box>
              <Box marginLeft="1rem">
                <IconButton
                  disabled={i === 0}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    removeStatement(i);
                  }}
                >
                  <Delete fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Box my={2}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            addNewStatement();
          }}
        >
          <Box display="flex" alignItems="center">
            <AddCircle style={{ marginRight: "1rem" }} />
            <span>Insert additional statement</span>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default StatementContent;
