import config from "./config.json";
import { Switch, Route } from "react-router-dom";
import { useState, useEffect, useContext, Fragment } from "react";
import { CTX } from "./Context";
import { Grid, Box } from "@mui/material";

import RecommendedNewsComponent from "./RecommendedNewsComponent";
import PopularNewsComponent from "./PopularNewsComponent";
import RecentNewsComponent from "./RecentNewsComponent";
import LocationRecentNewsComponent from "./LocationRecentNewsComponent";
import ArticlePage from "./ArticlePage";
import TopicPage from "./TopicPage";
import AuthorPage from "./AuthorPage";
import AdPlaceholderComponent from "./AdPlaceholderComponent";
import LiveNewsComponent from "./LiveNewsComponent";

const StartPage = () => {
  return (
    <Grid container spacing={3} px={[1, 1, 1, 0]}>
      <Grid item xs={12}>
        <AdPlaceholderComponent numberOfAds={1} />
      </Grid>

      <Grid container item spacing={3} xs={12} md={8} order={{ xs: 2, md: 1 }}>
        <Grid item xs={12}>
          <LiveNewsComponent />
        </Grid>
        <Grid item xs={12}>
          <RecommendedNewsComponent />
        </Grid>
        <Grid item xs={12}>
          <LocationRecentNewsComponent />
        </Grid>
        <Grid item xs={12}>
          <PopularNewsComponent />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        xs={12}
        md={4}
        order={{ xs: 1, md: 2 }}
        alignContent="start"
      >
        <Grid item xs={12}>
          <RecentNewsComponent />
        </Grid>
        <Grid item xs={12}>
          <AdPlaceholderComponent numberOfAds={5} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const PageContentComponent = () => {
  const { selectedUserProfile } = useContext(CTX);
  const [displayStart, setDisplayStart] = useState<boolean>(false);

  useEffect(() => {
    setDisplayStart(false);
    setTimeout(() => {
      setDisplayStart(true);
    }, 300);
  }, [selectedUserProfile]);

  return (
    <Switch>
      <Route
        path="/imatrics-times"
        exact={true}
        render={() => displayStart && <StartPage />}
      />
      <Route
        path={config.BASE_URL + config.PATHS.ARTICLE}
        exact={true}
        render={() => (
          <Fragment>
            <Box sx={{ mb: 4 }}>
              <AdPlaceholderComponent numberOfAds={1} />
            </Box>
            <ArticlePage />
          </Fragment>
        )}
      />
      <Route
        path={config.BASE_URL + config.PATHS.TOPIC}
        exact={true}
        render={() => <TopicPage />}
      />
      <Route
        path={config.BASE_URL + config.PATHS.AUTHOR}
        exact={true}
        render={() => <AuthorPage />}
      />
    </Switch>
  );
};

export default PageContentComponent;
