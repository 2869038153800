import gql from "graphql-tag";
const GET_ARTICLE_COUNT_IN_CHANNELS = gql`
  query ($query: ArticleCountChannelsNewspapersInput) {
    getArticleCountInChannels(query: $query) {
      title
      count
    }
  }
`;

export default GET_ARTICLE_COUNT_IN_CHANNELS;
