import gql from "graphql-tag";
const GET_CONNECTED_CATEGORIES = gql`
  query ($input: GetConnectedCategoriesInput) {
    getConnectedCategories(input: $input) {
      uuid
      title
      type
      keywords
    }
  }
`;

export default GET_CONNECTED_CATEGORIES;
