import config from "./config.json";
import { Fragment, useState, useEffect, useContext } from "react";
import { CTX } from "./Context";
import { fetchSearch } from "./api";
import { FetchResult, ItemType, Lang } from "./TypeDefs";

import { Span, Box, Tab, Loader } from "./StyledComponents";

import ArticleResults from "./ArticleResults";
import AuthorResults from "./AuthorResults";
import PageResults from "./PageResults";

const LANG = config.LANG;

interface Labels {
  all: Lang | any;
  articles: Lang | any;
  pages: Lang | any;
  authors: Lang | any;
}

const labels: Labels = {
  all: { en: "All", sv: "Allt" },
  articles: { en: "Articles", sv: "Artiklar" },
  pages: { en: "Pages", sv: "Sidor" },
  authors: { en: "Authors", sv: "Reportrar" },
};

const tabs: string[] = [
  labels.all[LANG],
  labels.articles[LANG],
  labels.pages[LANG],
  labels.authors[LANG],
];

interface Props {
  setOpenFilter: (open: boolean) => void;
  onSubmit?: (e: React.FormEvent, initTab: number) => void;
  onOptionClick?: (type: ItemType, uuid: string) => void;
  articlesData?: FetchResult;
  pagesData?: FetchResult;
  authorsData?: FetchResult;
  showAsSuggestion?: boolean;
  searchTerm?: string;
}

let prevSearchCode: string;

const ResultPage = (props: Props) => {
  const {
    onSubmit,
    onOptionClick,
    articlesData,
    pagesData,
    authorsData,
    showAsSuggestion,
    searchTerm,
  } = props;
  const { history } = useContext(CTX);
  const [numberOfResultLabel, setNumberOfResultLabel] = useState<string>();

  const [articlesRes, setArticlesRes] = useState<FetchResult>(
    showAsSuggestion && articlesData
      ? articlesData
      : {
          result: [],
          total_result_count: 0,
          search_id: "",
        }
  );
  const [authorsRes, setAuthorsRes] = useState<FetchResult>(
    showAsSuggestion && authorsData
      ? authorsData
      : {
          result: [],
          total_result_count: 0,
        }
  );
  const [pagesRes, setPagesRes] = useState<FetchResult>(
    showAsSuggestion && pagesData
      ? pagesData
      : {
          result: [],
          result_count: 0,
        }
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);

  const [overview] = useState<boolean>(
    (showAsSuggestion && activeTab === tabs[0]) ?? false
  );

  const getSearchCode = (
    searchString: string,
    offset: number,
    filterConcepts: string[] | undefined,
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    return (
      searchString +
      "_" +
      offset?.toString() +
      "_" +
      filterConcepts?.toString() +
      "_" +
      startDate +
      "_" +
      endDate
    );
  };

  const fetchResult = async (
    ac: AbortController,
    searchString: string,
    offset: number = 0,
    filter?: string[],
    min_pub_date?: string,
    max_pub_date?: string
  ) => {
    setIsLoading(true);
    const data: FetchResult[] = await fetchSearch(
      ac,
      searchString,
      undefined,
      offset,
      filter,
      min_pub_date,
      max_pub_date
    );
    prevSearchCode = getSearchCode(
      searchString,
      offset,
      filter,
      min_pub_date,
      max_pub_date
    );
    setIsLoading(false);

    setArticlesRes(data[0]);
    setAuthorsRes(data[1]);
    setPagesRes(data[2]);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const searchString: string = decodeURI(
      searchTerm || urlParams.get("q") || ""
    );

    setNumberOfResultLabel(
      LANG === "sv"
        ? `Söktermen ${searchTerm || searchString} gav ${
            articlesRes.total_result_count +
            authorsRes.result_count +
            pagesRes.total_result_count
          } träffar.`
        : `The search ${searchTerm || searchString} resulted in ${
            articlesRes.total_result_count +
            authorsRes.result_count +
            pagesRes.total_result_count
          } hits.`
    );
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlesRes, authorsRes, pagesRes]);

  // path listener
  useEffect(() => {
    if (showAsSuggestion) return;
    const ac = new AbortController();
    const urlParams = new URLSearchParams(history.location.search);
    const searchString: string = decodeURI(
      searchTerm || urlParams.get("q") || ""
    );
    const tab = Number(urlParams.get("t") || 0);
    typeof tab === "number" && setActiveTab(tabs[tab]);
    const page = Number(urlParams.get("p") || 1);
    const offset = (page - 1) * config.DEFAULT_PAGE_SIZE;

    const filterDates: string[] | undefined = urlParams.get("d")?.split(",");
    const startDate: string | undefined = filterDates
      ? filterDates[0]
      : undefined;
    const endDate: string | undefined = filterDates
      ? filterDates[1]
      : undefined;

    let filterConcepts: string[] | undefined = urlParams.get("f")?.split(",");
    if (filterConcepts)
      filterConcepts = filterConcepts.map((concept: string) =>
        concept.substring(2)
      );
    getSearchCode(searchString, offset, filterConcepts, startDate, endDate) !==
      prevSearchCode &&
      fetchResult(ac, searchString, offset, filterConcepts, startDate, endDate);

    return history.listen((location: Location) => {
      if (showAsSuggestion) return;
      const newUrlParams = new URLSearchParams(location.search);
      const searchString: string = decodeURI(
        searchTerm || newUrlParams.get("q") || ""
      );
      const tab = Number(newUrlParams.get("t") || 0);
      typeof tab === "number" && setActiveTab(tabs[tab]);
      const page = Number(newUrlParams.get("p") || 1);
      const offset = (page - 1) * config.DEFAULT_PAGE_SIZE;
      const filterDates: string[] | undefined = newUrlParams
        .get("d")
        ?.split(",");

      const startDate: string | undefined = filterDates
        ? filterDates[0]
        : undefined;
      const endDate: string | undefined = filterDates
        ? filterDates[1]
        : undefined;

      let filterConcepts: string[] | undefined = newUrlParams
        .get("f")
        ?.split(",");
      if (filterConcepts)
        filterConcepts = filterConcepts.map((concept: string) =>
          concept.substring(2)
        );

      getSearchCode(
        searchString,
        offset,
        filterConcepts,
        startDate,
        endDate
      ) !== prevSearchCode &&
        fetchResult(
          ac,
          searchString,
          offset,
          filterConcepts,
          startDate,
          endDate
        );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Box p={4}>
      <Span
        fontSize="0.9rem"
        style={{ visibility: !isLoading ? "visible" : "hidden" }}
      >
        {numberOfResultLabel}
      </Span>

      <Box py={3} display="flex" overflow="hidden">
        {tabs.map((tab: string, i: number) => (
          <Tab
            key={tab}
            active={activeTab === tabs[i]}
            onClick={() => {
              setActiveTab(tabs[i]);
              if (showAsSuggestion) return;
              let params: URLSearchParams = new URLSearchParams(
                history.location.search
              );
              params.set("t", i.toString());
              history.push(`${history.location.pathname}?${params.toString()}`);
            }}
          >
            <Span fontSize="1rem">{tab}</Span>
            {!isLoading && (
              <Span fontSize="1rem" fontWeight="normal" ml={1}>
                (
                {i === 0
                  ? articlesRes.total_result_count ??
                    0 + authorsRes.result_count ??
                    0 + pagesRes.total_result_count ??
                    0
                  : i === 1
                  ? articlesRes.total_result_count ?? 0
                  : i === 2
                  ? pagesRes.total_result_count ?? 0
                  : authorsRes.result_count ?? 0}
                )
              </Span>
            )}
          </Tab>
        ))}
      </Box>

      {isLoading ? (
        <Box p={2} display="flex" justifyContent="center" alignItems="center">
          <Loader size="40px" />
        </Box>
      ) : (
        <Box py={2}>
          {activeTab === tabs[0] && (
            <Fragment>
              <ArticleResults
                overview={true}
                articles={articlesRes.result?.slice(0, 4) || []}
                numberOfResult={articlesRes.total_result_count || 0}
                onSubmit={onSubmit}
                onOptionClick={onOptionClick}
              />
              <hr />
              <PageResults
                overview={true}
                pages={pagesRes.result?.slice(0, 4) || []}
                numberOfResult={pagesRes.total_result_count || 0}
                onSubmit={onSubmit}
                onOptionClick={onOptionClick}
              />
              <hr />
              <AuthorResults
                overview={true}
                authors={authorsRes.result?.slice(0, 4) || []}
                numberOfResult={authorsRes.result_count || 0}
                onSubmit={onSubmit}
                onOptionClick={onOptionClick}
              />
            </Fragment>
          )}
          {activeTab === tabs[1] && (
            <ArticleResults
              overview={overview}
              articles={articlesRes.result}
              numberOfResult={articlesRes.total_result_count}
              onSubmit={onSubmit}
              onOptionClick={onOptionClick}
            />
          )}
          {activeTab === tabs[2] && (
            <PageResults
              overview={overview}
              pages={pagesRes.result}
              numberOfResult={pagesRes.total_result_count}
              onSubmit={onSubmit}
              onOptionClick={onOptionClick}
            />
          )}
          {activeTab === tabs[3] && (
            <AuthorResults
              overview={overview}
              authors={authorsRes.result}
              numberOfResult={authorsRes.result_count}
              onSubmit={onSubmit}
              onOptionClick={onOptionClick}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ResultPage;
