import { Add, Delete, Info } from "@mui/icons-material";
import {
  List,
  ListSubheader,
  Box,
  IconButton,
  ListItem,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";

interface IProps {
  row: any;
  handleAddQueueRow: any;
  handleDeleteQueueRow: any;
  onChangeQueueRow: any;
  onChangeQueueNumber: any;
  isSuperAdmin?: boolean;
}

const QueueInputComponent = (props: IProps) => {
  const {
    row,
    handleAddQueueRow,
    handleDeleteQueueRow,
    onChangeQueueNumber,
    onChangeQueueRow,
    isSuperAdmin,
  } = props;

  return (
    <List
      dense
      subheader={
        <ListSubheader
          component="div"
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            Tag quality assurance queue
            <Tooltip title="Insert queue" placement="top">
              <span>
                <IconButton
                  disabled={!isSuperAdmin}
                  color="primary"
                  onClick={handleAddQueueRow}
                >
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Tooltip
            title={
              <span>
                Put <b>gate9999</b> to refer to customer 9999.
                <br />
                Put <b>-1</b> to fetch from the next available queue number.
                <br />
                <br />
                <b>Only iMatrics admins can edit this field.</b>
              </span>
            }
            placement="top"
          >
            <Info color="primary" fontSize="medium" sx={{ cursor: "help" }} />
          </Tooltip>
        </ListSubheader>
      }
    >
      {row.queue &&
        row.queue.map((item: any, queueIndex: number) => (
          <ListItem
            key={queueIndex}
            disableGutters
            secondaryAction={
              <Tooltip title="Remove queue">
                <span>
                  <IconButton
                    disabled={!isSuperAdmin}
                    size="small"
                    edge="end"
                    onClick={() => handleDeleteQueueRow(queueIndex)}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            }
          >
            <TextField
              disabled={!isSuperAdmin}
              autoFocus={item.key === ""}
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              value={item.key}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <input
                      disabled={!isSuperAdmin}
                      type="number"
                      autoComplete="off"
                      value={item.value}
                      style={{
                        width: `${
                          (Number.isInteger(item.value)
                            ? item.value.toString().length
                            : 1) + 4
                        }ch`,
                        minWidth: "5ch",
                        maxWidth: "8ch",
                        border: "none",
                        outline: "none",
                        paddingLeft: 16,
                        borderLeft: "1px solid rgba(0, 0, 0, 0.25)",
                        textAlign: "right",
                      }}
                      onChange={(e) => {
                        onChangeQueueNumber(e, queueIndex);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onChangeQueueRow(e, queueIndex);
              }}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default QueueInputComponent;
