import { useContext } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { CTX } from "../../utils/ContextStore";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Box,
  Typography,
  Grid,
  IconButton,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { Settings, Close } from "@mui/icons-material";
import ToggleSwitch from "./SettingsComponents/ToggleSwitch";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalHeader: {
      fontWeight: "bold",
      fontSize: "1em",
    },
    modalContainer: {
      minWidth: 500,
      minHeight: 250,
    },
    privSwitch: {
      marginRight: 0,
      marginBottom: 0,
    },
  })
);

const SettingsModal = () => {
  const classes = useStyles();
  const {
    showSettings,
    setShowSettings,
    superAdminMode,
    setSuperAdminMode,
    isExtendedAdmin,
    setIsExtendedAdmin,
    displayAltDatasetName,
    setDisplayAltDatasetName,
    showImageDisplayer,
    setShowImageDisplayer,
  }: any = useContext(CTX);

  const handleCloseModal = () => {
    setShowSettings(false);
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      className={classes.modal}
      open={showSettings}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showSettings}>
        <Paper className={classes.modalContainer} style={{ zIndex: 1 }}>
          <Box
            pl={2}
            pr={2}
            pt={1}
            pb={1}
            borderBottom="1px solid rgba(0, 0, 0, 0.125)"
            display="flex"
            alignItems="center"
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Settings className="pr-2" fontSize="large" />
                  <Typography
                    variant="overline"
                    className={classes.modalHeader}
                  >
                    Preferences
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          {(Authenticator.hasAuthority(["ADMIN"]) ||
            Authenticator.hasAuthority(["CUSTOMER_ADMIN"])) && (
            <Box
              pt={2}
              pl={2}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>{"Admin privileges"}</Typography>
              <FormGroup aria-label="priv-switch" row>
                <FormControlLabel
                  label={
                    <Typography variant="caption" style={{ fontWeight: 700 }}>
                      Extended admin
                    </Typography>
                  }
                  labelPlacement="top"
                  classes={{ root: classes.privSwitch }}
                  control={
                    <ToggleSwitch
                      disabled={superAdminMode}
                      value={isExtendedAdmin}
                      handler={setIsExtendedAdmin}
                    />
                  }
                />
                {Authenticator.hasAuthority(["ADMIN"]) && (
                  <FormControlLabel
                    label={
                      <Typography variant="caption" style={{ fontWeight: 700 }}>
                        Super admin
                      </Typography>
                    }
                    labelPlacement="top"
                    classes={{ root: classes.privSwitch }}
                    control={
                      <ToggleSwitch
                        value={superAdminMode}
                        handler={setSuperAdminMode}
                      />
                    }
                  />
                )}
              </FormGroup>
            </Box>
          )}
          <Box pt={2} pl={2} pr={2}>
            <ToggleSwitch
              label={"Alternative dataset name"}
              value={displayAltDatasetName}
              handler={setDisplayAltDatasetName}
            />
          </Box>
          {Authenticator.hasAuthority(["IMAGE_TAGGING"]) && (
            <Box pt={2} pl={2} pr={2}>
              <ToggleSwitch
                label={"Smart image suggestions"}
                value={showImageDisplayer}
                handler={setShowImageDisplayer}
              />
            </Box>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SettingsModal;
