import { Fragment, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  MenuList,
  MenuItem,
  Badge,
  CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles, withStyles } from "@mui/styles";
import {
  Description,
  Grain,
  PersonOutlineOutlined,
  AccountBalance,
  LocationOn,
  EventNote,
  Cancel,
  Search,
  Public,
} from "@mui/icons-material";
import { TiThLargeOutline } from "react-icons/ti";
import SEARCH_CONCEPTS from "../../../mutations/searchConcepts";

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      display: "flex",
      padding: "0.4rem",
      cursor: "default",
    },
    conceptText: {
      flexGrow: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    tooltip: {
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid rgba(0,0,0,0.25)",
      fontSize: "12px",
    },
    inputLabel: {
      backgroundColor: "#fff",
    },
  })
);

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      minWidth: "16px",
      height: "12px",
      right: "-4px",
      top: "14px",
      padding: 0,
      margin: 0,
      border: "none",
      color: "#000",
      fontSize: 9,
      fontWeight: "bold",
      backgroundColor: "#fff",
    },
  })
)(Badge);

let timeout: ReturnType<typeof setTimeout>;

interface Props {
  type: string;
  title: string;
}

const DuplicateValidationInput = (props: Props) => {
  const classes = useStyles();
  const { type, title } = props;

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);
  const [searchString, setSearchString] = useState<string>("");
  const [tagSuggestions, setTagSuggestions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSearchString(title);
  }, [title]);

  useEffect(() => {
    if (!isFirstRender) {
      clearTimeout(timeout);
      if (searchString !== "") {
        timeout = setTimeout(() => {
          setIsLoading(true);
          searchConcepts({
            variables: {
              title: searchString,
              type: type,
              draft: false,
              size: 10,
            },
          })
            .then((data: any) => {
              if (!searchString) setTagSuggestions([]);
              data?.data?.searchConcepts?.result &&
                setTagSuggestions(data.data.searchConcepts.result);
            })
            .finally(() => setIsLoading(false));
        }, 500);
      } else setTagSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, type]);

  useEffect(() => {
    setIsFirstRender(false);
    searchConcepts({
      variables: {
        title: title,
        type: type,
        draft: false,
        size: 10,
      },
    })
      .then((data: any) => {
        if (!searchString) setTagSuggestions([]);
        data?.data?.searchConcepts?.result &&
          setTagSuggestions(data.data.searchConcepts.result);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <TextField
        id={"duplicate-validator-input"}
        autoComplete="off"
        fullWidth
        type="text"
        variant="outlined"
        size="small"
        label={type}
        value={searchString}
        InputLabelProps={{
          shrink: true,
          style: { backgroundColor: "#fff" },
        }}
        aria-haspopup="true"
        InputProps={{
          style: { backgroundColor: "#fff" },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchString && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchString("");
                    setTagSuggestions([]);
                    $("#duplicate-validator-input") &&
                      $("#duplicate-validator-input").focus();
                  }}
                >
                  <Cancel />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onInput={(e: any) => {
          setSearchString(e.target.value);
        }}
        helperText={
          isLoading || tagSuggestions?.length > 0 || !searchString ? null : (
            <span style={{ color: "#56E39F" }}>
              No duplicates found for <b>{searchString}</b>
            </span>
          )
        }
      />
      {isLoading && (
        <Box py={1} px={2}>
          <CircularProgress size="1em" />
        </Box>
      )}
      <Paper
        className="popover-index dropdown-transition"
        style={{
          display: !isLoading && tagSuggestions?.length > 0 ? "block" : "none",
        }}
      >
        <MenuList id="possible-duplicates-list" dense>
          {tagSuggestions.map((item: any) => (
            <Tooltip
              key={item.uuid}
              title={
                <Box py={0.4}>
                  <Box>
                    <b>{item.title}</b>
                    {item.global ? (
                      <Box
                        component="span"
                        whiteSpace="nowrap"
                        style={{ opacity: 0.8 }}
                      >
                        {" (global)"}
                      </Box>
                    ) : null}
                  </Box>

                  <Box mt={0.4}>{item.shortDescription}</Box>
                </Box>
              }
              aria-label="concept-description"
              placement="right"
              arrow
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <MenuItem className={classes.listItem}>
                <Box className={classes.conceptText}>
                  {item.type === "x-im/category" || item.type === "category" ? (
                    <Description fontSize="small" className="mr-2" />
                  ) : item.type === "x-im/topic" || item.type === "topic" ? (
                    <Grain fontSize="small" className="mr-2" />
                  ) : item.type === "x-im/person" || item.type === "person" ? (
                    <PersonOutlineOutlined fontSize="small" className="mr-2" />
                  ) : item.type === "x-im/organisation" ||
                    item.type === "organisation" ? (
                    <AccountBalance fontSize="small" className="mr-2" />
                  ) : item.type === "x-im/place" || item.type === "place" ? (
                    <LocationOn fontSize="small" className="mr-2" />
                  ) : item.type === "x-im/event" || item.type === "event" ? (
                    <EventNote fontSize="small" className="mr-2" />
                  ) : item.type === "x-im/object" || item.type === "object" ? (
                    <TiThLargeOutline fontSize="medium" className="mr-2" />
                  ) : null}
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>

                  {item.shortDescription ? (
                    <Typography component="span" variant="body2">
                      {" "}
                      - {item.shortDescription}
                    </Typography>
                  ) : null}
                </Box>
                {item.global && (
                  <Box
                    flexGrow={0}
                    mr={1.25}
                    ml={0.2}
                    justifyContent="flex-end"
                  >
                    <StyledBadge badgeContent={item.language.toUpperCase()}>
                      <Public fontSize="small" htmlColor="#777" />
                    </StyledBadge>
                  </Box>
                )}
              </MenuItem>
            </Tooltip>
          ))}
        </MenuList>
      </Paper>
    </Fragment>
  );
};

export default DuplicateValidationInput;
