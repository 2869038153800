import gql from "graphql-tag";

const searchConcepts = gql`
  mutation (
    $title: String!
    $type: String!
    $draft: Boolean
    $size: Int
    $superAdmin: Boolean
    $extendedAdmin: Boolean
  ) {
    searchConcepts(
      title: $title
      type: $type
      draft: $draft
      size: $size
      superAdmin: $superAdmin
      extendedAdmin: $extendedAdmin
    ) {
      result {
        title
        type
        uuid
        rootId
        shortDescription
        longDescription
        pubStatus
        ignore
        keywords
        aliases
        broader
        global
        language
        geoJSON
        wikipedia
        wikidata
        broader
        language
        openStreetMap
        source
        hidden
        links {
          id
          relationType
          uuid
          source
          uri
          url
        }
      }
      response
      error
      scrollID
    }
  }
`;

export default searchConcepts;
