import gql from "graphql-tag";
const submitDemoRequest = gql`
  mutation($email: String, $name: String, $language: String) {
    submitDemoRequest(email: $email, name: $name, language: $language) {
      message
      error
    }
  }
`;

export default submitDemoRequest;
