import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  List,
  ListItem,
  Alert,
  CircularProgress,
  Typography,
} from "@mui/material";

import GET_ARTICLE_COUNT_IN_CHANNELS from "../../queries/GET_ARTICLE_COUNT_IN_CHANNELS";

interface IProps {
  endpoint: any;
  startDate: string;
  endDate: string;
  channel?: string;
  setChannel?: (newspaper: string) => void;
  includeConcepts: string[];
  excludeAuthors: string[];
}

const ProductionOverviewList = (props: IProps) => {
  const {
    endpoint,
    startDate,
    endDate,
    channel,
    setChannel,
    includeConcepts,
    excludeAuthors,
  } = props;

  const [fetchedChannelData, setFetchedChannelData] = useState<any>(undefined);

  const [getArticleCountInChannels, { loading, error }] = useLazyQuery(
    GET_ARTICLE_COUNT_IN_CHANNELS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        query: {
          startDate: startDate,
          endDate: endDate,
          concepts: includeConcepts,
          excludeAuthors: excludeAuthors,
        },
      },
      onCompleted: (data) => {
        setFetchedChannelData(data.getArticleCountInChannels);
      },
      onError: (err) => {
        setFetchedChannelData([]);
      },
    }
  );

  useEffect(() => {
    setFetchedChannelData && setFetchedChannelData(undefined);
    if (startDate && endDate) getArticleCountInChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, startDate, endDate, includeConcepts, excludeAuthors]);

  useEffect(() => {
    setChannel && setChannel("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return (
    <List
      dense
      disablePadding
      sx={{
        minHeight: "320px",
        height: "30vh",
        overflowY: "auto",
        overflowX: "hidden",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      {!error && !loading && fetchedChannelData?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            fontSize: 12,
            textAlign: "right",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.6)",
            bgcolor: "rgba(0, 0, 0, 0.02)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography component="span" variant="overline" fontWeight="bold">
            {`Channels (${fetchedChannelData.length})`}
          </Typography>
          {fetchedChannelData?.reduce(
            (acc: number, curr: any) => acc + curr.count,
            0
          )}
        </Box>
      )}
      {error ? (
        <Alert severity="error" sx={{ display: "inline-flex", m: 2 }}>
          No channel data found for the selected dataset.
        </Alert>
      ) : loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            p: 2,
          }}
        >
          <CircularProgress size="1.2em" />
        </Box>
      ) : fetchedChannelData?.length < 1 ? (
        <Alert severity="info" sx={{ display: "inline-flex", m: 2 }}>
          No channels found for the selected dataset.
        </Alert>
      ) : (
        fetchedChannelData?.map((data: any) => (
          <ListItem
            key={data.title}
            button
            selected={channel === data.title}
            onClick={() => setChannel && setChannel(data.title)}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: 14,
              }}
            >
              <Box
                component="span"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontWeight: 700,
                }}
              >
                {data.title}
              </Box>
              <Box component="span">{data.count}</Box>
            </Box>
          </ListItem>
        ))
      )}
    </List>
  );
};

export default ProductionOverviewList;
