import { Fragment, useMemo } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { getTagIcon } from "src/utils/helpers";
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import GET_NEWSPAGE_ARTICLE from "../../queries/GET_NEWSPAGE_ARTICLE";

const imgStyle: any = {
  width: "100%",
  height: "auto",
  aspectRatio: "16/9",
  objectFit: "cover",
};

const previewImage = (currentUrl: string, size?: number) => {
  if (!currentUrl) return undefined;
  const current = new URL(currentUrl);
  const currentParams = current.searchParams;
  const uuid = currentParams.get("uuid");

  const url = new URL(currentUrl.split("?")[0]);
  const params = url.searchParams;
  if (uuid) {
    params.set("uuid", uuid);
    params.set("type", "preview");
    size && params.set("width", size.toString());
  }
  return url?.toString() ?? undefined;
};

const allowedTags = [
  "category",
  "topic",
  "person",
  "place",
  "organisation",
  "organization",
  "event",
  "object",
];

interface IProps {
  article: any;
  open: boolean;
  onClose: () => void;
}

const ArticleModal = (props: IProps) => {
  const { article, open, onClose } = props;

  const { data, loading, error } = useQuery(GET_NEWSPAGE_ARTICLE, {
    fetchPolicy: "cache-and-network",
    variables: {
      uuid: article?.uuid ?? article?.articleID,
    },
    skip: !open && !article?.uuid,
  });

  const filteredAuthors = useMemo(() => {
    const authors = data?.getNewspageArticle.authors ?? null;
    if (!authors) return [];
    const uniqueAuthors: Array<string> =
      data.getNewspageArticle?.authors?.filter((element: string, i: number) => {
        return data.getNewspageArticle?.authors?.indexOf(element) === i;
      });
    return uniqueAuthors;
  }, [data?.getNewspageArticle.authors]);

  const filteredTags = useMemo(() => {
    const tags = data?.getNewspageArticle.concepts ?? null;
    if (!tags) return [];
    const filteredTags = tags.filter(
      (tag: any) =>
        tag &&
        !tag.uuid.includes("00000000") &&
        allowedTags.includes(tag.type.replace("x-im/", ""))
    );
    return filteredTags;
  }, [data?.getNewspageArticle.concepts]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          zIndex: 1,

          bgcolor: "rgba(255,255,255,0.75)",
          "&:hover": {
            bgcolor: "rgba(255,255,255,0.9)",
          },
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ p: 0 }}>
        {error ? (
          <Typography variant="h4">Article not found</Typography>
        ) : loading ? (
          <>
            <Skeleton variant="rectangular" width="100%" height="50vh" />
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ my: 2 }}
            />
            <Typography variant="h2">
              <Skeleton width="50%" />
            </Typography>
            <Typography variant="h6">
              <Skeleton width="90%" />
            </Typography>
            <Typography variant="body1">
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
              <Skeleton width="90%" />
            </Typography>
          </>
        ) : data ? (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ position: "relative" }}>
                <img
                  src={
                    previewImage(data?.getNewspageArticle?.image, 1200) ??
                    undefined
                  }
                  alt=""
                  style={imgStyle}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  p: [1, 4],
                  gap: 2,
                }}
              >
                <Grid
                  container
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Grid item>
                    <AvatarGroup max={2} total={filteredAuthors.length}>
                      {filteredAuthors.map((author: string, i: number) => (
                        <Avatar key={i} alt="" src="" />
                      ))}
                    </AvatarGroup>
                  </Grid>
                  <Grid item>
                    <Box>
                      {filteredAuthors.map((author: string, i: number) => (
                        <Fragment key={i}>
                          <Typography
                            component="a"
                            variant="subtitle1"
                            fontWeight={700}
                            pl={i !== 0 ? 1 : 0}
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            {author}
                          </Typography>
                          {i < filteredAuthors.length - 1 && ","}
                        </Fragment>
                      ))}
                    </Box>
                    <Typography variant="subtitle2">
                      {`${moment(
                        data.getNewspageArticle.publicationTimestamp
                      ).format("MMM Do YYYY, HH:mm")} · ${
                        data.getNewspageArticle.minuteRead
                      } min read`}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  variant="h3"
                  sx={{ fontFamily: "Crete Round", mb: 0.5, fontWeight: 700 }}
                >
                  {data.getNewspageArticle.headline}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Crete Round", mb: 0.5, fontWeight: 700 }}
                >
                  {data.getNewspageArticle.preamble}
                </Typography>

                {data.getNewspageArticle?.body?.map(
                  (text: string, i: number) => (
                    <Typography
                      key={i}
                      variant="body1"
                      sx={{
                        fontFamily: "Crete Round",
                        fontSize: "1.2rem",
                      }}
                    >
                      {text}
                    </Typography>
                  )
                )}

                <Grid container sx={{ gap: 0.5, mt: 2 }}>
                  {filteredTags?.map((concept: any) => (
                    <Chip
                      key={concept.uuid}
                      variant="outlined"
                      color="primary"
                      label={
                        <Box
                          component="span"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Box component="span">{getTagIcon(concept.type)}</Box>
                          {concept.title}
                        </Box>
                      }
                      sx={{ fontWeight: 700 }}
                    />
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography color="secondary" sx={{ fontWeight: 700 }}>
            Article not found
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ArticleModal;
