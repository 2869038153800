import gql from "graphql-tag";
const setEndpoint = gql`
  mutation (
    $url: String
    $name: String
    $active: Boolean
    $alternativeName: String
    $settingsName: String
    $isDemoEnvironment: Boolean
  ) {
    setEndpoint(
      url: $url
      name: $name
      active: $active
      alternativeName: $alternativeName
      settingsName: $settingsName
      isDemoEnvironment: $isDemoEnvironment
    ) {
      url
      name
      active
      alternativeName
      settingsName
      language
      genderAnalysisEnabled
      isDemoEnvironment
      tagLimits {
        category
        topic
        entity
      }
    }
  }
`;

export default setEndpoint;
