import config from "./config.json";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import moment from "moment";
import { Route } from "react-router-dom";
import ContextStore, { CTX } from "./Context";
import {
  Container,
  Box,
  Span,
  Chip,
  Col,
  QuickLink,
  IconButton,
  Overlay,
  Button,
  Dropdown,
  DropdownList,
  IconImage,
  SearchForm,
  SearchInput,
  Loader,
  ImgIcon,
  Select,
} from "./StyledComponents";

import { Concept, Author, FetchResult, QuickLinkType, Lang } from "./TypeDefs";

//import Fastfood from "./icons/Fastfood.svg";
//import Flower from "./icons/Flower.svg";
//import Heart from "./icons/Heart.svg";
//import Cart from "./icons/Cart.svg";
//import Headset from "./icons/Headset.svg";
//import Newspaper from "./icons/Newspaper.svg";
import { fetchSearch } from "./api";

import ResultPage from "./ResultPage";
import FilterContent from "./FilterContent";

const LANG = config.LANG;

interface Labels {
  search: Lang | any;
  advancedSearch: Lang | any;
  searchPlaceholder: Lang | any;
  relevance: Lang | any;
  latest: Lang | any;
  popularity: Lang | any;
}

const labels: Labels = {
  search: { en: "Search", sv: "Sök" },
  advancedSearch: { en: "ADVANCED SEARCH", sv: "AVANCERAD SÖKNING" },
  searchPlaceholder: {
    en: "Search for articles, pages, authors, etc.",
    sv: "Sök på artiklar, sidor, författare, etc.",
  },
  relevance: { en: "Relevance", sv: "Relevans" },
  latest: { en: "Latest", sv: "Senaste" },
  popularity: { en: "Popularity", sv: "Popularitet" },
};

const quickLinks: QuickLinkType[] = [
  {
    name: "Sport",
    iconUrl: "",
    url: "/topic?uuid=E8FABE91-6D2D-476D-B64F-048E9D5C85C0",
  },
  {
    name: "Vetenskap",
    iconUrl: "",
    url: "/topic?uuid=244ABD88-EA8E-486A-8BB3-D4121CF3257D",
  },
  {
    name: "Brott",
    iconUrl: "",
    url: "/topic?uuid=24F27E35-DABE-4CCA-9057-3B58907B6070",
  },
  {
    name: "Politik",
    iconUrl: "",
    url: "/topic?uuid=26322AE3-1669-4B67-A6B7-FAFD54F55541",
  },
];

interface IconProps {
  size?: number | string;
  color?: string;
}
const SearchIcon = ({ size, color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || "22"}
    height={size || "100%"}
    viewBox="0 0 32 32"
    fill={color || "black"}
  >
    <path d="M32 30.208L21.632 19.84c4.224-5.248 3.328-12.928-1.92-17.152S6.912-.64 2.688 4.608-.64 17.536 4.608 21.76c4.48 3.584 10.88 3.584 15.232 0l10.368 10.368L32 30.208zM12.16 21.76c-5.248 0-9.6-4.352-9.6-9.6s4.352-9.6 9.6-9.6 9.6 4.352 9.6 9.6c0 5.248-4.352 9.6-9.6 9.6z"></path>
  </svg>
);

const sortOptions: string[] = [
  labels.relevance[LANG],
  labels.latest[LANG],
  labels.popularity[LANG],
];

const SearchComponent = () => {
  const {
    history,
    navigate,

    showOverlay,
    setShowOverlay,
    openFilter,
    setOpenFilter,

    searchString,

    handleOnSubmit,
    handleOnChange,
    handleOnFocus,
    handleOnClear,
    handleOnClickSuggestion,

    handleClearDateFilter,
    handleClearConceptFilter,
    handleClearAuthorFilter,

    startDate,
    endDate,
    filterConcepts,
    filterAuthors,
  } = useContext(CTX);

  const typingTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );
  const searchTerm = useRef<string>("");
  const latestSearchTerm = useRef<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [articlesRes, setArticlesRes] = useState<FetchResult>({
    result: [],
    total_result_count: 0,
    search_id: "",
  });
  const [authorsRes, setAuthorsRes] = useState<FetchResult>({
    result: [],
    total_result_count: 0,
  });
  const [pagesRes, setPagesRes] = useState<FetchResult>({
    result: [],
    result_count: 0,
  });

  const [sortOption, setSortOption] = useState<string | undefined>(
    sortOptions[0] || undefined
  );

  const sortHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortOption(e.target.value);
  };

  useEffect(() => {
    searchTerm.current = searchString;
    typingTimeout?.current && clearTimeout(typingTimeout.current);
    const ac: AbortController = new AbortController();
    if (searchString.length > 1) {
      typingTimeout.current = setTimeout(async () => {
        setIsLoading(true);
        const urlParams = new URLSearchParams(history.location.search);
        const filterDates: string[] | undefined = urlParams
          .get("d")
          ?.split(",");
        const startDate: string | undefined = filterDates
          ? filterDates[0]
          : undefined;
        const endDate: string | undefined = filterDates
          ? filterDates[1]
          : undefined;

        let filterConcepts: string[] | undefined =
          urlParams.get("f")?.split(",") || undefined;
        if (filterConcepts)
          filterConcepts = filterConcepts?.map((concept: string) =>
            concept.substring(2)
          );

        const data: FetchResult[] = await fetchSearch(
          ac,
          searchString,
          3,
          undefined,
          filterConcepts,
          startDate,
          endDate
        );

        latestSearchTerm.current = searchTerm.current;

        /** DO NOTHING IF BAD RESULT */
        if (data && !data[0]?.result) return;
        setArticlesRes(data[0]);
        setAuthorsRes(data[1]);
        setPagesRes(data[2]);

        setIsLoading(false);
      }, 1000);
    }
    //return () => ac.abort(); // Abort both fetches on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  // path listener
  useEffect(() => {
    const ac: AbortController = new AbortController();
    typingTimeout.current = setTimeout(async () => {
      if (searchTerm.current.length > 1 || latestSearchTerm.current) {
        setIsLoading(true);
        const urlParams = new URLSearchParams(history.location.search);
        const filterDates: string[] | undefined = urlParams
          .get("d")
          ?.split(",");
        const startDate: string | undefined = filterDates
          ? filterDates[0]
          : undefined;
        const endDate: string | undefined = filterDates
          ? filterDates[1]
          : undefined;

        let filterConcepts: string[] | undefined =
          urlParams.get("f")?.split(",") || undefined;
        if (filterConcepts)
          filterConcepts = filterConcepts?.map((concept: string) =>
            concept.substring(2)
          );

        const data: FetchResult[] = await fetchSearch(
          ac,
          searchTerm.current.length > 1
            ? searchTerm.current
            : latestSearchTerm.current,
          3,
          undefined,
          filterConcepts,
          startDate,
          endDate
        );

        latestSearchTerm.current = searchTerm.current;

        /** DO NOTHING IF BAD RESULT */
        if (data && !data[0]?.result) return;
        setArticlesRes(data[0]);
        setAuthorsRes(data[1]);
        setPagesRes(data[2]);
        setIsLoading(false);
      }
    }, 250);
    return history.listen(async (location: any) => {
      typingTimeout.current = setTimeout(async () => {
        if (searchTerm.current.length > 1 || latestSearchTerm.current) {
          setIsLoading(true);
          const urlParams = new URLSearchParams(history.location.search);
          const filterDates: string[] | undefined = urlParams
            .get("d")
            ?.split(",");
          const startDate: string | undefined = filterDates
            ? filterDates[0]
            : undefined;
          const endDate: string | undefined = filterDates
            ? filterDates[1]
            : undefined;

          let filterConcepts: string[] | undefined =
            urlParams.get("f")?.split(",") || undefined;
          if (filterConcepts)
            filterConcepts = filterConcepts?.map((concept: string) =>
              concept.substring(2)
            );

          const data: FetchResult[] = await fetchSearch(
            ac,
            searchTerm.current.length > 1
              ? searchTerm.current
              : latestSearchTerm.current,
            3,
            undefined,
            filterConcepts,
            startDate,
            endDate
          );

          latestSearchTerm.current = searchTerm.current;
          /** DO NOTHING IF BAD RESULT */
          if (data && !data[0]?.result) return;

          setArticlesRes(data[0]);
          setAuthorsRes(data[1]);
          setPagesRes(data[2]);
        }

        setIsLoading(false);
      }, 250);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Container id="im-search-root">
      {
        <Overlay
          id="im-search-overlay"
          show={showOverlay || openFilter}
          onClick={() => {
            setShowOverlay(false);
            setOpenFilter(false);
          }}
        />
      }

      <SearchForm autoComplete="off" zIndex={1} onSubmit={handleOnSubmit}>
        <IconButton type="submit">
          <SearchIcon size={24} color="#000" />
        </IconButton>
        <SearchInput
          id="im-search-input"
          autoComplete="off"
          value={searchString}
          type="text"
          placeholder={labels.searchPlaceholder[LANG]}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.select();
          }}
        />
        {searchString && (
          <IconButton
            type="button"
            onClick={handleOnClear}
            fontSize="16px !important"
          >
            &#10006;
          </IconButton>
        )}
      </SearchForm>
      <Box backgroundColor="#fff" position="relative" width="100%">
        <Box px={2}>
          {
            <Box display="flex" gap={4}>
              <Button
                onClick={() => {
                  setOpenFilter((prev: boolean) => !prev);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 700,
                }}
                py={2}
              >
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/material-rounded/24/settings.png"
                  height="16px"
                  mr={1}
                />

                <span style={{ fontWeight: 700, textDecoration: "underline" }}>
                  {labels.advancedSearch[LANG]}
                </span>

                {openFilter ? (
                  <ImgIcon
                    alt=""
                    src="https://img.icons8.com/material/24/delete-sign--v1.png"
                    height="16px"
                    ml={2}
                  />
                ) : (
                  <ImgIcon
                    alt=""
                    src="https://img.icons8.com/material/24/expand-arrow--v1.png"
                    height="16px"
                    ml={2}
                  />
                )}
              </Button>
              <Select
                disabled
                name="sort-select"
                value={sortOption}
                onChange={sortHandler}
                style={{
                  marginLeft: "24px",
                  fontWeight: 700,
                  textDecoration: "underline",
                }}
              >
                {sortOptions.map((option: string, i: number) => (
                  <option value={option} key={i}>
                    {option}
                  </option>
                ))}
              </Select>
            </Box>
          }
          {startDate && endDate && (
            <Chip py={1} ml={1} backgroundColor="rgba(89, 107, 191, 0.20)">
              <ImgIcon
                mr={2}
                noCursor
                alt=""
                height="18"
                src="https://img.icons8.com/material-rounded/24/tear-off-calendar.png"
              />
              <Span fontWeight={700}>
                {moment(startDate).format("ll") +
                  " - " +
                  moment(endDate).format("ll")}
                {/*startDate.toLocaleDateString() +
                  " - " +
          endDate.toLocaleDateString()*/}
              </Span>
              <IconButton
                backgroundColor="transparent"
                type="button"
                onClick={() => {
                  handleClearDateFilter();
                }}
              >
                &#10006;
              </IconButton>
            </Chip>
          )}
          {filterConcepts &&
            filterConcepts.length > 0 &&
            filterConcepts.map((concept: Concept, i: number) => (
              <Chip py={1} ml={1} backgroundColor="rgba(87, 227, 158, 0.20)">
                <ImgIcon
                  mr={2}
                  noCursor
                  alt=""
                  height="18px"
                  src="https://img.icons8.com/material-outlined/24/price-tag.png"
                />
                <Span fontWeight={700}>{concept.title}</Span>
                <IconButton
                  backgroundColor="transparent"
                  type="button"
                  onClick={() => {
                    handleClearConceptFilter(concept.uuid);
                  }}
                >
                  &#10006;
                </IconButton>
              </Chip>
            ))}
          {filterAuthors &&
            filterAuthors.length > 0 &&
            filterAuthors.map((author: Author, i: number) => (
              <Chip
                key={author.title}
                py={1}
                ml={1}
                backgroundColor="rgba(219, 219, 222, 0.6)"
              >
                <ImgIcon
                  mr={2}
                  noCursor
                  alt=" "
                  height="18"
                  src="https://img.icons8.com/material-outlined/24/person-male.png"
                />
                <Span fontWeight={700}>{author.title}</Span>
                <IconButton
                  backgroundColor="transparent"
                  type="button"
                  onClick={() => {
                    handleClearAuthorFilter(author.uuid);
                  }}
                >
                  &#10006;
                </IconButton>
              </Chip>
            ))}
        </Box>

        {openFilter && (
          <Dropdown pb={2}>
            <FilterContent
              open={openFilter}
              onClose={() => {
                setOpenFilter(false);
                setShowOverlay(false);
              }}
            />
          </Dropdown>
        )}
      </Box>
      {!showOverlay || openFilter ? null : showOverlay &&
        !openFilter &&
        !searchString ? (
        <Dropdown pb={2}>
          {quickLinks?.length > 0 && (
            <Box p={2}>
              {quickLinks.map((quickLink: QuickLinkType) => (
                <Col xs={3} key={quickLink.name}>
                  <QuickLink
                    m={2}
                    p={4}
                    onClick={() => {
                      navigate("/imatrics-times" + quickLink.url);
                    }}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                      mb={2}
                    >
                      {quickLink?.iconUrl && (
                        <IconImage
                          alt=""
                          src={quickLink.iconUrl}
                          width="30px"
                          height="30px"
                          color="red"
                        />
                      )}
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                    >
                      {quickLink.name}
                    </Box>
                  </QuickLink>
                </Col>
              ))}
            </Box>
          )}
        </Dropdown>
      ) : isLoading ? (
        <Dropdown>
          <Box p={4} display="flex" justifyContent="center">
            <Loader size="40px" />
          </Box>
        </Dropdown>
      ) : (
        <Dropdown>
          <DropdownList>
            <ResultPage
              setOpenFilter={setOpenFilter}
              onSubmit={handleOnSubmit}
              onOptionClick={handleOnClickSuggestion}
              articlesData={articlesRes}
              pagesData={pagesRes}
              authorsData={authorsRes}
              showAsSuggestion={true}
              searchTerm={latestSearchTerm.current}
            />
          </DropdownList>
        </Dropdown>
      )}
    </Container>
  );
};

interface Props {
  searchFieldProps?: {
    hidden?: boolean;
    width?: string;
    wrapper?: any;
    style?: any;
  };
  pageProps?: {
    wrapper?: any;
    style?: any;
  };
}

const ArticleSearchComponent = (props: Props) => {
  const { searchFieldProps } = props;
  const { pageProps } = props;

  const { setOpenFilter, handleOnSubmit, handleOnClickSuggestion } =
    useContext(CTX);

  const SearchWrapper: any = searchFieldProps?.wrapper ?? Box;
  const PageWrapper: any = pageProps?.wrapper ?? Box;

  return (
    <Fragment>
      <SearchWrapper
        style={{
          height: searchFieldProps?.hidden ? "0px" : "auto",
          visibility: searchFieldProps?.hidden ? "hidden" : "visible",
          overflow: searchFieldProps?.hidden ? "hidden" : "visible",
          opacity: searchFieldProps?.hidden ? 0 : 1,
          transition: "0.3s ease-in-out",
        }}
      >
        <Box
          style={{
            margin: "auto",
            width: searchFieldProps?.width ?? "100%",
            padding: "16px",
            ...searchFieldProps?.style,
          }}
        >
          <SearchComponent />
        </Box>
      </SearchWrapper>

      <PageWrapper
        style={{
          margin: "32px 0",
          ...pageProps?.style,
        }}
      >
        <Route
          path="/imatrics-times/search:q?"
          render={() => (
            <ResultPage
              setOpenFilter={setOpenFilter}
              onSubmit={handleOnSubmit}
              onOptionClick={handleOnClickSuggestion}
            />
          )}
        />
      </PageWrapper>
    </Fragment>
  );
};

const ArticleSearch = (props: Props) => {
  return (
    <ContextStore>
      <ArticleSearchComponent {...props} />
    </ContextStore>
  );
};

export default ArticleSearch;
