import { Fragment } from "react";
import { Box, IconButton, List, Tooltip, Typography } from "@mui/material";
import { DeleteSweep } from "@mui/icons-material";

import ConceptItem from "./ConceptItem";

interface IProps {
  data: any[];
  endpoint: any;
  startDate: string;
  endDate: string;
  newspaper?: string;
  channel?: string;
  excludeAuthors: string[];
  removeFromConceptList: any;
  clearConceptList: () => void;
}
const ConceptOverviewList = (props: IProps) => {
  const {
    data,
    startDate,
    endDate,
    newspaper,
    channel,
    excludeAuthors,
    removeFromConceptList,
    clearConceptList,
  } = props;

  return (
    <List
      dense
      disablePadding
      sx={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {data.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            fontSize: 12,
            textAlign: "right",
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.6)",
            bgcolor: "rgba(0, 0, 0, 0.02)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography component="span" variant="overline" fontWeight="bold">
            Concepts ({data.length})
          </Typography>
          <span>
            {data?.reduce((acc: number, curr: any) => acc + curr.usage, 0)}
            <Tooltip title="Clear concept list" placement="top">
              <IconButton
                size="small"
                color="secondary"
                onClick={clearConceptList}
                sx={{ p: 0, ml: 1.5 }}
              >
                <DeleteSweep fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
        </Box>
      )}

      {data?.map((concept: any) => (
        <Fragment key={concept.uuid}>
          <ConceptItem
            concept={concept}
            startDate={startDate}
            endDate={endDate}
            newspaper={newspaper}
            channel={channel}
            excludeAuthors={excludeAuthors}
            removeFromConceptList={removeFromConceptList}
          />
        </Fragment>
      ))}
    </List>
  );
};

export default ConceptOverviewList;
