import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { CTX as tagCTX } from "../TagContext";
import { IQueueStatistics } from "src/utils/typeDefs";

import {
  Box,
  Paper,
  Typography,
  Popover,
  CircularProgress,
} from "@mui/material";
import { EmojiEvents } from "@mui/icons-material";

import GET_QUEUE_STATISTICS from "../../../queries/GET_QUEUE_STATISTICS";

interface IProps {
  customQueue?: boolean;
  queueName?: string;
  userQueueData?: any;
  callback?: (statistic?: any) => void;
}

const QueueStatisticsDisplayer = ({
  customQueue,
  queueName,
  userQueueData,
  callback,
}: IProps) => {
  const { activeEndpoint, queueStatisticsTrigger }: any = useContext(tagCTX);

  const [statistics, setStatistics] = useState<IQueueStatistics | undefined>(
    undefined
  );
  const [statisticsList, setStatisticsList] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = Boolean(anchorEl);

  const [getQueueStatistics, { loading }] = useLazyQuery(GET_QUEUE_STATISTICS, {
    fetchPolicy: "network-only",
    variables: {
      query: {
        customQueue: customQueue,
        queueName: queueName,
      },
    },
    onCompleted: (data) => {
      setStatistics(data?.getQueueStatistics ?? undefined);
    },
    onError: (err) => {
      setStatistics(undefined);
    },
  });

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    callback && callback(statistics);
    if (!statistics) {
      setStatisticsList([]);
      return;
    }
    setStatisticsList([
      { label: "User completed:", value: statistics.userCompleted },
      { label: "User skipped:", value: statistics.userSkipped },
      { label: "Total in progress:", value: statistics.inProgress },
      { label: "Total completed:", value: statistics.completed },
      { label: "Total skipped:", value: statistics.skipped },
      { label: "Last assigned:", value: statistics.lastAssigned },
      { label: "Article count:", value: statistics.maxQueue },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics]);

  useEffect(() => {
    if (customQueue && !queueName) return;
    setTimeout(() => {
      getQueueStatistics();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userQueueData,
    customQueue,
    queueName,
    queueStatisticsTrigger,
    activeEndpoint,
  ]);

  if (!statistics)
    return (
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          maxWidth: 160,
          maxHeight: 33,
          overflow: "hidden",
          whiteSpace: "nowrap",
          cursor: "pointer",
          gap: 0.5,
        }}
      >
        <Typography
          variant="overline"
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          Progress: <b>N/A</b>
          <Box component="span" sx={{ display: "flex", width: 16 }}>
            {loading ? <CircularProgress color="primary" size={14} /> : null}
          </Box>
        </Typography>
      </Paper>
    );

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        bgcolor:
          statistics.completed + statistics.skipped >= statistics.maxQueue
            ? "rgba(87, 227, 158, 0.2)"
            : undefined,
        maxWidth: 160,
        maxHeight: 33,
        overflow: "hidden",
        whiteSpace: "nowrap",
        cursor: "pointer",
        gap: 0.5,
      }}
    >
      <Typography
        variant="overline"
        aria-owns={openPopover ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "center",
        }}
      >
        Progress:
        <b>
          {statistics.lastAssigned}/{statistics.maxQueue}
        </b>
        <EmojiEvents
          htmlColor={
            statistics.completed + statistics.skipped >= statistics.maxQueue
              ? "rgba(255, 173, 117, 1)"
              : "rgba(0, 0, 0, 0.26)"
          }
        />
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{ paper: { sx: { p: 1 } } }}
        sx={{
          pointerEvents: "none",
        }}
      >
        {statisticsList.map((statistic: any, i: number) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "175px",
            }}
          >
            <Typography component="span" variant="overline">
              {statistic.label}
            </Typography>
            <Typography
              component="span"
              variant="overline"
              sx={{ fontWeight: 700 }}
            >
              {statistic.value}
            </Typography>
          </Box>
        ))}
      </Popover>
    </Paper>
  );
};

export default QueueStatisticsDisplayer;
