import gql from "graphql-tag";
const analyzeArticle = gql`
  mutation ($articleText: String, $settings: TagSettingsInput) {
    analyzeArticle(query: $articleText, settings: $settings) {
      concepts {
        weight
        title
        type
        uuid
        shortDescription
        wikipedia
        wikidata
        openStreetMap
        source
        longDescription
        pubStatus
        aliases
        author
        createdTimestamp
        latestVersionTimestamp
        broader
        geoJSON
        global
        language
      }
      genderData {
        maleScore
        maleMentions
        uniqueMaleEntities
        malePeople
        femaleScore
        femaleMentions
        uniqueFemaleEntities
        femalePeople
        nonbinaryScore
        nonbinaryMentions
        uniqueNonbinaryEntities
        nonbinaryPeople
        unknownScore
        unknownMentions
        uniqueUnknownEntities
        unknownPeople
      }
      broader {
        weight
        title
        type
        uuid
        shortDescription
        broader
        childConcepts
        global
        language
      }
    }
  }
`;

export default analyzeArticle;
