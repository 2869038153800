import { gql } from "@apollo/client";

const UPDATE_TOPIC_PAGES = gql`
  mutation ($query: [TopicPageInput]) {
    updateTopicPages(query: $query) {
      error
      message
    }
  }
`;

export default UPDATE_TOPIC_PAGES;
