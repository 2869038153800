import gql from "graphql-tag";

const ADD_CUSTOM_RULE = gql`
  mutation ($query: CustomRuleInput) {
    addCustomRule(query: $query) {
      ruleName
      operation
      textToManipulate
      replacingText
    }
  }
`;

export default ADD_CUSTOM_RULE;
