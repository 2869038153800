import config from "./config.json";
import { Fragment, useEffect, useState, useRef, useContext } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { CTX } from "./Context";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Divider,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import { Circle, ArrowDropDown } from "@mui/icons-material";

import GET_NEWS_RECOMMENDATIONS from "../../queries/GET_NEWS_RECOMMENDATIONS";

interface IArticle {
  uuid: string;
  headline: string;
  preamble: string;
  publication_timestamp: string;
  image: string;
  channels: string[];
}

const RecentNewsComponent = () => {
  const { history, selectedUserProfile } = useContext(CTX);

  const isInit = useRef<boolean>(false);

  const [articles, setArticles] = useState<Array<IArticle>>([]);

  const [getPopularArticles] = useLazyQuery(GET_NEWS_RECOMMENDATIONS, {
    onCompleted: (res) => {
      const data = res.getNewsRecommendations;
      const recommendations = data.recommendations;
      setArticles(recommendations);
    },
    onError: (e) => {
      console.error("err", e);
    },
  });

  const handleClick = (article: any) => {
    // dont trigger click if text is marked
    if (window?.getSelection()?.isCollapsed) {
      history.push(
        `${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${article.articleID}`
      );
    }
  };

  const loadMore = () => {
    getPopularArticles({
      variables: {
        query: {
          source: "bot",
          returnSize: articles.length + 3,
          userID: selectedUserProfile.id,
          signedIn: true,
          config: "imatrics_times_recent_config",
          returnFields: [
            "articleID",
            "headline",
            "preamble",
            "imageUrl",
            "publicationTimestamp",
            "topConcept",
            "concepts",
          ],
        },
      },
    });
  };

  useEffect(() => {
    if (!isInit.current) {
      setArticles([]);
      getPopularArticles({
        variables: {
          query: {
            source: "bot",
            returnSize: 5,
            userID: selectedUserProfile.id,
            signedIn: true,
            config: "imatrics_times_recent_config",
            returnFields: [
              "articleID",
              "headline",
              "preamble",
              "imageUrl",
              "publicationTimestamp",
              "topConcept",
              "concepts",
            ],
          },
        },
      });
    }
    isInit.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        //borderLeft: "2px solid #EF6F6C",
        overflow: "hidden",
      }}
    >
      <Box sx={{ py: 1, mx: 2 }}>
        <Typography
          component="span"
          sx={{
            px: 0.5,
            color: "#FFF",
            bgcolor: "#EF6F6C",
            fontWeight: 700,
          }}
        >
          RECENT NEWS
        </Typography>
      </Box>
      <List sx={{ maxHeight: "40vh", overflow: "auto" }}>
        {articles?.length > 0 &&
          articles?.map((article: any, i: number) => (
            <Fragment key={i}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleClick(article)}
                  sx={{ alignItems: "start" }}
                >
                  <Box component="span" sx={{ mr: 2 }}>
                    <Circle fontSize="small" color="secondary" />
                  </Box>
                  <Box width="100%">
                    <Box>
                      {article?.topConcept?.title && (
                        <Typography
                          component="span"
                          sx={{
                            color: "#EF6F6C",
                            fontWeight: 700,
                          }}
                        >
                          {article.topConcept.title.toUpperCase()}
                        </Typography>
                      )}
                      <Typography
                        component="span"
                        sx={{
                          float: "right",
                        }}
                      >
                        {moment(article.publicationTimestamp).format(
                          "HH:mm, ddd"
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Crete Round",
                        fontSize: "1.2rem",
                        fontWeight: 700,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {article.headline}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
              {i !== articles.length - 1 && <Divider variant="middle" />}
            </Fragment>
          ))}
      </List>
      <Box sx={{ py: 1, display: "flex", justifyContent: "center" }}>
        <Button
          color="secondary"
          size="small"
          startIcon={<ArrowDropDown fontSize="large" />}
          sx={{ textDecoration: "underline" }}
          onClick={loadMore}
        >
          View more
        </Button>
      </Box>
    </Paper>
  );
};

export default RecentNewsComponent;
