import { useContext } from "react";
import { CTX } from "./ApiDocsContext";
import { Box, Fab, Zoom, Tooltip } from "@mui/material";
import {
  CropFree,
  AddBox,
  ListAlt,
  GridOn,
  Visibility,
  DeveloperMode,
  Code,
  Warning,
  Label,
} from "@mui/icons-material";

const ElementButtons = () => {
  const {
    isEditable,
    activePage,
    setActivePage,
    selectedTab,
    setSelectedTab,
  }: any = useContext(CTX);

  const cardElement: String =
    '<div class="card mb-5">\n\t<div class="card-body">\n\t\t<h2>\n\t\t\tTITLE\n\t\t</h2>\n\t\t<p>\n\t\t\tCONTENT\n\t\t</p>\n\t</div>\n</div>';

  const codeElement: String = "<pre><code>\n\t\n</code></pre>";

  const listElement: String =
    '<ul class="list-group list-group-flush p-0">\n\t<li class="list-group-item">ITEM 1</li>\n\t<li class="list-group-item">ITEM 2</li>\n</ul>';

  const tableElement: String =
    '<table class="table">\n\t<thead>\n\t\t<tr>\n\t\t\t<th scope="col">HEAD 1</th>\n\t\t\t<th scope="col">HEAD 2</th>\n\t\t\t<th scope="col">HEAD3</th>\n\t\t</tr>\n\t</thead>\n\t<tbody>\n\t\t<tr>\n\t\t\t<th scope="row">ITEM 1</th>\n\t\t\t<td>ITEM 2</td>\n\t\t\t<td>ITEM 3</td>\n\t\t</tr>\n\t\t<tr>\n\t\t\t<th scope="row">ITEM 4</th>\n\t\t\t<td>ITEM 5</td>\n\t\t\t<td>ITEM 6</td>\n\t\t</tr>\n\t</tbody>\n</table>';

  const warningElement: String =
    '<div class="alert alert-warning"><strong>Important</strong>\n\t\n</div>';

  const badgeElement: String = '<span class="badge badge-info">\n\t\n</span>';

  const sectionElement: String = '<section id="" title="">\n\t\n</section>';

  const insertElement = (element: String) => {
    setActivePage({
      ...activePage,
      content: activePage.content
        ? activePage.content + "\n\n" + element
        : activePage.content + element,
    });
  };

  const togglePreview = () => {
    selectedTab === "preview"
      ? setSelectedTab("write")
      : setSelectedTab("preview");
  };

  return (
    <Box
      style={{
        display: "flex",
      }}
    >
      <Box mr={2}>
        <Zoom in={isEditable} timeout={{ enter: 50, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert section"
            aria-label="insert-section"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(sectionElement);
              }}
            >
              <CropFree />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box mr={2}>
        <Zoom in={isEditable} timeout={{ enter: 50, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert card"
            aria-label="insert-card"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(cardElement);
              }}
            >
              <AddBox />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box mr={2}>
        <Zoom in={isEditable} timeout={{ enter: 100, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert list"
            aria-label="insert-list"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(listElement);
              }}
            >
              <ListAlt />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box mr={2}>
        <Zoom in={isEditable} timeout={{ enter: 150, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert table"
            aria-label="insert-table"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(tableElement);
              }}
            >
              <GridOn />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box mr={2}>
        <Zoom in={isEditable} timeout={{ enter: 200, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert code"
            aria-label="insert-code"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(codeElement);
              }}
            >
              <Code />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box mr={2}>
        <Zoom in={isEditable} timeout={{ enter: 250, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert badge"
            aria-label="insert-badge"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(badgeElement);
              }}
            >
              <Label />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box mr={4}>
        <Zoom in={isEditable} timeout={{ enter: 300, exit: 250 }} unmountOnExit>
          <Tooltip
            title="Insert warning"
            aria-label="insert-warning"
            placement="right"
          >
            <Fab
              size="small"
              onClick={() => {
                insertElement(warningElement);
              }}
            >
              <Warning />
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
      <Box>
        <Zoom in={isEditable} timeout={{ enter: 350, exit: 250 }} unmountOnExit>
          <Tooltip
            title={selectedTab === "preview" ? "Edit mode" : "Preview page"}
            aria-label="preview-write"
            placement="right"
          >
            <Fab size="small" onClick={togglePreview}>
              {selectedTab === "preview" ? <DeveloperMode /> : <Visibility />}
            </Fab>
          </Tooltip>
        </Zoom>
      </Box>
    </Box>
  );
};

export default ElementButtons;
