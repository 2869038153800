import { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Autocomplete,
  Alert,
} from "@mui/material";

import { INewPage, ISubmitMessage } from "./types";

interface IProps {
  docPrivs: Array<string>;
  onSubmit: (page: INewPage) => void;
  submitError: ISubmitMessage;
}
const NewPageInput = (props: IProps) => {
  const { docPrivs, onSubmit, submitError } = props;

  const [newPage, setNewPage] = useState<INewPage>({
    name: "",
    priv: "",
  });

  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
      sx={{ minWidth: "180px" }}
    >
      <Grid item>
        <Typography variant="overline">Add page</Typography>
      </Grid>
      <Grid item>
        <TextField
          autoComplete="off"
          type="text"
          id="new-page-field"
          label="Page name"
          variant="outlined"
          size="small"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={newPage.name}
          onChange={(e: any) => {
            setNewPage({ ...newPage, name: e.target.value });
          }}
        />
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Autocomplete
          id="new-priv-input"
          freeSolo
          options={docPrivs}
          fullWidth
          size="small"
          onChange={(e: any, newValue: any) => {
            setNewPage({ ...newPage, priv: newValue });
          }}
          onInputChange={(e: any) => {
            setNewPage({ ...newPage, priv: e.target.value });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              label="Required privilege"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>

      <Grid item alignSelf="end">
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            onSubmit(newPage);
          }}
        >
          Add page
        </Button>
      </Grid>

      {submitError.show && (
        <Grid item>
          <Alert severity="error">{submitError.message}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default NewPageInput;
