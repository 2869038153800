import { Box } from "@mui/material";
import { IArticle } from "src/utils/typeDefs";

interface IProps {
  article?: IArticle | null;
}

const ArticleDisplayer = (props: IProps) => {
  const { article } = props;

  if (!article) return <Box sx={{ px: 2, py: 1 }} />;

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <p
        style={{
          fontSize: "32px",
          fontWeight: 700,
          marginBottom: 8,
        }}
      >
        {article.headline}
      </p>
      <p
        style={{
          fontSize: "20px",
          fontWeight: 500,
          marginBottom: 8,
        }}
      >
        {article.preamble}
      </p>
      {article.body?.map((text: string, i: number) => (
        <p key={i} style={{ fontSize: "14px", marginBottom: 8 }}>
          {text}
        </p>
      ))}
    </Box>
  );
};

export default ArticleDisplayer;
