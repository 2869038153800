import { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import ContextStore, { CTX as timesCTX } from "./Context";
import { CTX } from "../../utils/ContextStore";

import { Container, Box, Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import HeaderComponent from "./HeaderComponent";
import PageContentComponent from "./PageContentComponent";
import FooterComponent from "./FooterComponent";
import SelectProfileComponent from "./SelectProfileComponent";

const Newspage = () => {
  const history = useHistory();
  const {
    activeEndpoint,
    setActiveEndpoint,
    prevEndpoint,
    setPrevEndpoint,
    updateEndpoint,
  }: any = useContext(CTX);

  const { selectedUserProfile }: any = useContext(timesCTX);

  const [open, setOpen] = useState<boolean | undefined>(undefined);

  const switchEndpoint = () => {
    updateEndpoint({
      variables: {
        url: "https://gate1001.imatrics.com",
        name: "Swedish (Gota Media)",
        active: true,
        alternativeName: "Swedish (1001)",
        settingsName: "1001",
        isDemoEnvironment: false,
      },
      onCompleted: (res: any) => {
        if (res?.setEndpoint) {
          activeEndpoint && setPrevEndpoint(activeEndpoint);
          setActiveEndpoint(res.setEndpoint);
        } else {
          console.error("Failed to update dataset");
        }
      },
      onError: (err: Error) => {
        console.error(err.message);
      },
    });
  };

  const revertEndpoint = () => {
    if (!prevEndpoint) return;
    updateEndpoint({
      variables: prevEndpoint,
      onCompleted: (res: any) => {
        if (res?.setEndpoint) {
          activeEndpoint && setPrevEndpoint(activeEndpoint);
          setActiveEndpoint(res.setEndpoint);
        } else {
          console.error("Failed to update dataset");
        }
      },
      onError: (err: Error) => {
        console.error(err.message);
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => history.push("/"), 200);
  };

  useEffect(() => {
    var initComponent = () => {
      if (history.location.pathname.includes("/imatrics-times")) {
        !open && setOpen(true);
      } else {
        open && setOpen(false);
      }
    };
    initComponent();
    return history.listen((location: any) => {
      initComponent();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (open) {
      switchEndpoint();
    } else if (open === false) {
      revertEndpoint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open ?? false}
      onClose={handleClose}
      PaperProps={{
        id: "imatrics-times",
        style: { backgroundColor: "rgb(246, 248, 249)" },
      }}
    >
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          zIndex: 2,
          color: "#fff",
          position: "absolute",
          top: "16px",
          right: "16px",
          bgcolor: "rgba(0,0,0,0.75)",
          "&:hover": {
            opacity: 0.9,
            bgcolor: "rgba(0,0,0,0.75)",
          },
        }}
      >
        <Close />
      </IconButton>

      <Container
        id="im-imatrics-times"
        maxWidth="lg"
        disableGutters
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "100%",
          height: "100%",
          position: "relative",
          bgcolor: "transparent",
        }}
      >
        <SelectProfileComponent />
        {selectedUserProfile && (
          <Fragment>
            <Box sx={{ flex: "0 1 auto" }}>
              <HeaderComponent />
            </Box>
            <Box sx={{ flex: "1 1 auto" }}>
              <PageContentComponent />
            </Box>
            <Box sx={{ flex: "0 1 auto" }}>
              <FooterComponent />
            </Box>
          </Fragment>
        )}
      </Container>
    </Dialog>
  );
};

const NewspageComponent = () => {
  return (
    <ContextStore>
      <Newspage />
    </ContextStore>
  );
};

export default NewspageComponent;
