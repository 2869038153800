import gql from "graphql-tag";
const UPDATE_QUEUE_ARTICLE = gql`
  mutation ($query: UpdateQueueArticleInput) {
    updateQueueArticle(query: $query) {
      queueNr
      isDone
      concepts {
        weight
        title
        type
        uuid
        shortDescription
        wikipedia
        wikidata
        openStreetMap
        source
        longDescription
        pubStatus
        aliases
        author
        createdTimestamp
        latestVersionTimestamp
        broader
        geoJSON
        global
        language
      }
    }
  }
`;

export default UPDATE_QUEUE_ARTICLE;
