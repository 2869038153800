import { useState, createContext } from "react";
import { useQuery, useMutation } from "@apollo/client";

import GET_TOPIC_PAGES from "../../../queries/GET_TOPIC_PAGES";
import UPDATE_TOPIC_PAGES from "../../../mutations/UPDATE_TOPIC_PAGES";

export const CTX = createContext<any>(null);

interface ITopicPageContent {
  title: string;
  description: string;
  imgUrl: string;
  published: boolean;
  tags: Array<ITag>;
  tagCondition: string;
  slug: string;
  url: string;
  createdTimestamp: string;
  createdBy: string;
  lastEditedTimestamp: string;
  lastEditedBy: string;
}

interface ITag {
  uuid: string;
  title: string;
  type: string;
}

interface ISnackbar {
  show: boolean;
  message: string;
  severity: string;
}

export default function ContextStore(props: any) {
  const [topicPageList, setTopicPageList] = useState<Array<ITopicPageContent>>(
    []
  );
  const [selectedTopicPage, setSelectedTopicPage] = useState<any>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [snackBar, setSnackbar] = useState<ISnackbar | null>(null);

  const tagConditionOptions = [
    "All tags required",
    "At least one present",
    "At least two present",
  ];

  const { loading, error } = useQuery(GET_TOPIC_PAGES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.getTopicPages?.length > 0) {
        setTopicPageList(data.getTopicPages);
      } else {
        setTopicPageList([]);
      }
    },
    onError: (err: Error) => {
      setTopicPageList([]);
      throw err;
    },
  });

  const [updateTopicPages] = useMutation(UPDATE_TOPIC_PAGES, {
    onError: (err: Error) => {
      throw err;
    },
  });

  return (
    <CTX.Provider
      value={
        {
          loading,
          error,
          topicPageList,
          setTopicPageList,
          selectedTopicPage,
          setSelectedTopicPage,
          updateTopicPages,
          tagConditionOptions,
          isCreating,
          setIsCreating,
          snackBar,
          setSnackbar,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}
