import { useState, useEffect, useContext } from "react";
import {
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { CTX } from "../../utils/ContextStore";
import { useMutation } from "@apollo/client";

import GET_CONCEPT from "../../mutations/getConcept";
import CMConceptModalContent from "./CMConceptModalContent";

const useStyles = makeStyles({
  tab: {
    "&:hover": {
      opacity: 0.7,
    },
  },
});

interface Concept {
  uuid: string;
  title: string;
  type: string;
  gender?: string;
  shortDescription?: string;
  longDescription?: string;
  pubStatus?: boolean;
  ignore?: boolean;
  aliases?: string[];
  broader?: string;
  broaderConcept?: any;
  author?: string;
  source?: string;
  geoJSON?: string;
  subtypes?: string[];
  rootId?: string;
  correctionWeight?: number;
  minimumWeight?: number;
  mustNotConnectWords?: string[];
  mustConnectWords?: string[];
  keywords?: string[];
  mustBeMentionedWords?: string[];
  mustBeMentioned?: boolean;
  mustNotBeMentionedWords?: string[];
  links?: string[];
  global?: string;
  language?: string;
  wikipedia?: string;
  wikidata?: string;
  openStreetMap?: string;
  createdTimestamp?: string;
  latestVersionTimestamp?: string;
}

interface IProps {
  show: boolean;
  onHide: () => void;
  concept: Concept | null;
  createConcept?: boolean;
  setAddedConcept?: any;
  setModifiedConcept?: any;
  setDeletedConcept?: any;
  isConceptChangeSuggestion?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const CMConceptModal = (props: IProps) => {
  const classes = useStyles();
  const {
    show,
    onHide,
    concept,
    createConcept,
    setAddedConcept,
    setModifiedConcept,
    setDeletedConcept,
    isConceptChangeSuggestion,
    size = "lg",
  } = props;

  const modalLabel =
    createConcept && isConceptChangeSuggestion
      ? "Create Suggested Concept"
      : createConcept
      ? "Create Concept"
      : isConceptChangeSuggestion
      ? "Edit Suggested Concept"
      : "Edit Concept";

  if (concept?.type) concept.type = concept.type.replace("x-im/", "");

  const isEntity = !(
    concept?.type === "topic" ||
    concept?.type === "category" ||
    concept?.type === "x-im/topic" ||
    concept?.type === "x-im/category"
  );
  const { superAdminMode, isExtendedAdmin }: any = useContext(CTX);

  const [tab, setTab] = useState(0);
  const [customerConcept, setCustomerConcept] = useState<Concept | undefined>();
  const [languageConcept, setLanguageConcept] = useState<Concept | undefined>();
  const [globalConcept, setGlobalConcept] = useState<Concept | undefined>();
  const [customerConceptMerged, setCustomerConceptMerged] = useState<
    Concept | undefined
  >();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [customerError, setCustomerError] = useState<boolean>(false);
  const [languageError, setLanguageError] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<boolean>(false);

  const [getConceptCustomer] = useMutation(GET_CONCEPT, {
    variables: {
      uuid: concept?.uuid,
      global: concept?.global,
      language: concept?.language,
      sourceType: "customer",
      superAdmin: superAdminMode,
      extendedAdmin: isExtendedAdmin,
      quickSearch: false,
    },
    onError: () => {
      setCustomerConcept(undefined);
      setCustomerError(true);
    },
    onCompleted: (data) => {
      if (data?.getConcept?.result[0]) {
        setCustomerError(false);
        const retrievedConcept = data.getConcept.result[0];
        setCustomerConcept(retrievedConcept);
      } else {
        setCustomerError(true);
      }
    },
  });

  const [getMergedConceptLanguage] = useMutation(GET_CONCEPT, {
    variables: {
      uuid: concept?.uuid,
      global: concept?.global,
      language: concept?.language,
      sourceType: "language",
      superAdmin: superAdminMode,
      extendedAdmin: isExtendedAdmin,
      quickSearch: false,
    },
    onError: () => {
      setLanguageConcept(undefined);
      setLanguageError(true);
    },
    onCompleted: (data) => {
      if (data?.getConcept?.result[0]) {
        setLanguageError(false);
        const retrievedConcept = data.getConcept.result[0];
        setLanguageConcept(retrievedConcept);
      } else {
        setLanguageError(true);
      }
    },
  });

  const [getMergedConceptGlobal] = useMutation(GET_CONCEPT, {
    variables: {
      uuid: concept?.uuid,
      global: concept?.global,
      language: concept?.language,
      sourceType: "global",
      superAdmin: superAdminMode,
      extendedAdmin: isExtendedAdmin,
      quickSearch: false,
    },
    onError: (e) => {
      setGlobalConcept(undefined);
      setGlobalError(true);
    },
    onCompleted: (data) => {
      if (data?.getConcept?.result[0]) {
        setGlobalError(false);
        const retrievedConcept = data.getConcept.result[0];
        setGlobalConcept(retrievedConcept);
      } else {
        setGlobalError(true);
      }
    },
  });

  const [getMergedConceptAll] = useMutation(GET_CONCEPT, {
    variables: {
      uuid: concept?.uuid,
      global: concept?.global,
      language: concept?.language,
      superAdmin: superAdminMode,
      extendedAdmin: isExtendedAdmin,
      quickSearch: false,
    },
    onError: () => {
      setCustomerConceptMerged(undefined);
      setCustomerError(true);
    },
    onCompleted: (data) => {
      if (data?.getConcept?.result[0]) {
        setCustomerError(false);
        const retrievedConcept = data.getConcept.result[0];
        setCustomerConceptMerged(retrievedConcept);
      } else {
        setCustomerError(true);
      }
    },
  });

  const handleTabChange = (e: any, newValue: any) => {
    setTab(newValue);
  };

  const buildMergedConcept = (a1: any, a2: any) => {
    if (a1 && a2) {
      const resObj: any = { ...a1 };
      const inheritObj: any = { ...a2 };
      for (const key in resObj) {
        if (resObj[key] === null && inheritObj[key]) {
          resObj[key] = inheritObj[key];
        }
      }
      return resObj;
    }
    return undefined;
  };

  const fetchData = async () => {
    setIsFetching(true);
    let reqs = [getConceptCustomer()];
    if (!isEntity && superAdminMode) {
      reqs.push(getMergedConceptLanguage());
      reqs.push(getMergedConceptGlobal());
    } else if (!superAdminMode) {
      reqs.push(getMergedConceptAll());
    }
    await Promise.all(reqs);
    setIsFetching(false);
  };

  useEffect(() => {
    if (!show || !concept) return;
    setTab(0);
    if (!createConcept && concept?.uuid) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Dialog
      fullWidth
      maxWidth={size}
      open={show}
      onClose={onHide}
      scroll="body"
    >
      <DialogTitle
        style={{
          padding: 0,
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          backgroundColor: "rgba(232, 237, 242, 0.4)",
        }}
      >
        <Box
          px={2}
          pt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          zIndex={1}
        >
          <Box>{modalLabel}</Box>

          {!createConcept && (
            <Box width="77%" display="flex" justifyContent="center">
              {!isEntity && superAdminMode ? (
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                >
                  <Tab label="Customer" className={classes.tab} />
                  <Tab label="Language" className={classes.tab} />
                  <Tab label="Global" className={classes.tab} />
                  <Tab label="Links" className={classes.tab} />
                </Tabs>
              ) : !isEntity && isExtendedAdmin ? (
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                >
                  <Tab label="Concept" className={classes.tab} />
                  <Tab label="Links" className={classes.tab} />
                </Tabs>
              ) : superAdminMode || isExtendedAdmin ? (
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                >
                  <Tab label="Concept" className={classes.tab} />
                  <Tab label="Links" className={classes.tab} />
                </Tabs>
              ) : null}
            </Box>
          )}

          <IconButton aria-label="close" onClick={onHide}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        {isFetching ? (
          <Box
            display="flex"
            justifyContent="center"
            height="70vh"
            maxHeight="1200px"
            p={6}
          >
            <CircularProgress size="4rem" />
          </Box>
        ) : !createConcept && !isEntity && superAdminMode ? (
          <>
            <CMConceptModalContent
              concept={customerConcept}
              display={tab === 0 || tab === 3}
              showLinks={tab === 3}
              sourceType="customer"
              onHide={onHide}
              createConceptMode={createConcept}
              setAddedConcept={setAddedConcept}
              setModifiedConcept={setModifiedConcept}
              setDeletedConcept={setDeletedConcept}
              isConceptChangeSuggestion={isConceptChangeSuggestion}
              mergedConcept={buildMergedConcept(
                customerConcept,
                buildMergedConcept(languageConcept, globalConcept)
              )}
              error={customerError}
            />
            <CMConceptModalContent
              concept={languageConcept}
              display={tab === 1}
              sourceType="language"
              onHide={onHide}
              createConceptMode={createConcept}
              setAddedConcept={setAddedConcept}
              setModifiedConcept={setModifiedConcept}
              setDeletedConcept={setDeletedConcept}
              isConceptChangeSuggestion={isConceptChangeSuggestion}
              mergedConcept={buildMergedConcept(languageConcept, globalConcept)}
              error={languageError}
            />
            <CMConceptModalContent
              concept={globalConcept}
              display={!createConcept && tab === 2}
              sourceType="global"
              onHide={onHide}
              viewOnly
              createConceptMode={createConcept}
              setAddedConcept={setAddedConcept}
              setModifiedConcept={setModifiedConcept}
              setDeletedConcept={setDeletedConcept}
              isConceptChangeSuggestion={isConceptChangeSuggestion}
              mergedConcept={undefined}
              error={globalError}
            />
          </>
        ) : (
          <CMConceptModalContent
            concept={customerConcept || concept}
            display
            showLinks={isExtendedAdmin && tab === 1}
            onHide={onHide}
            createConceptMode={createConcept}
            setAddedConcept={setAddedConcept}
            setModifiedConcept={setModifiedConcept}
            setDeletedConcept={setDeletedConcept}
            isConceptChangeSuggestion={isConceptChangeSuggestion}
            mergedConcept={
              !isExtendedAdmin && !superAdminMode
                ? customerConceptMerged
                : buildMergedConcept(
                    customerConcept,
                    buildMergedConcept(languageConcept, globalConcept)
                  )
            }
            error={customerError}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CMConceptModal;
