import { gql } from "@apollo/client";

const updateMissingData = gql`
  mutation ($concept: ConceptInput, $cluster: String, $indexPrefix: String) {
    updateMissingData(
      concept: $concept
      cluster: $cluster
      indexPrefix: $indexPrefix
    ) {
      error
      message
    }
  }
`;

export default updateMissingData;
