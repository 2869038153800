import gql from "graphql-tag";
const GET_TOP_CONCEPTS = gql`
  query ($query: TopConceptsInput) {
    getTopConcepts(query: $query) {
      uuid
      title
      type
      usage
      usagePrevPeriod
    }
  }
`;

export default GET_TOP_CONCEPTS;
