import gql from "graphql-tag";

const getConceptChangeSuggestions = gql`
  mutation($handled: Boolean) {
    getConceptChangeSuggestions(handled: $handled) {
      result {
        concept {
          uuid
          title
          type
          gender
          aliases
          broader
          shortDescription
          longDescription
        }
        id
        reasonForChange
        geoChangeRequested
        createNewRequested
        deleteRequested
        reporter
        email
        source
        reportDate
        handled
        handledDate
      }
      error
    }
  }
`;

export default getConceptChangeSuggestions;
