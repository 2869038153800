import { Fragment, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { makeStyles } from "@mui/styles";

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
});

const useStyles = makeStyles({
  map: {
    width: "100%",
  },
});

interface Props {
  concept: any;
  size?: string;
  latLng?: string;
  setLatLng?: (loc: string) => void;
  pointSelectedCallback?: (geo: string) => void;
  setError?: (err: boolean) => void;
}

let addPoint: (lat: number, long: number) => void;

const CMMap = ({
  concept,
  size,
  latLng,
  setLatLng,
  pointSelectedCallback,
  setError,
}: Props) => {
  const classes = useStyles();
  const mapId = concept.uuid ? concept.uuid : "new-map-id";
  const renderMap = () => {
    const map = L.map(mapId, {
      zoomControl: size === "small" ? false : true,
      layers: [
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });

    let temp = concept.geoJSON;
    try {
      temp = JSON.parse(temp);
    } catch (err) {
      temp = {};
    }

    const geoJSON: GeoJSON.Feature = temp;
    geoJSON.properties = {};
    geoJSON.type = "Feature";
    let feature: L.GeoJSON;
    let addedPointFeature: L.GeoJSON;
    if (map && geoJSON && geoJSON.geometry) {
      try {
        feature = L.geoJSON(geoJSON.geometry).addTo(map);
        map.fitBounds(feature.getBounds());
        if (geoJSON.geometry.type === "Point") map.setZoom(12);
      } catch (err) {
        console.error("Bad geoJSON object entered");
      }
    } else {
      map.setView([51.505, -0.09], 3); //London, so that a point can be set.
    }
    map.on("contextmenu", function (e: any) {
      //Contextmenu is right-click with mouse.
      feature && feature.removeFrom(map);
      addedPointFeature && addedPointFeature.removeFrom(map);
      const addedPoint = L.marker(e.latlng, { title: "New point" });
      const addedPointGeoJSON: GeoJSON.Feature = addedPoint.toGeoJSON();
      addedPointFeature = L.geoJSON(addedPointGeoJSON.geometry).addTo(map);
      pointSelectedCallback &&
        pointSelectedCallback(JSON.stringify(addedPointGeoJSON));

      setLatLng &&
        setLatLng(
          `${e?.latlng?.lat?.toFixed(9)}, ${e?.latlng?.lng?.toFixed(9)}`
        );
    });

    //Define addPoint function
    addPoint = (lat: number, lng: number) => {
      feature && feature.removeFrom(map);
      addedPointFeature && addedPointFeature.removeFrom(map);
      const addedPoint = L.marker({ lat, lng }, { title: "New point" });
      const addedPointGeoJSON: GeoJSON.Feature = addedPoint.toGeoJSON();
      addedPointFeature = L.geoJSON(addedPointGeoJSON.geometry).addTo(map);
      map.setView([lat, lng], 9);
      pointSelectedCallback &&
        pointSelectedCallback(JSON.stringify(addedPointGeoJSON));
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(renderMap, []);

  useEffect(() => {
    if (latLng) {
      const lat: number = Number(latLng.split(",")[0]);
      const lng: number = Number(latLng.split(",")[1]);
      if (isNaN(lat) || isNaN(lng)) {
        setError && setError(true);
        return;
      }
      setError && setError(false);
      addPoint && addPoint(lat, lng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLng]);

  return (
    <Fragment>
      <div
        id={mapId}
        className={classes.map}
        style={size === "small" ? { height: 200 } : { height: 440 }}
      />
    </Fragment>
  );
};
export default CMMap;
