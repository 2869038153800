import { Fragment, useState, useEffect } from "react";
import { TextField } from "@mui/material";

const ArticleSearchInput = () => {
  const [searchString, setSearchString] = useState("");

  useEffect(() => {}, [searchString]);

  return (
    <Fragment>
      <TextField
        disabled={true}
        variant="outlined"
        size="small"
        fullWidth
        label="Search articles"
        value={searchString}
        InputLabelProps={{ shrink: true }}
        //InputProps={{ sx: { bgcolor: "#fff" } }}
        placeholder="Temporarily disabled"
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
      />
    </Fragment>
  );
};

export default ArticleSearchInput;
