import { Route, Redirect } from "react-router-dom";
import { Authenticator } from "../../utils/Authenticator";
import Unauthorized from "./Unauthorized";

export const PRIVILEGES = {
  ADMIN: "ADMIN",
  CUSTOMER_ADMIN: "CUSTOMER_ADMIN",
  USER_ADMIN: "USER_ADMIN",
  TAGGING: "TAGGING",
  CONCEPT_MANAGEMENT: "CONCEPT_MANAGEMENT",
  CONCEPT_SUGGESTIONS: "CONCEPT_SUGGESTIONS",
  PRODUCTION_INSIGHTS: "PRODUCTION_INSIGHTS",
  IMAGE_TAGGING: "IMAGE_TAGGING",
  GENDER_ANALYSIS: "GENDER_ANALYSIS",
  QUEUE: "QUEUE",
  API_DOCS: "API_DOCS",
  CREATE_WIKIDATA: "CREATE_WIKIDATA",
  TIMES: "TIMES",
  QUALITY_EVALUATOR: "QUALITY_EVALUATOR",
  PAT: "PAT",
  PER: "PER",
};

export const API_PRIVILEGES = {
  TAGGING_API: "TAGGING_API",
  CM_API: "CM_API",
  PI_API: "PI_API",
  REC_API: "REC_API",
};

export const PrivateRoute = ({
  component: Component,
  privileges,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props: any) =>
      Authenticator.isAuthenticated() ? (
        Authenticator.hasAuthority(privileges) ? (
          <Component {...props} />
        ) : (
          <Unauthorized />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
