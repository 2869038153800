import { Link } from "react-router-dom";
import { Authenticator } from "../../utils/Authenticator";
import { Grid, Box, Card, CardMedia, Typography } from "@mui/material";

interface IOption {
  id: string;
  privs?: string[];
  path: string;
  label?: string;
  description?: string;
  image?: string;
}

interface IProps {
  options: IOption[];
}

const Dashboard = (props: IProps) => {
  const { options } = props;
  return (
    <Grid
      container
      spacing={4}
      sx={{
        p: 4,
      }}
    >
      {options.map((option) =>
        !Authenticator.hasAuthority(option.privs) ? null : (
          <Grid item key={option.id} xs={4}>
            <Link to={option.path}>
              <Card
                elevation={4}
                sx={{
                  width: "100%",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    opacity: 0.8,
                    transform: "scale(1.02)",
                  },
                }}
              >
                {option.image && (
                  <CardMedia
                    title={option.label}
                    image={option.image}
                    sx={{
                      objectFit: "cover",
                      aspectRatio: "16/9",
                      pt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        py: 0.5,
                        px: 1,
                        bgcolor: "rgba(255, 255, 255, 0.8)",
                      }}
                    >
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {option.label}
                      </Typography>
                      <Typography sx={{ color: "#000" }}>{option.description}</Typography>
                    </Box>
                  </CardMedia>
                )}
              </Card>
            </Link>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Dashboard;
