import gql from "graphql-tag";

const DELETE_USER = gql`
  mutation ($username: String) {
    deleteUser(username: $username) {
      username
      error
      message
    }
  }
`;

export default DELETE_USER;
