import gql from "graphql-tag";
const getImages = gql`
  mutation ($tags: [ConceptInput]!) {
    getImages(tags: $tags) {
      data {
        credit
        caption
        imageUrl
        thumbnailUrl
      }
    }
  }
`;

export default getImages;