import config from "./config.json";
import { CTX } from "./Context";
import { Fragment, useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { Typography, Box, Paper } from "@mui/material";

import GET_NEWS_RECOMMENDATIONS from "../../queries/GET_NEWS_RECOMMENDATIONS";

import AdPlaceholderComponent from "./AdPlaceholderComponent";
import ArticleImageComponent from "./ArticleImageComponent";

const PopularNewsComponent = () => {
  const { history, selectedUserProfile } = useContext(CTX);
  const [articles, setArticles] = useState<any>([]);

  const [getPopularArticles] = useLazyQuery(GET_NEWS_RECOMMENDATIONS, {
    variables: {
      query: {
        source: "bot",
        returnSize: 20,
        userID: selectedUserProfile.id,
        signedIn: true,
        config: "imatrics_times_trending_config",
        returnFields: [
          "articleID",
          "headline",
          "preamble",
          "imageUrl",
          "publicationTimestamp",
          "topConcept",
          "concepts",
        ],
      },
    },
    onCompleted: (res) => {
      const data = res.getNewsRecommendations;
      const recommendations = data.recommendations;
      setArticles(recommendations);
    },
    onError: (e) => {
      console.error("err", e);
    },
  });

  const handleClick = (article: any) => {
    // dont trigger click if text is marked
    if (window?.getSelection()?.isCollapsed) {
      history.push(
        `${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${article.articleID}`
      );
    }
  };

  useEffect(() => {
    getPopularArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <AdPlaceholderComponent numberOfAds={1} />
      </Box>
      {articles?.length > 0 &&
        articles?.map((article: any, i: number) => (
          <Fragment key={i}>
            {i % 6 === 4 && (
              <Box sx={{ mb: 4 }}>
                <AdPlaceholderComponent numberOfAds={1} />
              </Box>
            )}
            <Paper
              elevation={4}
              sx={{ mb: 4, overflow: "hidden", cursor: "pointer" }}
              onClick={() => handleClick(article)}
            >
              
              <ArticleImageComponent uuid={article.articleID} size={800} />

              <Box sx={{ p: 3 }}>
                {article?.topConcept?.title && (
                  <Typography
                    sx={{
                      color: "#EF6F6C",
                      fontWeight: 700,
                    }}
                  >
                    {article.topConcept.title.toUpperCase()}
                  </Typography>
                )}
                <Typography
                  variant="h3"
                  sx={{ fontFamily: "Crete Round", fontWeight: 700, mb: 1 }}
                >
                  {article.headline}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Crete Round",
                    fontSize: "1.2rem",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {article.preamble}
                </Typography>
              </Box>
            </Paper>
          </Fragment>
        ))}
    </Box>
  );
};

export default PopularNewsComponent;
