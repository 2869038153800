import Modal from "react-bootstrap/Modal";
import { Box } from "@mui/material";
import ResourceModalContent from "./ResourceModalContent";

const ResourceModal = (props: any) => {
  const createResource: boolean = props.createResource
    ? props.createResource
    : false;

  const setAddedResource = props.setAddedResource
    ? props.setAddedResource
    : null;

  return (
    <Modal show={props.show} onHide={props.onHide} size="xl">
      <Modal.Header
        closeButton
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 16px",
        }}
      >
        <Box component="h4" pt={1}>
          {createResource ? "Create Resource" : "Edit Resource"}
        </Box>
      </Modal.Header>
      <Modal.Body>
        <ResourceModalContent
          resource={props.resource}
          language={props.language}
          cluster={props.cluster}
          display
          onHide={props.onHide}
          createResource={createResource}
          setAddedConcept={setAddedResource}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ResourceModal;
