import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CTX } from "./ApiDocsContext";
import {
  Box,
  Popover,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Edit,
  Done,
  Publish,
  PostAdd,
  Security,
  Close,
} from "@mui/icons-material";

import { INewPage, ISubmitMessage } from "./types";
import NewPageInput from "./NewPageInput";
import PrivInput from "./PrivInput";
import ElementButtons from "./ElementButtons";

const ManageButtonsComponent = () => {
  const {
    isEditable,
    setIsEditable,
    setDocPages,
    activePage,
    setActivePage,
    setActivePageBeforeChange,
    getApiDocs,
    newApiDocPage,
    updateApiDocs,
    activePageBeforeChange,
    docPrivs,
  }: any = useContext(CTX);

  const history = useHistory();

  const [submitError, setSubmitError] = useState<ISubmitMessage>({
    show: false,
    message: "",
  });
  const [snackbarData, setSnackbarData] = useState<ISubmitMessage>({
    show: false,
    message: "",
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorE2, setAnchorE2] = useState<HTMLButtonElement | null>(null);

  const openNewPage = Boolean(anchorEl);
  const openSetPriv = Boolean(anchorE2);

  const editClickHandler = () => {
    if (isEditable) {
      updateApiDocs({
        variables: {
          name: activePage.name,
          content: activePage.content,
          pubStatus: activePage.pubStatus,
          priv: activePage.priv,
        },
        onCompleted: () => {
          getApiDocs();
        },
        onError: (err: Error) => {
          console.error(err);
        },
      });
    }
    setIsEditable(!isEditable);
  };

  const newPageClickHandler = (newPage: INewPage) => {
    setSnackbarData({ show: false, message: "" });
    setSubmitError({ show: false, message: "" });
    if (isEditable && newPage.name !== "") {
      newApiDocPage({
        variables: {
          name: newPage.name,
          priv: newPage.priv,
          content: "<h1>" + newPage.name + "</h1>",
          pubStatus: false,
        },
      })
        .then((res: any) => {
          const newPath = res.data.newApiDocPage.path;
          getApiDocs({
            onCompleted: (data: any) => {
              setSnackbarData({
                show: true,
                message: "Page created successfully",
              });
              setDocPages(data.getApiDocs);
              history.push("/api-docs" + newPath);
            },
          });
        })
        .catch((err: Error) => {
          setSubmitError({ show: true, message: err.message });
        });
    }
  };

  const handleCloseNewPagePopover = () => {
    setAnchorEl(null);
  };

  const handleCloseSetPrivPopover = () => {
    setAnchorE2(null);
  };

  const handleOpenNewPagePopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenSetPrivPopover = (event: any) => {
    setAnchorE2(event.currentTarget);
  };

  const cancelClickHandler = () => {
    if (isEditable) {
      setIsEditable(false);
      setActivePage(activePageBeforeChange);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarData({ show: false, message: "" });
  };

  useEffect(() => {
    if (isEditable) {
      setActivePageBeforeChange(activePage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Snackbar
        open={snackbarData.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>

      <Popover
        sx={{ mt: 1, p: 3 }}
        open={openNewPage}
        anchorEl={anchorEl}
        onClose={handleCloseNewPagePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={2}>
          <NewPageInput
            onSubmit={newPageClickHandler}
            docPrivs={docPrivs}
            submitError={submitError}
          />
        </Box>
      </Popover>

      <Popover
        sx={{ mt: 1, p: 1 }}
        open={openSetPriv}
        anchorEl={anchorE2}
        onClose={handleCloseSetPrivPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <PrivInput
            activePage={activePage}
            setActivePage={setActivePage}
            docPrivs={docPrivs}
          />
        </Box>
      </Popover>

      <ElementButtons />
      <SpeedDial
        FabProps={{ color: isEditable ? "secondary" : "primary" }}
        ariaLabel="edit"
        icon={<SpeedDialIcon icon={<Edit />} openIcon={<Done />} />}
        open={isEditable}
        direction="left"
        onClick={() => {
          editClickHandler();
        }}
      >
        <SpeedDialAction
          icon={<Close />}
          tooltipTitle="Discard changes"
          onClick={(e: any) => {
            e.stopPropagation();
            cancelClickHandler();
          }}
        />
        <SpeedDialAction
          icon={<PostAdd />}
          tooltipTitle="Add page"
          onClick={(e: any) => {
            e.stopPropagation();
            handleOpenNewPagePopover(e);
          }}
        />
        <SpeedDialAction
          icon={<Publish color={activePage?.pubStatus ? "success" : "error"} />}
          tooltipTitle={
            activePage && activePage.pubStatus
              ? "Unpublish page"
              : "Publish page"
          }
          onClick={(e: any) => {
            e.stopPropagation();
            setActivePage({ ...activePage, pubStatus: !activePage.pubStatus });
          }}
        />
        <SpeedDialAction
          icon={<Security />}
          tooltipTitle="Set privilege"
          onClick={(e: any) => {
            e.stopPropagation();
            handleOpenSetPrivPopover(e);
          }}
        />
      </SpeedDial>
    </Box>
  );
};

export default ManageButtonsComponent;
