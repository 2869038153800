import { makeStyles, createStyles } from "@mui/styles";
import { ResponsiveBar } from "@nivo/bar";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: "hidden",
      height: "inherit",
    },
    chart: {
      height: 420,
      width: "100%",
    },
  })
);

const SubConceptContent = (props: any) => {
  const classes = useStyles();
  const chosenConcept = props.chosenConcept;
  const subConcepts = props.subConcepts
    ? props.subConcepts.filter((concept: any) => concept.frequency !== 0)
    : [];

  const formattedData =
    subConcepts && subConcepts.length !== 0
      ? subConcepts.map((concept: any) => {
          return { title: concept.title, [concept.title]: concept.frequency };
        })
      : [
          {
            title: chosenConcept.title,
            [chosenConcept.title]: chosenConcept.frequency,
          },
        ];

  const keyList: any[] = formattedData.map((concept: any) => {
    return concept.title;
  });

  return (
    <div className={classes.chart}>
      <ResponsiveBar
        data={formattedData}
        keys={keyList}
        indexBy="title"
        margin={{ top: 30, right: 60, bottom: 60, left: 60 }}
        padding={0.3}
        colors={{ scheme: "set2" }}
        layout={"vertical"}
        borderColor={{ from: "color", modifiers: [["darker", 3]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -15,
          legendPosition: "middle",
          legendOffset: 35,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Frequency",
          legendPosition: "middle",
          legendOffset: -45,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 3]] }}
      />
    </div>
  );
};

export default SubConceptContent;
