import { useState, useRef, useEffect, useContext } from "react";
import "./printArticleTaggingComponent.css";
import { IArticle, IUserQueueData, IQueueStatistics } from "src/utils/typeDefs";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CTX as tagCTX } from "src/components/Autotagging/TagContext";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  Check,
  Clear,
  Description,
  NavigateNext,
  CheckCircle,
  SubdirectoryArrowLeft,
  AccountBalance,
  EventNote,
  Grain,
  GridView,
  LocalOffer,
  LocationOn,
  Person,
} from "@mui/icons-material";

import DatasetPicker from "src/components//PanelComponents/DatasetPicker";
import TagDisplayer from "src/components//Autotagging/TagDisplayer";
import TagInfo from "src/components//Autotagging/TagInfo";
import SearchTagsComponent from "src/components//Autotagging/SearchTagsComponent";
import QueueStatisticsDisplayer from "src/components//Autotagging/TagQualityAssurance/QueueStatisticsDisplayer";

import GET_QUEUE_NAME from "src/queries/GET_QUEUE_NAME";
import STORE_QUEUE_NAME from "src/mutations/STORE_QUEUE_NAME";
import GET_QUEUE_ARTICLE from "src/queries/GET_QUEUE_ARTICLE";
import UPDATE_QUEUE_ARTICLE from "src/mutations/UPDATE_QUEUE_ARTICLE";
import ADD_TO_QUEUE from "src/mutations/ADD_TO_QUEUE";
import GET_CONCEPT from "src/mutations/getConcept";

import ImportJsonButton from "./ImportJsonButton";
import ExportJsonButton from "./ExportJsonButton";
import ArticleDisplayer from "./ArticleDisplayer";
import CMConceptModal from "src/components/ConceptManagement/CMConceptModal";

const newEntity = {
  uuid: "",
  title: "",
  type: "person",
  shortDescription: "",
  longDescription: "",
  aliases: [],
  broader: "",
};

const newTopic = {
  uuid: "",
  title: "",
  type: "topic",
  shortDescription: "",
  longDescription: "",
  broader: "",
};

const generateUUID = (length = 11) => {
  return "Q" + Math.random().toString(36).substr(2, length).toUpperCase();
};

const getTypeIcon = (type: string) => {
  if (type.includes("category")) {
    return <Description fontSize="small" />;
  } else if (type.includes("topic")) {
    return <Grain fontSize="small" />;
  } else if (type.includes("person")) {
    return <Person fontSize="small" />;
  } else if (type.includes("organisation")) {
    return <AccountBalance fontSize="small" />;
  } else if (type.includes("place")) {
    return <LocationOn fontSize="small" />;
  } else if (type.includes("event")) {
    return <EventNote fontSize="small" />;
  } else if (type.includes("object")) {
    return <GridView fontSize="small" />;
  } else {
    return <LocalOffer fontSize="small" />;
  }
};

const PrintArticleTaggingComponent = () => {
  const {
    activeEndpoint,
    categories,
    setCategories,
    topics,
    setTopics,
    entities,
    setEntities,
    addConcept,
    deleteCategory,
    deleteTopic,
    deleteEntity,
    formatTag,
    clearTags,
    editConceptModalShow,
    setEditConceptModalShow,
    createTopicModalShow,
    setCreateTopicModalShow,
    createConceptModalShow,
    setCreateConceptModalShow,
  }: any = useContext(tagCTX);

  const tagDisplayers = [
    {
      id: "category",
      label: "Categories",
      type: "category",
      tags: categories,
      deleteTag: deleteCategory,
      isEntity: false,
    },
    {
      id: "topic",
      label: "Topics",
      type: "topic",
      tags: topics,
      deleteTag: deleteTopic,
      isEntity: false,
    },
    {
      id: "entity",
      label: "Entities",
      type: "entity",
      tags: entities,
      deleteTag: deleteEntity,
      isEntity: true,
    },
  ];

  const isInit = useRef<boolean>(true);

  const [chosenConcept, setChosenConcept] = useState<any>({});
  const [modifiedConcept, setModifiedConcept] = useState<any>(undefined);
  const [deletedConcept, setDeletedConcept] = useState<any>(undefined);
  const [addedConcept, setAddedConcept] = useState<any>(undefined);

  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const [showBackdropTrophy, setShowBackdropTrophy] = useState<boolean>(false);
  const [userQueueData, setUserQueueData] = useState<IUserQueueData>({
    customQueue: true,
    queueName: undefined,
    queueNr: undefined,
    articleFinished: undefined,
  });

  const [file, setFile] = useState<any>(undefined);
  const [data, setData] = useState<Array<IArticle> | undefined>(undefined);
  const [uploadedSuccessUuids, setUploadedSuccessUuids] = useState<
    Array<string>
  >([]);
  const [queueStatistic, setQueueStatistic] = useState<
    IQueueStatistics | undefined
  >(undefined);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [dataUploadedSuccessCount, setDataUploadedSuccessCount] =
    useState<number>(0);
  const [dataUploadedFailCount, setDataUploadedFailCount] = useState<number>(0);

  const [jumpValue, setJumpValue] = useState<number | null>(null);
  const [errorJump, setErrorJump] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [getConcept] = useMutation(GET_CONCEPT);

  const [
    getQueueArticle,
    { loading: loadingNextArticle, error: nextArticleError },
  ] = useLazyQuery(GET_QUEUE_ARTICLE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const article = data.getQueueArticle;
      setUserQueueData({
        ...userQueueData,
        queueNr: data.getQueueArticle.queueNr,
        articleUuid: data.getQueueArticle.uuid,
        article: article,
        articleFinished: data.getQueueArticle.finished,
      });
      let categoryList: any = [];
      let topicList: any = [];
      let entityList: any = [];
      if (article?.concepts && article.concepts.length > 0) {
        article.concepts.forEach((item: any) => {
          if (item.type === "category" || item.type === "x-im/category") {
            categoryList = [...categoryList, formatTag(item)];
          } else if (item.type === "topic" || item.type === "x-im/topic") {
            topicList = [...topicList, formatTag(item)];
          } else {
            entityList = [...entityList, formatTag(item)];
          }
        });
      }
      JSON.stringify(categoryList) !== JSON.stringify(categories) &&
        setCategories(categoryList);
      JSON.stringify(topicList) !== JSON.stringify(topics) &&
        setTopics(topicList);
      JSON.stringify(entityList) !== JSON.stringify(entities) &&
        setEntities(entityList);
    },
    onError: () => {
      setUserQueueData({
        ...userQueueData,
        queueNr: undefined,
        articleUuid: undefined,
        article: undefined,
        articleFinished: undefined,
      });
    },
  });

  const [
    updateQueueArticle,
    { loading: loadingUpdateArticle, error: errorUpdateArticle },
  ] = useMutation(UPDATE_QUEUE_ARTICLE);

  const [addToQueue] = useMutation(ADD_TO_QUEUE);

  const [storeQueueName] = useMutation(STORE_QUEUE_NAME, {
    onCompleted: (data) => {
      setUserQueueData({
        ...userQueueData,
        queueName: data.storeQueueName,
      });
    },
    onError: () => {},
  });

  const [getQueueName] = useLazyQuery(GET_QUEUE_NAME, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getQueueName) {
        setUserQueueData({
          ...userQueueData,
          queueName: data.getQueueName,
        });
      }
    },
    onError: () => {},
  });

  const bulkAddToQueue = async (
    articles: Array<IArticle>,
    queueName = userQueueData.queueName
  ) => {
    setIsImporting(true);
    setShowBackdrop(true);

    for (const article of articles) {
      await addToQueue({
        variables: {
          query: {
            customQueue: userQueueData.customQueue,
            queueName: queueName,
            article: article,
          },
        },
        onCompleted: (data) => {
          const articleUuid = data.addToQueue;
          setUploadedSuccessUuids((prev) => [...prev, articleUuid]);
          setDataUploadedSuccessCount((prev) => prev + 1);
        },
        onError: () => {
          setDataUploadedFailCount((prev) => prev + 1);
        },
      });
    }

    await storeQueueName({
      variables: {
        query: {
          queueName: queueName,
        },
      },
      onCompleted: (data) => {
        setUserQueueData({
          ...userQueueData,
          customQueue: true,
          queueName: data.storeQueueName,
        });
      },
      onError: () => {
        const oldQueueName = userQueueData.queueName;
        setUserQueueData({
          ...userQueueData,
          queueName: oldQueueName,
        });
      },
    });
    setIsImporting(false);
  };

  const clearContent = () => {
    setUserQueueData({
      ...userQueueData,
      queueNr: undefined,
      articleUuid: undefined,
      article: undefined,
      articleFinished: undefined,
    });
    clearTags();
  };

  const handleFetch = () => {
    getQueueArticle({
      variables: {
        query: {
          customQueue: userQueueData.customQueue,
          queueName: userQueueData.queueName,
          queueNr: undefined,
        },
      },
    });
  };

  const handleJumpToIndex = (index: number | null) => {
    if (
      !userQueueData?.queueName ||
      !queueStatistic?.maxQueue ||
      !index ||
      Number.isNaN(index) ||
      index <= 0 ||
      index > queueStatistic?.maxQueue
    ) {
      setErrorJump(true);
      return;
    }
    setErrorJump(false);
    setJumpValue(null);
    getQueueArticle({
      variables: {
        query: {
          customQueue: userQueueData.customQueue,
          queueName: userQueueData.queueName,
          queueNr: index,
        },
      },
    })
      .then(() => {
        handleCloseJumpPopover();
      })
      .catch(() => {
        setErrorJump(true);
      });
  };

  const handleOpenJumpPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseJumpPopover = () => {
    setAnchorEl(null);
    setJumpValue(null);
    setErrorJump(false);
  };

  const openJumpPopover = Boolean(anchorEl);
  const idJumpPopover = openJumpPopover ? "article-jump-popover" : undefined;

  const handleSkip = () => {
    updateQueueArticle({
      variables: {
        query: {
          finished: true,
          concepts: [],
          customQueue: userQueueData.customQueue,
          queueName: userQueueData.queueName,
          queueNr: userQueueData.queueNr,
        },
      },
      onCompleted: () => {
        clearContent();
      },
    });
  };

  const handleDone = () => {
    updateQueueArticle({
      variables: {
        query: {
          finished: true,
          concepts: [...categories, ...topics, ...entities],
          customQueue: userQueueData.customQueue,
          queueName: userQueueData.queueName,
          queueNr: userQueueData.queueNr,
        },
      },
      onCompleted: () => {
        clearContent();
      },
    });
  };

  useEffect(() => {
    if (addedConcept) {
      setTimeout(() => {
        getConcept({ variables: addedConcept }).then((data: any) => {
          const res = data?.data?.getConcept?.result;
          if (!res || res?.length < 1) {
            return;
          }
          const newConcept = res[0];
          addConcept(newConcept);
        });
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedConcept]);

  useEffect(() => {
    if (modifiedConcept?.uuid) {
      let newEntities: any[] = entities.map((entity: any) =>
        entity.uuid === modifiedConcept.uuid
          ? { ...entity, ...modifiedConcept }
          : entity
      );
      setEntities(newEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedConcept]);

  useEffect(() => {
    if (deletedConcept) {
      let newEntities: any[] = entities.filter(
        (entity: any) => entity.uuid !== deletedConcept
      );
      setEntities(newEntities);
      setDeletedConcept(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedConcept]);

  useEffect(() => {
    if (!file || !data || data.length === 0) {
      return;
    }
    clearContent();
    const newQueueUUID = generateUUID();
    bulkAddToQueue(data, newQueueUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (!queueStatistic || !userQueueData.queueName) return;
    if (
      queueStatistic.completed + queueStatistic.skipped >=
      queueStatistic.maxQueue
    ) {
      if (!isInit.current) setShowBackdropTrophy(true);
    }
    isInit.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueStatistic]);

  useEffect(() => {
    if (!activeEndpoint || userQueueData.queueName) return;
    setData(undefined);
    setFile(undefined);
    clearContent();
    getQueueName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Backdrop
        open={showBackdropTrophy}
        sx={(theme) => ({
          color: "#fff",
          bgcolor: "rgba(0, 0, 0, 0.6)",
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <Box className="firework" />

        <Box sx={{ position: "absolute", left: "20%", top: "30%" }}>
          <Box className="firework" />
          <Box className="firework" />
        </Box>
        <Box sx={{ position: "absolute", left: "30%", top: "70%" }}>
          <Box className="firework" />
          <Box className="firework" />
        </Box>
        <Box sx={{ position: "absolute", left: "40%", top: "20%" }}>
          <Box className="firework" />
          <Box className="firework" />
        </Box>
        <Box sx={{ position: "absolute", left: "70%", top: "70%" }}>
          <Box className="firework" />
          <Box className="firework" />
        </Box>
        <Box sx={{ position: "absolute", left: "80%", top: "30%" }}>
          <Box className="firework" />
          <Box className="firework" />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40%",
            flexDirection: "column",
            rowGap: 2,
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 700 }}>
            Congratulations!
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            You have finished the queue
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 4 }}
          >
            <img
              className="img-wiggle"
              alt="trophy-pat"
              src="https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/trophy-pat.png"
              style={{
                height: "200px",
                width: "200px",
                objectFit: "cover",
              }}
            />

            <Typography variant="overline" sx={{ fontSize: 16 }}>
              <div>
                Queue ID: <b>{userQueueData.queueName}</b>
              </div>
              <div>
                Articles: <b>{queueStatistic?.maxQueue}</b>
              </div>
              <div>
                Completed:{" "}
                <b style={{ color: "#56e39f" }}>{queueStatistic?.completed}</b>
              </div>
              <div>
                Skipped:{" "}
                <b style={{ color: "#ffad75" }}>{queueStatistic?.skipped}</b>
              </div>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Box sx={{ width: "50%" }}>
              <ExportJsonButton
                queueName={userQueueData.queueName}
                size="large"
                fullWidth
                callback={undefined}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => setShowBackdropTrophy(false)}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Backdrop>

      <Backdrop
        open={showBackdrop}
        sx={(theme) => ({
          color: "#fff",
          bgcolor: "rgba(0, 0, 0, 0.6)",
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "40%",
            flexDirection: "column",
            rowGap: 1,
          }}
        >
          {data && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="overline"
                  sx={{ fontSize: 18, fontWeight: 700 }}
                >
                  {`Processed ${
                    dataUploadedSuccessCount + dataUploadedFailCount
                  } of ${data.length}${
                    dataUploadedSuccessCount + dataUploadedFailCount ===
                    data.length
                      ? ""
                      : "..."
                  }`}
                </Typography>
              </Box>

              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  color="success"
                  variant="determinate"
                  defaultValue={0}
                  value={
                    ((dataUploadedSuccessCount + dataUploadedFailCount) /
                      data.length) *
                    100
                  }
                  sx={{ height: 20 }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <Tooltip
                  arrow
                  disableInteractive={false}
                  title={
                    <span>
                      <Typography variant="body1">
                        Succeeded article upload count:{" "}
                        <b>{dataUploadedSuccessCount}</b>
                      </Typography>
                    </span>
                  }
                  placement="right"
                  enterDelay={500}
                >
                  <Typography
                    variant="overline"
                    color="success.main"
                    sx={{ fontSize: 16, lineHeight: 1.5, cursor: "pointer" }}
                  >
                    Success: <b>{dataUploadedSuccessCount}</b>
                  </Typography>
                </Tooltip>

                <Tooltip
                  arrow
                  disableInteractive={false}
                  title={
                    <span>
                      <Typography variant="body1">
                        Failed article upload count:{" "}
                        <b>{dataUploadedFailCount}</b>
                      </Typography>
                      <Typography variant="body2">
                        {data
                          .filter(
                            (article) =>
                              !uploadedSuccessUuids.includes(article.uuid)
                          )
                          .join(", ")}
                      </Typography>
                    </span>
                  }
                  placement="right"
                  enterDelay={500}
                >
                  <Typography
                    variant="overline"
                    color="error"
                    sx={{ fontSize: 16, lineHeight: 1.5, cursor: "pointer" }}
                  >
                    Failed: <b>{dataUploadedFailCount}</b>
                  </Typography>
                </Tooltip>

                {isImporting ? null : (
                  <Typography variant="overline" sx={{ fontSize: 16 }}>
                    Queue ID: <b>{userQueueData.queueName}</b>
                  </Typography>
                )}
              </Box>
            </>
          )}

          <Box sx={{ margin: "auto" }}>
            <Button
              disabled={isImporting}
              size="large"
              color="success"
              variant="contained"
              onClick={() => {
                setShowBackdrop(false);
                setData(undefined);
              }}
            >
              Upload finished
            </Button>
          </Box>
        </Box>
      </Backdrop>

      <CMConceptModal
        show={editConceptModalShow}
        onHide={() => setEditConceptModalShow(false)}
        concept={chosenConcept}
        setModifiedConcept={setModifiedConcept}
        setDeletedConcept={setDeletedConcept}
      />
      <CMConceptModal
        show={createConceptModalShow}
        onHide={() => setCreateConceptModalShow(false)}
        concept={newEntity}
        setAddedConcept={setAddedConcept}
        createConcept
      />
      <CMConceptModal
        show={createTopicModalShow}
        onHide={() => setCreateTopicModalShow(false)}
        concept={newTopic}
        setAddedConcept={setAddedConcept}
        createConcept
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1.5,
          gap: 1,
          borderBottom: "1px solid rgba(0,0,0,0.125)",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <ExportJsonButton
            queueName={userQueueData.queueName}
            callback={undefined}
          />
          <ImportJsonButton setFile={setFile} setData={setData} />
          {file && (
            <Tooltip
              arrow
              title={
                <span>
                  <Box sx={{ mb: 1 }}>You imported articles from a file!</Box>
                  <div>
                    Name: <b>{file?.name}</b>
                  </div>
                  <div>
                    Size: <b>{file?.size} bytes</b>
                  </div>
                  <div>
                    Last modified: <b>{`${file?.lastModifiedDate}`}</b>
                  </div>
                </span>
              }
            >
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  maxWidth: 160,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
              >
                <Typography variant="caption" fontWeight={700}>
                  {file?.name}
                </Typography>
              </Paper>
            </Tooltip>
          )}
        </Box>

        <DatasetPicker />
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          px: 2,
          pt: 1,
          gap: 1,
        }}
      >
        <ButtonGroup
          disabled={
            loadingNextArticle ||
            !userQueueData.queueName ||
            userQueueData.articleFinished === false
          }
          disableElevation={
            loadingNextArticle ||
            !userQueueData.queueName ||
            userQueueData.articleFinished === false
          }
          variant="contained"
        >
          <Tooltip
            arrow
            title={
              <span>
                <span>Fetch the next unassigned article from the queue</span>
                <div>
                  Queue ID: <b>{userQueueData.queueName}</b>
                </div>
              </span>
            }
            disableInteractive
            enterDelay={500}
          >
            <span>
              <Button onClick={() => handleFetch()} sx={{ gap: 0.5 }}>
                {loadingNextArticle ? (
                  <CircularProgress size="23px" sx={{ p: "4px" }} />
                ) : (
                  <NavigateNext />
                )}
                Fetch article
              </Button>
            </span>
          </Tooltip>
          <Popover
            id={idJumpPopover}
            open={openJumpPopover}
            anchorEl={anchorEl}
            onClose={handleCloseJumpPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box sx={{ p: 2, maxWidth: 255 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleJumpToIndex(jumpValue);
                }}
              >
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  type="number"
                  fullWidth
                  size="small"
                  label="Queue number"
                  value={jumpValue ?? ""}
                  error={errorJump}
                  helperText={errorJump ? "Invalid input value" : undefined}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    autoFocus: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit" size="small" edge="end">
                          <SubdirectoryArrowLeft />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setErrorJump(false);
                    setJumpValue(Number(e.target.value) || null);
                  }}
                  sx={{ mt: 0.5 }}
                />
              </form>

              <Alert severity="info" sx={{ px: 1.5, mt: 2 }}>
                You can revisit a finished article between number <b>1</b> and{" "}
                <b>
                  {queueStatistic?.lastAssigned && queueStatistic.lastAssigned}
                </b>
                .
              </Alert>
            </Box>
          </Popover>
          <Tooltip
            arrow
            title="Revisit a specific article in the queue"
            enterDelay={500}
          >
            <span>
              <Button onClick={handleOpenJumpPopover}>
                <SubdirectoryArrowLeft />
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
        <Tooltip
          arrow
          title="Mark article as finished with the specified tags"
          disableInteractive
          enterDelay={500}
        >
          <span>
            <Button
              disabled={
                loadingNextArticle ||
                loadingUpdateArticle ||
                !userQueueData.article ||
                userQueueData.articleFinished
              }
              variant="contained"
              color="success"
              onClick={handleDone}
              sx={{ gap: 0.5 }}
            >
              <Check />
              Done
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          arrow
          title="Mark article as finished without any tags"
          disableInteractive
          enterDelay={500}
        >
          <span>
            <Button
              disabled={
                loadingNextArticle ||
                loadingUpdateArticle ||
                !userQueueData.article ||
                userQueueData.articleFinished
              }
              variant="contained"
              color="secondary"
              onClick={handleSkip}
              sx={{ gap: 0.5 }}
            >
              <Clear />
              Skip
            </Button>
          </span>
        </Tooltip>

        <QueueStatisticsDisplayer
          customQueue={userQueueData.customQueue}
          queueName={userQueueData.queueName}
          userQueueData={userQueueData}
          callback={(statistic: IQueueStatistics) => {
            setQueueStatistic(statistic ?? undefined);
          }}
        />

        <Tooltip
          arrow
          disableInteractive={false}
          title={
            <span>
              <div>
                Current queue ID: <b>{userQueueData.queueName}</b>
              </div>
              <div>
                Current queue number: <b>{userQueueData.queueNr ?? "N/A"}</b>
              </div>
              <div>
                Current article UUID:{" "}
                <b>{userQueueData.articleUuid ?? "N/A"}</b>
              </div>
            </span>
          }
          enterDelay={500}
        >
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              maxWidth: 160,
              maxHeight: 33,
              overflow: "hidden",
              whiteSpace: "nowrap",
              cursor: "pointer",
              gap: 0.5,
            }}
          >
            {userQueueData.queueNr ? (
              <>
                <Typography
                  variant="overline"
                  sx={{ lineHeight: 1, display: "flex", gap: 0.5 }}
                >
                  Current:
                  <b>{userQueueData.queueNr}</b>
                </Typography>
                <CheckCircle
                  color={userQueueData.articleFinished ? "success" : "disabled"}
                />
              </>
            ) : (
              <Typography
                variant="overline"
                sx={{ lineHeight: 1, display: "flex", gap: 0.5 }}
              >
                ID:
                <b>{`${userQueueData.queueName ?? "N/A"}`}</b>
              </Typography>
            )}
          </Paper>
        </Tooltip>
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "100%",
          px: 2,
          pt: 1.5,
          overflow: "auto",
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ flex: 1, p: 0.25, height: "100%", maxHeight: "100%" }}
          >
            <Paper
              elevation={4}
              sx={{
                height: "100%",
                overflowY: "auto",
                fontFamily: "Crete Round",
              }}
            >
              <LinearProgress
                sx={{ visibility: loadingNextArticle ? "visible" : "hidden" }}
              />
              <ArticleDisplayer article={userQueueData.article} />
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            gap={2}
            sx={{
              flex: 1,
              height: "100%",
              maxHeight: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                overflowY: "auto",
                p: 0.25,
                flex: 1,
                height: "100%",
                maxHeight: "100%",
              }}
            >
              {(nextArticleError || errorUpdateArticle) && (
                <Alert severity="error">
                  {nextArticleError?.message ?? errorUpdateArticle?.message}
                </Alert>
              )}

              {tagDisplayers.map((tagDisplayer) => (
                <Paper key={tagDisplayer.id} elevation={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      py: 0.5,
                      px: 1,
                      bgcolor: "#f5f5f5",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {getTypeIcon(tagDisplayer.type)}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        <b>{tagDisplayer.label}</b>
                        {tagDisplayer.tags.length > 0 && (
                          <b>({tagDisplayer.tags.length})</b>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TagInfo type={tagDisplayer.type} />
                    </Box>
                  </Box>

                  <Box id={tagDisplayer.id} sx={{ p: 1 }}>
                    <TagDisplayer
                      //disabled={userQueueData.articleFinished}
                      tags={tagDisplayer.tags}
                      getConcept={getConcept}
                      deleteTag={tagDisplayer.deleteTag}
                      setChosenConcept={setChosenConcept}
                      setEditConceptModalShow={setEditConceptModalShow}
                      isEntity={tagDisplayer.isEntity}
                      tagQualityAssurance={true}
                      tagQueueArticle={updateQueueArticle}
                      tagQueueProps={{
                        customQueue: userQueueData.customQueue,
                        queueName: userQueueData.queueName,
                        queueNr: userQueueData.queueNr,
                        finished: userQueueData.articleFinished,
                      }}
                    />
                    <SearchTagsComponent
                      //disabled={userQueueData.articleFinished}
                      type={tagDisplayer.type}
                      tagQualityAssurance={true}
                      tagQueueArticle={updateQueueArticle}
                      tagQueueProps={{
                        customQueue: userQueueData.customQueue,
                        queueName: userQueueData.queueName,
                        queueNr: userQueueData.queueNr,
                        finished: userQueueData.articleFinished,
                      }}
                    />
                  </Box>
                </Paper>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PrintArticleTaggingComponent;
