import moment from "moment";
import { ResponsiveBar } from "@nivo/bar";
import { Box } from "@mui/material";

const tickList = (data: any) => {
  let res: any[] = [];
  if (data.length > 7) {
    data.forEach((timestamp: any, index: number) => {
      if (index % 2 === 0) res.push(timestamp.date);
    });
  } else {
    data.forEach((timestamp: any) => {
      res.push(timestamp.date);
    });
  }
  return res;
};

const ConceptOvertimeBarChart = ({ plotData }: any) => {
  let keyList: any[] = [];
  const formatData = (plotData: any) => {
    if (plotData) {
      let resArray: any[] = [];
      keyList = plotData.map((plot: any) => plot.id);

      if (plotData[0] && plotData[0].data) {
        plotData[0].data.forEach((timestamp: any) => {
          resArray.push({ date: timestamp.x });
        });
      }

      plotData?.forEach((plot: any) => {
        if (plot?.data) {
          plot?.data?.forEach((timestamp: any, index: number) => {
            try {
              resArray[index][plot.id] = timestamp.y;
            } catch (err) {}
          });
        }
      });
      return resArray;
    }
    return [];
  };

  const binData = (data: any) => {
    let newData: any[] = [];
    let listLength = 0;

    if (data.length > 32 && data.length <= 90) {
      //console.log("weekly bins");
      data.forEach((i: number) => {
        if (i === 0) {
          newData.push(data[i]);
          listLength++;
        } else if (
          moment(newData[listLength - 1].date).week() !==
          moment(data[i].date).week()
        ) {
          newData.push(data[i]);
          listLength++;
        } else {
          keyList.forEach((key: any) => {
            newData[listLength - 1][key] += data[i][key];
          });
        }
      });
      return newData;
    } else if (data.length > 90) {
      //console.log("monthly bins");
      data.forEach((i: number) => {
        if (i === 0) {
          newData.push(data[i]);
          listLength++;
        } else if (
          moment(newData[listLength - 1].date).format("YY-MM") !==
          moment(data[i].date).format("YY-MM")
        ) {
          newData.push(data[i]);
          listLength++;
        } else {
          keyList.forEach((key: any) => {
            newData[listLength - 1][key] += data[i][key];
          });
        }
      });
      return newData;
    } else {
      //console.log("daily bins");
      return data;
    }
  };

  let binnedData = binData(formatData(plotData));

  return (
    <Box sx={{ minHeight: "320px", height: "30vh" }}>
      <ResponsiveBar
        data={binnedData}
        keys={keyList}
        indexBy="date"
        margin={{ top: 20, right: 120, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "set2" }}
        layout={"vertical"}
        defs={[]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -25,
          legend: "Date",
          legendPosition: "middle",
          legendOffset: 40,
          truncateTickAt: 0,
          format: (value) => {
            if (
              formatData(plotData).length > 32 &&
              formatData(plotData).length <= 90
            ) {
              return moment(value).format("[W]WW");
            } else if (formatData(plotData).length > 90) {
              return moment(value).format("MMM YY");
            } else {
              return moment(value).format("Do");
            }
          },
          tickValues: tickList(binnedData),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Usage",
          legendPosition: "middle",
          legendOffset: -50,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Box>
  );
};

export default ConceptOvertimeBarChart;
