import gql from "graphql-tag";
const loginMutation = gql`
  mutation($username: String, $password: String) {
    login(username: $username, password: $password) {
      username
      token
      privileges
      expiresAt
    }
  }
`;

export default loginMutation;
