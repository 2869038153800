import gql from "graphql-tag";

const UPDATE_USER = gql`
  mutation ($userdata: UserInput) {
    updateUser(userdata: $userdata) {
      username
      password
      created
      privs
      privsString
      docPrivs
      webdemo {
        dataset
        expires
        api_calls
        max_api_calls
      }
      queue
      dataset
      apiCalls
      expires
      confirmed
      confirmed_at
      demo_user
    }
  }
`;

export default UPDATE_USER;
