import { MenuItem, TextField } from "@mui/material";

interface Props {
  value: string;
  onChange: (e: any) => void;
  options: any;
}

const LimitSelector = (props: Props) => {
  const { value, onChange, options } = props;
  return (
    <TextField
      name="max-input"
      autoComplete="off"
      select
      label="Max"
      size="small"
      variant="outlined"
      style={{
        margin: "0 8px",
        width: "5rem",
      }}
      InputProps={{
        style: { fontWeight: 700, maxHeight: "1.8rem" },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={onChange}
    >
      {options.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LimitSelector;
