import gql from "graphql-tag";

const createConcept = gql`
  mutation (
    $superAdmin: Boolean
    $extendedAdmin: Boolean
    $rootId: String
    $title: String!
    $type: String!
    $gender: String
    $pubStatus: Boolean
    $shortDescription: String
    $longDescription: String
    $aliases: [String]
    $broader: String
    $author: String
    $ignore: Boolean
    $subtypes: [String]
    $geoJSON: String
    $conceptType: String
    $keywords: [String]
    $mustConnectWords: [[String]]
    $mustNotConnectWords: [[String]]
    $mustBeMentioned: Boolean
    $mustBeMentionedWords: [String]
    $mustNotBeMentionedWords: [String]
    $correctionWeight: Float
    $minimumWeight: Int
  ) {
    createConcept(
      superAdmin: $superAdmin
      extendedAdmin: $extendedAdmin
      rootId: $rootId
      title: $title
      type: $type
      gender: $gender
      pubStatus: $pubStatus
      shortDescription: $shortDescription
      longDescription: $longDescription
      aliases: $aliases
      broader: $broader
      author: $author
      ignore: $ignore
      subtypes: $subtypes
      geoJSON: $geoJSON
      conceptType: $conceptType
      keywords: $keywords
      mustConnectWords: $mustConnectWords
      mustNotConnectWords: $mustNotConnectWords
      mustBeMentioned: $mustBeMentioned
      mustBeMentionedWords: $mustBeMentionedWords
      mustNotBeMentionedWords: $mustNotBeMentionedWords
      correctionWeight: $correctionWeight
      minimumWeight: $minimumWeight
    ) {
      uuid
      concept {
        uuid
        global
        language
      }
      response
      error
    }
  }
`;

export default createConcept;
