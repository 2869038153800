import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface IProps {
  nextPath: string;
  open: boolean;
  onSave: (nextPath: string) => void;
  onDiscard: (nextPath: string) => void;
  onClose: () => void;
}

const AlertModalComponent = (props: IProps) => {
  const { nextPath, open, onSave, onDiscard, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have unsaved changes. Would you like to save them?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave(nextPath)}>Save</Button>
        <Button onClick={() => onDiscard(nextPath)}>Discard</Button>
        <Button onClick={() => onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModalComponent;
