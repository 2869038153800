import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ListItem, Box, IconButton, Tooltip } from "@mui/material";
import {
  AccountBalance,
  Cancel,
  Description,
  EventNote,
  Grain,
  GridView,
  LocationOn,
  Person,
} from "@mui/icons-material";

import GET_SUB_CONCEPTS_MUTATION from "../../mutations/getSubConceptsMutation";
import SubConceptModal from "./SubConcepts/SubConceptModal";

interface IProps {
  concept: any;
  startDate: string;
  endDate: string;
  newspaper?: string;
  channel?: string;
  excludeAuthors?: string[];
  removeFromConceptList: any;
}

const ConceptItem = (props: IProps) => {
  const {
    concept,
    startDate,
    endDate,
    newspaper,
    channel,
    removeFromConceptList,
  } = props;
  const [chosenConcept, setChosenConcept] = useState({});
  const [getSubConcepts] = useMutation(GET_SUB_CONCEPTS_MUTATION);
  const [subConceptModalShow, setSubConceptModalShow] = useState(false);
  const [subConcepts, setSubConcepts] = useState({});

  const conceptClickHandler = (data: any, concept: any) => {
    if (concept) {
      setChosenConcept(concept);
      setSubConcepts(data.data.getStatisticMutation.result);
      setSubConceptModalShow(true);
    }
  };

  return (
    <>
      {concept && (
        <ListItem
          button
          secondaryAction={
            <Tooltip title="Remove from list" placement="top">
              <IconButton
                edge="end"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFromConceptList(concept);
                }}
              >
                <Cancel fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          onClick={(e) => {
            e.stopPropagation();
            getSubConcepts({
              variables: {
                statisticOperation: "conceptFrequencyByParent",
                conceptType: concept.type,
                parent: concept.uuid,
                startDate: startDate,
                endDate: endDate,
                newspaper: channel,
                channel: channel,
              },
            }).then((data: any) => {
              conceptClickHandler(data, concept);
            });
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontWeight: 700,
              }}
            >
              {!concept.type ? null : concept.type.includes("category") ? (
                <Description fontSize="small" />
              ) : concept.type.includes("topic") ? (
                <Grain fontSize="small" />
              ) : concept.type.includes("person") ? (
                <Person fontSize="small" />
              ) : concept.type.includes("place") ? (
                <LocationOn fontSize="small" />
              ) : concept.type.includes("organisation") ? (
                <AccountBalance fontSize="small" />
              ) : concept.type.includes("event") ? (
                <EventNote fontSize="small" />
              ) : concept.type.includes("object") ? (
                <GridView fontSize="small" />
              ) : null}
              <Box component="span" sx={{ fontWeight: "bold", pl: 1 }}>
                {concept.title}
              </Box>
            </Box>
            <Box component="span">{concept.usage}</Box>
          </Box>
          <SubConceptModal
            show={subConceptModalShow}
            onHide={(e: any) => {
              e.stopPropagation();
              setSubConceptModalShow(false);
            }}
            chosenConcept={chosenConcept}
            subConcepts={subConcepts}
            setChosenConcept={setChosenConcept}
            setSubConcepts={setSubConcepts}
            startDate={startDate}
            endDate={endDate}
            newspaper={newspaper}
            channel={channel}
          />
        </ListItem>
      )}
    </>
  );
};

export default ConceptItem;
