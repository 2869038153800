import { useState, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  MenuItem,
  Chip,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Add, ExpandLess, Grain } from "@mui/icons-material";

import SEARCH_CONCEPTS from "../../mutations/searchConcepts";
//import GET_ROOT_ID_TO_TITLE from "../../mutations/getRootIdToTitle";

interface Props {
  id: string;
  wordConcepts: any;
  setWordConcepts: any;
  setWordIds?(ids: any): void;
  disabled?: boolean;
  label: string | any;
  adornment?: any;
  className?: any;
  small?: boolean;
  isSuperAdmin?: boolean;
  isExtendedAdmin?: boolean;

  words?: any;
  mergedWords?: any;
  isInheriting?: boolean;
  setIsInheriting?: (inherit: boolean) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      marginRight: 60,
    },
    smallInput: {
      marginRight: 20,
    },
    listItem: {
      borderBottom: "1px solid rgba(0,0,0,0.125)",
    },
  })
);

const WordsInput = (props: Props) => {
  const classes = useStyles();
  const {
    id,
    setWordIds,
    wordConcepts,
    setWordConcepts,
    disabled,
    label,
    adornment,
    className,
    small,
    isSuperAdmin,
    isExtendedAdmin,

    words,
    mergedWords,
    isInheriting,
    setIsInheriting,
  } = props;

  //const [getRootIdToTitle] = useMutation(GET_ROOT_ID_TO_TITLE);
  const [wordTitles, setWordTitles] = useState<string[]>(
    wordConcepts.map(
      (concept: any) => concept.title || concept.uuid || concept.rootId
    ) || []
  );
  const [wordInput, setWordInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copiedIndex, setCopiedIndex] = useState<number>(-1);
  const [limitTags, setLimitTags] = useState<number>(5);
  const [expanded, setExpanded] = useState<boolean>(false);

  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);
  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);

  const isInitRender = useRef(true);

  const handleAddWord = (option: any) => {
    const word = option.rootId || option.uuid;
    if (word) {
      setWordInput("");
      const newWordConcept = {
        rootId: option.rootId,
        title: option.title,
        type: option.type,
        uuid: option.uuid,
      };

      const newWordConcepts: any = [...wordConcepts, newWordConcept];
      setWordConcepts && setWordConcepts(newWordConcepts);
      setWordIds &&
        setWordIds(
          newWordConcepts.map((concept: any) => concept.rootId || concept.uuid)
        );

      /*
      setIsLoading(true);
      setTimeout(() => {
        if (document.getElementById(id)) {
          getRootIdToTitle({
            variables: {
              rootIds: [word],
            },
          })
            .then((res: any) => {
              if (
                document.getElementById(id) &&
                res?.data?.getRootIdToTitle?.result
              ) {
                const newWordConcept = res.data.getRootIdToTitle.result[0];
                const newWordConcepts: any = [...wordConcepts, newWordConcept];
                setWordConcepts && setWordConcepts(newWordConcepts);
                setWordIds &&
                  setWordIds(
                    newWordConcepts.map(
                      (concept: any) => concept.rootId || concept.uuid
                    )
                  );
              }
            })
            .catch(() => {
              setWordIds &&
                setWordIds((prev: any) => {
                  return [...prev, wordInput];
                });
              const newWordConcepts: any = [
                ...wordConcepts,
                {
                  title: wordInput,
                  uuid: wordInput,
                  rootId: wordInput,
                  type: null,
                },
              ];
              setWordConcepts && setWordConcepts(newWordConcepts);
              setTagSuggestions([]);
            })
            .finally(() => setIsLoading(false));
        }
      }, 100);
      */
    }
  };

  useEffect(() => {
    if (isExtendedAdmin && !isSuperAdmin) {
      try {
        if (JSON.stringify(words) === JSON.stringify(mergedWords)) {
          !isInheriting && setIsInheriting && setIsInheriting(true);
        } else {
          isInheriting && setIsInheriting && setIsInheriting(false);
        }
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [words, wordConcepts]);

  useEffect(() => {
    if (isSuperAdmin) {
      setWordTitles(
        wordConcepts.map((concept: any) => concept.title || concept.rootId)
      );
    } else if (isExtendedAdmin && !isSuperAdmin) {
      setWordTitles(wordConcepts.map((concept: any) => concept.title));
    }
  }, [wordConcepts, isExtendedAdmin, isSuperAdmin]);

  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  useEffect(() => {
    if (!isInitRender?.current) {
      clearTimeouts(searchTagTimeouts);
      if (!wordInput) {
        setOpen(false);
        setTagSuggestions([]);
      } else {
        let searchTagTimeout = setTimeout(async () => {
          setIsLoading(true);
          searchConcepts({
            variables: {
              title: wordInput,
              type: "topic",
              draft: false,
              size: 5,
              superAdmin: isSuperAdmin,
              extendedAdmin: isExtendedAdmin,
            },
          })
            .then((res: any) => {
              if (res?.data?.searchConcepts?.result) {
                const concepts = res.data.searchConcepts.result;
                const exactMatchArray = concepts.filter(
                  (tag: any) => tag.rootId === wordInput
                );
                if (
                  exactMatchArray.length === 1 &&
                  wordInput === exactMatchArray[0].rootId
                ) {
                  setTagSuggestions([]);
                } else {
                  setTagSuggestions(concepts);
                }
                setOpen(true);
                setSearchTagTimeouts([]);
              }
            })
            .catch(() => {
              setTagSuggestions([]);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }, 700);
        setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordInput]);

  useEffect(() => {
    if (!expanded) {
      document?.getElementById(id ?? "word-input")?.blur();
      setLimitTags(5);
      setWordInput("");
    } else setLimitTags(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    isInitRender.current = false;
  }, []);

  return (
    <Box width="100%">
      <Autocomplete
        id={id ?? "word-input"}
        className="word-input"
        style={{ display: "inline-block", whiteSpace: "nowrap" }}
        classes={{
          input: small ? classes.smallInput : classes.input,
          option: classes.listItem,
        }}
        disabled={
          isExtendedAdmin && !isSuperAdmin ? false : disabled ? true : false
        }
        value={wordTitles}
        onChange={(e: any, newValue: any) => {
          setWordConcepts !== null &&
            setWordConcepts !== undefined &&
            setWordIds &&
            setWordIds(newValue);
        }}
        inputValue={wordInput}
        fullWidth
        multiple
        limitTags={limitTags}
        freeSolo
        open={open}
        autoComplete
        options={tagSuggestions}
        renderOption={(props: any, option: any) => (
          <MenuItem
            disabled={!option.rootId && !option.uuid}
            dense
            onClick={() => {
              handleAddWord(option);
            }}
          >
            <Grain fontSize="small" className="mr-1" />
            {option.title}
          </MenuItem>
        )}
        getOptionLabel={(option: any) => option.title}
        onBlur={() => {
          setIsFocused(false);
          setOpen(false);
          //setWordInput("");
        }}
        onFocus={() => {
          setIsFocused(true);
          setOpen(true);
          setExpanded(true);
        }}
        renderInput={(params) => (
          <TextField
            className={className || undefined}
            {...params}
            style={{ display: "inline-block", whiteSpace: "nowrap" }}
            variant="outlined"
            label={label}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            onFocus={() => !expanded && setExpanded(true)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setWordInput(e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && isSuperAdmin) {
                handleAddWord(wordInput);
              } else {
                e.stopPropagation();
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Box position="absolute" right="14px">
                    {isLoading ? (
                      <Box
                        display="inline-block"
                        style={{ verticalAlign: "middle" }}
                      >
                        <CircularProgress size="1em" />
                      </Box>
                    ) : (
                      <Box component="span">
                        {isFocused && (
                          <Tooltip title="Add word" placement="top">
                            <span>
                              <IconButton
                                disabled={
                                  isExtendedAdmin && !isSuperAdmin
                                    ? false
                                    : disabled
                                    ? true
                                    : false
                                }
                                size="small"
                                onClick={() => {
                                  handleAddWord(wordInput);
                                }}
                              >
                                <Add />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    )}
                    {adornment}
                    {expanded === true && wordConcepts.length > 5 && (
                      <Tooltip title="Expand less" placement="top">
                        <span>
                          <IconButton
                            disabled={disabled}
                            size="small"
                            style={{ marginLeft: "0.3rem" }}
                            onClick={() =>
                              setTimeout(() => {
                                setExpanded(false);
                              }, 50)
                            }
                          >
                            <ExpandLess />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderTags={(value: any[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Tooltip
              key={index}
              style={{ display: option === null ? "none" : "block" }}
              title={
                <Box fontSize="1.1em">
                  <Box mb={"2px"}>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {wordConcepts[index]?.rootId ? "Root ID" : "UUID"}
                    </span>
                    {copiedIndex === index && (
                      <span style={{ color: "#56E39F", float: "right" }}>
                        COPIED
                      </span>
                    )}
                  </Box>
                  <span style={{ display: "block" }}>
                    {`${wordConcepts[index]?.title} ${
                      wordConcepts[index]?.type
                        ? "(" + wordConcepts[index]?.type + ")"
                        : ""
                    }`}
                  </span>
                  <span
                    style={{
                      color: `${copiedIndex === index ? "#56E39F" : "inherit"}`,
                    }}
                  >
                    {wordConcepts[index]?.rootId || wordConcepts[index]?.uuid}
                  </span>
                </Box>
              }
              arrow
            >
              <Chip
                onClick={async () => {
                  navigator.clipboard.writeText(
                    wordConcepts[index].uuid || wordConcepts[index].rootId
                  );
                  setCopiedIndex(index);
                  setTimeout(() => setCopiedIndex(-1), 2000);
                }}
                size="small"
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                onDelete={() => {
                  //doesn't remove index?
                  let words = [...wordConcepts];
                  words.splice(index, 1);
                  setWordConcepts && setWordConcepts(words);
                  setWordIds &&
                    setWordIds(
                      words.map(
                        (concept: any) => concept.uuid || concept.rootId
                      )
                    );
                }}
              />
            </Tooltip>
          ))
        }
      />
    </Box>
  );
};

export default WordsInput;
