import gql from "graphql-tag";

const GET_CURRENT_ENDPOINT = gql`
  query ($username: String!) {
    getCurrentEndpoint(username: $username) {
      url
      name
      active
      alternativeName
      settingsName
      language
      genderAnalysisEnabled
      isDemoEnvironment
      tagLimits {
        category
        topic
        entity
      }
    }
  }
`;

export default GET_CURRENT_ENDPOINT;
