import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  LinearProgress,
  ListItemIcon,
  Alert,
  ListItem,
} from "@mui/material";

import GET_QE_FILES_LIST from "../../../queries/GET_QE_FILES";
import GET_QE_FILE from "../../../queries/GET_QE_FILE";
import { Delete, Folder, FileOpen } from "@mui/icons-material";

interface IFile {
  name: string;
  size: number;
}

interface IProps {
  label: string;
  type: string;
  id?: string;
  disabled?: boolean;
  onUploadAccepted?: (file: any) => void;
  loadedFile: IFile | null;
  setLoadedFile: (file: IFile | null) => void;
}

const QEFileExplorerButton = (props: IProps) => {
  const { label, type, disabled, onUploadAccepted, loadedFile, setLoadedFile } =
    props;

  const [files, setFiles] = useState<Array<IFile> | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? props.id : undefined;

  const [getQEFiles, { loading, error }] = useLazyQuery(GET_QE_FILES_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      data = data.getQEFiles.filter((file: IFile) => file.name.includes(type));
      setFiles(data);
    },
    onError: (err) => {
      setFiles(null);
    },
  });

  const [getQEFile, { loading: loading2, error: error2 }] =
    useLazyQuery(GET_QE_FILE);

  useEffect(() => {
    open && getQEFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = (file: any) => {
    getQEFile({
      variables: { name: file.name },
      onCompleted: (data) => {
        data = data.getQEFile;
        if (onUploadAccepted && data) {
          setLoadedFile(file);
          onUploadAccepted(data);
        }
      },
      onError: (err) => {
        setLoadedFile(null);
        onUploadAccepted && onUploadAccepted(undefined);
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {error2 && <Alert severity="error">Failed to load file</Alert>}
      {loadedFile ? (
        <Button
          disabled={disabled}
          variant="contained"
          fullWidth
          color="secondary"
          startIcon={<Delete />}
          onClick={() => {
            onUploadAccepted && onUploadAccepted(undefined);
            setLoadedFile(null);
          }}
          sx={{ overflowWrap: "anywhere" }}
        >
          {loadedFile.name} ({loadedFile.size + "B"})
        </Button>
      ) : (
        <>
          <Button
            disabled={disabled}
            variant="contained"
            fullWidth
            startIcon={<Folder />}
            onClick={handleClick}
          >
            {label}
          </Button>
          {loading2 && <LinearProgress color="primary" sx={{ mt: 1 }} />}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {loading && <LinearProgress color="primary" />}
            <List dense sx={{ minWidth: "300px", width: "100%" }}>
              {error ? (
                <ListItem>
                  <Alert severity="error">Error: Failed to load files</Alert>
                </ListItem>
              ) : (
                files?.length === 0 && (
                  <ListItem>
                    <Alert severity="error">
                      No reports available for your service at this moment
                    </Alert>
                  </ListItem>
                )
              )}
              {files?.map((file) => (
                <ListItemButton
                  key={file.name}
                  onClick={() => {
                    handleUpload(file);
                    handleClose();
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <FileOpen />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    secondary={file.size + " B"}
                    primaryTypographyProps={{ sx: { fontWeight: 700 } }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default QEFileExplorerButton;
