import gql from "graphql-tag";

const getDemoArticles = gql`
  query ($language: String) {
    getDemoArticles(language: $language) {
      uuid
      headline
      altHeadline
      body
      language
    }
  }
`;

export default getDemoArticles;
