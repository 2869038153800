import gql from "graphql-tag";
const GET_ARTICLE_COUNT_OVER_TIME = gql`
  query ($query: ArticleCountInput) {
    getArticleCountOverTime(query: $query) {
      count
      date
    }
  }
`;

export default GET_ARTICLE_COUNT_OVER_TIME;
