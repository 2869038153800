import React, { useContext } from "react";
import { CTX } from "../../utils/ContextStore";
import { Snackbar, Alert } from "@mui/material";

const SnackbarComponent = () => {
  const { showSnackbar, setShowSnackbar, snackbarMessage, snackbarError }: any =
    useContext(CTX);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <Snackbar
      disableWindowBlurListener={true}
      open={showSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={4000}
      onClose={() => handleCloseSnackbar()}
      key={snackbarMessage}
    >
      <Alert
        elevation={6}
        variant="standard"
        onClose={handleCloseSnackbar}
        severity={snackbarError ? "error" : "success"}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
