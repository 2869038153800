import { Fragment, useState, useEffect, useContext } from "react";
import { CTX } from "./Context";
import {
  Box,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Search,
  AccountCircle,
  ExitToApp,
} from "@mui/icons-material";

import SearchComponent from "./ArticleSearch/SearchComponent";
import TrendingMenuButtons from "./TrendingMenuButtons";
import InterestsComponent from "./InterestsComponent";

const HeaderMenuComponent = () => {
  const { selectedUserProfile, setOpenProfileModal } = useContext(CTX);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [openPref, setOpenPref] = useState<boolean>(false);

  const handleClickOpenPref = () => {
    setOpenPref(true);
  };

  const handleClosePref = () => {
    setOpenPref(false);
  };

  useEffect(() => {
    openPref && setOpenPref(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserProfile]);

  return (
    <Fragment>
      <Grid
        container
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "#fff",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs
          lg
          sx={{
            alignSelf: "center",
          }}
        >
          <TrendingMenuButtons />
        </Grid>

        <Grid
          item
          xs={12}
          lg="auto"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            color="neutral"
            size="large"
            onClick={handleClickOpenPref}
            sx={{
              borderRadius: 0,
            }}
          >
            {selectedUserProfile && (
              <Typography fontWeight={700} sx={{ textTransform: "none" }}>
                {`Welcome, ${selectedUserProfile.id}!`}
              </Typography>
            )}
            <AccountCircle fontSize="large" sx={{ ml: 0.5 }} />
          </Button>
          <Button
            color="neutral"
            size="large"
            onClick={() => setShowSearch((prev: boolean) => !prev)}
            sx={{ borderRadius: 0 }}
          >
            <Search fontSize="large" sx={{ ml: 0.5 }} />
          </Button>
          <Button
            color="neutral"
            onClick={() => {}}
            sx={{
              height: "100%",
              borderRadius: 0,
            }}
          >
            <MenuIcon fontSize="large" />
          </Button>

          <Dialog
            open={openPref}
            onClose={handleClosePref}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              My profile: <b>{selectedUserProfile?.id}</b>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography>
                    Here you can find and change settings for your profile, and
                    also change profile to see how the content changes.
                  </Typography>
                </Grid>
                <Grid item xs="auto" sx={{ justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      setOpenProfileModal(true);
                    }}
                    startIcon={<ExitToApp />}
                  >
                    Change user profile
                  </Button>
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InterestsComponent
                    interests={selectedUserProfile.interests}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button color="secondary" onClick={handleClosePref}>
                Cancel
              </Button>
              <Button autoFocus onClick={handleClosePref}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>

      <Box position="relative" zIndex={1}>
        <SearchComponent
          searchFieldProps={{
            hidden: !showSearch,
            width: "85%",
            wrapper: Paper,
          }}
          pageProps={{ wrapper: Paper }}
        />
      </Box>
    </Fragment>
  );
};

export default HeaderMenuComponent;
