import { useContext } from "react";
import { CTX } from "./Context";
import { Typography, Chip, Box, Button, List, ListItem } from "@mui/material";

interface IProps {
  tags: any[];
}

const TagBoxComponent = (props: IProps) => {
  const { tags } = props;
  const { onTagClick } = useContext(CTX);

  return (
    <Box sx={{ my: 2 }}>
      <Typography
        component="div"
        variant="h6"
        fontWeight="700"
        boxSizing="border-box"
        color="rgb(10, 38, 69)"
        borderTop="4px solid rgb(10, 38, 69)"
        p={2}
      >
        Topics in this article
      </Typography>
      <List sx={{ maxHeight: "20vh", overflow: "auto" }}>
        {tags?.map((tag: any) => (
          <ListItem
            key={tag.uuid}
            secondaryAction={
              <Button variant="contained" color="primary" size="small">
                Subscribe
              </Button>
            }
          >
            <Chip
              variant="outlined"
              label={tag.title}
              sx={{ fontWeight: 700 }}
              size="small"
              color="primary"
              onClick={() => {
                onTagClick(tag.uuid);
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TagBoxComponent;
