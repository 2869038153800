import { Fragment, useContext } from "react";
import { CTX } from "../CMContext";
import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { WifiTethering } from "@mui/icons-material";
import GET_CUSTOMERS_BY_LANGUAGE from "../../../queries/GET_CUSTOMERS_BY_LANGUAGE";

import MissingDataList from "./MissingDataList";
import DatasetPicker from "../../../components/PanelComponents/DatasetPicker";
import Picker from "../ResourceManagement/Picker";

const Header = () => {
  return (
    <Box
      component="span"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      <WifiTethering fontSize="large" />
      <Typography component="span" variant="h6">
        Missing Data Detector
      </Typography>
    </Box>
  );
};

const MissingDataDetector = () => {
  const {
    languages,
    clusters,
    customers,
    setCustomers,
    selectedLanguage,
    setSelectedLanguage,
    selectedCluster,
    setSelectedCluster,
    selectedCustomer,
    setSelectedCustomer,
  }: any = useContext(CTX);

  useQuery(GET_CUSTOMERS_BY_LANGUAGE, {
    variables: { language: selectedLanguage },
    onError: (err) => {},
    onCompleted: (data: any) => {
      if (data?.getCustomersByLanguage.length > 0) {
        let customerList = ["*", ...(data.getCustomersByLanguage || [])];
        setCustomers(customerList);
        setSelectedCustomer(customerList[0]);
      } else {
        setCustomers([]);
        setSelectedCustomer("");
      }
    },
  });

  return (
    <Fragment>
      <Box
        width="100%"
        p={1.4}
        bgcolor="#fafafa"
        borderBottom="1px solid rgba(0,0,0,.125)"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header />
        <Box display="flex">
          <Box component="span" mr={1}>
            <Picker
              disabled={clusters && clusters.length > 0 ? false : true}
              label="Cluster"
              value={clusters.length > 0 ? selectedCluster : ""}
              onChange={setSelectedCluster}
              dataList={clusters}
            />
          </Box>
          <Box component="span" mr={1}>
            <Picker
              disabled={languages && languages.length > 0 ? false : true}
              label="Language"
              value={languages.length > 0 ? selectedLanguage : ""}
              onChange={setSelectedLanguage}
              dataList={languages}
            />
          </Box>
          <Box component="span" mr={1}>
            <Picker
              disabled={customers && customers.length > 0 ? false : true}
              label="Customer"
              value={customers.length > 0 ? selectedCustomer : ""}
              onChange={setSelectedCustomer}
              dataList={customers}
            />
          </Box>
          <Box component="span">
            <DatasetPicker disabled={selectedCustomer !== "*" ? true : false} />
          </Box>
        </Box>
      </Box>

      <Box p={4}>
        {selectedLanguage && selectedCluster && (
          <MissingDataList
            language={selectedLanguage}
            cluster={selectedCluster}
            customer={selectedCustomer}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default MissingDataDetector;
