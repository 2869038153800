import { Fragment, useState, useRef, useEffect, useContext } from "react";
import "./ConceptManagement.css";
import { useMutation } from "@apollo/client";
import { Row, Col } from "react-bootstrap";
import { CTX } from "../../utils/ContextStore";
import { CTX as CMContext } from "./CMContext";
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { Search, Cancel } from "@mui/icons-material";

import SEARCH_CONCEPTS from "../../mutations/searchConcepts";
import DatasetPicker from "../PanelComponents/DatasetPicker";
import SearchFilterComponent from "./SearchFilterComponent";
import CMConceptList from "./CMConceptList";

interface Concept {
  title: string;
  type: string;
  gender?: string;
  uuid: string;
  shortDescription?: string;
  longDescription?: string;
  pubStatus: boolean;
  ignore: boolean;
  aliases?: string[];
  broader?: string;
  broaderConcept?: any;
  author?: string;
  source?: string;
  geoJSON?: string;
  subtypes?: string[];
  rootId?: string;
  correctionWeight?: number;
  minimumWeight?: number;
  mustNotConnectWords?: string[];
  mustConnectWords?: string[];
  keywords?: string[];
  mustBeMentionedWords?: string[];
  mustBeMentioned?: boolean;
  mustNotBeMentionedWords?: string[];
  links?: string[];
  global: string;
  language?: string;
  wikipedia?: string;
  wikidata?: string;
  openStreetMap?: string;
  createdTimestamp: string;
  latestVersionTimestamp: string;
}

interface IErrorMessage {
  show: boolean;
  message: string;
}

const ConceptManagement = () => {
  const { superAdminMode, isExtendedAdmin, activeEndpoint }: any =
    useContext(CTX);
  const { entityFilter, includeDrafts, setKeepChanges }: any =
    useContext(CMContext);

  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);
  const isInitRender = useRef(true);
  let inputString = "";

  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [topicList, setTopicList] = useState<Concept[]>([]);
  const [entityList, setEntityList] = useState<Concept[]>([]);
  const [conceptSearchString, setConceptSearchString] = useState<string>("");
  const [searchTagTimeouts, setSearchTagTimeouts] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>({
    show: false,
    message: "",
  });
  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  /** TRIGGERS A NEW SEARCH AFTER CHANGING SEARCH DEPENDENCIES */
  useEffect(() => {
    const inputField = document.getElementById("conceptSearchInput");
    if (inputField) {
      inputField.dispatchEvent(new Event("input", { bubbles: true }));
    }
  }, [activeEndpoint, superAdminMode, includeDrafts]);

  useEffect(() => {
    if (errorMessage.show) {
      errorTimeout.current && clearTimeout(errorTimeout.current);
      errorTimeout.current = setTimeout(() => {
        setErrorMessage({
          show: false,
          message: "",
        });
      }, 4000);
    }
  }, [errorMessage]);

  useEffect(() => {
    isInitRender.current = false;
    setKeepChanges({
      title: false,
      type: false,
      gender: false,
      shortDescription: false,
      longDescription: false,
      aliases: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SearchConceptInput = (
    <TextField
      autoFocus
      autoComplete="off"
      id="conceptSearchInput"
      className="new-tag-field"
      value={conceptSearchString}
      type="text"
      label="Search concepts"
      variant="outlined"
      size="small"
      fullWidth
      helperText={
        errorMessage.show && (
          <Alert severity="error" sx={{ position: "absolute" }}>
            {errorMessage.message}
          </Alert>
        )
      }
      FormHelperTextProps={{
        sx: { position: "absolute", bottom: 0, zIndex: 3 },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: conceptSearchString ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                setConceptSearchString("");
                $("#conceptSearchInput") && $("#conceptSearchInput").focus();
              }}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onInput={(e: any) => {
        clearTimeouts(searchTagTimeouts);
        inputString = e.target.value; //Used to prevent search fault due to state change delay
        setConceptSearchString(e.target.value);
        setErrorMessage({
          show: false,
          message: "",
        });
        const searchTagTimeout = setTimeout(() => {
          setSearchTagTimeouts([]);
          if (!inputString) {
            setCategoryList([]);
            setTopicList([]);
            setEntityList([]);
          } else {
            searchConcepts({
              variables: {
                title: inputString,
                type: "all",
                draft: includeDrafts,
                size: 200,
                superAdmin: superAdminMode,
                extendedAdmin: isExtendedAdmin,
              },
            })
              .then((data: any) => {
                let foundCategories: any = [];
                let foundTopics: any = [];
                let foundEntities: any = [];
                data?.data?.searchConcepts?.result?.forEach(
                  (concept: Concept) => {
                    concept.type.includes("category")
                      ? (foundCategories = [...foundCategories, concept])
                      : concept.type.includes("topic")
                      ? (foundTopics = [...foundTopics, concept])
                      : (foundEntities = [...foundEntities, concept]);
                  }
                );
                setCategoryList(foundCategories);
                setTopicList(foundTopics);
                setEntityList(foundEntities);
              })
              .catch((err: Error) => {
                setErrorMessage({
                  show: true,
                  message: err.message,
                });
              });
          }
        }, 500);
        setSearchTagTimeouts([...searchTagTimeouts, searchTagTimeout]);
      }}
    />
  );

  return (
    <Fragment>
      <Grid
        container
        sx={{
          backgroundColor: "rgba(255,255,255,0.9)",
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          zIndex: 1,
        }}
      >
        <Grid item xs={6}>
          <Box
            sx={{ display: "flex", alignItems: "center", p: 1, height: "100%" }}
          >
            {SearchConceptInput}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
            }}
          >
            <SearchFilterComponent />
            <DatasetPicker />
          </Box>
        </Grid>
      </Grid>
      <Row
        noGutters
        style={{ padding: "1.7rem", marginRight: "auto", marginLeft: "auto" }}
      >
        <Col md={4} className="mb-3">
          <CMConceptList
            list={categoryList}
            listLabel="Categories"
            listType="category"
          />
        </Col>
        <Col md={4} className="mb-3">
          <CMConceptList list={topicList} listLabel="Topics" listType="topic" />
        </Col>
        <Col md={4} className="mb-3">
          <CMConceptList
            list={
              entityFilter === "all"
                ? entityList
                : entityList.filter(
                    (entity: Concept) => entity.type === entityFilter
                  )
            }
            listLabel="Entities"
            listType="entity"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ConceptManagement;
