import { IPage } from "./types";
import { Grid, TextField, Typography, Autocomplete } from "@mui/material";

interface IProps {
  activePage: IPage;
  setActivePage: (page: IPage) => void;
  docPrivs: Array<string>;
}

const PrivInput = (props: IProps) => {
  const { activePage, setActivePage, docPrivs } = props;

  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
      sx={{ minWidth: "180px" }}
    >
      <Grid item>
        <Typography variant="overline">Set privilege</Typography>
      </Grid>

      <Grid item sx={{ width: "100%" }}>
        <Autocomplete
          fullWidth
          id="req-priv-input"
          freeSolo
          options={docPrivs}
          size="small"
          inputValue={
            docPrivs.indexOf(activePage.priv) !== -1
              ? docPrivs[docPrivs.indexOf(activePage.priv)]
              : activePage.priv
          }
          onChange={(e: any, newValue: any) => {
            setActivePage({ ...activePage, priv: newValue ?? "" });
          }}
          onInputChange={(e: any) => {
            setActivePage({ ...activePage, priv: e.target.value ?? "" });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              fullWidth
              label="Required privilege"
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                <span>
                  <strong>NO_PRIV_REQUIRED</strong> makes the page accessible to
                  everyone
                </span>
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default PrivInput;
