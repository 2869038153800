import React from "react";
import { Typography, Popover, Theme, Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import {
  Person,
  AccountBalance,
  LocationOn,
  EventNote,
  GridView,
  HelpOutline,
} from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const TagInfo = ({ type }: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return type === "entity" ? (
    <Box component="span" sx={{ mr: 0.5, float: "right" }}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpOutline className="interactive" sx={{ ml: 0.5 }} />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ py: 0.5, px: 1 }}>
          <Person fontSize="small" sx={{ mr: 1 }} /> person
        </Box>
        <Box sx={{ py: 0.5, px: 1 }}>
          <LocationOn fontSize="small" sx={{ mr: 1 }} /> place
        </Box>
        <Box sx={{ py: 0.5, px: 1 }}>
          <AccountBalance fontSize="small" sx={{ mr: 1 }} /> organisation
        </Box>
        <Box sx={{ py: 0.5, px: 1 }}>
          <EventNote fontSize="small" sx={{ mr: 1 }} /> event
        </Box>
        <Box sx={{ py: 0.5, px: 1 }}>
          <GridView fontSize="small" sx={{ mr: 1 }} /> object
        </Box>
      </Popover>
    </Box>
  ) : (
    <Box component="span" sx={{ mr: 0.5, float: "right" }}>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpOutline className="interactive" sx={{ ml: 0.5 }} />
      </Typography>
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: "none",
        }}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ py: 0.5, px: 1, bgcolor: "rgba(87, 227, 158, 0.25)" }}>
          90 – 100% Relevancy
        </Box>
        <Box sx={{ py: 0.5, px: 1, bgcolor: "rgba(177, 227, 87, 0.25)" }}>
          80 – &nbsp;89% Relevancy
        </Box>
        <Box sx={{ py: 0.5, px: 1, bgcolor: "rgb(255, 237, 115, 0.25)" }}>
          70 – &nbsp;79% Relevancy
        </Box>
        <Box sx={{ py: 0.5, px: 1, bgcolor: "rgb(255, 173, 117, 0.25)" }}>
          60 – &nbsp;69% Relevancy
        </Box>
        <Box sx={{ py: 0.5, px: 1, bgcolor: "rgba(255, 133, 117, 0.25)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#60; &nbsp;59% Relevancy
        </Box>
      </Popover>
    </Box>
  );
};

export default TagInfo;
