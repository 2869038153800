import { useRef } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { Facebook, Twitter, Link } from "@mui/icons-material";

interface IProps {
  uuid: string;
}

const ShareArticleComponent = (props: IProps) => {
  const options = useRef<Array<any>>([
    { title: "Facebook", icon: <Facebook /> },
    { title: "Twitter", icon: <Twitter /> },
  ]);
  return (
    <Box sx={{ my: 2 }}>
      <Typography
        component="div"
        variant="h6"
        fontWeight="700"
        boxSizing="border-box"
        color="rgb(10, 38, 69)"
        borderTop="4px solid rgb(10, 38, 69)"
        p={2}
      >
        Share this article
      </Typography>
      <Grid container spacing={2}>
        {options?.current?.map((option: any, i: number) => (
          <Grid key={i} item>
            <Button
              variant="outlined"
              size="small"
              color="neutral"
              startIcon={option.icon}
            >
              {option.title}
            </Button>
          </Grid>
        ))}
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            color="neutral"
            startIcon={<Link />}
          >
            Copy link
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShareArticleComponent;
