import gql from "graphql-tag";
const GET_RAW_KEYWORDS = gql`
  query ($query: String) {
    getRawKeywords(query: $query) {
      rawKeywords {
        title
        type
        weight
      }
    }
  }
`;

export default GET_RAW_KEYWORDS;
