import gql from "graphql-tag";

const DELETE_CUSTOM_RULE = gql`
  mutation ($query: CustomRuleInput) {
    deleteCustomRule(query: $query) {
      ruleName
    }
  }
`;

export default DELETE_CUSTOM_RULE;
