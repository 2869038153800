import { useState, useRef, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { CompareArrows } from "@mui/icons-material";

import SummaryTableComponent from "./SummaryTableComponent";
import QEFileExplorerButton from "./QEFileExplorerButton";
import ExportTsvButton from "./ExportTsvButton";

const headers = [
  { id: "Type", label: "Type", numeric: false },
  {
    id: "Precision",
    label: "Precision",
    numeric: true,
  },
  { id: "Recall", label: "Recall", numeric: true },
  { id: "F1", label: "F1", numeric: true },
  { id: "Possible correct", label: "Chosen/kept", numeric: true },
  { id: "Guesses", label: "Auto-tagged", numeric: true },
];

interface IFile {
  name: string;
  size: number;
}

interface IProps {
  endpoint: any;
}

const ConceptSummaryComponent = (props: IProps) => {
  const { endpoint } = props;

  const [loadedFile, setLoadedFile] = useState<IFile | null>(null);
  const [loadedFileCompare, setLoadedFileCompare] = useState<IFile | null>(
    null
  );
  const [data, setData] = useState<Array<Array<string>> | null | undefined>(
    undefined
  );

  const [isFetchingExportData, setIsFetchingExportData] = useState(false);
  const exportData = useRef<any>(null);

  const [dataCompare, setDataCompare] = useState<
    Array<Array<string>> | null | undefined
  >(undefined);

  const onUploadAccepted = (input: any) => {
    try {
      const res = JSON.parse(input);
      setData(res);
    } catch (e) {
      setData(null);
    }
  };

  const onUploadAcceptedCompare = (input: any) => {
    try {
      const res = JSON.parse(input);
      setDataCompare(res);
    } catch (e) {
      setDataCompare(null);
    }
  };

  useEffect(() => {
    setData(undefined);
    setDataCompare(undefined);
    setLoadedFile(null);
    setLoadedFileCompare(null);
  }, [endpoint]);

  /*
  useEffect(() => {
    // SET COMPARE DATA AS DATA WHEN DATA IS REMOVED
    if (data === null && dataCompare) {
      setData(dataCompare);
      setLoadedFile(loadedFileCompare);
      setDataCompare(null);
      setLoadedFileCompare(null);
    }
  }, [data]);
  */

  return (
    <Grid container>
      <Grid container item xs={12} sx={{ alignItems: "center", gap: 2 }}>
        <Grid item xs={2} sx={{ p: 4 }}>
          <Typography
            variant="h5"
            sx={{ borderLeft: "4px solid #0B2545", pl: 2 }}
          >
            Summary
            <br /> report
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <QEFileExplorerButton
            id="primary-summary-file-input"
            label="Choose report"
            type="summary"
            onUploadAccepted={onUploadAccepted}
            loadedFile={loadedFile}
            setLoadedFile={setLoadedFile}
          />
        </Grid>

        <CompareArrows fontSize="large" color="primary" />
        <Grid item xs={3}>
          <QEFileExplorerButton
            disabled={!data}
            id="compare-summary-file-input"
            label="Choose report to compare with"
            type="summary"
            onUploadAccepted={onUploadAcceptedCompare}
            loadedFile={loadedFileCompare}
            setLoadedFile={setLoadedFileCompare}
          />
        </Grid>

        <Grid item sx={{ margin: "auto" }}>
          <ExportTsvButton
            disabled={
              isFetchingExportData || !data || dataCompare ? true : false
            }
            data={exportData}
            fileName={loadedFile?.name || "summary-export.tsv"}
            isFetchingExportData={isFetchingExportData}
            setIsFetchingExportData={setIsFetchingExportData}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ p: 2 }}>
        {headers && data && (
          <SummaryTableComponent
            headers={headers}
            data={data}
            dataCompare={dataCompare}
            exportData={exportData}
            isFetchingExportData={isFetchingExportData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ConceptSummaryComponent;
