import { Grid, Box, Typography } from "@mui/material";
import DatasetPicker from "../../PanelComponents/DatasetPicker";

const HeaderComponent = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            height: "100%",
          }}
        >
          <Typography variant="h4">Auto-tagging Quality Evaluator</Typography>
          <DatasetPicker />
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
