import { useState, useContext } from "react";
import moment from "moment";
import { CTX } from "../CMContext";
import { CTX as mainCTX } from "../../../utils/ContextStore";
import {
  Button,
  Typography,
  Box,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { CheckBox } from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      borderTop: "1px solid rgba(0,0,0,0.125)",
    },
  })
);

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const ConfirmModal = (props: ModalProps) => {
  const { onClose, open } = props;
  const { forceUpdateListener, setForceUpdateListener }: any =
    useContext(mainCTX);
  const {
    updateConceptChangeSuggestions,
    selectedSuggestion,
    setSelectedSuggestion,
    setCurrentConcept,
  }: any = useContext(CTX);

  if (!selectedSuggestion?.concept) return null;

  return (
    <Dialog onClose={onClose} aria-labelledby="confirm-discard" open={open}>
      <DialogTitle id="confirm-discard">
        Are you sure you want to mark this suggestion as{" "}
        <Box component="span" color="#f50057">
          unhandled
        </Box>
        ?
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box p={1}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => {
                if (selectedSuggestion?.id) {
                  updateConceptChangeSuggestions({
                    variables: {
                      id: selectedSuggestion.id,
                      handled: false,
                    },
                  })
                    .then(() => {
                      setTimeout(() => {
                        setForceUpdateListener(
                          forceUpdateListener === 0 ? 1 : 0
                        );
                      }, 250);
                    })
                    .catch((e: Error) => {
                      console.error(e);
                    })
                    .finally(() => {
                      onClose();
                      setCurrentConcept(null);
                      setSelectedSuggestion(null);
                    });
                }
              }}
            >
              Confirm
            </Button>
          </Box>
          <Box p={1}>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const ReporterInfoBox = () => {
  const classes = useStyles();
  const { selectedSuggestion }: any = useContext(CTX);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <Box className={classes.root}>
      <Grid container item spacing={0}>
        <Grid item xs style={{ borderRight: "1px solid rgba(0,0,0,0.125)" }}>
          <Box p={2}>
            <Box display="flex" alignItems="center">
              <Chip
                style={{ fontWeight: 900 }}
                label={
                  selectedSuggestion.createNewRequested
                    ? "Create concept"
                    : selectedSuggestion.deleteRequested
                    ? "Delete concept"
                    : "Edit concept"
                }
                variant={
                  selectedSuggestion.createNewRequested ||
                  selectedSuggestion.deleteRequested
                    ? "outlined"
                    : "outlined"
                }
                color={
                  selectedSuggestion.createNewRequested
                    ? "primary"
                    : selectedSuggestion.deleteRequested
                    ? "secondary"
                    : "default"
                }
              />

              {selectedSuggestion.geoChangeRequested && (
                <Box display="flex" alignItems="center" pl={1}>
                  <CheckBox color="secondary" />
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "0.2rem" }}
                  >
                    Incorrect geo position indicated
                  </Typography>
                </Box>
              )}
            </Box>
            <Box mt={2}>
              <Typography style={{ fontWeight: 900 }} variant="body1">
                Reason for change
              </Typography>
              <Typography variant="subtitle1">
                {selectedSuggestion.reasonForChange}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box p={2}>
            <Typography
              variant="body1"
              style={{ fontWeight: 900, overflowWrap: "break-word" }}
            >
              {selectedSuggestion.email}
            </Typography>
            {selectedSuggestion?.reporter !== "undefined undefined" && (
              <Typography variant="body2">
                {selectedSuggestion.reporter}
              </Typography>
            )}
            <Typography variant="body2">{selectedSuggestion.source}</Typography>
            <Box mt={2}>
              <Typography variant="body1" style={{ fontWeight: 900 }}>
                {moment(selectedSuggestion.reportDate).format("llll")}
              </Typography>
              <Typography variant="body2">
                {selectedSuggestion.handled
                  ? `Handled (${
                      selectedSuggestion.handledDate
                        ? moment(selectedSuggestion.handledDate).format("llll")
                        : "Unknown date"
                    })`
                  : " Unhandled"}
              </Typography>
            </Box>
            {selectedSuggestion.handled && (
              <Box mt={3}>
                <ConfirmModal
                  open={showConfirmModal}
                  onClose={() => setShowConfirmModal(false)}
                />
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => setShowConfirmModal(true)}
                >
                  Mark as unhandled
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReporterInfoBox;
