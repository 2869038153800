import gql from "graphql-tag";

const CONVERT_CONCEPT_TO_WIKIDATA = gql`
  mutation ($concept: ConceptInput) {
    convertConceptToWikidata(concept: $concept) {
      id
      revid
      claims {
        P {
          id
          label
          description
        }
        Q {
          id
          label
          description
        }
      }
      languageFields {
        language
        label
        description
        aliases
      }
    }
  }
`;

export default CONVERT_CONCEPT_TO_WIKIDATA;
