import { Grid, Typography, Switch } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

interface Props {
  value: boolean;
  handler: any;
  disabled?: boolean;
  label?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    activeBold: {
      textShadow: "0px 0px 1px #000",
    },
  })
);

const ToggleSwitch = ({ disabled, label, value, handler }: Props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item>
        <span className={!value ? classes.activeBold : undefined}>Off</span>
        <Switch
          disabled={disabled}
          checked={value}
          onChange={() => handler(!value)}
          name="displayAltDatasetName"
          color="primary"
        />
        <span className={value ? classes.activeBold : undefined}>On</span>
      </Grid>
    </Grid>
  );
};

export default ToggleSwitch;
