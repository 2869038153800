import gql from "graphql-tag";
const GET_CONCEPT_USAGE_OVER_TIME = gql`
  query ($query: ConceptUsageOverTimeInput) {
    getConceptUsageOverTime(query: $query) {
      uuid
      title
      type
      usage
      result {
        date
        usage
      }
    }
  }
`;

export default GET_CONCEPT_USAGE_OVER_TIME;
