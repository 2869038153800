import gql from "graphql-tag";

const NEW_API_DOC_PAGE = gql`
  mutation (
    $name: String
    $content: String
    $pubStatus: Boolean
    $priv: String
  ) {
    newApiDocPage(
      name: $name
      content: $content
      pubStatus: $pubStatus
      priv: $priv
    ) {
      name
      content
      pubStatus
      priv
      path
    }
  }
`;

export default NEW_API_DOC_PAGE;
