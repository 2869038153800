import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Alert,
  Badge,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountBalance,
  Description,
  EventNote,
  Grain,
  GridView,
  LocationOn,
  Person,
} from "@mui/icons-material";

import GET_LATEST_CONCEPTS from "src/queries/GET_LATEST_CONCEPTS";
import CMConceptModal from "../ConceptManagement/CMConceptModal";

interface Concept {
  title: string;
  type: string;
  gender?: string;
  uuid: string;
  shortDescription?: string;
  longDescription?: string;
  pubStatus: boolean;
  ignore: boolean;
  aliases?: string[];
  broader?: string;
  broaderConcept?: any;
  author?: string;
  source?: string;
  geoJSON?: string;
  subtypes?: string[];
  rootId?: string;
  correctionWeight?: number;
  minimumWeight?: number;
  mustNotConnectWords?: string[];
  mustConnectWords?: string[];
  keywords?: string[];
  mustBeMentionedWords?: string[];
  mustBeMentioned?: boolean;
  mustNotBeMentionedWords?: string[];
  links?: string[];
  global: string;
  language?: string;
  wikipedia?: string;
  wikidata?: string;
  openStreetMap?: string;
  createdTimestamp: string;
  latestVersionTimestamp: string;
}

interface IProps {
  endpoint: any;
}

const eventNameOptions: Array<string> = ["create", "update", "delete"];
const sizeOptions: Array<number> = [10, 50, 100, 200];

const NewTagsDisplayComponent = (props: IProps) => {
  const { endpoint } = props;

  const [eventName, setEventName] = useState<string>("create");
  const [size, setSize] = useState<number>(10);
  const [concepts, setConcepts] = useState<any>([]);

  const [editModalShow, setEditModalShow] = useState<boolean>(false);
  const [chosenConcept, setChosenConcept] = useState<Concept | null>(null);

  const handleOnClick = (concept: any) => {
    if (concept.deleted) return;
    setChosenConcept({ ...concept });
    setEditModalShow(true);
  };

  const [getLatestConcepts, { error, loading }] = useLazyQuery(
    GET_LATEST_CONCEPTS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setConcepts(data.getLatestConcepts ?? []);
      },
      onError: (err) => {
        setConcepts([]);
      },
    }
  );

  useEffect(() => {
    endpoint &&
      getLatestConcepts({
        variables: { query: { eventName, size } },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, eventName, size]);

  useEffect(() => {
    setConcepts([]);
    setSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return (
    <Paper sx={{ mt: 4, width: "100%" }}>
      <CMConceptModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        concept={chosenConcept}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          bgcolor: "rgba(0, 0, 0, 0.04)",
          gap: 2,
        }}
      >
        <Box>
          <Typography variant="overline" sx={{ pl: 2, fontWeight: "bold" }}>
            {`Recent ${
              eventName === "create"
                ? "created"
                : eventName === "update"
                ? "updated"
                : "deleted"
            } tags`}
          </Typography>
          <Typography component="span" variant="body2" sx={{ pl: 1 }}>
            ({concepts?.length || 0})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TextField
            select
            size="small"
            label="Event type"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{ sx: { fontWeight: "bold" } }}
            sx={{ width: "10%", minWidth: 120 }}
          >
            {!eventNameOptions.includes(eventName) && (
              <MenuItem disabled value={eventName}>
                {eventName}
              </MenuItem>
            )}
            {eventNameOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            size="small"
            label="Size"
            value={size}
            onChange={(e) => setSize(parseInt(e.target.value))}
            InputLabelProps={{ shrink: true }}
            InputProps={{ sx: { fontWeight: "bold" } }}
            sx={{ width: "10%", minWidth: 100 }}
          >
            {!sizeOptions.includes(size) && (
              <MenuItem disabled value={size}>
                {size}
              </MenuItem>
            )}
            {sizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      {loading && (
        <LinearProgress
          sx={{
            visibility: loading ? "visible" : "hidden",
          }}
        />
      )}
      <List
        dense
        disablePadding
        sx={{
          minHeight: "320px",
          height: "30vh",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {error ? (
          <Alert severity="error" sx={{ m: 2 }}>
            Failed to fetch data.
          </Alert>
        ) : (
          <>
            {!loading && concepts?.length === 0 ? (
              <Alert variant="filled" severity="warning" sx={{ m: 2 }}>
                No data found.
              </Alert>
            ) : (
              concepts?.map((concept: any) => (
                <ListItem
                  key={concept.uuid}
                  button
                  onClick={() => handleOnClick(concept)}
                  sx={{
                    bgcolor: concept.deleted
                      ? "rgba(239, 111, 108,0.2)"
                      : concept.createdTimestamp ===
                        concept.latestVersionTimestamp
                      ? "rgba(87, 227, 158, 0.2)"
                      : "rgba(89, 107, 191, 0.1)",
                    "&:hover": {
                      bgcolor: concept.deleted
                        ? "rgba(239, 111, 108,0.3)"
                        : concept.createdTimestamp ===
                          concept.latestVersionTimestamp
                        ? "rgba(87, 227, 158, 0.3)"
                        : "rgba(89, 107, 191, 0.2)",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            flexGrow: 1,
                          }}
                        >
                          {concept.type.includes("category") ? (
                            <Description
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : concept.type.includes("topic") ? (
                            <Grain
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : concept.type.includes("person") ? (
                            <Person
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : concept.type.includes("place") ? (
                            <LocationOn
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : concept.type.includes("organisation") ? (
                            <AccountBalance
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : concept.type.includes("event") ? (
                            <EventNote
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : concept.type.includes("object") ? (
                            <GridView
                              fontSize="small"
                              sx={{ position: "relative" }}
                            />
                          ) : null}

                          <b style={{ padding: "0 4px" }}>{concept.title}</b>
                          {concept.shortDescription &&
                            ` — ${concept.shortDescription}`}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "#999",
                            whiteSpace: "nowrap",
                            flexGrow: 0,
                          }}
                        >
                          {eventName === "create" &&
                          concept.createdTimestamp !==
                            concept.latestVersionTimestamp ? (
                            <Badge
                              color={concept.deleted ? "secondary" : "primary"}
                              badgeContent=""
                              variant="dot"
                            >
                              <Tooltip
                                title={`This concept has been ${
                                  concept.deleted ? "deleted" : "updated"
                                } since creation`}
                                placement="top"
                              >
                                <Box sx={{ textAlign: "end" }}>
                                  {concept.author},{" "}
                                  <b>
                                    {
                                      concept.latestVersionTimestamp.split(
                                        "T"
                                      )[0]
                                    }
                                  </b>
                                </Box>
                              </Tooltip>
                            </Badge>
                          ) : (
                            <Box sx={{ textAlign: "end" }}>
                              {concept.author},{" "}
                              <b>
                                {concept.latestVersionTimestamp.split("T")[0]}
                              </b>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              ))
            )}
          </>
        )}
      </List>
      {concepts?.length > 0 && (
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            setSize(size + 10);
          }}
        >
          See more
        </Button>
      )}
    </Paper>
  );
};

export default NewTagsDisplayComponent;
