import { createContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Authenticator } from "./Authenticator";

import GET_CURRENT_ENDPOINT from "../queries/GET_CURRENT_ENDPOINT";
import GET_ENDPOINTS from "../mutations/getEndpoints";
import SET_ENDPOINT from "../mutations/setEndpoint";

export interface Endpoint {
  url: string;
  name: string;
  alternativeName: string;
  settingsName: string;
  active: boolean;
  language: string | undefined;
  genderAnalysisEnabled: boolean | undefined;
  isDemoEnvironment: boolean | undefined;
}

export const CTX = createContext<any>(null);

interface Props {
  isAuthorized: boolean;
  setIsAuthorized: (auth: boolean) => void;
  children: any;
}

export default function ContextStore(props: Props) {
  const { isAuthorized, setIsAuthorized } = props;
  const user = Authenticator.getSignedInUser();
  const [username, setUsername] = useState<string>(Authenticator.getUsername());

  const [activeEndpoint, setActiveEndpoint] = useState<Endpoint | undefined>(
    undefined
  );
  const [prevEndpoint, setPrevEndpoint] = useState<Endpoint | undefined>(
    undefined
  );
  const [endpointList, setEndpointList] = useState<Endpoint[]>([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState<Endpoint[]>([]);

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  /** snackbarStatus set false will display successful indicator */
  const [snackbarError, setSnackbarError] = useState<boolean>(false);
  const [forceUpdateListener, setForceUpdateListener] = useState<number>(0); // integer state
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [displayAltDatasetName, setDisplayAltDatasetName] =
    useState<boolean>(false);
  const [showImageDisplayer, setShowImageDisplayer] = useState<boolean>(false);

  const [getEndpoints] = useMutation(GET_ENDPOINTS);
  const [updateEndpoint] = useMutation(SET_ENDPOINT);

  /** superAdmin allows for admin to execute high privileged functions */
  const [superAdminMode, setSuperAdminMode] = useState<boolean>(false);
  const [isExtendedAdmin, setIsExtendedAdmin] = useState<boolean>(false);

  useQuery(GET_CURRENT_ENDPOINT, {
    variables: { username: username || Authenticator.getUsername() },
    onCompleted: (data) => {
      const currentEP = data.getCurrentEndpoint;
      setActiveEndpoint(currentEP);
    },
    skip: !isAuthorized || !username || !Authenticator.getUsername(),
  });

  useEffect(() => {
    if (superAdminMode) setIsExtendedAdmin(true);
  }, [superAdminMode]);

  useEffect(() => {
    if (!activeEndpoint) {
      setSelectedEndpoints([]);
      return;
    }
    if (activeEndpoint?.url && prevEndpoint?.url) {
      setForceUpdateListener((value) => (value === 0 ? 1 : 0));
    }
    if (!superAdminMode) setSelectedEndpoints([activeEndpoint]);
    //else setSelectedEndpoints([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  useEffect(() => {
    if (Authenticator.getUsername()) {
      setUsername(Authenticator.getUsername());
      setSuperAdminMode(Authenticator.hasAuthority(["ADMIN"]));
      setIsExtendedAdmin(Authenticator.hasAuthority(["CUSTOMER_ADMIN"]));
      setTimeout(() => {
        getEndpoints({
          variables: {
            username: Authenticator.getUsername(),
          },
        })
          .then((data) => {
            const res = data?.data?.getEndpoints ?? [];
            setEndpointList(res);
          })
          .catch(() => {});
      }, 200);
    } else {
      if (Authenticator.getUsername()) {
        setUsername(Authenticator.getUsername());
        setSuperAdminMode(Authenticator.hasAuthority(["ADMIN"]));
        setIsExtendedAdmin(Authenticator.hasAuthority(["CUSTOMER_ADMIN"]));
        setIsAuthorized(true);
      } else {
        setUsername("");
        setActiveEndpoint(undefined);
        setEndpointList([]);
        setIsAuthorized(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  return (
    <CTX.Provider
      value={
        {
          user,
          username,
          activeEndpoint,
          setActiveEndpoint,
          prevEndpoint,
          setPrevEndpoint,
          selectedEndpoints,
          setSelectedEndpoints,
          forceUpdateListener,
          setForceUpdateListener,

          superAdminMode,
          setSuperAdminMode,
          isExtendedAdmin,
          setIsExtendedAdmin,

          displayAltDatasetName,
          setDisplayAltDatasetName,
          showImageDisplayer,
          setShowImageDisplayer,

          showSettings,
          setShowSettings,

          showSnackbar,
          setShowSnackbar,
          snackbarMessage,
          setSnackbarMessage,
          snackbarError,
          setSnackbarError,

          endpointList,
          getEndpoints,
          updateEndpoint,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}
