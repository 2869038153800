import gql from "graphql-tag";

const GET_QUEUE_STATISTIC = gql`
  query ($query: GetQueueStatisticsInput) {
    getQueueStatistics(query: $query) {
      userCompleted
      userInProgress
      userSkipped
      inProgress
      completed
      skipped
      lastAssigned
      maxQueue
    }
  }
`;

export default GET_QUEUE_STATISTIC;
