import { Fragment, useState, useContext, useEffect } from "react";
import { CTX } from "../CMContext";
import { Grid, Box, Typography, Alert, AlertTitle, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import SectionContent from "./SectionContent";
import ReporterInfoBox from "./ReporterInfoBox";
import ActionButtons from "./ActionButtons";
import DuplicateValidationInput from "./DuplicateValidationInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    header: {
      backgroundColor: "rgba(230, 230, 230,0.8)",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
    },
    borderItem: {
      padding: theme.spacing(2),
      borderRight: "1px solid rgba(0,0,0,0.125)",
    },
    marginRight: {
      marginRight: "0.5rem",
    },
  })
);

const arraysEqual = (a: string[], b: string[]) => {
  if (a === null) {
    a = [];
  }
  if (b === null) {
    b = [];
  }
  return a.length === b.length && a.every((val, index) => val === b[index]);
};

/** ############################################################ */
const ChangeInfoBox = () => {
  const classes = useStyles();
  const [changed, setChanged] = useState<any>({
    title: false,
    type: false,
    gender: false,
    shortDescription: false,
    longDescription: false,
    aliases: false,
  });

  const {
    currentConcept,
    setChangedConcept,
    keepChanges,
    setKeepChanges,
    selectedSuggestion,
  }: any = useContext(CTX);

  const handleCheckChanges = () => {
    /** Is editing a concept */
    if (
      !selectedSuggestion.deleteRequested &&
      !selectedSuggestion.createNewRequested &&
      currentConcept &&
      selectedSuggestion.concept
    ) {
      let temp: any = {
        title: false,
        type: false,
        gender: false,
        shortDescription: false,
        longDescription: false,
        aliases: false,
      };
      const keys = Object.keys(selectedSuggestion.concept);
      keys.forEach((key: string) => {
        if (key === "aliases") {
          if (
            (currentConcept[key] === null &&
              selectedSuggestion?.concept[key]?.length === 0) ||
            (currentConcept[key]?.length === 0 &&
              selectedSuggestion.concept[key] === null)
          ) {
            selectedSuggestion.concept[key] = currentConcept[key];
          }
          //Alias was changed
          temp[key] = !arraysEqual(
            currentConcept.aliases,
            selectedSuggestion.concept.aliases
          );
        } else {
          if (
            (currentConcept[key] === null &&
              selectedSuggestion.concept[key] === "") ||
            (currentConcept[key] === "" &&
              selectedSuggestion.concept[key] === null)
          ) {
            selectedSuggestion.concept[key] = currentConcept[key];
          }
          //Field was changed
          temp[key] = currentConcept[key] !== selectedSuggestion.concept[key];
        }
      });
      setChanged(temp); // Used to keep track of the changes
      setKeepChanges(temp); // Used to keep track of which changes to keep
    } else {
      setChanged({
        title: false,
        type: false,
        gender: false,
        shortDescription: false,
        longDescription: false,
        aliases: false,
      });
      setKeepChanges({
        title: false,
        type: false,
        gender: false,
        shortDescription: false,
        longDescription: false,
        aliases: false,
      });
    } // Used to keep track of which changes to keep
  };

  useEffect(() => {
    setChangedConcept(selectedSuggestion.concept);
    handleCheckChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuggestion.concept]);

  return selectedSuggestion?.concept ? (
    <Box display="flex" height="100%" minHeight="100%" flexDirection="column">
      <Box flexDirection="column" height="fit-content">
        <Grid
          container
          item
          alignItems="center"
          spacing={0}
          className={classes.header}
        >
          <Grid item xs>
            <Box p={1} textAlign="center">
              <Typography style={{ fontWeight: 900 }}>Current</Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box p={1} textAlign="center">
              <Typography style={{ fontWeight: 900 }}>Suggested</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="column" flex={1} overflow="auto">
        <Grid container item>
          {!selectedSuggestion.createNewRequested && (
            <Grid container item>
              <Grid item xs className={classes.borderItem}>
                {currentConcept && (
                  <SectionContent
                    disabled={true}
                    label="UUID"
                    content={currentConcept.uuid}
                  />
                )}
              </Grid>

              <Grid item xs className={classes.borderItem}>
                {!selectedSuggestion.deleteRequested ? (
                  <SectionContent
                    disabled={true}
                    label="UUID"
                    content={selectedSuggestion.concept.uuid || ""}
                  />
                ) : (
                  <Box position="relative">
                    <Box
                      position="absolute"
                      display="flex"
                      width="100%"
                      flexDirection="column"
                    >
                      <Alert variant="filled" severity="warning">
                        <AlertTitle>Delete requested</AlertTitle>
                        This concept has been requested to be deleted
                      </Alert>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={3} className={classes.borderItem}></Grid>
            </Grid>
          )}

          <Grid container item>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.createNewRequested ? (
                currentConcept && (
                  <SectionContent
                    label="Title"
                    content={currentConcept.title}
                  />
                )
              ) : (
                <Box position="relative">
                  <Box
                    position="absolute"
                    display="flex"
                    width="100%"
                    flexDirection="column"
                  >
                    <Alert severity="info">
                      <AlertTitle>Create new requested</AlertTitle>
                      This concept has been requested to be created
                    </Alert>
                    <Box pt={5} pb={2} display="flex" justifyContent="center">
                      <Typography variant="body2">
                        Possible duplicates in {selectedSuggestion.concept.type}
                      </Typography>
                    </Box>
                    <DuplicateValidationInput
                      type={selectedSuggestion.concept.type}
                      title={selectedSuggestion.concept.title}
                    />
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested && (
                <SectionContent
                  label="Title"
                  content={selectedSuggestion.concept.title || ""}
                  highlight={changed.title}
                  active={keepChanges.title}
                />
              )}
            </Grid>
            <Grid item xs={3} className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested &&
                !selectedSuggestion.createNewRequested &&
                changed &&
                changed.title && <ActionButtons variable="title" />}
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.createNewRequested && currentConcept && (
                <SectionContent
                  label="Type"
                  content={currentConcept.type || ""}
                />
              )}
            </Grid>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested && (
                <SectionContent
                  label="Type"
                  content={selectedSuggestion.concept.type || ""}
                  highlight={changed.type}
                  active={keepChanges.type}
                  createConcept={selectedSuggestion.createNewRequested}
                />
              )}
            </Grid>
            <Grid item xs={3} className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested &&
                !selectedSuggestion.createNewRequested &&
                changed &&
                changed.type && <ActionButtons variable="type" />}
            </Grid>
          </Grid>

          {(selectedSuggestion?.concept?.type === "person" ||
            currentConcept?.type === "person") && (
            <Grid container item>
              <Grid item xs className={classes.borderItem}>
                {!selectedSuggestion.createNewRequested && currentConcept && (
                  <SectionContent
                    label="Gender"
                    content={currentConcept.gender || ""}
                  />
                )}
              </Grid>
              <Grid item xs className={classes.borderItem}>
                {!selectedSuggestion.deleteRequested && (
                  <SectionContent
                    label="Gender"
                    content={selectedSuggestion.concept.gender || ""}
                    highlight={changed.gender}
                    active={keepChanges.gender}
                    createConcept={selectedSuggestion.createNewRequested}
                  />
                )}
              </Grid>
              <Grid item xs={3} className={classes.borderItem}>
                {!selectedSuggestion.deleteRequested &&
                  !selectedSuggestion.createNewRequested &&
                  changed &&
                  changed.gender && <ActionButtons variable="gender" />}
              </Grid>
            </Grid>
          )}

          <Grid container item>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.createNewRequested && currentConcept && (
                <SectionContent
                  label="Short description"
                  content={currentConcept.shortDescription || ""}
                />
              )}
            </Grid>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested && (
                <SectionContent
                  label="Short description"
                  content={selectedSuggestion.concept.shortDescription || ""}
                  highlight={changed.shortDescription}
                  active={keepChanges.shortDescription}
                />
              )}
            </Grid>
            <Grid item xs={3} className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested &&
                !selectedSuggestion.createNewRequested &&
                changed &&
                changed.shortDescription && (
                  <ActionButtons variable="shortDescription" />
                )}
            </Grid>
          </Grid>

          <Grid container item>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.createNewRequested && currentConcept && (
                <SectionContent
                  label="Long description"
                  content={currentConcept.longDescription || ""}
                />
              )}
            </Grid>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested && (
                <SectionContent
                  label="Long description"
                  content={selectedSuggestion.concept.longDescription || ""}
                  highlight={changed.longDescription}
                  active={keepChanges.longDescription}
                />
              )}
            </Grid>
            <Grid item xs={3} className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested &&
                !selectedSuggestion.createNewRequested &&
                changed &&
                changed.longDescription && (
                  <ActionButtons variable="longDescription" />
                )}
            </Grid>
          </Grid>

          {selectedSuggestion.concept.type === "category" ||
          selectedSuggestion.concept.type === "topic" ? null : (
            <Fragment>
              <Grid container item>
                <Grid item xs className={classes.borderItem}>
                  {!selectedSuggestion.createNewRequested && currentConcept && (
                    <SectionContent
                      label="Aliases"
                      content={currentConcept || []}
                    />
                  )}
                </Grid>
                <Grid item xs className={classes.borderItem}>
                  {!selectedSuggestion.deleteRequested && (
                    <SectionContent
                      label="Aliases"
                      content={selectedSuggestion.concept || []}
                      highlight={changed.aliases}
                      active={keepChanges.aliases}
                    />
                  )}
                </Grid>
                <Grid item xs={3} className={classes.borderItem}>
                  {!selectedSuggestion.deleteRequested &&
                    !selectedSuggestion.createNewRequested &&
                    changed &&
                    changed.aliases && <ActionButtons variable="aliases" />}
                </Grid>
              </Grid>
            </Fragment>
          )}
          <Grid container item>
            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.createNewRequested && currentConcept && (
                <SectionContent
                  label="Broader"
                  content={currentConcept.broader}
                />
              )}
            </Grid>

            <Grid item xs className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested && (
                <SectionContent
                  label="Broader"
                  content={selectedSuggestion.concept.broader || ""}
                  highlight={changed.broader}
                  active={keepChanges.broader}
                />
              )}
            </Grid>
            <Grid item xs={3} className={classes.borderItem}>
              {!selectedSuggestion.deleteRequested &&
                !selectedSuggestion.createNewRequested &&
                changed &&
                changed.broader && <ActionButtons variable="broader" />}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ReporterInfoBox />
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export default ChangeInfoBox;
