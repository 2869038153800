import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Logo from "../../images/logo/Logo.png";

const headerStyle = { padding: "16px", backgroundColor: "#0B2545" };

const HeaderComponent = () => {
  return (
    <Grid container sx={{ zIndex: 1 }}>
      <Grid item xs={12}>
        <Box style={headerStyle} >
          <Link to="/api-docs/">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <img alt="" src={Logo} height="50px" width="50px" />
              <Typography
                variant="h6"
                component="span"
                sx={{
                  ml: 1,
                  flex: 1,
                  display: "flex",
                  color: "#fff",
                  fontFamily: "inherit",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                iMatrics API Docs
              </Typography>
            </Box>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
