import config from "./config.json";
import { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Concept, Author } from "./TypeDefs";
import {
  //fetchConceptByUuid,
  //fetchEntityByUuid,
  fetchAuthorByUuid,
} from "./api";

import GET_CONCEPT_BY_UUID from "../../../mutations/getConcept";

import { ItemType } from "./TypeDefs";

export const CTX = createContext<any>(null);

export default function ContextStore(props: any) {
  /**
   * #####################
   * ##### VARIABLES #####
   * #####################
   * */

  const lang = config.LANG;
  const baseUrl = config.BASE_URL;
  const searchPath = config.PATHS.SEARCH;
  const articlePath = config.PATHS.ARTICLE;
  const pagePath = config.PATHS.PAGE;
  const authorPath = config.PATHS.AUTHOR;

  const history = useHistory();

  /**
   * ##################
   * ##### STATES #####
   * ##################
   * */

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [openCustomize, setOpenCustomize] = useState<boolean>(false);
  const [filterConcepts, setFilterConcepts] = useState<Concept[]>([]);
  const [filterAuthors, setFilterAuthors] = useState<Author[]>([]);
  const [numberOfActiveFilters, setNumberOfActiveFilters] = useState<number>(0);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const urlParams = new URLSearchParams(history?.location?.search);

  const [searchString, setSearchString] = useState<string>("");

  const [getConceptByUuid] = useMutation(GET_CONCEPT_BY_UUID);

  /**
   * ###################
   * ##### HANDLERS #####
   * ###################
   * */

  const handleOnSubmit = async (e: React.FormEvent, initTab: number = 0) => {
    e.preventDefault();
    //searchTimeout && clearTimeout(searchTimeout);
    let searchParam = encodeURI(searchString);
    if (!searchParam) searchParam = urlParams.get("q") ?? "";
    urlParams.set("q", searchParam);
    urlParams.delete("uuid");
    history.push(`${baseUrl + searchPath + "?" + urlParams}`);
    setShowOverlay(false);
    setOpenFilter(false);
  };

  const handleOnClickSuggestion = (type: ItemType, uuid: string) => {
    if (type === "article") {
      history.push(`${baseUrl + articlePath}?uuid=${uuid}`);
    } else if (type === "page") {
      history.push(`${baseUrl + pagePath}?uuid=${uuid}`);
    } else if (type === "author") {
      history.push(`${baseUrl + authorPath}?uuid=${uuid}`);
    }
    setShowOverlay(false);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    setShowOverlay(true);
  };

  const handleOnFocus = (e: any) => {
    setShowOverlay(true);
  };

  const handleOnClear = (e: React.ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearchString("");
    document.getElementById("im-search-input")?.focus();
  };

  const handleClearDateFilter = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    urlParams.delete("d");
    history.push(`${history.location.pathname}?${urlParams.toString()}`);
  };

  const handleClearConceptFilter = (uuid: string) => {
    setFilterConcepts(
      filterConcepts.filter((concept) => concept.uuid !== uuid)
    );
    //quick fix to remove entity filter
    const conceptCode = "c_" + uuid;
    const conceptCode2 = "e_" + uuid;
    let conceptParam = urlParams.get("f")?.split(",");
    conceptParam = conceptParam?.filter(
      (value: string) => value !== conceptCode && value !== conceptCode2
    );

    if (conceptParam && conceptParam.length > 0) {
      urlParams.set("f", conceptParam?.join(","));
    } else {
      urlParams.delete("f");
    }
    history.push(`${history.location.pathname}?${urlParams.toString()}`);
  };

  const handleClearAuthorFilter = (uuid: string) => {
    setFilterAuthors(filterConcepts.filter((author) => author.uuid !== uuid));
    const conceptCode = "a_" + uuid;
    let conceptParam = urlParams.get("f")?.split(",");
    conceptParam = conceptParam?.filter(
      (value: string) => value !== conceptCode
    );

    if (conceptParam && conceptParam.length > 0) {
      urlParams.set("f", conceptParam?.join(","));
    } else {
      urlParams.delete("f");
    }
    history.push(`${history.location.pathname}?${urlParams.toString()}`);
  };

  /**
   * ###################
   * ##### METHODS #####
   * ###################
   * */

  const navigate = (path: string) => {
    history.push(path);
    setShowOverlay(false);
    setOpenFilter(false);
  };

  const updateFilterParamsToState = async (urlParams: URLSearchParams) => {
    let filterConcepts: string[] | undefined = urlParams.get("f")?.split(",");
    filterConcepts = filterConcepts?.filter((filter) => filter !== "");

    if (!filterConcepts || filterConcepts.length < 1) {
      setFilterConcepts([]);
      setFilterAuthors([]);
    } else {
      //FETCH ALL CONCEPT BY THE FILTER
      const filterConceptsObj2 = filterConcepts.map(async (concept: string) => {
        const typeCode = concept[0];
        if (typeCode === "c") {
          //const data = await fetchConceptByUuid(concept.substring(2));
          const data = await getConceptByUuid({
            variables: { uuid: concept.substring(2) },
          });
          return data;
        } else if (typeCode === "e") {
          const data = await getConceptByUuid({
            variables: { uuid: concept.substring(2) },
          });
          return data;
        } else if (typeCode === "a") {
          const data = await fetchAuthorByUuid(concept.substring(2));
          return data;
        }
      });
      const allFilteredObj = await Promise.all(filterConceptsObj2);
      setFilterConcepts(
        allFilteredObj
          .filter((concept: Concept) => concept.type !== "x-im/author")
          .map((obj: any) => {
            obj = obj.data.getConcept.result[0];
            return obj;
          })
      );

      setFilterAuthors(
        allFilteredObj.filter(
          (concept: Concept) => concept.type === "x-im/author"
        )
      );
    }

    const filterDates: string[] | undefined = urlParams.get("d")?.split(",");
    if (filterDates && filterDates[0] && filterDates[1]) {
      setStartDate(new Date(filterDates[0]));
      setEndDate(new Date(filterDates[1]));
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }

    let activeFilters = 0;
    filterDates && filterDates?.length > 0 && activeFilters++;
    if (filterConcepts && filterConcepts.length > 0)
      activeFilters = activeFilters + filterConcepts.length;
    setNumberOfActiveFilters(activeFilters);
  };

  // path listener
  useEffect(() => {
    updateFilterParamsToState(new URLSearchParams(history.location.search));
    return history.listen((location: any) => {
      updateFilterParamsToState(new URLSearchParams(location.search));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <CTX.Provider
      value={
        {
          history,
          navigate,
          lang,

          updateFilterParamsToState,

          startDate,
          setStartDate,
          endDate,
          setEndDate,
          openCustomize,
          setOpenCustomize,
          filterConcepts,
          setFilterConcepts,
          filterAuthors,
          setFilterAuthors,
          numberOfActiveFilters,
          setNumberOfActiveFilters,

          showOverlay,
          setShowOverlay,
          openFilter,
          setOpenFilter,

          searchString,
          setSearchString,

          handleOnSubmit,
          handleOnChange,
          handleOnFocus,
          handleOnClear,
          handleOnClickSuggestion,

          handleClearDateFilter,
          handleClearConceptFilter,
          handleClearAuthorFilter,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}
