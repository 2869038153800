import { TextField, InputAdornment } from "@mui/material";

const InputComponent = ({ inputRef, ...other }: any) => <div {...other} />;

const OutlinedDiv = ({
  disabled,
  children,
  label,
  onClick,
  adornment,
  onFocus,
  onBlur,
  style,
}: any) => {
  return (
    <TextField
      disabled={disabled}
      size="small"
      variant="outlined"
      onClick={onClick}
      style={style}
      label={label}
      fullWidth
      multiline
      onFocus={onFocus}
      onBlur={onBlur}
      InputLabelProps={{ shrink: true, style: { paddingLeft: "0px" } }}
      InputProps={{
        inputComponent: InputComponent,
        endAdornment: adornment ? (
          <InputAdornment position="end">{adornment}</InputAdornment>
        ) : null,
      }}
      inputProps={{ children: children }}
    />
  );
};

export default OutlinedDiv;
