import { useState, useContext } from "react";
import moment from "moment";
import { CTX } from "../../utils/ContextStore";
import { useMutation } from "@apollo/client";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  Autocomplete,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Endpoint } from "../../utils/ContextStore";

import UPDATE_USER from "src/mutations/UPDATE_USER";
import QueueInputComponent from "./QueueInputComponent";

// Listify stringified queue data
const formatQueue = (queueStringified: string) => {
  if (!queueStringified) return [];
  try {
    const queueData = JSON.parse(queueStringified);
    return Object.keys(queueData).map((key) => ({
      key,
      value: queueData[key],
    }));
  } catch (err) {
    return [];
  }
};

// Unlistify queue data and return it as a string
const unformatQueue = (queueListified: any[]) => {
  if (!queueListified) return undefined;
  const queueData: any = {};
  queueListified?.forEach((item) => {
    queueData[item.key] = item.value;
  });
  try {
    return JSON.stringify(queueData);
  } catch (err) {
    return undefined;
  }
};

interface IProps {
  row: any;
  rows: Array<any>;
  setRows: (rows: Array<any>) => void;
  rowDetails: Array<any>;
  setRowDetails: (details: Array<any>) => void;
  userLatestSavedData: any;
  privOptions: string[];
  apiOptions: string[];
  onClose: (username: string) => void;
  handleDeleteUser: (username: string) => void;
}

const EditBoxComponent = (props: IProps) => {
  const {
    row,
    rows,
    setRows,
    rowDetails,
    setRowDetails,
    userLatestSavedData,
    privOptions,
    apiOptions,
    onClose,
    handleDeleteUser,
  } = props;

  const {
    superAdminMode,
    setShowSnackbar,
    setSnackbarMessage,
    setSnackbarError,
    endpointList,
  }: any = useContext(CTX);

  const [showPassword, setShowPassword] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      data = data.updateUser;
      data = {
        ...data,
        confirmed: data.confirmed ? "true" : "false",
        confirmed_at: data.confirmed_at ? data.confirmed_at : "N/A",
        demo_user: data.webdemo ? "true" : "false",
        queue: formatQueue(data.queue),
      };

      const index = rows.findIndex(
        (item: any) => item.username === data.username
      );
      let updatedRows = [...rows];
      data.queue = formatQueue(data.queue);
      updatedRows[index] = { ...data };

      const index2 = rowDetails.findIndex(
        (item: any) => item.username === data.username
      );

      let updatedRowDetails = [...rowDetails];
      updatedRowDetails[index2] = {
        username: data.username,
        privs: data.privs,
        docPrivs: data.docPrivs,
        webdemo: data.webdemo,
        queue: data.queue,
        created: data.created,
        confirmed: data.confirmed,
        confirmed_at: data.confirmed_at,
        demo_user: data.demo_user,
      };

      setRows(updatedRows);
      setRowDetails(updatedRowDetails);

      setSnackbarMessage(data.username + " updated successfully");
      setSnackbarError(false);
      setShowSnackbar(true);
      onClose(data.username);
    },
    onError: (error) => {
      setSnackbarMessage(error.message);
      setSnackbarError(true);
      setShowSnackbar(true);
    },
  });

  const handleSubmitChanges = async (username: string) => {
    const index = rowDetails.findIndex(
      (item: any) => item.username === username
    );

    // validate queue keys
    const params = {
      username: username,
      privs: rowDetails[index].privs,
      docPrivs: rowDetails[index].docPrivs,
      webdemo: rowDetails[index].webdemo,
      queue: unformatQueue(rowDetails[index].queue),
    };
    updateUser({
      variables: {
        userdata: params,
      },
    });
  };

  const handleCancelChanges = (username: string, data: any) => {
    const index = rows.findIndex((item: any) => item.username === username);
    const newDetails: any = [...rows];
    const details = {
      username: data.username,
      privs: data.privs,
      docPrivs: data.docPrivs,
      webdemo: data.webdemo,
      queue: data.queue,
      created: data.created,
      confirmed: data.confirmed,
      confirmed_at: data.confirmed_at,
      demo_user: data.demo_user,
    };
    newDetails[index] = details;
    setRowDetails(newDetails);
  };

  const handleAddQueueRow = () => {
    const index = rowDetails.findIndex(
      (item: any) => item.username === row.username
    );
    const newDetails = [...rowDetails];
    newDetails[index].queue = [
      ...newDetails[index].queue,
      { key: "", value: -1 },
    ];
    setRowDetails(newDetails);
  };

  const handleDeleteQueueRow = (queueIndex: number) => {
    const index = rowDetails.findIndex(
      (item: any) => item.username === row.username
    );
    const newDetails = [...rowDetails];
    newDetails[index].queue = [...newDetails[index].queue];
    newDetails[index].queue.splice(queueIndex, 1);
    setRowDetails(newDetails);
  };

  const onChangeQueueRow = (e: any, queueIndex: number) => {
    const index = rowDetails.findIndex(
      (item: any) => item.username === row.username
    );
    let newDetails = [...rowDetails];
    newDetails[index].queue = [...newDetails[index].queue];
    newDetails[index].queue[queueIndex].key = e.target.value;
    setRowDetails && setRowDetails(newDetails);
  };

  const onChangeQueueNumber = (e: any, queueIndex: number) => {
    if (isNaN(+e.target.value)) return;
    const index = rowDetails.findIndex(
      (item: any) => item.username === row.username
    );
    let newDetails = [...rowDetails];
    newDetails[index].queue = [...newDetails[index].queue];
    newDetails[index].queue[queueIndex].value = !e.target.value
      ? ""
      : +e.target.value;
    setRowDetails && setRowDetails(newDetails);
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              <b>{row.username}</b> details
            </span>
            <IconButton size="small" onClick={() => onClose(row.username)}>
              <Close />
            </IconButton>
          </Typography>
        </Grid>
        <Grid container item direction="column" xs={6} sx={{ gap: 3 }}>
          <TextField
            disabled={!superAdminMode}
            autoComplete="off"
            select
            label="Dataset"
            variant="standard"
            fullWidth
            value={
              rowDetails.find((detail) => row.username === detail.username)
                ?.webdemo?.dataset
            }
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: !superAdminMode,
              sx: { fontWeight: 700 },
            }}
            helperText={
              Number.isNaN(
                rowDetails.find((detail) => row.username === detail.username)
                  ?.webdemo?.dataset
              ) && <Typography color="error">Unsupported NaN input</Typography>
            }
            onChange={(e: { target: { value: string } }) => {
              if (!superAdminMode) return;
              const index = rowDetails.findIndex(
                (item: any) => item.username === row.username
              );
              const newDetails = [...rowDetails];
              newDetails[index].webdemo = {
                ...newDetails[index].webdemo,
              };
              newDetails[index].webdemo.dataset = !e.target.value
                ? ""
                : +e.target.value;
              setRowDetails(newDetails);
            }}
          >
            {endpointList
              .sort((a: Endpoint, b: Endpoint) =>
                a.settingsName.localeCompare(b.settingsName)
              )
              .map((option: Endpoint) => (
                <MenuItem key={option.name} value={option.settingsName}>
                  <b>{option.settingsName}</b>&nbsp;— {option.name}
                </MenuItem>
              ))}
          </TextField>

          <Autocomplete
            multiple
            limitTags={4}
            fullWidth
            size="small"
            options={privOptions}
            getOptionLabel={(option) => option}
            value={rowDetails
              .find((detail) => row.username === detail.username)
              ?.privs?.map((priv: string) => priv)}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                variant="standard"
                label="Privs"
                InputLabelProps={{ shrink: true }}
              />
            )}
            onChange={(e: any, newValue: any) => {
              const index = rowDetails.findIndex(
                (item: any) => item.username === row.username
              );
              const newDetails = [...rowDetails];
              newDetails[index].privs = newValue;
              setRowDetails(newDetails);
            }}
          />

          {rowDetails
            .find((user) => user.username === row.username)
            ?.privs?.includes("API_DOCS") && (
            <Autocomplete
              multiple
              limitTags={4}
              fullWidth
              size="small"
              options={apiOptions}
              getOptionLabel={(option) => option}
              value={rowDetails
                .find((detail) => row.username === detail.username)
                ?.docPrivs?.map((priv: string) => priv)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  variant="standard"
                  label="API Docs privs"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(e: any, newValue: any) => {
                const index = rowDetails.findIndex(
                  (item: any) => item.username === row.username
                );
                const newDetails = [...rowDetails];
                newDetails[index].docPrivs = newValue;
                setRowDetails(newDetails);
              }}
            />
          )}

          {rowDetails
            .find((user) => user.username === row.username)
            ?.privs?.includes("QUEUE") && (
            <QueueInputComponent
              isSuperAdmin={superAdminMode}
              row={rowDetails.find((user) => user.username === row.username)}
              handleAddQueueRow={handleAddQueueRow}
              handleDeleteQueueRow={handleDeleteQueueRow}
              onChangeQueueRow={onChangeQueueRow}
              onChangeQueueNumber={onChangeQueueNumber}
            />
          )}
        </Grid>
        <Grid container item direction="column" xs={6} sx={{ gap: 3 }}>
          <TextField
            disabled={!superAdminMode}
            autoComplete="off"
            variant="standard"
            type="number"
            fullWidth
            size="small"
            label="Max API calls"
            InputLabelProps={{ shrink: true }}
            value={
              rowDetails.find((detail) => row.username === detail.username)
                ?.webdemo?.max_api_calls
            }
            InputProps={{
              readOnly: !superAdminMode,
              endAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      borderLeft: "1px solid rgba(0, 0, 0, 0.25)",
                      pl: 2,
                      fontWeight: 700,
                      fontSize: 16,
                      color:
                        rowDetails.find(
                          (detail) => row.username === detail.username
                        )?.webdemo?.api_calls <
                        rowDetails.find(
                          (detail) => row.username === detail.username
                        )?.webdemo?.max_api_calls
                          ? "#56E39F"
                          : "#EF6F6C",
                    }}
                  >
                    {
                      rowDetails.find(
                        (detail) => row.username === detail.username
                      )?.webdemo?.api_calls
                    }
                  </Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              if (!superAdminMode) return;
              const index = rowDetails.findIndex(
                (item: any) => item.username === row.username
              );
              const newDetails = [...rowDetails];
              newDetails[index].webdemo = {
                ...newDetails[index].webdemo,
              };
              newDetails[index].webdemo.max_api_calls = !e.target.value
                ? ""
                : +e.target.value;
              setRowDetails(newDetails);
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
            }}
          >
            <TextField
              disabled={true}
              type="text"
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              label="Created at"
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
              value={
                rowDetails.find((detail) => row.username === detail.username)
                  ?.created
                  ? moment(
                      rowDetails.find(
                        (detail) => row.username === detail.username
                      )?.created
                    ).format("L")
                  : ""
              }
            />
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              label="Valid until"
              InputLabelProps={{ shrink: true }}
              type="date"
              value={
                rowDetails.find((detail) => row.username === detail.username)
                  ?.webdemo?.expires
              }
              InputProps={{
                sx: {
                  color:
                    moment() >
                    moment(
                      rowDetails.find(
                        (detail) => row.username === detail.username
                      )?.webdemo?.expires
                    )
                      ? "#EF6F6C"
                      : "#56E39F",
                },
              }}
              onChange={(e) => {
                const index = rowDetails.findIndex(
                  (item: any) => item.username === row.username
                );
                const newDetails = [...rowDetails];
                newDetails[index].webdemo = {
                  ...newDetails[index].webdemo,
                };
                newDetails[index].webdemo.expires = moment(
                  e.target.value
                ).format("YYYY-MM-DD");
                setRowDetails(newDetails);
              }}
            />
          </Box>

          <TextField
            InputProps={{ readOnly: true }}
            type="text"
            autoComplete="off"
            variant="standard"
            fullWidth
            size="small"
            label="Last login at"
            InputLabelProps={{ shrink: true }}
            value={
              rowDetails.find((detail) => row.username === detail.username)
                ?.last_login
                ? moment(
                    rowDetails.find(
                      (detail) => row.username === detail.username
                    )?.last_login
                  ).format("L LT")
                : ""
            }
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
            }}
          >
            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              label="Demo user"
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
              value={
                rowDetails.find((detail) => row.username === detail.username)
                  ?.demo_user
              }
            />

            <TextField
              autoComplete="off"
              variant="standard"
              fullWidth
              size="small"
              label="Confirmed"
              InputLabelProps={{ shrink: true }}
              value={
                rowDetails.find((detail) => row.username === detail.username)
                  ?.confirmed
              }
              InputProps={{
                readOnly: true,
                endAdornment:
                  rowDetails.find((detail) => row.username === detail.username)
                    ?.confirmed === "true" ? (
                    <InputAdornment position="start">
                      <Typography
                        sx={{
                          borderLeft: "1px solid rgba(0, 0, 0, 0.25)",
                          pl: 2,
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        {moment(
                          rowDetails.find(
                            (detail) => row.username === detail.username
                          )?.confirmed_at
                        ).format("L")}
                      </Typography>
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Grid item sx={{ display: "flex", flexGrow: 1, gap: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                handleDeleteUser(row.username);
              }}
            >
              Delete user
            </Button>
            {superAdminMode && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {!showPassword ? "Show password" : "Hide password"}
                </Button>
                {showPassword && (
                  <Typography sx={{ fontWeight: 700, alignSelf: "center" }}>
                    {row.password}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid sx={{ display: "flex", flexGrow: 0, gap: 2 }}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                handleCancelChanges(row.username, userLatestSavedData);
              }}
            >
              Undo changes
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleSubmitChanges(row.username);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditBoxComponent;
