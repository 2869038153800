import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CTX } from "src/utils/ContextStore";
import { Container, Dialog, IconButton, Box, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";

import HeaderComponent from "./HeaderComponent";
import ConceptPerformanceComponent from "./ConceptPerformanceComponent";
import ConceptSummaryComponent from "./ConceptSummaryComponent";

const ConceptExplorerComponent = () => {
  const history = useHistory();
  const { activeEndpoint } = useContext(CTX);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => history.push("/"), 200);
  };

  useEffect(() => {
    const initComponent = () => {
      if (
        history.location.pathname.includes("/auto-tagging-quality-evaluator")
      ) {
        !open && setOpen(true);
      } else {
        open && setOpen(false);
      }
    };
    initComponent();

    return history.listen((location: any) => {
      initComponent();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        id: "concept-explorer",
        style: { backgroundColor: "rgb(246, 248, 249)" },
      }}
    >
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          zIndex: 2,
          color: "#fff",
          position: "absolute",
          top: "16px",
          right: "16px",
          bgcolor: "rgba(0,0,0,0.75)",
          "&:hover": {
            opacity: 0.9,
            bgcolor: "rgba(0,0,0,0.75)",
          },
        }}
      >
        <Close />
      </IconButton>

      <Container
        id="im-concept-explorer"
        disableGutters
        component={Paper}
        maxWidth={false}
        sx={{
          display: "flex",
          flexFlow: "column",
          width: "100%",
          position: "relative",
        }}
      >
        <>
          <Box
            sx={{
              flex: "0 1 auto",
              borderBottom: "1px solid rgba(0,0,0,0.125)",
            }}
          >
            <Box sx={{ maxWidth: "calc(100% - 54px)" }}>
              <HeaderComponent />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              gap: 4,
              py: 4,
            }}
          >
            <ConceptSummaryComponent endpoint={activeEndpoint} />
            <ConceptPerformanceComponent endpoint={activeEndpoint} />
          </Box>
        </>
      </Container>
    </Dialog>
  );
};

export default ConceptExplorerComponent;
