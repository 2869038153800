import { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { CTX as timesCTX } from "./Context";
import { CTX } from "./../../utils/ContextStore";

import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Divider,
  Alert,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import UserPickerComponent from "./UserPickerComponent";
import DatasetPicker from "../PanelComponents/DatasetPicker";

import GET_USER_PROFILES from "./../../queries/GET_USER_PROFILES";
import GET_PROFILE_INTERESTS from "./../../queries/GET_PROFILE_INTERESTS";

interface ILocation {
  name: string;
  latLng: string;
}

interface IInterest {
  conceptID: string;
  count: number;
}

interface IUserProfile {
  id?: string;
  customer?: string;
  active?: boolean;
  description?: string;
  imgUrl?: string;
  interests?: IInterest[];
  location: ILocation;
}

const SelectProfileComponent = () => {
  const {
    openProfileModal,
    setOpenProfileModal,
    selectedUserProfile,
    setSelectedUserProfile,
  } = useContext(timesCTX);
  const { superAdminMode, activeEndpoint, setActiveEndpoint }: any =
    useContext(CTX);
  const [loadedImgs, setLoadedImgs] = useState<Array<string | undefined>>([]);

  const [userProfileOptions, setUserProfileOptions] = useState<
    Array<IUserProfile>
  >([]);

  const onClose = () => {
    setOpenProfileModal(false);
  };

  const { loading, error } = useQuery(GET_USER_PROFILES, {
    variables: { customer: activeEndpoint?.settingsName },
    onCompleted: (data) => {
      if (data?.getUserProfiles?.length > 0) {
        setSelectedUserProfile(data.getUserProfiles[0]);
        setUserProfileOptions(data.getUserProfiles);
      } else {
        setSelectedUserProfile(null);
        setUserProfileOptions([]);
      }
    },
    onError: () => {
      setSelectedUserProfile(null);
      setUserProfileOptions([]);
    },
    skip: !activeEndpoint?.settingsName,
  });

  useQuery(GET_PROFILE_INTERESTS, {
    variables: { userID: selectedUserProfile?.id },
    onCompleted: (data) => {
      setSelectedUserProfile((prev: IUserProfile | any) => {
        return {
          ...prev,
          interests: data?.getProfileInterests?.interests ?? [],
        };
      });
    },
    onError: (e) => {},
    skip: !selectedUserProfile?.id,
  });

  useEffect(() => {
    const demoEndpoint = {
      url: "https://gate1001.imatrics.com",
      name: "Swedish (Gota Media)",
      active: true,
      alternativeName: "Swedish (1001)",
      settingsName: "1001",
      language: "sv",
      genderAnalysisEnabled: true,
      isDemoEnvironment: false,
    };
    setActiveEndpoint(demoEndpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={openProfileModal} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <b>Select user profile</b>
        <IconButton size="small" edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              Tailor your experience to your interests. Pick a user profile
              below to showcase how a user's interest can be displayed in
              iMatrics Times.
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <DatasetPicker />
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <LinearProgress sx={{ my: 2 }} />
        ) : (
          <Divider sx={{ my: 2 }} />
        )}
        <Grid container sx={{ position: "relative" }}>
          <Grid item sm={9} sx={{ pr: [0, 2] }}>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              {error ? (
                <Grid item>
                  <Alert severity="error">Failed to fetch user profiles</Alert>
                </Grid>
              ) : (
                userProfileOptions?.map((profile: IUserProfile) => {
                  return profile.imgUrl ? (
                    <Grid key={profile.id} item xs={4}>
                      <Paper
                        elevation={2}
                        onClick={() => {
                          setSelectedUserProfile(profile);
                          onClose();
                        }}
                        sx={{
                          display: loadedImgs.includes(profile.id)
                            ? "block"
                            : "none",
                          position: "relative",
                          overflow: "hidden",
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.05)",
                            transition: "all 0.2s ease-in-out",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            px: 1,
                            py: 0.5,
                            width: "100%",
                            position: "absolute",
                            bgcolor: "rgba(255,255,255,0.75)",
                          }}
                        >
                          <Typography fontWeight={700} sx={{}}>
                            {profile.id}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ lineHeight: "90%" }}
                          >
                            {profile.description}
                          </Typography>
                        </Box>
                        <img
                          src={profile.imgUrl}
                          alt=""
                          width="100%"
                          style={{ objectFit: "cover", aspectRatio: 16 / 10 }}
                          onLoad={() => {
                            setLoadedImgs([...loadedImgs, profile.id]);
                          }}
                        />
                      </Paper>
                    </Grid>
                  ) : null;
                })
              )}
            </Grid>
          </Grid>
          {superAdminMode && (
            <Grid item sm={3} xs={12} sx={{ justifyContent: "flex-end" }}>
              <UserPickerComponent
                selectedUserProfile={selectedUserProfile}
                setSelectedUserProfile={setSelectedUserProfile}
                userProfileOptions={userProfileOptions}
                cb={onClose}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectProfileComponent;
