import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Switch,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Description,
  Grain,
  LocalOfferOutlined,
  FilterList,
  Refresh,
} from "@mui/icons-material";

import { CTX } from "../../../utils/ContextStore";

import GET_MISSING_DATA from "../../../queries/GET_MISSING_DATA";
import UPDATE_MISSING_DATA from "../../../mutations/UPDATE_MISSING_DATA";

import RootIdInput from "../RootIdInput";
import AliasInput from "../AliasInput";
import WordsInput from "../WordsInput";
import ConfirmButton from "./ConfirmButton";
import HideButton from "./HideButton";

import CMConceptModal from "../CMConceptModal";
import ViewOnlyConceptModal from "./ViewOnlyConceptModal";

interface Data {
  uuid: string;
  index: string;
  type: string;
  title: string;
  missingField: string;
  data: string[];
  confirmed: boolean;
  hiddenInDetector: boolean;
  keywordConcepts: any[];
}

const createData = (
  uuid: string,
  index: string,
  type: string,
  title: string,
  missingField: string,
  data: string[],
  confirmed: boolean,
  hiddenInDetector: boolean,
  keywordConcepts: any[]
): Data => {
  return {
    index,
    type,
    title,
    uuid,
    missingField,
    data,
    confirmed,
    hiddenInDetector,
    keywordConcepts,
  };
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: HeadCell[] = [
  { id: "index", label: "Index" },
  { id: "type", label: "Type" },
  { id: "title", label: "Title" },
  {
    id: "uuid",
    label: "UUID",
  },
  { id: "data", label: "Data" },
  { id: "confirmed", label: "Confirm" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === "confirmed" || headCell.id === "data") {
            return (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
                style={{ fontWeight: 700 }}
              >
                <Typography component="span">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <span
                    style={{
                      border: 0,
                      clip: "rect(0 0 0 0)",
                      height: 1,
                      margin: -1,
                      overflow: "hidden",
                      padding: 0,
                      position: "absolute",
                      top: 20,
                      width: 1,
                    }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ fontWeight: 700 }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography component="span">{headCell.label}</Typography>
                {orderBy === headCell.id ? (
                  <span
                    style={{
                      border: 0,
                      clip: "rect(0 0 0 0)",
                      height: 1,
                      margin: -1,
                      overflow: "hidden",
                      padding: 0,
                      position: "absolute",
                      top: 20,
                      width: 1,
                    }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    highlight: {
      backgroundColor: "rgba(87, 227, 158, 0.3)",
      opacity: 0.75,
    },
    strikeThrough: {
      textDecoration: "line-through",
    },
    titleText: {
      color: "#586CBF",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
    paginationLabel: { marginBottom: 0 },
  })
);

interface Props {
  language: string;
  cluster: string;
  customer: string;
}

const MissingDataList = (props: Props) => {
  const classes = useStyles();
  const { language, cluster, customer } = props;
  const {
    username,
    activeEndpoint,
    setActiveEndpoint,
    setPrevEndpoint,
    getEndpoints,
    updateEndpoint,
  }: any = useContext(CTX);

  const [isSavingIndex, setIsSavingIndex] = useState(-1);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("index");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);

  const [concepts, setConcepts] = useState<any[]>([]);
  const [listSize, setListSize] = useState<number | undefined>(undefined);

  const [rows, setRows] = useState<any[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refetch();
    setIsRefreshing(false);
  };

  const handleTitleClick = (concept: any) => {
    if (customer !== "*") {
      concept = {
        uuid: concept.uuid,
        type: concept.type,
      };
    }

    setSelectedConcept(concept);
    setShowModal(true);

    //CREATE A MODAL THAT TAKES IN THE CONCEPT SIMILAR TO CMMODAL.
  };

  useEffect(() => {
    setRows(
      concepts.map((concept: any) =>
        createData(
          concept.uuid,
          concept.index,
          concept.type,
          concept.title,
          concept.missingField,
          concept[concept.missingField],
          concept.confirmed || false,
          concept.hiddenInDetector,
          concept.keywordConcepts || []
        )
      )
    );
  }, [concepts]);

  useEffect(() => {
    customer && adjustToCustomerEndpoint(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const [updateMissingData] = useMutation(UPDATE_MISSING_DATA);
  const { loading, error, refetch } = useQuery(GET_MISSING_DATA, {
    variables: {
      cluster: cluster,
      indexPrefix: customer && customer !== "*" ? customer : language,
    },
    fetchPolicy: isRefreshing ? "cache-and-network" : "cache-first",
    onError: (err) => {},
    onCompleted: (data: any) => {
      if (data?.getMissingData?.concepts?.length > 0) {
        setConcepts(data.getMissingData.concepts);
        setListSize(data.getMissingData.totalNumberOfConcepts);
        !isRefreshing && page !== 0 && setPage(0);
      } else {
        setConcepts([]);
        setListSize(0);
        setPage(0);
      }
    },
  });

  const adjustToCustomerEndpoint = async (customer: string) => {
    if (customer !== "*") {
      const endpoints = await getEndpoints({
        variables: {
          username: username,
        },
      });
      const endpoint = endpoints.data.getEndpoints.filter(
        (endpoint: any) => endpoint.settingsName === customer
      )[0];
      if (endpoint && endpoint.name !== activeEndpoint.name) {
        const newEndpoint = await updateEndpoint({
          variables: endpoint,
        });
        if (newEndpoint?.data?.setEndpoint) {
          setPrevEndpoint(activeEndpoint);
          setActiveEndpoint(newEndpoint.data.setEndpoint);
        }
      }
    }
  };

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage: any = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: any = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedConcept, setSelectedConcept] = useState<any>(undefined);
  const [showHidden, setShowHidden] = useState<boolean>(false);

  return (
    <Paper
      style={{
        width: "100%",
        marginBottom: "16px",
      }}
    >
      <Box width="100%" maxHeight="70vh" overflow="auto">
        {customer === "*" ? (
          <ViewOnlyConceptModal
            concept={selectedConcept}
            open={showModal}
            onClose={() => setShowModal(false)}
          />
        ) : (
          <CMConceptModal
            show={showModal}
            onHide={() => setShowModal(false)}
            concept={selectedConcept}
          />
        )}
        <Box
          ml={2}
          mr={2}
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box component="span" display="flex" alignItems="center">
            <Box component="span" mr={2}>
              <Tooltip title="Filter list">
                <span>
                  <IconButton disabled size="small" aria-label="filter list">
                    <FilterList />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Box component="span" mr={2}>
              <Tooltip title="Refresh list">
                <span>
                  <IconButton
                    disabled={loading}
                    aria-label="refresh list"
                    onClick={() => handleRefresh()}
                  >
                    {isRefreshing ? (
                      <CircularProgress size="22.5px" />
                    ) : (
                      <Refresh />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <Box component="span" mr={1}>
              <Tooltip title="Toggle hidden concepts">
                <span>
                  <Switch
                    edge="end"
                    color="primary"
                    checked={showHidden}
                    onChange={() => {
                      setShowHidden(!showHidden);
                    }}
                  />
                </span>
              </Tooltip>
            </Box>
            {!showHidden &&
              concepts.filter((concept: any) => concept.hiddenInDetector)
                .length > 0 && (
                <Typography
                  component="span"
                  variant="subtitle2"
                  color={showHidden ? "primary" : "secondary"}
                >
                  {`(+${
                    concepts.filter((concept: any) => concept.hiddenInDetector)
                      .length
                  })`}
                </Typography>
              )}
          </Box>
          <Box component="span">
            {listSize !== undefined && (
              <Typography component="span" variant="subtitle2">
                Displaying {concepts.length}/
                <span style={{ color: "#EF6F6C", fontWeight: "bold" }}>
                  {`${listSize} `}
                </span>
                concepts
              </Typography>
            )}
          </Box>
        </Box>
        <TableContainer>
          {error ? (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={1}
              m={1}
              color="#EF6F6C"
              fontWeight={700}
            >
              Oops, something went wrong :(
            </Box>
          ) : loading && !isRefreshing ? (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={1}
              m={1}
            >
              <CircularProgress size="2em" />
            </Box>
          ) : (
            <Table
              style={{
                minWidth: 750,
              }}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {concepts?.length < 1 && (
                  <Box component="tr" height="130px" display="block">
                    <Box
                      component="th"
                      m={4}
                      display="inline-block"
                      position="absolute"
                    >
                      <Alert severity="success">
                        <AlertTitle>Good job!</AlertTitle>
                        No missing data were detected in:{" "}
                        <Box
                          component="span"
                          fontWeight={700}
                        >{`${cluster}`}</Box>
                        /
                        <Box
                          component="span"
                          fontWeight={700}
                        >{`${language}`}</Box>
                        /
                        <Box
                          component="span"
                          fontWeight={700}
                        >{`${customer}`}</Box>
                      </Alert>
                    </Box>
                  </Box>
                )}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, i: number) => {
                    return (
                      <TableRow
                        hover={!row.confirmed}
                        tabIndex={-1}
                        key={i}
                        className={
                          row.confirmed ? classes.highlight : undefined
                        }
                        style={
                          !showHidden && row.hiddenInDetector
                            ? { display: "none" }
                            : showHidden && row.hiddenInDetector
                            ? { backgroundColor: "#ededed" }
                            : undefined
                        }
                      >
                        <TableCell
                          style={{ width: "12%" }}
                          className={
                            row.confirmed ? classes.strikeThrough : undefined
                          }
                        >
                          {row.index}
                        </TableCell>
                        <TableCell
                          style={{ width: "12%" }}
                          className={
                            row.confirmed ? classes.strikeThrough : undefined
                          }
                        >
                          {row.type === "category" ? (
                            <Description fontSize="small" />
                          ) : row.type === "topic" ? (
                            <Grain fontSize="small" />
                          ) : (
                            <LocalOfferOutlined />
                          )}
                          <Typography component="span" variant="overline">
                            <Box component="span" ml={0.5} fontWeight={700}>
                              {row.type}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>
                          <Typography component="span">
                            <Box
                              component="span"
                              ml={0.5}
                              fontWeight={700}
                              className={
                                row.confirmed
                                  ? classes.strikeThrough
                                  : classes.titleText
                              }
                              onClick={() =>
                                handleTitleClick(
                                  concepts[page * rowsPerPage + i]
                                )
                              }
                            >
                              {row.title}
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ width: "20%" }}
                          className={
                            row.confirmed ? classes.strikeThrough : undefined
                          }
                        >
                          {row.uuid}
                        </TableCell>
                        <TableCell style={{ width: "26%" }}>
                          {row.missingField === "rootId" ? (
                            <RootIdInput
                              disabled={row.confirmed}
                              small={true}
                              type={row.type}
                              rootId={row.data || ""}
                              setRootId={(rootId: string) => {
                                let newConcepts = [...concepts];
                                newConcepts[page * rowsPerPage + i] = {
                                  ...newConcepts[page * rowsPerPage + i],
                                  [row.missingField]: rootId,
                                };
                                setConcepts(newConcepts);
                              }}
                              editable={true}
                              sourceType={"customer"}
                            />
                          ) : row.missingField === "keywords" &&
                            row.type === "category" ? (
                            <WordsInput
                              id={`word-input-${i}`}
                              small={true}
                              label="Connected topics"
                              disabled={row.confirmed}
                              wordConcepts={row.keywordConcepts}
                              setWordConcepts={(keywordConcepts: any[]) => {
                                let newConcepts = [...concepts];
                                newConcepts[page * rowsPerPage + i] = {
                                  ...newConcepts[page * rowsPerPage + i],
                                  [row.missingField]: keywordConcepts.map(
                                    (keyword) =>
                                      keyword.rootId || keyword.uuid || ""
                                  ),
                                  keywordConcepts: keywordConcepts,
                                };
                                setConcepts(newConcepts);
                              }}
                            />
                          ) : row.missingField === "keywords" &&
                            row.type === "topic" ? (
                            <AliasInput
                              disabled={row.confirmed}
                              id={`alias-input-${i}`}
                              aliases={row.data || []}
                              setAliases={(word: string[]) => {
                                let newConcepts = [...concepts];
                                newConcepts[page * rowsPerPage + i] = {
                                  ...newConcepts[page * rowsPerPage + i],
                                  [row.missingField]: word,
                                };
                                setConcepts(newConcepts);
                              }}
                              label="Keywords"
                            />
                          ) : (
                            row.missingField
                          )}
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <HideButton
                                disabled={false}
                                hidden={row.hiddenInDetector}
                                onClick={() => {
                                  const newConcepts = [...concepts];

                                  newConcepts[page * rowsPerPage + i] = {
                                    ...newConcepts[page * rowsPerPage + i],
                                    hiddenInDetector: row.hiddenInDetector
                                      ? false
                                      : true,
                                  };
                                  setConcepts(newConcepts);
                                  updateMissingData({
                                    variables: {
                                      concept: {
                                        uuid: row.uuid,
                                        hiddenInDetector: row.hiddenInDetector
                                          ? false
                                          : true,
                                      },
                                      cluster: cluster,
                                      indexPrefix:
                                        customer && customer !== "*"
                                          ? customer
                                          : language,
                                    },
                                  }).catch(() => {
                                    const newConcepts = [...concepts];
                                    newConcepts[page * rowsPerPage + i] = {
                                      ...newConcepts[page * rowsPerPage + i],
                                      hiddenInDetector: row.hiddenInDetector
                                        ? false
                                        : true,
                                    };
                                    setConcepts(newConcepts);
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={7}>
                              <ConfirmButton
                                disabled={
                                  concepts[page * rowsPerPage + i] &&
                                  concepts[page * rowsPerPage + i]
                                    .missingField &&
                                  (!concepts[page * rowsPerPage + i][
                                    concepts[page * rowsPerPage + i]
                                      .missingField
                                  ] ||
                                    concepts[page * rowsPerPage + i][
                                      concepts[page * rowsPerPage + i]
                                        .missingField
                                    ]?.length < 1)
                                }
                                isLoading={i === isSavingIndex}
                                confirmed={row.confirmed}
                                onClick={() => {
                                  setIsSavingIndex(i);
                                  const newConcepts = [...concepts];
                                  const missingField =
                                    newConcepts[page * rowsPerPage + i]
                                      .missingField;
                                  updateMissingData({
                                    variables: {
                                      concept: {
                                        uuid: row.uuid,
                                        [missingField]:
                                          newConcepts[page * rowsPerPage + i][
                                            missingField
                                          ] || [],
                                      },
                                      cluster: cluster,
                                      indexPrefix:
                                        customer && customer !== "*"
                                          ? customer
                                          : language,
                                    },
                                  })
                                    .then(() => {
                                      newConcepts[page * rowsPerPage + i] = {
                                        ...newConcepts[page * rowsPerPage + i],
                                        confirmed: true,
                                      };
                                      setConcepts(newConcepts);
                                    })
                                    .catch(() => {
                                      newConcepts[page * rowsPerPage + i] = {
                                        ...newConcepts[page * rowsPerPage + i],
                                        confirmed: false,
                                      };
                                      setConcepts(newConcepts);
                                    })
                                    .finally(() => {
                                      setIsSavingIndex(-1);
                                    });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Box>
      <TablePagination
        component="div"
        style={{ borderTop: "1px solid rgba(0, 0, 0, 0.125)" }}
        rowsPerPageOptions={[50, 100, 200]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          displayedRows: classes.paginationLabel,
          selectLabel: classes.paginationLabel,
        }}
      />
    </Paper>
  );
};

export default MissingDataList;
