import { useContext } from "react";
import { CTX } from "../CMContext";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Box,
  Typography,
  Grid,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalHeader: {
      fontWeight: "bold",
      fontSize: "1em",
    },
    modalContainer: {
      minWidth: 500,
      minHeight: 250,
    },
  })
);

const HistoryLogModal = () => {
  const classes = useStyles();
  const { showHistoryLog, setShowHistoryLog }: any = useContext(CTX);

  const handleCloseModal = () => {
    setShowHistoryLog(false);
  };

  return (
    <Modal
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
      className={classes.modal}
      open={showHistoryLog}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showHistoryLog}>
        <Paper className={classes.modalContainer}>
          <Box
            pl={2}
            pr={2}
            pt={1}
            pb={1}
            borderBottom="1px solid rgba(0, 0, 0, 0.125)"
            display="flex"
            alignItems="center"
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box display="flex">
                  <Typography
                    variant="overline"
                    className={classes.modalHeader}
                  >
                    History Log
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box p={2}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              There are currently no concept records saved
            </Alert>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default HistoryLogModal;
