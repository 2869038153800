import { Fragment } from "react";
import { Box, Button } from "@mui/material";

interface Props {
  disabled?: boolean;
  hidden?: boolean;
  onClick?: any;
}

const hiddenButton = (props: Props) => {
  const { disabled, hidden, onClick } = props;
  return (
    <Fragment>
      {!hidden ? (
        <Button
          disabled={disabled}
          fullWidth
          size="small"
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            Hide
          </Box>
        </Button>
      ) : (
        <Button
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
          onClick={onClick}
        >
          Unhide
        </Button>
      )}
    </Fragment>
  );
};

export default hiddenButton;
