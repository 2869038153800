import gql from "graphql-tag";

const GET_API_DOCS = gql`
  query {
    getApiDocs {
      name
      content
      pubStatus
      priv
      path
      lastUpdated
    }
  }
`;

export default GET_API_DOCS;
