import gql from "graphql-tag";
const updateConceptChangeSuggestions = gql`
  mutation($id: String, $handled: Boolean) {
    updateConceptChangeSuggestions(id: $id, handled: $handled) {
      error
      message
    }
  }
`;

export default updateConceptChangeSuggestions;
