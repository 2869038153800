import { Box } from "@mui/material";

import DatePicker from "./DatePicker";
import AuthorExcludeComponent from "./AuthorExcludeComponent";
import ChannelInput from "./ChannelInput";
import ConceptIncludeComponent from "./ConceptIncludeComponent";
//import NewspaperInput from "./NewspaperInput";

interface IProps {
  setIncludeConcepts: any;
  deleteFromConceptList: any;
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  newspaper: string;
  setNewspaper: (newspaper: string) => void;
  channel: string;
  setChannel: (channel: string) => void;
  includeConcepts: string[];
  excludeAuthors: string[];
  setExcludeAuthors: (authors: string[]) => void;
}

const FilterPanel = (props: IProps) => {
  const {
    setIncludeConcepts,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    channel,
    setChannel,
    includeConcepts,
    excludeAuthors,
    setExcludeAuthors,
  } = props;
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      {/*<NewspaperInput newspaper={newspaper} setNewspaper={setNewspaper} />*/}
      <ChannelInput
        startDate={startDate}
        endDate={endDate}
        channel={channel}
        setChannel={setChannel}
        includeConcepts={includeConcepts}
      />
      <AuthorExcludeComponent
        excludeAuthors={excludeAuthors}
        setExcludeAuthors={setExcludeAuthors}
      />
      {window.location.pathname.indexOf("/production-insights/production-overview") !== -1 && <Box sx={{ width: "30%", minWidth: 200 }}>
        <ConceptIncludeComponent setIncludeConcepts={setIncludeConcepts} />
      </Box>}
    </Box>
  );
};

export default FilterPanel;
