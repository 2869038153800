import { useEffect, useState, useContext } from "react";
import { CTX as tagCTX } from "./TagContext";
import {
  Grid,
  ButtonBase,
  Box,
  Card,
  Typography,
  CircularProgress,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { PermMediaOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "100%" },
    cardBody: {
      padding: theme.spacing(1),
    },
    imageFrame: {
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      backgroundColor: "#e8eef2",
      color: "#000",
      borderRadius: "0.25rem",
      //border: "1px solid rgba(0,0,0,0.125)",
    },
    previewImg: {
      maxWidth: "100%",
      maxHeight: "100%",
      height: 255,
    },
    captionContent: {
      maxWidth: "100%",
      padding: "0px 4px",
      maxHeight: 90,
      overflow: "auto",
      backgroundColor: "#fff",
    },
    minifiedImg: {
      maxWidth: "100%",
      height: "100%",
      transition: "all ease-out 100ms",
      "&:hover": {
        opacity: 0.5,
      },
    },
    img: {
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: "0.25rem",
    },
    selectedMinImg: {
      opacity: 0.5,
    },
    leftCol: {
      maxHeight: "350px",
      height: "fit-content",
    },
    rightCol: {
      height: "350px",
      overflowX: "hidden",
      overflowY: "auto",
    },
  })
);

const ImageDisplayer = () => {
  const classes = useStyles();
  const { entities, topics, categories, getImages }: any = useContext(tagCTX);
  const [images, setImages] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMapping = () => {
    const tags = [...entities, ...topics, ...categories];
    setIsLoading(true);
    getImages({
      variables: {
        tags: tags,
      },
    })
      .then((data: any) => {
        if (data?.data?.getImages?.data) {
          setImages(data.data.getImages.data);
          setSelectedImage(data.data.getImages.data[0] || null);
        }
      })
      .catch((err: Error) => {
        console.error("Failed to fetch images: ", err.message);
        setSelectedImage(null);
        setImages([]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (entities?.length > 0) {
      handleMapping();
      return;
    }
    setSelectedImage(null);
    setImages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, topics, categories]);

  return (
    <Box className={classes.root}>
      <Card variant="outlined">
        <Box className="card-header" padding="5.5px 8.8px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <PermMediaOutlined
                className="mr-2"
                style={{ fontSize: "1.7em" }}
              />
              <Box fontSize="1.25em">Smart image suggestions</Box>
              {isLoading && (
                <CircularProgress size="1rem" style={{ marginLeft: "1em" }} />
              )}
            </Box>
          </Box>
        </Box>
        <Box className={classes.cardBody}>
          {images.length > 0 && (
            <Grid container direction="row" spacing={1}>
              <Grid
                container
                item
                xs={9}
                direction="column"
                className={classes.leftCol}
              >
                {selectedImage && (
                  <Grid
                    justifyContent="center"
                    container
                    item
                    xs={12}
                    direction="row"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      className={`${classes.imageFrame}`}
                    >
                      <img
                        className={`${classes.img} ${classes.previewImg}`}
                        alt={selectedImage.credit}
                        src={selectedImage.imageUrl}
                      />
                    </Box>
                    <Box className={classes.captionContent} textAlign="center">
                      <Typography component="span" variant="caption">
                        {selectedImage.caption}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={3} className={classes.rightCol}>
                <Grid container item direction="row" spacing={1}>
                  {images?.length > 0 &&
                    images.map((image: any, i: number) => (
                      <Grid item xs={12} key={i}>
                        <ButtonBase
                          className={`${classes.imageFrame} ${
                            classes.minifiedImg
                          } ${
                            selectedImage &&
                            selectedImage.thumbnailUrl === image.thumbnailUrl
                              ? classes.selectedMinImg
                              : ""
                          }`}
                          onClick={() => setSelectedImage(image)}
                        >
                          <img
                            className={classes.img}
                            alt={image.thumbnailUrl}
                            src={image.thumbnailUrl}
                          />
                        </ButtonBase>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default ImageDisplayer;
