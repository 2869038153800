import { useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { CTX } from "./Context";
import { Box } from "@mui/material";
import GET_ARTICLE_BY_UUID from "src/queries/GET_ARTICLE_BY_UUID";

const imgStyle: any = {
  width: "100%",
  height: "auto",
  //aspectRatio: "16/9",
  //paddingLeft: "8px",
};

interface IProps {
  uuid?: string;
  src?: string;
  byline?: string;
  size?: number;
  style?: any;
}

const ArticleImageComponent = (props: IProps) => {
  const { uuid, src, byline, size, style } = props;
  const { previewImage } = useContext(CTX);

  const [getArticleByUuid, { data, error }] = useLazyQuery(
    GET_ARTICLE_BY_UUID,
    {
      onError: (e) => {
        console.error("err", e);
      },
    }
  );

  useEffect(() => {
    if (!src && uuid) getArticleByUuid({ variables: { uuid: uuid } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return null;

  return (
    <Box sx={{ position: "relative" }}>
      {byline && (
        <Box
          sx={{
            fontSize: "0.9rem",
            position: "absolute",
            left: 0,
            bottom: 0,
            bgcolor: "rgba(255,255,255,0.8)",
            px: 1,
          }}
        >
          {`Photo by: ${byline}`}
        </Box>
      )}
      <img
        src={
          src ?? previewImage(data?.getArticleByUuid?.image, size) ?? undefined
        }
        alt=""
        style={{ ...imgStyle, ...style } ?? imgStyle}
      />
    </Box>
  );
};

export default ArticleImageComponent;
