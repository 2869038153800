import { useContext } from "react";
import { CTX } from "../CMContext";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Search, Cancel } from "@mui/icons-material";

const useStyles = makeStyles(() =>
  createStyles({
    searchInput: {
      backgroundColor: "rgba(255,255,255,0.4)",
    },
  })
);

const SearchField = () => {
  const classes = useStyles();
  const { suggestionSearchString, setSuggestionSearchString }: any =
    useContext(CTX);

  return (
    <TextField
      autoFocus
      autoComplete="off"
      id="conceptSearchInput"
      className={classes.searchInput}
      value={suggestionSearchString}
      type="text"
      label={<span className="font-weight-bold">Search</span>}
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              style={{
                visibility: suggestionSearchString ? "visible" : "hidden",
              }}
              size="small"
              onClick={() => {
                setSuggestionSearchString("");
              }}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onInput={(e: any) => {
        setSuggestionSearchString(e.target.value);
      }}
    />
  );
};

export default SearchField;
