import { useState, useRef } from "react";
import { Grid, Box, Typography } from "@mui/material";

interface IProps {
  numberOfAds?: number;
  multiCol?: boolean;
}

const AdPlaceholderComponent = (props: IProps) => {
  const { numberOfAds = 0, multiCol } = props;
  const ads = [];
  const [loadedIndex, setLoadedIndex] = useState<Array<number>>([]);

  if (numberOfAds) {
    for (let index = 0; index < numberOfAds; index++) {
      ads.push(index);
    }
  }

  const getRandomAd = () => {
    const rnd = Math.floor(Math.random() * 12) + 1;
    return `https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/ads/ad${rnd}.png`;
  };

  const getRandomBanner = () => {
    const rnd = Math.floor(Math.random() * 12) + 1;
    return `https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/ads/banner${rnd}.png`;
  };

  const adsList = useRef(
    ads.map(() => {
      if (numberOfAds <= 1) {
        return getRandomBanner();
      }
      return getRandomAd();
    })
  );

  return (
    <Grid container spacing={2}>
      {ads.map((ad: any, i: number) => {
        return (
          <Grid key={i} item xs={12} md={multiCol ? 6 : 12}>
            <Box
              sx={{
                display: loadedIndex.includes(i) ? "block" : "none",
                cursor: "pointer",
              }}
            >
              <Typography
                component="div"
                variant="caption"
                sx={{ textAlign: "center" }}
              >
                ADVERTISEMENT
              </Typography>
              <img
                src={adsList.current[i]}
                alt="ad"
                onLoad={() => {
                  setLoadedIndex([...loadedIndex, i]);
                }}
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AdPlaceholderComponent;
