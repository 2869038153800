import React, { useState, useEffect, useContext, useRef } from "react";
import { CTX } from "../CMContext";
import { CTX as mainCTX } from "../../../utils/ContextStore";
import {
  Box,
  Grid,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { ArrowDropDown, Refresh } from "@mui/icons-material";

import SearchField from "./SearchField";
import DatasetPicker from "../../PanelComponents/DatasetPicker";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ConceptListPanel = () => {
  const { forceUpdateListener, setForceUpdateListener }: any =
    useContext(mainCTX);
  const {
    setSelectedSuggestion,
    suggestionFilter,
    setSuggestionFilter,
    disabledPanel,
  }: any = useContext(CTX);
  const [open, setOpen] = useState(false);
  const options = ["unhandled", "handled", "all"];
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    selectedIndex === 0
      ? setSelectedIndex(1)
      : selectedIndex === 1
      ? setSelectedIndex(2)
      : setSelectedIndex(0);
  };

  const handleRefresh = () => {
    setSelectedSuggestion(null);
    setForceUpdateListener(forceUpdateListener === 0 ? 1 : 0);
  };

  useEffect(() => {
    setSuggestionFilter(options[selectedIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: "rgba(255,255,255,0.9)",
        borderBottom: "1px solid rgba(0,0,0,0.125)",
        zIndex: 1,
      }}
    >
      <Grid item xs={3}>
        <Box p={1} height="100%" display="flex" alignItems="center">
          <Box mr={1}>
            <Button
              style={{ minWidth: "30px", padding: "5px" }}
              disabled={disabledPanel}
              variant="outlined"
              onClick={handleRefresh}
            >
              <Refresh />
            </Button>
          </Box>
          <ButtonGroup
            disabled={disabledPanel}
            variant="outlined"
            aria-label="handled menu"
            fullWidth
            style={{ width: "100%", minWidth: "10%" }}
          >
            <Button onClick={handleClick}>{suggestionFilter}</Button>
            <Button
              style={{ width: "fit-content" }}
              aria-controls={open ? "handle-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select-handled"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <span ref={anchorRef}>
                <ArrowDropDown />
              </span>
            </Button>
          </ButtonGroup>
          <Popper
            style={{ zIndex: 1 }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {capitalizeFirstLetter(option)}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box
          p={1}
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          <SearchField />
          <DatasetPicker />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConceptListPanel;
