import { useState, createContext, useContext, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CTX as mainCTX } from "../../utils/ContextStore";

import ANALYZE_ARTICLE from "../../mutations/analyzeArticle";
import TAG_ARTICLE from "../../mutations/getTagsMutation";
import GET_IMAGES from "../../mutations/getImages";
import GET_DEMO_ARTICLES from "../../queries/getDemoArticles";

export const CTX = createContext(null);

interface Error {
  error: boolean;
  message: string;
}

export default function AutoTaggingContext(props: any) {
  const [genderData, setGenderData] = useState({
    maleScore: 0,
    maleMentions: 0,
    uniqueMaleEntities: 0,
    malePeople: [],
    femaleScore: 0,
    femaleMentions: 0,
    uniqueFemaleEntities: 0,
    femalePeople: [],
    nonbinaryScore: 0,
    nonbinaryMentions: 0,
    uniqueNonbinaryEntities: 0,
    nonbinaryPeople: [],
    unknownScore: 0,
    unknownMentions: 0,
    uniqueUnknownEntities: 0,
    unknownPeople: [],
  });

  const [analyzeArticle] = useMutation(ANALYZE_ARTICLE);
  const [tagArticle] = useMutation(TAG_ARTICLE);
  const [getImages] = useMutation(GET_IMAGES);
  const [editorText, setEditorText] = useState("");
  const [genderTagText, setGenderTagText] = useState("");
  const [textEditor, setTextEditor] = useState();
  const [tagQualityAssurance, setTagQualityAssurance] = useState(false);

  const [tagSettings, setTagSettings] = useState({
    categories: -1,
    topics: -1,
    entities: -1,
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const [categories, setCategories] = useState<any>([]);
  const [topics, setTopics] = useState<any>([]);
  const [entities, setEntities] = useState<any>([]);

  const [broaderConcepts, setBroaderConcepts] = useState<any>(undefined);
  const [broaderConceptsFallback, setBroaderConceptsFallback] = useState<any>(
    []
  );

  const taggingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [hideCategories, setHideCategories] = useState<boolean>(false);
  const [hideTopics, setHideTopics] = useState<boolean>(false);
  const [hideEntities, setHideEntities] = useState<boolean>(false);

  const [editConceptModalShow, setEditConceptModalShow] =
    useState<boolean>(false);
  const [createConceptModalShow, setCreateConceptModalShow] =
    useState<boolean>(false);
  const [createTopicModalShow, setCreateTopicModalShow] =
    useState<boolean>(false);

  const [demoArticles, setDemoArticles] = useState([]);
  const [selectedDemoArticle, setSelectedDemoArticle] =
    useState<any>(undefined);

  const [queueStatisticsTrigger, setQueueStatisticsTrigger] = useState(0);
  const [articleFormat, setArticleFormat] = useState("json");

  const { activeEndpoint }: any = useContext(mainCTX);

  const [tagError, setTagError] = useState<Error>({
    error: false,
    message: "",
  });

  const formatTag = (item: any) => {
    delete item.__typename;
    return {
      ...item,
      weight: item.weight === "" || isNaN(item.weight) ? 1 : item.weight,
    };
  };

  const clearTags = () => {
    setCategories([]);
    setTopics([]);
    setEntities([]);
  };

  const clearTimeouts = (timeoutList: any[]) => {
    timeoutList.forEach((id: any) => {
      clearTimeout(id);
    });
  };

  //takes in a tag (string) and tags (list) and return true if tag in list
  const checkDuplicate = (item: any, list: object[]) => {
    let hasDuplicate = false;
    list.forEach((tag: any) => {
      if (tag.uuid === item.uuid) {
        hasDuplicate = true;
      }
    });
    return hasDuplicate;
  };

  const addConcept = (item: any) => {
    if (item.type === "category") {
      if (Object.keys(item).length > 0 && !checkDuplicate(item, categories)) {
        setCategories([formatTag(item), ...categories]);
      }
    } else if (item.type === "topic") {
      if (Object.keys(item).length > 0 && !checkDuplicate(item, topics)) {
        setTopics([formatTag(item), ...topics]);
      }
    } else {
      if (Object.keys(item).length > 0 && !checkDuplicate(item, entities)) {
        setEntities([formatTag(item), ...entities]);
      }
    }
  };

  const deleteCategory = (item: any) => {
    setCategories(
      categories.filter((category: any) => category.uuid !== item.uuid)
    );
  };

  const deleteTopic = (item: any) => {
    setTopics(topics.filter((topic: any) => topic.uuid !== item.uuid));
  };

  const deleteEntity = (item: any) => {
    setEntities(entities.filter((entity: any) => entity.uuid !== item.uuid));
  };

  const resetGenderData = () => {
    setGenderData({
      maleScore: 0,
      maleMentions: 0,
      uniqueMaleEntities: 0,
      malePeople: [],
      femaleScore: 0,
      femaleMentions: 0,
      uniqueFemaleEntities: 0,
      femalePeople: [],
      nonbinaryScore: 0,
      nonbinaryMentions: 0,
      uniqueNonbinaryEntities: 0,
      nonbinaryPeople: [],
      unknownScore: 0,
      unknownMentions: 0,
      uniqueUnknownEntities: 0,
      unknownPeople: [],
    });
  };

  const updateQueueStatistics = () => {
    setQueueStatisticsTrigger((value: number) => value + 1);
  };

  useQuery(GET_DEMO_ARTICLES, {
    variables: { language: activeEndpoint?.language },
    onCompleted: (data) => {
      if (data?.getDemoArticles.length > 0) {
        setDemoArticles(data.getDemoArticles);
        setSelectedDemoArticle(data.getDemoArticles[0]);
      } else {
        setSelectedDemoArticle(undefined);
      }
    },
    skip: !activeEndpoint?.isDemoEnvironment,
  });

  return (
    <CTX.Provider
      value={
        {
          activeEndpoint,

          categories,
          setCategories,
          topics,
          setTopics,
          entities,
          setEntities,
          broaderConcepts,
          setBroaderConcepts,
          broaderConceptsFallback,
          setBroaderConceptsFallback,

          tagArticle,
          analyzeArticle,
          getImages,

          genderData,
          setGenderData,
          resetGenderData,

          editorText,
          setEditorText,
          genderTagText,
          setGenderTagText,
          textEditor,
          setTextEditor,

          tagQualityAssurance,
          setTagQualityAssurance,
          tagSettings,
          setTagSettings,
          formatTag,
          clearTags,
          isPlaying,
          setIsPlaying,

          taggingTimeout,
          clearTimeouts,

          hideCategories,
          setHideCategories,
          hideTopics,
          setHideTopics,
          hideEntities,
          setHideEntities,

          editConceptModalShow,
          setEditConceptModalShow,
          createConceptModalShow,
          setCreateConceptModalShow,
          createTopicModalShow,
          setCreateTopicModalShow,

          addConcept,
          deleteCategory,
          deleteTopic,
          deleteEntity,

          queueStatisticsTrigger,
          updateQueueStatistics,

          demoArticles,
          selectedDemoArticle,
          setSelectedDemoArticle,

          tagError,
          setTagError,

          articleFormat,
          setArticleFormat,
        } as any
      }
    >
      {props.children}
    </CTX.Provider>
  );
}
