import { Button, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";

interface IProps {
  disabled?: boolean;
  tagQueueArticle?: any;
  tagQueueProps?: any;
  tagQueueArticleCallback?: any;
}

const SkipButton = (props: IProps) => {
  const {
    disabled,
    tagQueueArticle,
    tagQueueProps = {},
    tagQueueArticleCallback,
  } = props;

  return (
    <Tooltip
      arrow
      disableInteractive
      title="Mark article as finished without any tags"
      enterDelay={500}
    >
      <span>
        <Button
          disabled={disabled}
          variant="contained"
          color="secondary"
          onClick={() => {
            tagQueueArticle &&
              tagQueueArticle({
                variables: {
                  query: {
                    finished: true,
                    concepts: [],
                    customQueue: false,
                    queueName: undefined,
                    queueNr: undefined,
                    ...tagQueueProps,
                  },
                },
                onCompleted: (data: any) => {
                  tagQueueArticleCallback && tagQueueArticleCallback(data);
                },
              });
          }}
          sx={{ gap: 0.5 }}
        >
          <Clear />
          Skip
        </Button>
      </span>
    </Tooltip>
  );
};

export default SkipButton;
