import { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Box,
  Alert,
  LinearProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import GET_STRATEGY from "src/queries/GET_STRATEGY";
import ReactJson from "react-json-view";

import UPDATE_STRATEGY from "src/mutations/UPDATE_STRATEGY";

interface IStrategy {
  name: string;
  description: string;
  isABTest: boolean;
}

interface IProps {
  strategy: IStrategy | null;
  open: boolean;
  handleClose: () => void;
}

const EditStrategyModal = (props: IProps) => {
  const { strategy, open, handleClose } = props;
  const { setShowSnackbar, setSnackbarMessage, setSnackbarError }: any =
    useContext(CTX);

  const [src, setSrc] = useState<any | null | undefined>(undefined);

  const { loading, error } = useQuery(GET_STRATEGY, {
    fetchPolicy: "network-only",
    variables: { id: strategy?.name },
    onCompleted: (data) => {
      data = data.getStrategy;
      if (!data) {
        setSrc(null);
        return;
      }
      setSrc(JSON.parse(data));
    },
    onError: () => {
      setSrc(null);
    },
    skip: !strategy,
  });

  const [updateStrategy] = useMutation(UPDATE_STRATEGY, {
    variables: { query: JSON.stringify(src) },
    onCompleted: () => {
      setShowSnackbar(true);
      setSnackbarMessage("Strategy updated successfully");
      setSnackbarError(false);
      handleClose();
    },
    onError: () => {
      setShowSnackbar(true);
      setSnackbarMessage("Failed to update strategy");
      setSnackbarError(true);
    },
  });

  const onEdit = (e: any) => {
    setSrc(e.updated_src);
  };

  const onAdd = (e: any) => {
    setSrc(e.updated_src);
  };

  const onDelete = (e: any) => {
    setSrc(e.updated_src);
  };

  const onSubmit = () => {
    if (!src) {
      setShowSnackbar(true);
      setSnackbarMessage("Failed to update strategy");
      setSnackbarError(true);
      return;
    }
    updateStrategy();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 12, right: 12 }}
      >
        <Close />
      </IconButton>
      <DialogTitle>
        Edit
        <Box component="span" sx={{ ml: 1, fontWeight: 700 }}>
          {strategy?.name}
        </Box>
      </DialogTitle>

      <Box sx={{ height: "100%", overflow: "auto" }}>
        {error ? (
          <Alert severity="error" sx={{ width: "fit-content", mx: 2 }}>
            {error.message}
          </Alert>
        ) : (
          <Box sx={{ position: "relative" }}>
            <LinearProgress
              color="primary"
              variant={loading ? "indeterminate" : "determinate"}
              value={0}
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                visibility: loading ? "visible" : "hidden",
                zIndex: 1,
              }}
            />
            {src === null ? (
              <Alert severity="info" sx={{ width: "fit-content", mx: 2 }}>
                No data object found for the selected strategy
              </Alert>
            ) : (
              <ReactJson
                src={src}
                theme="summerfruit"
                iconStyle="triangle"
                name={false}
                displayObjectSize={false}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                indentWidth={4}
                collapsed={2}
                onEdit={onEdit}
                onAdd={onAdd}
                onDelete={onDelete}
                style={{ minHeight: "500px", padding: "8px" }}
              />
            )}
          </Box>
        )}
      </Box>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={!src} variant="contained" onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStrategyModal;
