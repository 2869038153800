import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { CTX } from "./Context";

import { Tabs, Tab } from "@mui/material";

import GET_STATISTIC from "../../queries/getStatisticQuery";

const TrendingMenuButtons = () => {
  const { history, onTagClick } = useContext(CTX);

  const [trendingTopics, setTrendingTopics] = useState<any>([]);
  const [tabValue, setTabValue] = useState<boolean | number>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [getTrendingTopics] = useLazyQuery(GET_STATISTIC, {
    onCompleted: (res) => {
      res.getStatistic?.result && setTrendingTopics(res.getStatistic.result);
    },
    onError: () => {
      setTrendingTopics([]);
    },
  });

  useEffect(() => {
    const params = {
      statisticOperation: "conceptFrequencyByType",
      conceptType: "category",
      returnSize: 6,
      startDate: moment().subtract(14, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      newspaper: "",
      channel: "bot",
    };

    getTrendingTopics({
      variables: params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const topicUuid = new URLSearchParams(history.location.search)?.get("uuid");
    if (!topicUuid) {
      setTabValue(false);
    } else {
      const topicIndex = trendingTopics.findIndex(
        (topic: any) => topic.uuid === topicUuid
      );
      setTabValue(topicIndex !== -1 ? topicIndex : false);
    }

    return history.listen((location: any) => {
      const topicUuid = new URLSearchParams(location.search)?.get("uuid");
      if (!topicUuid) {
        setTabValue(false);
      } else {
        const topicIndex = trendingTopics.findIndex(
          (topic: any) => topic.uuid === topicUuid
        );
        setTabValue(topicIndex !== -1 ? topicIndex : false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, trendingTopics]);

  return (
    <Tabs
      value={tabValue}
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleChange}
    >
      {trendingTopics.map((topic: any, i: number) => (
        <Tab
          key={i}
          label={topic.title}
          onClick={() => {
            onTagClick(topic.uuid);
          }}
        />
      ))}
    </Tabs>
  );
};

export default TrendingMenuButtons;
