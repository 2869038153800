import gql from "graphql-tag";

const GET_CUSTOM_RULES = gql`
  query {
    getCustomRules {
      customerID
      ruleName
      operation
      textToManipulate
      replacingText
    }
  }
`;

export default GET_CUSTOM_RULES;
