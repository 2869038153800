import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, Chip, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OutlinedDiv from "./OutlinedDiv";

import GET_RAW_KEYWORDS from "../../queries/GET_RAW_KEYWORDS";

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    display: "flex",
    height: "fit-content",
    padding: theme.spacing(0.1),
    marginTop: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
  },
}));

interface RawKeyword {
  title: string;
  type: string;
  weight: number;
}

interface Props {
  disabled?: boolean;
  title: string;
  type: string;
  keywords: string[];
}

const RawKeywordDisplayer = (props: Props) => {
  const classes = useStyles();
  const { title, type, keywords, disabled } = props;

  const [rawKeywords, setRawKeyword] = useState<[RawKeyword] | undefined>(
    undefined
  );

  const { loading } = useQuery(GET_RAW_KEYWORDS, {
    variables: {
      query:
        type !== "topic"
          ? title.toLowerCase()
          : type === "topic" && (!keywords || keywords.length < 1)
          ? title
          : `${title}, ${keywords.join(", ")}`,
    },
    onCompleted: (data) => {
      setRawKeyword(data.getRawKeywords.rawKeywords);
    },
    onError: () => {
      setRawKeyword(undefined);
    },
  });

  return (
    <OutlinedDiv disabled={disabled} label="Connected raw keywords">
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
        minHeight="24px"
      >
        {loading && (
          <CircularProgress
            color="primary"
            size="1em"
            style={{ marginRight: "1em" }}
          />
        )}
        {rawKeywords?.map((rawKeyword) => (
          <Chip
            color="default"
            key={rawKeyword.title}
            label={
              <Box textAlign="center">
                <Box fontWeight="700">{rawKeyword.title}</Box>
                <Box>{rawKeyword.weight.toFixed(5)}</Box>
              </Box>
            }
            variant="outlined"
            className={classes.chip}
          />
        ))}
      </Box>
    </OutlinedDiv>
  );
};

export default RawKeywordDisplayer;
