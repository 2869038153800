import { gql } from "@apollo/client";

const GET_DEMO_LANGUAGES = gql`
  query {
    getDemoLanguages {
      language_name
      language_code
    }
  }
`;

export default GET_DEMO_LANGUAGES;
