import { useState, useContext } from "react";
import { CTX as tagCTX } from "./TagContext";
import {
  Box,
  Typography,
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
  Grow,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  KeyboardReturn,
  ChevronRight,
  LocalOffer,
  Description,
  Grain,
  Person,
  AccountBalance,
  LocationOn,
  EventNote,
  GridView,
  Clear,
} from "@mui/icons-material";
import { FaWikipediaW, FaBarcode, FaMapMarkedAlt } from "react-icons/fa";

import CMMap from "../Map/CMMap";

interface IProps {
  tags: any[];
  getConcept: any;
  deleteTag: (item: any) => void;
  setChosenConcept: (item: any) => void;
  setEditConceptModalShow: (item: any) => void;
  disabled?: boolean;
  isEntity?: boolean;
  tagQualityAssurance?: boolean;
  tagQueueArticle?: any;
  tagQueueProps?: any;
}

const getTagColor = (weight: number, alpha: number = 1) => {
  return weight >= 0.9
    ? `rgba(87, 227, 158, ${alpha})`
    : weight < 0.9 && weight >= 0.8
    ? `rgba(177, 227, 87, ${alpha})`
    : weight < 0.8 && weight >= 0.7
    ? `rgba(255, 237, 115, ${alpha})`
    : weight < 0.7 && weight >= 0.6
    ? `rgba(255, 173, 117, ${alpha})`
    : `rgba(255, 133, 117, ${alpha})`;
};

const getTypeIcon = (type: string) => {
  if (type.includes("category")) {
    return <Description fontSize="small" />;
  } else if (type.includes("topic")) {
    return <Grain fontSize="small" />;
  } else if (type.includes("person")) {
    return <Person fontSize="small" />;
  } else if (type.includes("organisation")) {
    return <AccountBalance fontSize="small" />;
  } else if (type.includes("place")) {
    return <LocationOn fontSize="small" />;
  } else if (type.includes("event")) {
    return <EventNote fontSize="small" />;
  } else if (type.includes("object")) {
    return <GridView fontSize="small" />;
  } else {
    return <LocalOffer fontSize="small" />;
  }
};

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: theme.shadows[4],
  },
}))(Tooltip);

const TagDisplayer = (props: IProps) => {
  const {
    disabled,
    tags,
    tagQueueArticle,
    tagQueueProps = {},
    getConcept,
    deleteTag,
    setChosenConcept,
    isEntity,
    tagQualityAssurance,
  } = props;

  const {
    categories,
    topics,
    entities,
    broaderConcepts,
    broaderConceptsFallback,
    setBroaderConceptsFallback,
    setEditConceptModalShow,
  }: any = useContext(tagCTX);

  const [isLoadingBroader, setIsLoadingBroader] = useState<boolean>(false);
  const [hoveredBroader, setHoveredBroader] = useState<any[]>([]);

  const applyBroaderConceptsFallback = async (concept: any) => {
    let res: any[] = broaderConceptsFallback;
    let currentConcept: any = concept;
    if (!currentConcept) {
      return res;
    }
    const index = broaderConceptsFallback.findIndex(
      (broader: any) => broader?.uuid === currentConcept?.broader
    );
    if (index === -1) {
      while (currentConcept?.broader) {
        setIsLoadingBroader(true);
        let data: any = await getConcept({
          variables: { uuid: currentConcept.broader },
        }).catch(() => {});
        let broaderConcept: any = null;
        if (
          data?.data?.getConcept?.error !== true &&
          data?.data?.getConcept?.result?.length > 0
        ) {
          broaderConcept = data.data.getConcept.result[0];
          broaderConcept.childConcepts = [currentConcept.uuid];
          res.push(broaderConcept);
        }
        /*else {
          res.push({
            title: currentConcept.broader,
            uuid: currentConcept.broader,
            broader: "",
            childConcepts: [currentConcept.uuid],
            invalid: true,
          });
        }*/
        if (currentConcept?.uuid === broaderConcept?.uuid) {
          broaderConcept = null;
        }
        currentConcept = broaderConcept;
      }
    } else {
      //Store broaders in client
      !res[index].childConcepts.includes(concept.uuid) &&
        res[index].childConcepts.push(concept.uuid);
    }
    !broaderConceptsFallback.some(
      (broader: any) => broader.uuid === concept.broader
    ) && setBroaderConceptsFallback(res);
    setIsLoadingBroader(false);
    return res;
  };

  const getBroaderConcepts = (uuid: string) => {
    let res: any[] = [];
    let broaderArray = broaderConcepts || broaderConceptsFallback;
    if (broaderArray || broaderArray.length > 0) {
      let broaderIndex = broaderArray.findIndex((broader: any) => {
        if (!broader?.childConcepts) {
          broader.childConcepts = [];
        }
        return broader.childConcepts.includes(uuid);
      });
      while (broaderIndex !== -1) {
        const broaderConcept = broaderArray[broaderIndex];
        res.push(broaderConcept);
        broaderIndex = broaderArray.findIndex((broader: any) => {
          if (!broader?.childConcepts) {
            broader.childConcepts = [];
          }
          return broader.childConcepts.includes(broaderConcept.uuid);
        });
        if (uuid && broaderConcept?.uuid === uuid) {
          break;
        }
      }
    }
    return res;
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, pb: 2 }}>
      {tags.map((item: any, i: number) => (
        <Grow key={item.uuid} in={true} timeout={i * 250}>
          <Chip
            key={item.uuid}
            deleteIcon={
              <IconButton disabled={disabled} size="small" sx={{ p: 0.2 }}>
                <Clear fontSize="small" />
              </IconButton>
            }
            label={
              <Box>
                <LightTooltip
                  placement="top"
                  arrow
                  disableInteractive
                  title={
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: 700, mr: 4 }}>
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            borderBottom: !isEntity
                              ? `2px solid ${getTagColor(item.weight, 1)}`
                              : undefined,
                          }}
                        >
                          {`(${
                            !isEntity
                              ? (100 * item.weight).toFixed(0) + "%"
                              : item.weight
                          })`}
                        </Typography>
                      </Box>

                      {isLoadingBroader ? (
                        <CircularProgress size="1em" />
                      ) : null}

                      {hoveredBroader.length > 0 && (
                        <Box>
                          {hoveredBroader.map((broader: any, i: number) => {
                            return i === 0 ? (
                              <Typography
                                key={i}
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  fontWeight: 700,
                                  fontSize: "0.8rem",
                                  color: "#666",
                                }}
                              >
                                <KeyboardReturn
                                  sx={{
                                    transform: "rotateY(180deg)",
                                    mr: 0.5,
                                  }}
                                  fontSize="small"
                                />
                                {broader.title}
                              </Typography>
                            ) : (
                              <Typography
                                key={i}
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  fontWeight: 700,
                                  fontSize: "0.8rem",
                                  color: "#666",
                                }}
                              >
                                <ChevronRight fontSize="small" />
                                {broader.title}
                              </Typography>
                            );
                          })}
                        </Box>
                      )}
                      {item.shortDescription && (
                        <Typography component="div" variant="subtitle2">
                          <Box mt={0.5}>{item.shortDescription}</Box>
                        </Typography>
                      )}
                      {item.aliases?.length > 0 && (
                        <Typography
                          component="div"
                          variant="caption"
                          sx={{ color: "#666", mt: 0.5 }}
                        >
                          <Box component="span" sx={{ fontWeight: 700 }}>
                            Aliases:{" "}
                          </Box>
                          {item.aliases.map((alias: String, index: Number) => {
                            return index !== 0 ? ", " + alias : alias;
                          })}
                        </Typography>
                      )}
                      {item.source && (
                        <Typography
                          component="div"
                          variant="caption"
                          sx={{ color: "#666", mt: 0.5 }}
                        >
                          <Box component="span" sx={{ fontWeight: 700 }}>
                            Source:{" "}
                          </Box>
                          {item.source}
                        </Typography>
                      )}
                      {item.geoJSON &&
                      item.geoJSON !== "{}" &&
                      (item.type.includes("place") ||
                        item.type.includes("organisation")) ? (
                        <Box width="250px" mt={0.5}>
                          <CMMap concept={item} size="small" />
                        </Box>
                      ) : null}
                    </Box>
                  }
                >
                  <Box sx={{ fontWeight: 700 }}>{item.title}</Box>
                </LightTooltip>
                {isEntity && (
                  <Box pt={0.5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "23px",
                        width: "100%",
                        gap: 0.5,
                        color: "#777",
                        fontWeight: 700,
                        fontSize: "10px",
                      }}
                    >
                      {item.wikipedia && (
                        <Tooltip
                          title="Visit Wikipedia"
                          placement="top"
                          disableInteractive
                        >
                          <IconButton
                            color="primary"
                            className="interactive"
                            size="small"
                            sx={{
                              color: "rgb(55, 55, 55)",
                              backgroundColor: "#fff",
                              border: "1px solid rgba(0,0,0,0.125)",
                            }}
                            href={
                              item.wikipedia.startsWith("https://")
                                ? item.wikipedia
                                : "https://" + item.wikipedia
                            }
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FaWikipediaW size="0.75em" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {item.wikidata && (
                        <Tooltip
                          title="Visit Wikidata"
                          placement="top"
                          disableInteractive
                        >
                          <IconButton
                            className="interactive"
                            size="small"
                            sx={{
                              color: "rgb(55, 55, 55)",
                              backgroundColor: "#fff",
                              border: "1px solid rgba(0,0,0,0.125)",
                            }}
                            href={
                              "https://www.wikidata.org/wiki/" + item.wikidata
                            }
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FaBarcode size="0.75em" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {item.openStreetMap && (
                        <Tooltip
                          title="Visit OpenStreetMap"
                          placement="top"
                          disableInteractive
                        >
                          <IconButton
                            className="interactive"
                            size="small"
                            sx={{
                              color: "rgb(55, 55, 55)",
                              backgroundColor: "#fff",
                              border: "1px solid rgba(0,0,0,0.125)",
                            }}
                            href={item.openStreetMap}
                            target="_blank"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <FaMapMarkedAlt size="0.75em" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Box component="span">
                        weight:
                        <Box
                          component="span"
                          sx={{ ml: 0.4, color: "#000", fontWeight: 700 }}
                        >
                          {item.weight}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            }
            sx={{
              py: 0.5,
              height: "auto",
              boxShadow: "2px 2px 6px -4px rgba(77, 92, 105)",
              borderRadius: "0.25rem",
              bgcolor: !isEntity
                ? getTagColor(item.weight, 0.25)
                : "rgba(232, 237, 242, 1)",
              "&:hover": {
                bgcolor: !isEntity
                  ? getTagColor(item.weight, 0.5)
                  : "rgba(232, 237, 242, 0.5)",
              },
            }}
            clickable
            icon={getTypeIcon(item.type)}
            onMouseEnter={async () => {
              setHoveredBroader([]);
              if (!broaderConcepts && item.broader) {
                await applyBroaderConceptsFallback(item);
              }
              setHoveredBroader(getBroaderConcepts(item.uuid));
            }}
            onClick={() => {
              if (disabled) return;
              setChosenConcept(item);
              setEditConceptModalShow(true);
            }}
            onDelete={
              disabled
                ? undefined
                : () => {
                    if (tagQualityAssurance)
                      tagQueueArticle &&
                        tagQueueArticle({
                          variables: {
                            query: {
                              finished: false,
                              concepts: [
                                ...categories,
                                ...topics,
                                ...entities,
                              ].filter(
                                (concept: any) => concept.uuid !== item.uuid
                              ),
                              customQueue: false,
                              queueName: undefined,
                              queueNr: undefined,
                              ...tagQueueProps,
                            },
                          },
                          onCompleted: () => {
                            deleteTag(item);
                          },
                        });
                    else deleteTag(item);
                  }
            }
          />
        </Grow>
      ))}
    </Box>
  );
};

export default TagDisplayer;
