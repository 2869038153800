import { useEffect, useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { CTX } from "src/utils/ContextStore";
import ReactJson from "react-json-view";
import {
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  Box,
  DialogActions,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import CREATE_STRATEGY from "src/mutations/CREATE_STRATEGY";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const CreateStrategyModal = (props: IProps) => {
  const { open, handleClose } = props;
  const { setShowSnackbar, setSnackbarMessage, setSnackbarError }: any =
    useContext(CTX);

  const [strategy, setStrategy] = useState<any>({
    name: "",
    customerID: "",
    configName: "",
    published: true,
    description: "",
    abtest: false,
    filterSettings: [],
    multiplierSettings: [],
    scorerSettings: [],
    selectorSetting: {},
    scorers: [],
    multipliers: [],
    filters: [],
    selector: {},
    channelsToIncludeInPool: [],
    asSimpleJSON: {},
  });

  const [createStrategy] = useMutation(CREATE_STRATEGY, {
    variables: { query: JSON.stringify(strategy) },
    onCompleted: (data) => {
      setShowSnackbar(true);
      setSnackbarError(false);
      setSnackbarMessage("Strategy created successfully");
      handleClose();
    },
    onError: (err) => {},
  });

  const onEdit = (e: any) => {
    setStrategy(e.updated_src);
  };

  const onAdd = (e: any) => {
    setStrategy(e.updated_src);
  };

  const onDelete = (e: any) => {
    setStrategy(e.updated_src);
  };

  const onSubmit = () => {
    if (!strategy.name || !strategy.customerID || !strategy.configName) {
      setShowSnackbar(true);
      setSnackbarMessage("Failed to create strategy, missing required fields");
      setSnackbarError(true);
      return;
    }
    createStrategy();
  };

  useEffect(() => {
    if (!open) {
      setStrategy({
        name: "",
        customerID: "",
        configName: "",
        published: true,
        description: "",
        abtest: false,
        filterSettings: [],
        multiplierSettings: [],
        scorerSettings: [],
        selectorSetting: {},
        scorers: [],
        multipliers: [],
        filters: [],
        selector: {},
        channelsToIncludeInPool: [],
        asSimpleJSON: {},
      });
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 12, right: 12 }}
      >
        <Close />
      </IconButton>
      <DialogTitle>Create new strategy</DialogTitle>
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <ReactJson
          src={strategy}
          theme="summerfruit"
          iconStyle="triangle"
          name={false}
          displayObjectSize={false}
          enableClipboard={false}
          displayDataTypes={false}
          quotesOnKeys={false}
          indentWidth={4}
          collapsed={2}
          onEdit={onEdit}
          onAdd={onAdd}
          onDelete={onDelete}
          style={{ minHeight: "500px", padding: "8px" }}
        />
      </Box>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateStrategyModal;
