import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { CTX } from "../CMContext";
import { CTX as MainCTX } from "../../../utils/ContextStore";

import ChangeInfoBox from "./ChangeInfoBox";
import ConfirmBox from "./ConfirmBox";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      backgroundColor: "rgba(255,255,255,0.9)",
      transition: "500ms linear",
    },
    hidden: {
      opacity: 0,
    },
  })
);

const DetailView = () => {
  const classes = useStyles();
  const {
    getConcept,
    setDisabledInboxItems,
    selectedSuggestion,
    setSelectedSuggestion,
    currentConcept,
    setCurrentConcept,
    updateConceptChangeSuggestions,
  }: any = useContext(CTX);
  const { forceUpdateListener, setForceUpdateListener }: any =
    useContext(MainCTX);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (selectedSuggestion?.concept?.uuid) {
      setIsLoading(true);
      setHasError(false);
      setDisabledInboxItems(true);
      getConcept({
        variables: {
          uuid: selectedSuggestion.concept.uuid,
        },
      })
        .then((data: any) => {
          if (data?.data?.getConcept?.result[0]) {
            const retrievedConcept = data.data.getConcept.result[0];
            setCurrentConcept(retrievedConcept);
          } else setHasError(true);
        })
        .catch((err: Error) => {
          console.error(err);
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
          setDisabledInboxItems(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuggestion]);

  if (!selectedSuggestion?.concept) return null;

  return (
    <Box
      className={`${classes.root} ${
        !selectedSuggestion?.concept && classes.hidden
      }`}
    >
      {selectedSuggestion?.concept &&
      (currentConcept || selectedSuggestion.createNewRequested) ? (
        <Box
          display="flex"
          height="100%"
          minHeight="100%"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" flex={1} overflow="auto">
            <ChangeInfoBox />
          </Box>
          <Box flexDirection="column" height="fit-content">
            <ConfirmBox />
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          pt={5}
          width="100%"
        >
          {isLoading && <CircularProgress size="3rem" />}
          {hasError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <Box>
                Make sure that the concept exists and has not been deleted
                already for the chosen dataset
              </Box>
              <Box display="flex" justifyContent="center" pt={2} pb={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    updateConceptChangeSuggestions({
                      variables: {
                        id: selectedSuggestion.id,
                        handled: true,
                      },
                    })
                      .then(() => {
                        setTimeout(() => {
                          setForceUpdateListener(
                            forceUpdateListener === 0 ? 1 : 0
                          );
                        }, 250);
                      })
                      .catch((e: Error) => {
                        console.error(e);
                      })
                      .finally(() => {
                        setCurrentConcept(null);
                        setSelectedSuggestion(null);
                      });
                  }}
                >
                  Mark suggestion as handled
                </Button>
              </Box>
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DetailView;
