import gql from "graphql-tag";

const getProfileInterests = gql`
  query ($userID: String) {
    getProfileInterests(userID: $userID) {
      userID
      interests {
        count
        conceptID
      }
    }
  }
`;

export default getProfileInterests;
