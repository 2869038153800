import { useState, useEffect, useContext } from "react";
import { CTX } from "./ApiDocsContext";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Link,
} from "@mui/material";

/** Highlightning the active section on the section list */
const navHighlighter = () => {
  const apiDocs: any = document.querySelector("#api-docs");
  const scrollY = apiDocs.scrollTop;
  const sections = Array.from(apiDocs.querySelectorAll("section[title]"));
  sections &&
    sections.length !== 0 &&
    sections.forEach((section: any) => {
      const sectionHeight: any = section.offsetHeight;
      const sectionTop: any = section.offsetTop - 60;
      const sectionId = section.getAttribute("id");
      try {
        const titleSectionItem: any = document.querySelector(
          ".section-title-item"
        );
        const pageSection: any = document.querySelector(
          ".navigation > a[href*=" + sectionId + "] > li"
        );
        if (scrollY >= 0 && scrollY <= 60) {
          titleSectionItem?.classList.add("active-section", "Mui-selected");
        } else if (
          scrollY > sectionTop &&
          scrollY <= sectionTop + sectionHeight
        ) {
          pageSection?.classList.add("active-section", "Mui-selected");
        } else {
          titleSectionItem?.classList.remove("active-section", "Mui-selected");
          pageSection?.classList.remove("active-section", "Mui-selected");
        }
      } catch (err) {
        console.error(err);
      }
    });
};

const scrollToSectionById = (id: string = "") => {
  if (!id) return;
  const sectionElem = document.querySelector(id);
  sectionElem?.scrollIntoView();
};

const SectionList = () => {
  const {
    history,
    isEditable,
    pageSections,
    setPageSections,
    activePage,
  }: any = useContext(CTX);
  const [pageTitle, setPageTitle] = useState<string>("");

  useEffect(() => {
    if (activePage) {
      const htmlObject = document.createElement("div");
      htmlObject.innerHTML = activePage.content;
      const pageTitle = htmlObject.querySelector("h1")?.textContent ?? "";
      setPageTitle(pageTitle);
      setPageSections(
        Array.from(htmlObject.querySelectorAll("h1, section[title]"))
      );
      setTimeout(() => {
        navHighlighter();
        scrollToSectionById(history.location.hash);
      }, 100);
    } else {
      setPageSections([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, isEditable, setPageSections]);

  useEffect(() => {
    const elem: any = document.querySelector("#api-docs");
    elem && elem.addEventListener("scroll", navHighlighter);
    return () => {
      const elem: any = document.querySelector("#api-docs");
      elem && elem.removeEventListener("scroll", navHighlighter);
    };
  }, []);

  return !isEditable && activePage ? (
    <List
      dense
      disablePadding
      id="navigation"
      className="navigation"
      style={{
        borderLeft: "1px solid rgba(0,0,0,0.125)",
        width: "100%",
      }}
    >
      <Link
        href="#api-docs"
        sx={{
          display: "block",
          color: "#333",
          textDecoration: "none",
        }}
      >
        <ListItem disablePadding className="section-title-item">
          <ListItemButton>
            <ListItemText
              disableTypography
              primary={pageTitle}
              sx={{
                fontWeight: 700,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            />
          </ListItemButton>
        </ListItem>
      </Link>

      {pageSections &&
        pageSections.length !== 0 &&
        pageSections.map(
          (section: any) =>
            section.title && (
              <Link
                key={section.id}
                href={"#" + section.id}
                sx={{
                  display: "block",
                  color: "#333",
                  textDecoration: "none",
                }}
              >
                <ListItem disablePadding className="section-title-item">
                  <ListItemButton>
                    <ListItemText
                      disableTypography
                      primary={section.title}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            )
        )}
    </List>
  ) : null;
};

export default SectionList;
