import { ItemDiv, AuthorAvatar, AuthorNameDiv } from "./StyledComponents";

interface Props {
  author: string;
  onClick?: () => void;
}

const AuthorItem = (props: Props) => {
  const { author, onClick } = props;
  return (
    <ItemDiv onClick={onClick}>
      <AuthorAvatar
        src={
          "https://www.seekpng.com/png/detail/110-1100707_person-avatar-placeholder.png"
        }
        alt=" "
      />
      <AuthorNameDiv>{author}</AuthorNameDiv>
    </ItemDiv>
  );
};

export default AuthorItem;
