import { useState, useEffect, useContext } from "react";
import config from "./config.json";
import { CTX } from "./Context";
import { useQuery } from "@apollo/client";

import { Grid, Box, Paper, Chip, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";

import GET_NEWS_RECOMMENDATIONS from "../../queries/GET_NEWS_RECOMMENDATIONS";

const LiveNewsComponent = () => {
  const { history } = useContext(CTX);

  const [articles, setArticles] = useState<any>([]);

  const { loading, error } = useQuery(GET_NEWS_RECOMMENDATIONS, {
    variables: {
      query: {
        source: "bot",
        returnSize: 2,
        userID: "DEMO_TRENDING",
        signedIn: true,
        config: "imatrics_times_trending_config",
        returnFields: [
          "articleID",
          "headline",
          "preamble",
          "imageUrl",
          "publicationTimestamp",
          "topConcept",
          "concepts",
        ],
      },
    },
    onCompleted: (res) => {
      const data = res.getNewsRecommendations;
      const recommendations = data.recommendations;
      setArticles(recommendations);
    },
    onError: (e) => {
      console.error("err", e);
    },
  });

  useEffect(() => {
    setInterval(() => {
      $ && $(".blink").fadeIn(1000).fadeOut(2000);
    }, 3000);
  });

  const handleClick = (article: any) => {
    // dont trigger click if text is marked
    if (window?.getSelection()?.isCollapsed) {
      history.push(
        `${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${article.articleID}`
      );
    }
  };

  if (loading || error) return null;

  return (
    <Box>
      <Grid container spacing={2}>
        {articles?.length > 0 &&
          articles?.map((article: any, i: number) => (
            <Grid key={i} item xs={12}>
              <Paper
                elevation={3}
                onClick={() => handleClick(article)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  cursor: "pointer",
                }}
              >
                <Chip
                  className="blink"
                  variant="filled"
                  color="secondary"
                  size="small"
                  label="LIVE"
                  icon={<Circle />}
                  sx={{ mr: 2, fontSize: "1.2rem", fontWeight: 700 }}
                />
                <Typography
                  component="span"
                  sx={{
                    fontFamily: "Crete Round",
                    fontSize: "1.2rem",
                    fontWeight: 700,
                  }}
                >
                  {article?.topConcept?.title && (
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "inherit",
                        fontSize: "inherit",
                        color: "#EF6F6C",
                        mr: 1,
                      }}
                    >
                      {article.topConcept.title.toUpperCase() + ":"}
                    </Typography>
                  )}
                  {article.headline}
                </Typography>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default LiveNewsComponent;
