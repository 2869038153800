import { Link } from "react-router-dom";
import { Grid, Box, Typography, List, IconButton } from "@mui/material";
import { LinkedIn, Facebook, Twitter, Instagram } from "@mui/icons-material";

import Logo from "../../images/logo/Logo.png";

interface IOption {
  title: string;
  icon: any;
  url: string;
}

const aboutItems = [
  { title: "The iMatrics Company", url: "" },
  { title: "Privacy Policy", url: "" },
  { title: "Terms of Sale", url: "" },
  { title: "Subscriptions", url: "" },
];

const helpItems = [
  { title: "FAQ", url: "" },
  { title: "Contact Us", url: "" },
  { title: "Feedback", url: "" },
];

const followUsItems: Array<IOption> = [
  {
    title: "LinkedIn",
    icon: <LinkedIn fontSize="large" color="primary" />,
    url: "https://www.linkedin.com/company/imatrics",
  },
  {
    title: "Facebook",
    icon: <Facebook fontSize="large" color="primary" />,
    url: "https://www.facebook.com/iMatrics",
  },
  {
    title: "Twitter",
    icon: <Twitter fontSize="large" color="primary" />,
    url: "https://twitter.com/imatricsab",
  },
  {
    title: "Instagram",
    icon: <Instagram fontSize="large" color="primary" />,
    url: "https://www.instagram.com/imatrics",
  },
];

const FooterComponent = () => {
  return (
    <Grid
      container
      spacing={0}
      padding={2}
      mt={4}
      color="#fff"
      bgcolor="#0B2545"
    >
      <Grid item xs={4}>
        <Link to="/imatrics-times/">
          <Box
            sx={{
              display: "inline-flex flex",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                opacity: 0.8,
              },
            }}
          >
            <img alt="" src={Logo} width="50px" height="auto" />
            <Typography
              variant="h6"
              noWrap
              sx={{
                display: { xs: "none", md: "flex" },
                ml: 1,
                color: "#fff",
                fontFamily: "Crete Round",
                fontWeight: 700,
                textDecoration: "none",
              }}
            >
              The iMatrics Times
            </Typography>
          </Box>
        </Link>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Let our crazy passion for metadata and publishing help you reach your
          digital goals.
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          noWrap
          sx={{
            mb: 1,
            display: { xs: "none", md: "flex" },
            color: "#fff",
            fontFamily: "inherit",
            fontWeight: 700,
          }}
        >
          About
        </Typography>
        <List>
          {aboutItems.map((item, i) => (
            <Box
              mb={0.5}
              sx={{
                "&:hover": {
                  opacity: 0.8,
                },
                cursor: "pointer",
              }}
              key={i}
            >
              <Link to={item.url}>{item.title}</Link>
            </Box>
          ))}
        </List>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="h6"
          mb={1}
          noWrap
          sx={{
            display: { xs: "none", md: "flex" },
            color: "#fff",
            fontFamily: "inherit",
            fontWeight: 700,
          }}
        >
          Help
        </Typography>
        <List>
          {helpItems.map((item, i) => (
            <Box
              mb={0.5}
              sx={{
                "&:hover": {
                  opacity: 0.8,
                },
                cursor: "pointer",
              }}
              key={i}
            >
              <Link to={item.url}>{item.title}</Link>
            </Box>
          ))}
        </List>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="h6"
          mb={1}
          noWrap
          sx={{
            mb: 1,
            display: { xs: "none", md: "flex" },
            color: "#fff",
            fontFamily: "inherit",
            fontWeight: 700,
          }}
        >
          Follow us
        </Typography>
        <Grid container spacing={2}>
          {followUsItems.map((item: IOption) => (
            <Grid item xs={12} md={6} key={item.title}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <IconButton
                  sx={{
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                      opacity: 0.8,
                    },
                  }}
                >
                  {item.icon}
                </IconButton>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterComponent;
