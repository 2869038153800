import { useState, useContext } from "react";
import { CTX } from "../../utils/ContextStore";

import { Box, Grid } from "@mui/material";

import ArticleResultList from "./ArticleResultList";
import ArticleContextComponent from "./ArticleContextComponent";
import StrategyComponent from "./StrategyComponent";
import UserComponent from "./ProfileComponent";
import DatasetPicker from "../PanelComponents/DatasetPicker";
import ArticleModal from "./ArticleModal";

const PersonalizationComponent = () => {
  const { activeEndpoint }: any = useContext(CTX);

  const [selectedUserProfile, setSelectedUserProfile] = useState<any | null>(
    null
  );
  const [selectedArticle, setSelectedArticle] = useState<any | null>(null);
  const [selectedStrategy, setSelectedStrategy] = useState<string | null>(null);
  const [articleAsContext, setArticleAsContext] = useState<boolean>(false);
  const [modalArticle, setModalArticle] = useState<any>(null);

  const onClose = () => {
    setModalArticle(null);
  };

  const onUsernameChange = (userProfile: any | null) => {
    setSelectedUserProfile(userProfile);
  };
  const onStrategyChange = (strategy: string) => {
    setSelectedStrategy(strategy);
  };
  const onArticleContextChange = (option: boolean) => {
    setArticleAsContext(option);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          p: 1,
          backgroundColor: "rgba(255,255,255,0.9)",
          borderBottom: "1px solid rgba(0,0,0,0.125)",
          zIndex: 1,
        }}
      >
        <DatasetPicker />
      </Box>

      <ArticleModal
        article={modalArticle}
        open={modalArticle ? true : false}
        onClose={onClose}
      />

      <Box sx={{ p: 2, height: "100%" }}>
        <Grid container item spacing={2}>
          <Grid
            item
            gap={2}
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} style={{ flex: 0 }}>
              <ArticleContextComponent
                user={selectedUserProfile}
                strategy={selectedStrategy}
                selectedArticle={selectedArticle}
                setSelectedArticle={setSelectedArticle}
                articleAsContext={articleAsContext}
                onChange={onArticleContextChange}
                setModalArticle={setModalArticle}
              />
            </Grid>

            <Grid
              container
              item
              spacing={2}
              style={{
                flex: 1,
              }}
            >
              <Grid item xs={12} md={6}>
                <UserComponent
                  endpoint={activeEndpoint}
                  userProfile={selectedUserProfile}
                  onChange={onUsernameChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StrategyComponent
                  endpoint={activeEndpoint}
                  selected={selectedStrategy}
                  onChange={onStrategyChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <ArticleResultList
              endpoint={activeEndpoint}
              user={selectedUserProfile}
              strategy={selectedStrategy}
              article={!articleAsContext ? null : selectedArticle}
              setModalArticle={setModalArticle}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PersonalizationComponent;
