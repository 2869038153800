import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
  LinearProgress,
  Alert,
  Divider,
  IconButton,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import {
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
  Close,
} from "@mui/icons-material";

import SEARCH_ARTICLE from "../../queries/searchArticleQuery";

import CMConceptModal from "src/components/ConceptManagement/CMConceptModal";

const getBorderColor = (uuid: String, article: any) => {
  return article.evaluation.correct.some(
    (concept: any) => concept.uuid === uuid
  )
    ? "#56E39F"
    : article.evaluation.incorrect.some((concept: any) => concept.uuid === uuid)
    ? "#EF6F6C"
    : "#FFAD75";
};

interface IProps {
  chosenArticle: any;
  articleModalShow: boolean;
  handleArticleModalClose: () => void;
  enableClickTags?: boolean;
}

const ArticleModal = (props: IProps) => {
  const {
    chosenArticle,
    articleModalShow,
    handleArticleModalClose,
    enableClickTags,
  } = props;

  const [article, setArticle] = useState<any>(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [chosenConcept, setChosenConcept] = useState<any>({});

  const handleOnClick = (concept: any) => {
    if (!enableClickTags || concept.deleted) return;
    setChosenConcept({ ...concept });
    setEditModalShow(true);
  };

  const { error, loading, data } = useQuery(SEARCH_ARTICLE, {
    variables: {
      uuid: true,
      searchString: chosenArticle.uuid,
      returnSize: 1,
    },
  });

  useEffect(() => {
    if (data?.searchArticleQuery?.length > 0) {
      setArticle(data.searchArticleQuery[0]);
    }
  }, [data]);

  return (
    <Dialog
      open={articleModalShow}
      onClose={handleArticleModalClose}
      maxWidth="lg"
      fullWidth
    >
      <CMConceptModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        concept={chosenConcept}
        size="md"
      />

      <IconButton
        onClick={handleArticleModalClose}
        sx={{ position: "absolute", top: 16, right: 16 }}
      >
        <Close />
      </IconButton>

      {error ? (
        <Box sx={{ p: 3 }}>
          <Alert severity="error">Error fetching article</Alert>
        </Box>
      ) : loading ? (
        <LinearProgress />
      ) : (
        article && (
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3,
              width: "100%",
              rowGap: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              {article?.authors?.length > 0 && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <AvatarGroup max={4} sx={{ flexDirection: "row" }}>
                    {article.authors.map((author: any, index: number) => (
                      <Avatar key={index} alt={author}>
                        {author.charAt(0).toUpperCase()}
                      </Avatar>
                    ))}
                  </AvatarGroup>

                  <Typography variant="body2">
                    {article.authors?.join(", ")}
                  </Typography>
                </Box>
              )}

              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {(article?.dateline || article?.publicationTimestamp) && (
                  <Typography variant="overline">
                    {moment
                      .parseZone(
                        article.dateline || article.publicationTimestamp
                      )
                      .format("YYYY-MM-DD, HH:mm z")}
                  </Typography>
                )}
                <span>|</span>
                {article?.minuteRead && (
                  <Typography variant="overline">
                    {article.minuteRead} minutes read
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={{ display: "gap", rowGap: 2 }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {article.headline}
              </Typography>

              {article.preamble && (
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {article.preamble}
                </Typography>
              )}
              {article?.body?.length > 0 && (
                <Typography>
                  {article.body.map((paragraph: String, index: number) =>
                    index === 0 ? (
                      <Box component="span" key={index}>
                        {paragraph}
                      </Box>
                    ) : (
                      <Box component="span" key={index}>
                        {" " + paragraph}
                      </Box>
                    )
                  )}
                </Typography>
              )}
            </Box>

            <Box>
              {article?.concepts?.map((concept: any) => (
                <Chip
                  key={concept.uuid}
                  component="span"
                  variant="outlined"
                  label={concept.title}
                  color="primary"
                  sx={{
                    mr: 0.5,
                    mt: 0.5,
                    borderWidth: 2,
                  }}
                  icon={
                    concept.type.includes("category") ? (
                      <Description fontSize="small" />
                    ) : concept.type.includes("topic") ? (
                      <Grain fontSize="small" />
                    ) : concept.type.includes("person") ? (
                      <Person fontSize="small" />
                    ) : concept.type.includes("place") ? (
                      <LocationOn fontSize="small" />
                    ) : concept.type.includes("organisation") ? (
                      <AccountBalance fontSize="small" />
                    ) : concept.type.includes("event") ? (
                      <EventNote fontSize="small" />
                    ) : concept.type.includes("object") ? (
                      <GridView fontSize="small" />
                    ) : undefined
                  }
                  onClick={() => handleOnClick(concept)}
                />
              ))}
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2">Tag evaluation:</Typography>
              <Box sx={{ mt: 0.5 }}>
                {chosenArticle?.concepts?.map((concept: any) => (
                  <Chip
                    key={concept.uuid}
                    component="span"
                    variant="outlined"
                    sx={{
                      mr: 0.5,
                      mt: 0.5,
                      borderColor: getBorderColor(concept.uuid, chosenArticle),
                      borderWidth: 3,
                    }}
                    label={concept.title}
                    icon={
                      concept.type.includes("category") ? (
                        <Description fontSize="small" />
                      ) : concept.type.includes("topic") ? (
                        <Grain fontSize="small" />
                      ) : concept.type.includes("person") ? (
                        <Person fontSize="small" />
                      ) : concept.type.includes("place") ? (
                        <LocationOn fontSize="small" />
                      ) : concept.type.includes("organisation") ? (
                        <AccountBalance fontSize="small" />
                      ) : concept.type.includes("event") ? (
                        <EventNote fontSize="small" />
                      ) : concept.type.includes("object") ? (
                        <GridView fontSize="small" />
                      ) : undefined
                    }
                    onClick={() => handleOnClick(concept)}
                  />
                ))}
              </Box>
            </Box>
          </DialogContent>
        )
      )}

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignitems: "center",
          p: 2,
        }}
      >
        {article?.uuid && (
          <Typography variant="overline" sx={{ lineHeight: 1.5 }}>
            UUID:
            <br />
            <b>{article.uuid}</b>
          </Typography>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={handleArticleModalClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleModal;
