import gql from "graphql-tag";
const searchArticles = gql`
  mutation($searchString: String!, $returnSize: Int) {
    searchArticlesMutation(query: $searchString, returnSize: $returnSize) {
      preamble
      dateline
      concepts {
        title
        type
        uuid
      }
      body
      groupAndGroupCluster
      uuid
      headline
    }
  }
`;

export default searchArticles;
