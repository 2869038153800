import { useState, useEffect } from "react";
import "./ApiDocs.css";
import { useHistory } from "react-router-dom";
import ApiDocsContext from "./ApiDocsContext";

import { Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import HeaderComponent from "./HeaderComponent";
import ApiDocsContentComponent from "./ApiDocsContentComponent";

const ApiDocs = () => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => history.push("/"), 300);
  };

  useEffect(() => {
    if (history.location.pathname.includes("/api-docs")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      PaperProps={{
        id: "api-docs",
        sx: {
          backgroundColor: "rgb(246, 248, 249)",
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          zIndex: 2,
          color: "#fff",
          position: "absolute",
          top: "16px",
          right: "16px",
          bgcolor: "rgba(0,0,0,0.75)",
          "&:hover": {
            opacity: 0.9,
            bgcolor: "rgba(0,0,0,0.75)",
          },
        }}
      >
        <Close />
      </IconButton>

      <HeaderComponent />
      <ApiDocsContentComponent />
    </Dialog>
  );
};

const ApiDocsComponent = () => {
  return (
    <ApiDocsContext>
      <ApiDocs />
    </ApiDocsContext>
  );
};

export default ApiDocsComponent;
