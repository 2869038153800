import gql from "graphql-tag";

const getPopularArticles = gql`
  query ($query: SearchInNewspageInput) {
    getPopularArticles(query: $query) {
      result {
        uuid
        headline
        preamble
        publication_timestamp
        channels
        image
      }
      search_id
      total_result_count
    }
  }
`;

export default getPopularArticles;
