import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, Chip, CircularProgress, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OutlinedDiv from "./OutlinedDiv";

import GET_CONNECTED_CATEGORIES from "../../queries/GET_CONNECTED_CATEGORIES";

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    display: "flex",
    height: "fit-content",
    padding: theme.spacing(0.1),
    marginTop: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
  },
}));

interface ConnectedCategory {
  uuid: string;
  title: string;
  type: string;
}

interface Props {
  disabled?: boolean;
  sourceType?: string;
  concept: any;
}

const ConnectedCategoryDisplayer = (props: Props) => {
  const classes = useStyles();
  const { concept, disabled, sourceType } = props;

  const [connectedCategories, setConnectedCategories] = useState<
    [ConnectedCategory] | undefined
  >(undefined);

  const { loading } = useQuery(GET_CONNECTED_CATEGORIES, {
    variables: {
      input:{
        concept:concept,
        sourceType:sourceType,
      }
    },
    onCompleted: (data) => {
      setConnectedCategories(data?.getConnectedCategories);
    },
    onError: () => {
      setConnectedCategories(undefined);
    },
  });

  return (
    <OutlinedDiv disabled={disabled} label="Connected categories">
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
        minHeight="24px"
      >
        {loading && (
          <CircularProgress
            color="primary"
            size="1em"
            style={{ marginRight: "1em" }}
          />
        )}
        {connectedCategories?.map((category) => (
          <Chip
            color="default"
            key={category.title}
            label={
              <Box textAlign="center">
                <Box fontWeight="700">{category.title}</Box>
              </Box>
            }
            variant="outlined"
            className={classes.chip}
          />
        ))}
      </Box>
    </OutlinedDiv>
  );
};

export default ConnectedCategoryDisplayer;
