import { useState, useRef, useEffect, useContext } from "react";
import config from "./config.json";
import { useLazyQuery } from "@apollo/client";
import { Box, Typography, Paper, Skeleton } from "@mui/material";
import GET_NEWS_RECOMMENDATIONS from "../../queries/GET_NEWS_RECOMMENDATIONS";
import { CTX } from "./Context";
import ArticleImageComponent from "./ArticleImageComponent";

interface IRelatedArticle {
  uuid: string;
  headline: string;
  preamble: string;
  publicationTimestamp: string;
  imgSrc: string;
}

interface IProps {
  uuid: string;
}

const ReadMoreComponent = (props: IProps) => {
  const { history, selectedUserProfile } = useContext(CTX);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<Array<IRelatedArticle>>([]);

  const size = useRef<number>(articles.length);

  useEffect(() => {
    size.current = articles.length;
  }, [articles]);

  const [getRecommendations] = useLazyQuery(GET_NEWS_RECOMMENDATIONS, {
    onCompleted: (res) => {
      setIsLoading(false);
      const data = res.getNewsRecommendations;
      const relatedArticles = data.recommendations;
      setArticles(relatedArticles);
    },
    onError: (e) => {
      setIsLoading(false);
      console.error("err", e);
    },
  });

  const handleClick = (article: any) => {
    // dont trigger click if text is marked
    if (window?.getSelection()?.isCollapsed) {
      history.push(
        `${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${article.articleID}`
      );
    }
  };

  useEffect(() => {
    var elem = document.querySelector("#imatrics-times");
    if (elem) {
      var handleScroll = () => {
        if (
          elem &&
          elem.scrollHeight - elem.scrollTop <= elem.clientHeight * 1.3 &&
          !isLoading
        ) {
          if (!isLoading && size.current <= 30) {
            setIsLoading(true);
            getRecommendations({
              variables: {
                query: {
                  source: "bot",
                  returnSize: size.current + 3,
                  userID: selectedUserProfile.id,
                  signedIn: true,
                  config: "imatrics_times_trending_config",
                  returnFields: [
                    "articleID",
                    "headline",
                    "preamble",
                    "imageUrl",
                    "publicationTimestamp",
                    "topConcept",
                    "concepts",
                  ],
                },
              },
            });
          }
        }
      };
      elem?.addEventListener("scroll", handleScroll);
    }
    return () => {
      elem?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box my={2}>
        <Typography
          component="div"
          variant="h6"
          fontWeight="700"
          boxSizing="border-box"
          color="rgb(10, 38, 69)"
          borderTop="4px solid rgb(10, 38, 69)"
          p={2}
        >
          Read more
        </Typography>
        <Box>
          {articles.map((article: any, i: number) => (
            <Paper
              key={i}
              elevation={4}
              sx={{ mx: 2, mb: 3, overflow: "hidden", cursor: "pointer" }}
              onClick={() => handleClick(article)}
            >
              <ArticleImageComponent uuid={article.articleID} size={1200} />
              <Box sx={{ p: 3 }}>
                {article?.topConcept?.title && (
                  <Typography
                    sx={{
                      color: "#EF6F6C",
                      fontWeight: 700,
                    }}
                  >
                    {article.topConcept.title.toUpperCase()}
                  </Typography>
                )}
                <Typography
                  variant="h3"
                  sx={{ fontFamily: "Crete Round", fontWeight: 700, mb: 1 }}
                >
                  {article.headline}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Crete Round",
                    fontSize: "1.2rem",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {article.preamble}
                </Typography>
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>

      {isLoading && (
        <Typography variant="body1">
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
        </Typography>
      )}
    </Box>
  );
};

export default ReadMoreComponent;
