import { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import SEARCH_CONCEPTS from "src/mutations/searchConcepts";
import {
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
} from "@mui/icons-material";

interface IProps {
  setIncludeConcepts: (value: string[]) => void;
}

let searchTimeout: ReturnType<typeof setTimeout>;

const ConceptIncludeComponent = (props: IProps) => {
  const { setIncludeConcepts } = props;

  const [selectedConcepts, setSelectedConcepts] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const [searchConcepts, { loading, error }] = useMutation(SEARCH_CONCEPTS);

  useEffect(() => {
    searchTimeout && clearTimeout(searchTimeout);
    if (searchString.length === 0) {
      setSuggestions([]);
    }
    if (searchString.length > 1) {
      searchTimeout = setTimeout(() => {
        searchConcepts({
          variables: {
            title: searchString,
            type: "all",
            draft: false,
            size: 15,
          },
          onCompleted: (data: any) => {
            setSuggestions(data.searchConcepts.result);
          },
          onError: (err) => {
            setSuggestions([]);
          },
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    const uuids = selectedConcepts.map((item) => item.uuid);
    setIncludeConcepts(uuids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConcepts]);

  return (
    <Autocomplete
      multiple
      limitTags={2}
      loading={loading}
      loadingText={<CircularProgress color="primary" size="1em" />}
      id="search-include-concepts"
      size="small"
      options={suggestions}
      freeSolo
      noOptionsText=""
      filterOptions={(options, state) => options}
      getOptionLabel={(option) => option.title}
      inputValue={searchString}
      value={selectedConcepts}
      ListboxProps={{ sx: { p: 0 } }}
      onInputChange={(e, value) => {
        setSearchString(value);
      }}
      onChange={(e, value) => {
        if (value.length < 1) {
          setSelectedConcepts([]);
          setSuggestions([]);
          setSearchString("");
          return;
        }
        const incoming = value[value.length - 1];
        if (!incoming?.uuid) return;
        if (!selectedConcepts.some((item) => item.uuid === incoming.uuid)) {
          setSelectedConcepts(value);
          setSuggestions([]);
          setSearchString("");
        }
      }}
      renderOption={(props, option, { inputValue }) => {
        return (
          <MenuItem {...props} key={option.uuid} dense>
            <Box
              component="span"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {option.type.includes("category") ? (
                <Description fontSize="small" />
              ) : option.type.includes("topic") ? (
                <Grain fontSize="small" />
              ) : option.type.includes("person") ? (
                <Person fontSize="small" />
              ) : option.type.includes("place") ? (
                <LocationOn fontSize="small" />
              ) : option.type.includes("organisation") ? (
                <AccountBalance fontSize="small" />
              ) : option.type.includes("event") ? (
                <EventNote fontSize="small" />
              ) : option.type.includes("object") ? (
                <GridView fontSize="small" />
              ) : null}
              <Box component="span" sx={{ fontWeight: "bold", pl: 1 }}>
                {option.title}
              </Box>
              {option.shortDescription && " — " + option.shortDescription}
            </Box>
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          label="Search concepts"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          error={error ? true : false}
          onKeyDown={(event: any) => {
            if (
              event.key === "Backspace" ||
              event.key === "Delete" ||
              event.key === "ArrowLeft" ||
              event.key === "Enter"
            ) {
              event.stopPropagation();
            }
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            color="primary"
            size="small"
            variant="outlined"
            label={option.title}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
    />
  );
};

export default ConceptIncludeComponent;
