import config from "./config.json";
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { Lang, Concept, Author } from "./TypeDefs";
import { CTX } from "./Context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Span,
  Box,
  IconButton,
  CollapsibleButton,
  CollapsibleContent,
  Button,
  OutlinedButton,
  ImgIcon,
  Dropdown,
  DropdownList,
  ListItem,
  SearchWrapper,
} from "./StyledComponents";

import { FilterInput } from "./StyledComponents";
import {
  fetchAuthors,
  //fetchConcepts
} from "./api";

import SEARCH_CONCEPTS from "src/mutations/searchConcepts";

const LANG = config.LANG;

interface IconProps {
  size?: number | string;
  color?: string;
}

const SearchIcon = ({ size, color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || "22"}
    height={size || "100%"}
    viewBox="0 0 32 32"
    fill={color || "black"}
  >
    <path d="M32 30.208L21.632 19.84c4.224-5.248 3.328-12.928-1.92-17.152S6.912-.64 2.688 4.608-.64 17.536 4.608 21.76c4.48 3.584 10.88 3.584 15.232 0l10.368 10.368L32 30.208zM12.16 21.76c-5.248 0-9.6-4.352-9.6-9.6s4.352-9.6 9.6-9.6 9.6 4.352 9.6 9.6c0 5.248-4.352 9.6-9.6 9.6z"></path>
  </svg>
);

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

const daysInYear = (year: number) => {
  const date: Date = new Date(`${year}-12-31`);
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
};

interface DateSpan {
  id: number;
  label: Lang | any;
}
const dateSpans: DateSpan[] = [
  { id: 0, label: { sv: "Default", en: "Default" } },
  { id: 1, label: { sv: "Senaste dygnet", en: "Last 24 hours" } },
  { id: 2, label: { sv: "Senaste veckan", en: "Last week" } },
  { id: 3, label: { sv: "Senaste månaden", en: "Last month" } },
  { id: 4, label: { sv: "Senaste året", en: "Last year" } },
  { id: 5, label: { sv: "Anpassa", en: "Customized" } },
];

interface Labels {
  filterTitle: Lang | any;
  dateLabel: Lang | any;
  searchAuthorsLabel: Lang | any;
  searchTopicsLabel: Lang | any;
  authorLabel: Lang | any;
  topicLabel: Lang | any;
  from: Lang | any;
  to: Lang | any;
  clearFilter: Lang | any;
  applyFilter: Lang | any;
}

const labels: Labels = {
  filterTitle: { en: "Filter", sv: "Filtrera" },
  dateLabel: { en: "Date", sv: "Datum" },
  authorLabel: { en: "Author", sv: "Reporter" },
  topicLabel: { en: "Topic", sv: "Ämne" },
  searchAuthorsLabel: { en: "Search authors", sv: "Sök reporter" },
  searchTopicsLabel: { en: "Search topics", sv: "Sök ämnen" },
  from: { en: "From", sv: "Från" },
  to: { en: "To", sv: "Till" },
  clearFilter: { en: "Clear filter", sv: "Rensa filter" },
  applyFilter: { en: "Close filter", sv: "Stäng filter" },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

const FilterContent = (props: Props) => {
  const { open, onClose } = props;
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterConcepts,
    setFilterConcepts,
    filterAuthors,
    setFilterAuthors,
    openCustomize,
    setOpenCustomize,
    setSearchString,
    searchString,
  } = useContext(CTX);

  const history = useHistory();
  let searchTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );
  const newStartDate = useRef<Date | undefined>(startDate);
  const newEndDate = useRef<Date | undefined>(endDate);
  const newFilterConcepts = useRef<Array<Concept>>(filterConcepts);
  const newFilterAuthors = useRef<Array<Author>>(filterAuthors);

  const [openDateCollapse, setOpenDateCollapse] = useState<boolean>(true);
  const [openConceptCollapse, setOpenConceptCollapse] =
    useState<boolean>(false);
  const [openAuthor, setOpenAuthor] = useState<boolean>(false);

  const [conceptSearchString, setConceptSearchString] = useState<string>("");
  const [prevConceptSearchString, setPrevConceptSearchString] =
    useState<string>("");
  const [authorSearchString, setAuthorSearchString] = useState<string>("");
  const [prevAuthorSearchString, setPrevAuthorSearchString] =
    useState<string>("");

  const [conceptSuggestions, setConceptSuggestions] = useState<Concept[]>([]);
  const [authorSuggestions, setAuthorSuggestions] = useState<Concept[]>([]);

  const [showconceptSuggestions, setShowconceptSuggestions] =
    useState<boolean>(false);
  const [showAuthorSuggestions, setShowAuthorSuggestions] =
    useState<boolean>(false);

  const [searchConceptsMutation] = useMutation(SEARCH_CONCEPTS);

  const CustomizedDateInput = forwardRef(({ value, onClick }: any, ref) => (
    <OutlinedButton
      minHeight="40px"
      minWidth="143px"
      px={2}
      onClick={onClick}
      ref={ref}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexGrow={1}>{value}</Box>
        <Box> &#128197;</Box>
      </Box>
    </OutlinedButton>
  ));

  const handleOnChangeDate = (e: React.ChangeEvent<HTMLFormElement>) => {
    switch (e.target.value) {
      case "0":
        newStartDate.current = undefined;
        newEndDate.current = undefined;
        setStartDate(newStartDate.current);
        setEndDate(newEndDate.current);
        handleSubmitFilter("date", true);
        break;
      case "1":
        newStartDate.current = moment().subtract(1, "days").toDate();
        newEndDate.current = moment().toDate();
        setStartDate(newStartDate.current);
        setEndDate(newEndDate.current);
        handleSubmitFilter("date", true);
        break;
      case "2":
        newStartDate.current = moment().subtract(1, "weeks").toDate();
        newEndDate.current = moment().toDate();
        setStartDate(newStartDate.current);
        setEndDate(newEndDate.current);
        handleSubmitFilter("date", true);
        break;
      case "3":
        newStartDate.current = moment().subtract(1, "months").toDate();
        newEndDate.current = moment().toDate();
        setStartDate(newStartDate.current);
        setEndDate(newEndDate.current);
        handleSubmitFilter("date", true);
        break;
      case "4":
        newStartDate.current = moment().subtract(1, "years").toDate();
        newEndDate.current = moment().toDate();
        setStartDate(newStartDate.current);
        setEndDate(newEndDate.current);
        handleSubmitFilter("date", true);
        break;
      case "5":
        setOpenCustomize(true);
        break;
      default:
    }
    if (e.target.value !== "5") {
      setOpenCustomize(false);
    }
  };

  const clearDateCheckbox = () => {
    let options: any = document.getElementsByName("date-filter");
    for (let i = 0; i < options.length; i++) {
      if (i === 0) {
        options[i].checked = true;
        continue;
      }
      options[i].checked = false;
    }
  };

  const handleClearFilter = () => {
    let params: URLSearchParams = new URLSearchParams(history.location.search);
    clearDateCheckbox();
    setStartDate(undefined);
    setEndDate(undefined);
    setFilterConcepts([]);
    setFilterAuthors([]);
    setConceptSearchString("");
    setAuthorSearchString("");
    setConceptSuggestions([]);
    setAuthorSuggestions([]);
    setShowconceptSuggestions(false);
    setShowAuthorSuggestions(false);
    params.delete("d");
    params.delete("f");

    let searchTerm: string =
      $("#im-search-input")?.val()?.toString() ||
      searchString ||
      params.get("q") ||
      "";
    setSearchString(decodeURI(searchTerm));
    history.push(`${history.location.pathname}?${params.toString()}`);
    //onClose();
  };

  const handleSubmitFilter = (
    type?: "date" | "concept" | "author",
    persistModal?: boolean
  ) => {
    if (type === "date") {
      newFilterConcepts.current = filterConcepts;
      newFilterAuthors.current = filterAuthors;
    } else if (type === "concept") {
      newStartDate.current = startDate;
      newEndDate.current = endDate;
      newFilterAuthors.current = filterAuthors;
    } else if (type === "author") {
      newStartDate.current = startDate;
      newEndDate.current = endDate;
      newFilterConcepts.current = filterConcepts;
    } else {
      newStartDate.current = startDate;
      newEndDate.current = endDate;
      newFilterConcepts.current = filterConcepts;
      newFilterAuthors.current = filterAuthors;
    }

    const params: URLSearchParams = new URLSearchParams(
      history.location.search
    );
    params.delete("d");
    params.delete("f");

    if (newStartDate?.current && newEndDate?.current) {
      params.set(
        "d",
        `${moment(newStartDate.current).format("YYYY-MM-DD")},${moment(
          newEndDate.current
        ).format("YYYY-MM-DD")}`
      );
    }
    const filterUuid: string[] = [];

    newFilterAuthors.current?.forEach((author: Author) =>
      filterUuid.push(`a_${author.uuid}`)
    );

    newFilterConcepts.current?.forEach((concept: Concept) =>
      filterUuid.push(
        concept.type === "category" || concept.type === "topic"
          ? `c_${concept.uuid}`
          : `e_${concept.uuid}`
      )
    );

    filterUuid.length > 0 && params.set("f", filterUuid.toString());
    history.push(`${history.location.pathname}?${params.toString()}`);

    !persistModal && onClose();
  };

  useEffect(() => {
    searchTimeout?.current && clearTimeout(searchTimeout.current);
    const ac = new AbortController();
    if (!authorSearchString) setAuthorSuggestions([]);
    else if (authorSearchString !== prevAuthorSearchString)
      searchTimeout.current = setTimeout(async () => {
        setPrevAuthorSearchString(authorSearchString);
        const authors = await fetchAuthors(ac, authorSearchString);
        setAuthorSuggestions(authors.result);
      }, 400);

    //return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorSearchString]);

  useEffect(() => {
    searchTimeout?.current && clearTimeout(searchTimeout.current);
    if (!conceptSearchString) setConceptSuggestions([]);
    else if (conceptSearchString !== prevConceptSearchString)
      searchTimeout.current = setTimeout(async () => {
        setPrevConceptSearchString(conceptSearchString);
        //const tags = await fetchConcepts(conceptSearchString);

        const reqs = [
          searchConceptsMutation({
            variables: {
              title: conceptSearchString,
              type: "topic",
              size: 5,
            },
          }),
          searchConceptsMutation({
            variables: {
              title: conceptSearchString,
              type: "entity",
              size: 5,
            },
          }),
        ];
        const data = await Promise.all(reqs);
        const topics = data[0].data.searchConcepts.result;
        const entities = data[1].data.searchConcepts.result;
        setConceptSuggestions([...topics, ...entities]);
      }, 400);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptSearchString]);

  useEffect(() => {
    const options: any = document.getElementsByName("date-filter");
    if (!startDate && !endDate && options && options[0]) {
      options[0].checked = true;
    }
    if (startDate && endDate) {
      const now = new Date();
      const diff = Math.floor(
        (Date.parse(endDate) - Date.parse(startDate)) / 86400000
      );
      if (diff === 1) {
        options[1].checked = true;
      } else if (diff === 7) {
        options[2].checked = true;
      } else if (
        diff === daysInMonth(endDate.getMonth(), endDate.getFullYear())
      ) {
        options[3].checked = true;
      } else if (diff === daysInYear(now.getFullYear())) {
        options[4].checked = true;
      } else {
        options[5].checked = true;
        setOpenCustomize(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (filterConcepts?.length > 0) {
      setOpenConceptCollapse(true);
    }
    if (filterAuthors?.length > 0) {
      setOpenAuthor(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Box p={2} height="100%" overflowY="auto">
      <Box position="relative">
        <Box>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => setOpenDateCollapse(!openDateCollapse)}
          >
            <CollapsibleButton>
              {openDateCollapse ? (
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/metro/24/minus.png"
                  height="18px"
                />
              ) : (
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/metro/24/plus.png"
                  height="18px"
                />
              )}
              <Span ml={1}>{labels.dateLabel[LANG]}</Span>
            </CollapsibleButton>
          </Box>
          <CollapsibleContent open={openDateCollapse}>
            <Box m={2}>
              <form onChange={handleOnChangeDate}>
                {dateSpans.map((dateSpan: DateSpan, i: number) => (
                  <Box key={i} pb={1}>
                    <Span mr={2}>
                      <input
                        type="radio"
                        value={dateSpan.id}
                        name="date-filter"
                        style={{ cursor: "pointer" }}
                      />
                    </Span>
                    <Span>{dateSpan.label[LANG]}</Span>
                  </Box>
                ))}
              </form>
              <CollapsibleContent open={openCustomize}>
                <Box py={2} display="flex" alignItems="center">
                  <Box>
                    {labels.from[LANG]}
                    <DatePicker
                      selected={startDate}
                      customInput={<CustomizedDateInput />}
                      onChange={(date: Date) => {
                        newStartDate.current = date;
                        setStartDate(newStartDate.current);
                        if (newStartDate.current && newEndDate.current) {
                          handleSubmitFilter("date", true);
                        }
                      }}
                      dateFormat="MMM d, yyyy"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                          },
                        },
                      ]}
                    />
                  </Box>
                  <Box ml={2} mr={2} pt={3}>
                    -
                  </Box>
                  <Box>
                    {labels.to[LANG]}
                    <DatePicker
                      selected={endDate}
                      customInput={<CustomizedDateInput />}
                      onChange={(date: Date) => {
                        newEndDate.current = date;
                        setEndDate(newEndDate.current);
                        if (newStartDate.current && newEndDate.current) {
                          handleSubmitFilter("date", true);
                        }
                      }}
                      dateFormat="MMM d, yyyy"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                          },
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </CollapsibleContent>
            </Box>
          </CollapsibleContent>
        </Box>

        <Box mb={2}>
          <Box onClick={() => setOpenConceptCollapse(!openConceptCollapse)}>
            <CollapsibleButton>
              {openConceptCollapse ? (
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/metro/24/minus.png"
                  height="18px"
                />
              ) : (
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/metro/24/plus.png"
                  height="18px"
                />
              )}
              <Span ml={1}>{labels.topicLabel[LANG]}</Span>
            </CollapsibleButton>
          </Box>
          <CollapsibleContent open={openConceptCollapse}>
            <Box mb={3}>
              <SearchWrapper>
                <SearchIcon size={18} />
                <FilterInput
                  id="search-topic-input"
                  autoComplete="off"
                  value={conceptSearchString}
                  placeholder={labels.searchTopicsLabel[LANG]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConceptSearchString(e.target.value);
                  }}
                  onClear={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    setConceptSearchString("");
                    document.getElementById("search-topic-input")?.focus();
                  }}
                  onFocus={() => setShowconceptSuggestions(true)}
                  onBlur={() =>
                    setTimeout(() => setShowconceptSuggestions(false), 100)
                  }
                />
                {conceptSearchString && (
                  <IconButton
                    type="button"
                    onClick={() => setConceptSearchString("")}
                  >
                    &#10006;
                  </IconButton>
                )}
              </SearchWrapper>
              {showconceptSuggestions && conceptSuggestions?.length > 0 && (
                <Box position="relative" zIndex={2}>
                  <Dropdown>
                    <DropdownList
                      maxHeight="236px"
                      overflow="auto"
                      border="1px solid rgba(0, 0, 0, 0.125)"
                    >
                      {conceptSuggestions.map((concept: Concept) => (
                        <ListItem
                          key={concept.uuid}
                          bgColor="#000"
                          onMouseDown={() => {
                            if (
                              !filterConcepts.some(
                                (targetConcept: Concept) =>
                                  targetConcept.uuid === concept.uuid
                              )
                            ) {
                              newFilterConcepts.current = [
                                ...filterConcepts,
                                concept,
                              ];
                              setFilterConcepts(newFilterConcepts.current);
                              handleSubmitFilter("concept", true);
                            }
                            setShowconceptSuggestions(false);
                            setConceptSearchString("");
                            setConceptSuggestions([]);
                          }}
                        >
                          <ImgIcon
                            mr={2}
                            noCursor
                            alt=" "
                            height="14"
                            src="https://img.icons8.com/ios/14/000000/price-tag.png"
                          />
                          <Span>{concept.title}</Span>
                        </ListItem>
                      ))}
                    </DropdownList>
                  </Dropdown>
                </Box>
              )}
            </Box>
          </CollapsibleContent>
        </Box>

        <Box mb={2}>
          <Box onClick={() => setOpenAuthor(!openAuthor)}>
            <CollapsibleButton>
              {openAuthor ? (
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/metro/24/minus.png"
                  height="18px"
                />
              ) : (
                <ImgIcon
                  alt=""
                  src="https://img.icons8.com/metro/24/plus.png"
                  height="18px"
                />
              )}
              <Span ml={1}>{labels.authorLabel[LANG]}</Span>
            </CollapsibleButton>
          </Box>

          <CollapsibleContent open={openAuthor}>
            <Box mb={3}>
              <SearchWrapper>
                <SearchIcon size={18} />
                <FilterInput
                  id="search-author-input"
                  autoComplete="off"
                  value={authorSearchString}
                  placeholder={labels.searchAuthorsLabel[LANG]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAuthorSearchString(e.target.value);
                  }}
                  onClear={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    setAuthorSearchString("");
                    document.getElementById("search-author-input")?.focus();
                  }}
                  onFocus={() => setShowAuthorSuggestions(true)}
                  onBlur={() =>
                    setTimeout(() => setShowAuthorSuggestions(false), 100)
                  }
                />
                {authorSearchString && (
                  <IconButton
                    type="button"
                    onClick={() => setAuthorSearchString("")}
                  >
                    &#10006;
                  </IconButton>
                )}
              </SearchWrapper>
              {showAuthorSuggestions && authorSuggestions?.length > 0 && (
                <Box mb={2}>
                  <Box position="relative">
                    <Dropdown pb={2}>
                      <DropdownList
                        maxHeight="236px"
                        overflow="auto"
                        border="1px solid rgba(0, 0, 0, 0.125)"
                      >
                        {authorSuggestions.map((author: Author) => (
                          <ListItem
                            key={author.uuid}
                            onMouseDown={() => {
                              if (
                                !filterAuthors.some(
                                  (author2: Author) =>
                                    author2.uuid === author.uuid
                                )
                              ) {
                                newFilterAuthors.current = [
                                  ...filterAuthors,
                                  author,
                                ];
                                setFilterAuthors(newFilterAuthors.current);
                                handleSubmitFilter("author", true);
                              }
                              setShowAuthorSuggestions(false);
                              setAuthorSearchString("");
                              setAuthorSuggestions([]);
                            }}
                          >
                            <ImgIcon
                              mr={1}
                              noCursor
                              alt=" "
                              height="18"
                              src="https://img.icons8.com/pastel-glyph/18/000000/person-male--v1.png"
                            />
                            {author.title}
                          </ListItem>
                        ))}
                      </DropdownList>
                    </Dropdown>
                  </Box>
                </Box>
              )}
            </Box>
          </CollapsibleContent>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          mr={2}
          width="100%"
          color="#fff"
          backgroundColor="rgba(77, 92, 105, 1)"
          onClick={() => handleClearFilter()}
        >
          {labels.clearFilter[LANG]}
        </Button>
        <Button
          ml={2}
          width="100%"
          color="#fff"
          backgroundColor="rgba(89, 107, 191, 1)"
          onClick={() => handleSubmitFilter()}
        >
          {labels.applyFilter[LANG]}
        </Button>
      </Box>
    </Box>
  );
};

export default FilterContent;
