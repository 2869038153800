import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import HeaderMenuComponent from "./HeaderMenuComponent";
import Logo from "../../images/logo/Logo.png";

const HeaderComponent = () => {
  return (
    <Grid container mb={2} sx={{ zIndex: 1 }}>
      <Grid item xs={12}>
        <Box sx={{ padding: 2, bgcolor: "#0B2545" }}>
          <Link to="/imatrics-times/">
            <Box
              sx={{
                display: "inline-flex flex",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <img alt="" src={Logo} width="75px" height="auto" />
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  color: "#fff",
                  fontSize: "32px",
                  fontFamily: "Crete Round",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                The iMatrics Times
              </Typography>
            </Box>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <HeaderMenuComponent />
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
