import { useState, useRef, useEffect, useContext } from "react";
import { CTX } from "./Context_TPM";
import moment from "moment";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Typography,
  TextField,
  Tooltip,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Add, CheckBox, Search, Cancel } from "@mui/icons-material";

const TopicPageListComponent = () => {
  const {
    loading,
    error,
    selectedTopicPage,
    setSelectedTopicPage,
    topicPageList,
    tagConditionOptions,
    setIsCreating,
    snackBar,
    setSnackbar,
  } = useContext(CTX);

  const [searchString, setSearchString] = useState("");
  const [searchedTopicPages, setSearchedTopicPages] = useState(topicPageList);
  const timeout: any = useRef(null);

  const handleOnClick = (topicPage: any) => {
    if (topicPage?.title === selectedTopicPage?.title) {
      setSelectedTopicPage(null);
    } else {
      setSelectedTopicPage(topicPage);
    }
    setIsCreating(false);
  };

  const handleCreateNew = () => {
    setSelectedTopicPage({
      title: "",
      description: "",
      imgUrl: "",
      published: false,
      tags: [],
      tagCondition:
        tagConditionOptions?.length > 0 ? tagConditionOptions[0] : "",
      slug: "",
      url: "",
      createdTimestamp: moment().format("DD/MM/YYYY"),
      createdBy: "iMatrics",
      lastEditedTimestamp: moment().format("DD/MM/YYYY"),
      lastEditedBy: "iMatrics",
    });
    setIsCreating(true);
  };

  useEffect(() => {
    clearTimeout(timeout?.current);
    if (!searchString) {
      setSearchedTopicPages(topicPageList);
      return;
    }
    timeout.current = setTimeout(() => {
      const res = topicPageList.filter(
        (topicPage: any) =>
          topicPage.title.includes(searchString) ||
          topicPage.description.includes(searchString)
      );
      setSearchedTopicPages(res);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, topicPageList]);

  return (
    <Box>
      <Snackbar
        open={snackBar?.show}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbar(null);
        }}
      >
        <Alert severity={snackBar?.severity}>{snackBar?.message}</Alert>
      </Snackbar>

      <Box sx={{ p: 2 }}>
        <Tooltip title="Search topics" placement="top-start">
          <TextField
            name="search-topic-pages"
            autoComplete="off"
            size="small"
            fullWidth
            value={searchString}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className="mr-1" />
                </InputAdornment>
              ),
              endAdornment: searchString && (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchString("");
                    }}
                  >
                    <Cancel fontSize="medium" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
        </Tooltip>
        <Box sx={{ mt: 0.5 }}>
          <Typography
            variant="body2"
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
          >
            <CheckBox color="primary" sx={{ mr: 0.5 }} />
            <span>Show only managed</span>
          </Typography>
        </Box>
      </Box>
      <ListItem
        disablePadding
        disableGutters
        onClick={handleCreateNew}
        sx={{ borderBottom: "1px solid rgba(0,0,0,0.125)" }}
      >
        <Button fullWidth color="primary">
          <Typography
            sx={{
              fontWeight: 700,
            }}
          >
            <Add /> New topic page
          </Typography>
        </Button>
      </ListItem>
      <Box
        display="flex"
        width="100%"
        height="100%"
        minHeight="100%"
        flexDirection="column"
      >
        <Box display="flex" flex={1} overflow="auto">
          <List disablePadding sx={{ width: "100%" }}>
            {error ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pt: 4,
                }}
              >
                <Typography variant="body1" color="error">
                  Failed to fetch topic pages
                </Typography>
              </Box>
            ) : loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pt: 4,
                  overflow: "hidden",
                }}
              >
                <CircularProgress color="primary" size="2em" />
              </Box>
            ) : (
              searchedTopicPages?.length > 0 &&
              searchedTopicPages?.map((topicPage: any, i: number) => (
                <ListItem
                  disablePadding
                  disableGutters
                  key={i}
                  onClick={() => {
                    handleOnClick(topicPage);
                  }}
                  sx={{
                    backgroundColor:
                      selectedTopicPage?.title === topicPage?.title
                        ? "rgba(0,0,0,0.1)"
                        : "inherit",
                  }}
                >
                  <ListItemButton>
                    <ListItemText
                      primary={topicPage.title}
                      secondary={topicPage.description}
                      primaryTypographyProps={{
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{
                        sx: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default TopicPageListComponent;
