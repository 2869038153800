import { useRef, useEffect, useContext } from "react";
import TPMCTX from "./Context_TPM";
import { CTX } from "../../../utils/ContextStore";

import { Grid } from "@mui/material";

import TopicPageListComponent from "./TopicPageListComponent";
import TopicPageContentComponent from "./TopicPageContentComponent";

const TopicPageManagement = () => {
  const {
    activeEndpoint,
    setActiveEndpoint,
    prevEndpoint,
    setPrevEndpoint,
    updateEndpoint,
  }: any = useContext(CTX);

  const prevEP = useRef<any>({ ...prevEndpoint });

  const switchEndpoint = () => {
    updateEndpoint({
      variables: {
        url: "https://gate1001.imatrics.com",
        name: "Swedish (Gota Media)",
        active: true,
        alternativeName: "Swedish (1001)",
        settingsName: "1001",
        isDemoEnvironment: false,
      },
      onCompleted: (res: any) => {
        if (res?.setEndpoint) {
          activeEndpoint && setPrevEndpoint(activeEndpoint);
          prevEP.current = activeEndpoint;
          setActiveEndpoint(res.setEndpoint);
        } else {
          console.error("Failed to update dataset");
        }
      },
      onError: (err: Error) => {
        console.error(err.message);
      },
    });
  };

  const revertEndpoint = () => {
    if (!prevEP.current) return;
    updateEndpoint({
      variables: prevEP.current,
      onCompleted: (res: any) => {
        if (res?.setEndpoint) {
          activeEndpoint && setPrevEndpoint(activeEndpoint);
          setActiveEndpoint(res.setEndpoint);
        } else {
          console.error("Failed to update dataset");
        }
      },
      onError: (err: Error) => {
        console.error(err.message);
      },
    });
  };

  useEffect(() => {
    switchEndpoint();

    return () => {
      revertEndpoint();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TPMCTX>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={3}
          sx={{
            maxHeight: "100%",
            overflow: "auto",
            borderRight: "1px solid rgba(0,0,0,0.125)",
          }}
        >
          <TopicPageListComponent />
        </Grid>
        <Grid item xs={9} sx={{ maxHeight: "100%", overflow: "auto" }}>
          <TopicPageContentComponent />
        </Grid>
      </Grid>
    </TPMCTX>
  );
};

export default TopicPageManagement;
