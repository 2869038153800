import config from "./config.json";

const PROXY_URL: string = config.ARTICLESEARCH_PROXY_URL;
//const PROXY_URL: string = "http://localhost:4001";
const SCOPE: string = config.SCOPE.ID;
const SECTIONS_ONLY: boolean = config.SECTIONS_ONLY;
const CHANNEL: string = config.CHANNEL;
const DEFAULT_PAGE_SIZE: number = config.DEFAULT_PAGE_SIZE;

let headers = {
  "x-api-key": config.API_KEY ?? "",
  "content-type": "application/json",
};

const initCredentials = async () => {
  const {
    SecretsManagerClient,
    GetSecretValueCommand,
  } = require("@aws-sdk/client-secrets-manager");

  const AWS_SETTINGS = {
    region: "eu-central-1",
  };

  const client = new SecretsManagerClient(AWS_SETTINGS);

  let response;
  let secret;
  try {
    response = await client.send(
      new GetSecretValueCommand({
        SecretId: "articlesearch/gota",
        VersionStage: "AWSCURRENT",
      })
    );
  } catch (error) {
    response = undefined;
    throw error;
  }

  if (response?.SecretString) {
    secret = JSON.parse(response.SecretString);
    headers = {
      "x-api-key": secret.key,
      "content-type": "application/json",
    };
  }
};

!config?.API_KEY && initCredentials();

export const fetchSearch = async (
  ac: AbortController,
  freetext: string,
  page_size: number = DEFAULT_PAGE_SIZE || 20,
  offset: number = 0,
  concept_filter?: string[],
  min_pub_date?: string,
  max_pub_date?: string
) => {
  const reqs = [
    fetchArticles(
      ac,
      freetext,
      page_size,
      offset,
      concept_filter,
      min_pub_date,
      max_pub_date
    ),
    fetchAuthors(ac, freetext, page_size),
    fetchPages(ac, freetext, page_size),
  ];
  let data;
  try {
    data = await Promise.all(reqs);
  } catch {
    data = [
      {
        result: null,
      },
    ];
  }
  return data;
};

export const fetchArticles = async (
  ac: AbortController,
  freetext: string,
  page_size: number = DEFAULT_PAGE_SIZE,
  offset: number,
  concept_filter: string[] = [],
  min_pub_date: string | undefined = undefined,
  max_pub_date: string | undefined = undefined,
  channels: string[] = [CHANNEL],
  scope: string = SCOPE
) => {
  let params: any = {
    freetext,
    page_size,
    offset,
    channels,
    concept_filter,
    scope,
  };
  if (min_pub_date) {
    params = { ...params, min_pub_date };
  }
  if (max_pub_date) {
    params = { ...params, max_pub_date };
  }
 
  const data = await fetch(`${PROXY_URL}/search/`, {
    method: "POST",
    body: JSON.stringify(params),
    signal: ac.signal,
    headers: headers,
  });

  if (data.status !== 200)
    return { result: [], total_result_count: 0, search_id: "" };
  return data.json();
};

export const fetchAuthors = async (
  ac: AbortController,
  freetext: string,
  page_size: number = DEFAULT_PAGE_SIZE,
  channel: string = CHANNEL
) => {
  const params = {
    freetext,
    page_size: page_size.toString(),
    channel,
  };
  const searchParams = new URLSearchParams(params).toString();
  const data = await fetch(`${PROXY_URL}/search/authors?${searchParams}`, {
    method: "GET",
    signal: ac.signal,
    headers: headers,
  });
  if (data.status !== 200)
    return {
      result: [],
      result_count: 0,
    };
  let res = await data.json();
  res.result = res?.result?.filter(
    (author: any) => !author.title.includes("/TT")
  );
  return res;
};

export const fetchPages = async (
  ac: AbortController,
  freetext: string,
  page_size: number = DEFAULT_PAGE_SIZE,
  sections_only: boolean = SECTIONS_ONLY,
  scope: string = SCOPE
) => {
  let paramsObj = {
    freetext,
    page_size,
    sections_only,
    scope,
  };
  const data = await fetch(`${PROXY_URL}/search/pages`, {
    method: "POST",
    body: JSON.stringify(paramsObj),
    signal: ac.signal,
    headers: headers,
  });
  if (data.status !== 200) return { result: [], total_result_count: 0 };
  return data.json();
};

export const fetchAutoSuggestions = async (
  freetext: string,
  page_size: number = 10
) => {
  const data = await fetch(`${PROXY_URL}/search/autosuggest/`, {
    method: "POST",
    body: JSON.stringify({ freetext, page_size }),
  });
  return data.json();
};

export const fetchConcepts = async (freetext: string, max_size: number = 7) => {
  const reqs = [
    fetchCategoriesTopics(freetext, max_size),
    fetchEntities(freetext, max_size),
  ];
  const data = await Promise.all(reqs);
  return data;
};

export const fetchCategoriesTopics = async (
  freetext: string,
  max_size: number = 7
) => {
  const params = {
    freetext,
    max_size: max_size.toString(),
  };
  const searchParams = new URLSearchParams(params).toString();
  const data = await fetch(`${PROXY_URL}/search/concepts?${searchParams}`, {
    method: "GET",
  });
  if (data.status !== 200) return [];
  const arr = await data.json();
  const seen = new Set();
  const filteredArr = arr.filter((el: any) => {
    const duplicate = seen.has(el.title);
    seen.add(el.title);
    return !duplicate;
  });
  return filteredArr;
};

export const fetchEntities = async (freetext: string, max_size: number = 7) => {
  const params = {
    freetext,
    max_size: max_size.toString(),
  };
  const searchParams = new URLSearchParams(params).toString();
  const data = await fetch(`${PROXY_URL}/search/entities?${searchParams}`, {
    method: "GET",
  });
  if (data.status !== 200) return [];
  return data.json();
};

export const fetchAuthorByUuid = async (id: string) => {
  const data = await fetch(`${PROXY_URL}/search/authors/${id}`, {
    method: "GET",
  });
  return data.json();
};

export const fetchEntityByUuid = async (id: string) => {
  const data = await fetch(`${PROXY_URL}/search/entities/${id}`, {
    method: "GET",
  });
  return data.json();
};

export const fetchConceptByUuid = async (id: string) => {
  const data = await fetch(`${PROXY_URL}/search/concepts/${id}`, {
    method: "GET",
  });
  return data.json();
};
