import { useState, useEffect, useContext } from "react";
import { CTX } from "../CMContext";
import { CTX as MainCTX } from "../../../utils/ContextStore";
import { Box, Grid } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import ConceptList from "./ConceptList";
import DetailView from "./DetailView";
import Panel from "./Panel";
import bg from "../../../images/bgs/aurora-bg.jpg";

import CMConceptModal from "../CMConceptModal";
import HistoryLogModal from "./HistoryLogModal";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      overflow: "hidden",
    },
    bg: {
      height: "100%",
      backgroundImage: `url(${bg})`,
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column",
    },
    container: {
      height: "calc(100% - 55.42px)",
    },
    maxHeight100: {
      maxHeight: "100%",
    },
  })
);

const ConceptSuggestions = () => {
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const {
    changedConcept,
    selectedSuggestion,
    showConceptModal,
    setShowConceptModal,
    showCreateConceptModal,
    setShowCreateConceptModal,
    showHistoryLog,
    setSelectedSuggestion,
    pageNumber,
    setPageNumber,
  }: any = useContext(CTX);

  const {
    forceUpdateListener,
    activeEndpoint,
    prevEndpoint,
    setPrevEndpoint,
  }: any = useContext(MainCTX);

  useEffect(() => {
    setHasLoaded(true);
    setSelectedSuggestion(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedSuggestion(null);
    if (activeEndpoint?.name !== prevEndpoint?.name) {
      pageNumber !== 0 && setPageNumber(0);
      setPrevEndpoint(activeEndpoint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdateListener]);

  return hasLoaded ? (
    <Box className={`${classes.root} ${classes.bg} w3-animate-opacity`}>
      <Panel />

      <CMConceptModal
        show={showConceptModal}
        onHide={() => setShowConceptModal(false)}
        concept={changedConcept}
        isConceptChangeSuggestion
      />

      <CMConceptModal
        show={showCreateConceptModal}
        onHide={() => setShowCreateConceptModal(false)}
        concept={selectedSuggestion?.concept}
        createConcept
        isConceptChangeSuggestion
      />

      {showHistoryLog && <HistoryLogModal />}
      <Grid
        container
        className={classes.container}
        key={forceUpdateListener || 0}
      >
        <Grid item xs={3} className={classes.maxHeight100}>
          <ConceptList />
        </Grid>
        <Grid item xs={9} className={classes.maxHeight100}>
          <DetailView />
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default ConceptSuggestions;
