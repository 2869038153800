import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomPalette {
    neutral?: {
      main: string;
      contrastText?: string;
    };
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const Theme = createTheme({
  palette: {
    primary: {
      main: "#586CBF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EF6F6C",
      contrastText: "#fff",
    },
    neutral: {
      main: "#002255",
      contrastText: "#fff",
    },
    success: {
      main: "#56E39F",
      contrastText: "#fff",
    },
    error: {
      main: "#EF6F6C",
      contrastText: "#fff",
    },
    warning: {
      main: "#FFEE73",
    },
    info: {
      main: "#586CBF",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "MontSerrat",
  },
  /*
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "inherit",
        },
      },
    },
  },
  */
});

export default Theme;
