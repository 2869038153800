import { useState, useContext } from "react";
import moment from "moment";
import { CTX } from "./Context_TPM";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import TagBoxComponent from "./TagBoxComponent";
import NoImgUrl from "./no-img-url.png";

interface ITopicPageContent {
  title: string;
  description: string;
  imgUrl: string;
  published: boolean;
  tags: Array<ITag>;
  tagCondition: string;
  slug: string;
  url: string;
  createdTimestamp: string;
  createdBy: string;
  lastEditedTimestamp: string;
  lastEditedBy: string;
}

interface ITag {
  uuid: string;
  title: string;
  type: string;
}

interface IResponse {
  updateTopicPages: {
    error: boolean;
    message: string;
  };
}

const TopicPageContentComponent = () => {
  const {
    selectedTopicPage,
    setSelectedTopicPage,
    topicPageList,
    setTopicPageList,
    updateTopicPages,
    tagConditionOptions,
    isCreating,
    setIsCreating,
    setSnackbar,
  } = useContext(CTX);

  //props?
  const [imgHovered, setImgHovered] = useState<boolean>(false);

  const readURL = (input: any) => {
    if (input?.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setSelectedTopicPage({ ...selectedTopicPage, imgUrl: e.target.result });
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handlePublishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTopicPage({ ...selectedTopicPage, published: e.target.checked });
  };

  const handleSubmit = () => {
    let query: Array<ITopicPageContent> = topicPageList;
    if (
      !topicPageList?.find(
        (topic: ITopicPageContent) => topic.title === selectedTopicPage.title
      )
    ) {
      // Creating new topic page
      let newTopic = selectedTopicPage;
      if (!selectedTopicPage?.slug && selectedTopicPage?.title) {
        const topicTitle = selectedTopicPage.title;
        const generatedSlug =
          "/" +
          topicTitle
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
        newTopic = { ...selectedTopicPage, slug: generatedSlug };
        setSelectedTopicPage(newTopic);
      }
      query = [...topicPageList, newTopic];
    } else {
      // Editing existing topic page
      query = topicPageList?.map((topic: ITopicPageContent) => {
        if (topic.title === selectedTopicPage.title) return selectedTopicPage;
        else return topic;
      });
    }
    updateTopicPages({
      variables: {
        query: query,
      },
      onCompleted: (data: IResponse) => {
        if (!data.updateTopicPages.error) {
          setTopicPageList(query);
          setIsCreating(false);
          setSnackbar({
            show: true,
            message: isCreating ? "Topic page created" : "Topic page saved",
            severity: "success",
          });
        } else {
          setSnackbar({
            show: true,
            message: isCreating
              ? "Failed to delete topic page"
              : "Failed to delete topic page",
            severity: "error",
          });
        }
      },
      onError: (err: Error) => {
        setSnackbar({
          show: true,
          message: isCreating
            ? "Failed to create topic page"
            : "Failed to save topic page",
          severity: "error",
        });
      },
    });
  };

  const handleDeleteTopicPage = () => {
    const query: Array<ITopicPageContent> = topicPageList.filter(
      (topic: ITopicPageContent) => topic.title !== selectedTopicPage.title
    );
    updateTopicPages({
      variables: {
        query: query,
      },
      onCompleted: (data: IResponse) => {
        if (!data.updateTopicPages.error) {
          setTopicPageList(query);
          setSelectedTopicPage(null);
          setSnackbar({
            show: true,
            message: "Topic page deleted",
            severity: "success",
          });
        } else {
          setSnackbar({
            show: true,
            message: isCreating
              ? "Failed to delete topic page"
              : "Failed to delete topic page",
            severity: "error",
          });
        }
      },
      onError: (err: Error) => {
        setSnackbar({
          show: true,
          message: isCreating
            ? "Failed to delete topic page"
            : "Failed to delete topic page",
          severity: "error",
        });
      },
    });
  };

  if (!selectedTopicPage)
    return (
      <Grid container gap={2} sx={{ p: 4 }}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Select a topic page or{" "}
            <Box
              component="span"
              onClick={() => {
                setSelectedTopicPage({
                  title: "",
                  description: "",
                  imgUrl: "",
                  published: false,
                  tags: [],
                  slug: "",
                  url: "",
                  createdTimestamp: moment().format("DD/MM/YYYY"),
                  createdBy: "iMatrics",
                  lastEditedTimestamp: moment().format("DD/MM/YYYY"),
                  lastEditedBy: "iMatrics",
                });
                setIsCreating(true);
              }}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              create a new topic page
            </Box>
            .
          </Typography>
        </Grid>
      </Grid>
    );

  return (
    <Grid container gap={2} sx={{ p: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {isCreating ? "Create topic page" : "Edit topic page"}
      </Typography>
      <Grid item xs={12}>
        <TextField
          disabled={!isCreating}
          label="Title"
          variant="outlined"
          size="small"
          fullWidth
          value={selectedTopicPage?.title ?? ""}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSelectedTopicPage({
              ...selectedTopicPage,
              title: e.target.value,
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sx={{ mr: 2 }}>
            <Box
              sx={{
                mb: 2,

                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box component="span">
                <Typography component="span">Published</Typography>
                <Switch
                  checked={selectedTopicPage?.published ?? false}
                  onChange={handlePublishChange}
                />
              </Box>
              <Button disabled variant="contained" size="small">
                Generate pre-written description
              </Button>
            </Box>
            <TextField
              label="Page description"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              placeholder="This description will be shown at the top of the topic page. Write something descriptive and engaging to encourage users to read on."
              value={selectedTopicPage?.description ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setSelectedTopicPage({
                  ...selectedTopicPage,
                  description: e.target.value,
                });
              }}
            />

            <Box sx={{ my: 2 }}>
              <TagBoxComponent
                tags={selectedTopicPage?.tags ?? []}
                setTags={(tags: any) => {
                  setSelectedTopicPage({
                    ...selectedTopicPage,
                    tags: tags,
                  });
                }}
              />
            </Box>
          </Grid>

          <Box
            sx={{
              width: "40%",
            }}
          >
            <Box
              onMouseOver={() => {
                setImgHovered(true);
              }}
              onMouseOut={() => {
                //setImgHovered(false);
              }}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                id="upload-button"
                onClick={() => {
                  $("#file-upload")?.click();
                }}
                sx={{ cursor: "pointer" }}
              >
                <img
                  id="page-image"
                  src={selectedTopicPage?.imgUrl || `${NoImgUrl}`}
                  alt="upload"
                  width="100%"
                  style={{
                    aspectRatio: 16 / 9,
                    objectFit: "cover",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.125)",
                  }}
                />
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e: any) => {
                    readURL(e.target);
                  }}
                />
              </Box>
              {selectedTopicPage?.imgUrl && (
                <Box
                  sx={{
                    display: imgHovered ? "block" : "none",
                    overflow: "hidden",
                  }}
                >
                  <input
                    type="text"
                    value={selectedTopicPage.imgUrl}
                    style={{ width: "100%", border: "none" }}
                  />

                  <Button
                    disabled={!selectedTopicPage?.imgUrl}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setSelectedTopicPage({
                        ...selectedTopicPage,
                        imgUrl: "",
                      });
                    }}
                    sx={{ mt: 0.5 }}
                  >
                    Remove image
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField
          select
          label="Condition"
          size="small"
          value={selectedTopicPage?.tagCondition ?? tagConditionOptions[0]}
          onChange={(e) => {
            setSelectedTopicPage({
              ...selectedTopicPage,
              tagCondition: e.target.value,
            });
          }}
        >
          {tagConditionOptions.map((option: any) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          This combination generates
          <b>{` ${isCreating ? "0" : Math.floor(Math.random() * 300)} `}</b>
          active articles
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Slug"
          variant="outlined"
          size="small"
          fullWidth
          value={selectedTopicPage?.slug}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSelectedTopicPage({
              ...selectedTopicPage,
              slug: e.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="URL"
          variant="outlined"
          size="small"
          fullWidth
          value={selectedTopicPage?.url ?? ""}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setSelectedTopicPage({ ...selectedTopicPage, url: e.target.value });
          }}
        />
      </Grid>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              disabled
              fullWidth
              label="Created"
              variant="outlined"
              size="small"
              value={selectedTopicPage?.createdTimestamp ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>{selectedTopicPage?.createdBy}</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              disabled
              fullWidth
              label="Last edited"
              variant="outlined"
              size="small"
              value={selectedTopicPage?.lastEditedTimestamp ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>{selectedTopicPage?.lastEditedBy}</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        columnGap={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid rgba(0,0,0,0.125)",
          pt: 3,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDeleteTopicPage}
        >
          <Delete sx={{ mr: 1 }} /> Delete topic page
        </Button>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedTopicPage(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectedTopicPage?.title) {
                handleSubmit();
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TopicPageContentComponent;
