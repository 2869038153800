import { useState, useRef, useEffect, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.css";
import { CTX as tagCTX } from "./TagContext";
import { LinearProgress, Paper } from "@mui/material";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false, 5] }],
    //[{ header: 1 }, { header: 2 }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    //[{ font: [] }],
    ["link"],
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    //["undo", "redo"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font",
];

let isFetching: boolean = false;

const unwrapText = (text: string) => {
  text = text.replaceAll("</p>", "</p>\n");
  text = text.replaceAll("<br>", "\n");
  text = text.replaceAll("</h1>", "</h1>\n");
  text = text.replaceAll("</h2>", "</h2>\n");
  text = text.replaceAll("</h3>", "</h3>\n");
  text = text.replaceAll("</h4>", "</h4>\n");
  text = text.replaceAll(/<[^>]*>?/gm, "");
  return text;
  //return text.replaceAll(/&nbsp;/g, " ");
};

const Editor = () => {
  const {
    setCategories,
    setTopics,
    setEntities,
    setBroaderConcepts,
    broaderConceptsFallback,
    setBroaderConceptsFallback,
    analyzeArticle,
    editorText,
    setEditorText,
    setTextEditor,
    setTagError,
    taggingTimeout,

    formatTag,
    clearTags,
    setGenderData,
    tagQualityAssurance,
    isPlaying,

    tagSettings,
    articleFormat,
  }: any = useContext(tagCTX);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastTaggedText, setLastTaggedText] = useState<string>("");

  const quillRef = useRef<any>();

  const formatArticleToJSON = (text: string) => {
    let res: any = {
      headline: "",
      preamble: "",
      body: [],
      dateline: "",
      images: [
        {
          caption: "",
        },
        {
          caption: "",
        },
      ],
    };
    const html = new DOMParser().parseFromString(text, "text/html");
    const htmlContent = html.body;
    res.headline = unwrapText($(htmlContent)?.find("h1")?.text());
    res.preamble = unwrapText($(htmlContent)?.find("h2")?.text());
    res.dateline = unwrapText($(htmlContent)?.find("h3")?.text());
    //res.image. = unwrapText($(htmlContent)?.find("h2")?.text());
    res.body = $(htmlContent)
      ?.find("p")
      ?.get()
      ?.map((p: any) => unwrapText(p.innerHTML));

    return res;
  };

  useEffect(() => {
    isFetching = isLoading;
  }, [isLoading]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.getEditor().root.dataset.placeholder =
        tagQualityAssurance ? "" : "Write something awesome here :)";
    }
  }, [tagQualityAssurance]);

  useEffect(() => {
    if (quillRef.current) {
      setTextEditor(quillRef.current?.getEditor());
      quillRef.current?.getEditor().focus();
      quillRef.current.getEditor().onEditorChange = onEditorChange;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatText = (content: any) => {
    let resText = content.replaceAll(/<img[^>]*>/g, "");
    resText = resText.replaceAll("<a[^>]*>(.*?)</a>", "");
    resText = resText.replaceAll(/<\/?figure[^>]*>/g, "");
    //resText = resText.replaceAll(/<[^/>][^>]*><\/[^>]+>/gim, "");
    return resText;
  };

  const isEditorEmpty = (editor: any) => {
    if (!editor) {
      return false;
    }
    if ((editor.getContents()["ops"] || []).length !== 1) {
      return false;
    }
    return editor.getText().trim().length === 0;
  };

  const onEditorChange = (
    content: any,
    editor: any = null,
    tagDelay: number = 2000
  ) => {
    !isPlaying && clearTimeout(taggingTimeout.current);
    setEditorText(formatText(content));
    if (tagQualityAssurance) return;
    if (editor && !isPlaying && editor.getText() === lastTaggedText) {
      return;
    }
    if (isEditorEmpty(quillRef.current?.getEditor())) {
      // Editor is empty
      setLastTaggedText(formatText(content));
      clearTags();
      return;
    }

    if (!isPlaying && !tagQualityAssurance && !isEditorEmpty(editor)) {
      setTagError({
        error: false,
        message: "",
      });

      taggingTimeout.current = setTimeout(() => {
        if (isFetching) {
          return;
        }
        let settings = {
          articleFormat: undefined,
          categories: tagSettings.categories,
          topics: tagSettings.topics,
          entities: tagSettings.entities,
        };
        let articleData = unwrapText(content);
        if (articleFormat === "json") {
          articleData = JSON.stringify(formatArticleToJSON(content));
          settings = { ...tagSettings, articleFormat: articleFormat };
        }

        setIsLoading(true);

        analyzeArticle({
          variables: {
            articleText: articleData,
            settings: settings,
          },
        })
          .then((data: any) => {
            setLastTaggedText(editor?.getText());
            let categoryList: any = [];
            let topicList: any = [];
            let entityList: any = [];
            let concepts: any = [];

            concepts = data?.data?.analyzeArticle?.concepts || [];
            setGenderData(
              data?.data?.analyzeArticle?.genderData || {
                maleScore: 0,
                maleMentions: 0,
                uniqueMaleEntities: 0,
                malePeople: [],
                femaleScore: 0,
                femaleMentions: 0,
                uniqueFemaleEntities: 0,
                femalePeople: [],
                nonbinaryScore: 0,
                nonbinaryMentions: 0,
                uniqueNonbinaryEntities: 0,
                nonbinaryPeople: [],
                unknownScore: 0,
                unknownMentions: 0,
                uniqueUnknownEntities: 0,
                unknownPeople: [],
              }
            );

            if (data?.data?.analyzeArticle?.broader) {
              setBroaderConcepts(data.data.analyzeArticle.broader);
              data.data.analyzeArticle.broader.forEach((broader: any) => {
                if (
                  !concepts.some(
                    (concept: any) => concept.uuid === broader.uuid
                  )
                ) {
                  concepts.push(broader);
                }
              });
              if (broaderConceptsFallback.length > 0)
                setBroaderConceptsFallback([]);
            } else {
              setBroaderConcepts(null);
            }

            concepts.forEach((item: any) => {
              delete item.__typename;
              if (item.type.includes("category")) {
                categoryList = [...categoryList, formatTag(item)];
              } else if (item.type.includes("topic")) {
                topicList = [...topicList, formatTag(item)];
              } else {
                entityList = [...entityList, formatTag(item)];
              }
            });

            setCategories(categoryList);
            setTopics(topicList);
            setEntities(entityList);
          })
          .catch((e: Error) => {
            setTagError({
              error: true,
              message:
                e?.message + ". Contact iMatrics if this problem persists.",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, tagDelay);
    }
  };

  return (
    <Paper
      id="editor"
      elevation={0}
      sx={{
        height: "100%",
        position: "relative",
        bgcolor: "#fff",
        border: "1px solid #dcdcdd",
        borderRadius: 1,
      }}
    >
      <LinearProgress
        color="primary"
        variant={isLoading ? "indeterminate" : "determinate"}
        value={0}
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          visibility: isLoading ? "visible" : "hidden",
          zIndex: 1,
        }}
      />

      <ReactQuill
        id="demo-editor"
        ref={quillRef}
        readOnly={tagQualityAssurance || isPlaying ? true : false}
        theme="snow"
        modules={modules}
        formats={formats}
        style={{
          display: "flex",
          height: "100%",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
        }}
        value={editorText}
        onChange={(content: any, delta: any, source: any, editor: any) => {
          onEditorChange(content, editor);
        }}
      />
    </Paper>
  );
};

export default Editor;
