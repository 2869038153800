import gql from "graphql-tag";

const PUBLISH_TO_WIKIDATA = gql`
  mutation ($wikiConcept: WikidataConceptInput) {
    publishToWikidata(wikiConcept: $wikiConcept) {
      id
      message
    }
  }
`;

export default PUBLISH_TO_WIKIDATA;
