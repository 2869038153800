import { Fragment, useState, useEffect, ChangeEvent } from "react";
import "../../../App.css";
import {
  Box,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, FreeBreakfast } from "@mui/icons-material";
import ResourceModal from "./ResourceModal";

import { useCurrentHeight } from "../../../utils/helpers";

interface Column {
  id: "title" | "description";
  label: string;
  type: string;
  align?: "left";
  format?: (value: number) => string;
}

const useStyles = makeStyles({
  root: {
    display: "block",
    width: "100%",
    overflowX: "hidden",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rowContent: {
    display: "block",
    width: "100%",
    padding: "0 0.2em",
  },
  descriptionText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "0 0.6em",
  },
  fullwidthBlock: {
    display: "block",
    width: "100%",
  },
  tableBody: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  pagination: {
    width: "100%",
    borderTop: "1px solid rgba(0, 0, 0, 0.125)",
  },
  paginationLabel: { marginBottom: 0 },
});

const ResourceList = ({
  list,
  listLabel,
  listType,
  cluster,
  language,
}: any) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [chosenResource, setChosenResource] = useState({});
  const [editModalShow, setEditModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);

  const cols: Column[] = [
    {
      id: "title",
      label: listLabel,
      type: listType,
    },
  ];
  const [rows, setRows] = useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //##### RESETS PAGE TO 0 EACH NEW SEARCH #####
  useEffect(() => {
    setPage(0);
    setRows(list);
  }, [list]);

  return (
    <div>
      {editModalShow && (
        <ResourceModal
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          resource={chosenResource}
          language={language}
          cluster={cluster}
        />
      )}

      {createModalShow && (
        <ResourceModal
          show={createModalShow}
          onHide={() => setCreateModalShow(false)}
          createResource={true}
          language={language}
          cluster={cluster}
        />
      )}

      <Paper className={classes.root}>
        <TableContainer>
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow className={classes.fullwidthBlock}>
                {cols.map((column) => (
                  <TableCell key={column.id} className={classes.fullwidthBlock}>
                    <Box>
                      <Box
                        component="span"
                        display="inline-flex"
                        alignItems="center"
                      >
                        <FreeBreakfast className="title-icon" />
                        <Box
                          component="span"
                          fontSize="14.2px"
                          fontWeight="bolder"
                        >
                          {column.label}
                        </Box>
                      </Box>
                      <Tooltip title={"Create new resource"} placement="top">
                        <IconButton
                          className="float-right"
                          size="small"
                          onClick={() => {
                            setChosenResource({
                              title: "Dummy",
                              id: "FAKE_ID",
                            });
                            setCreateModalShow(true);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              className={classes.tableBody}
              sx={{ height: `${useCurrentHeight() - 261}px` }}
            >
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  return (
                    <TableRow
                      hover
                      key={`${row.id}-${row.title}`}
                      className={classes.fullwidthBlock}
                      onClick={() => {
                        setChosenResource(row);
                        setEditModalShow(true);
                      }}
                    >
                      {cols.map((column) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={column.id}>
                            <TableCell
                              className={`${classes.fullwidthBlock} interactive-cell`}
                            >
                              <Box className={classes.rowContent}>
                                <Box display="flex">
                                  <Box flexGrow={0}>
                                    <FreeBreakfast fontSize="small" />
                                  </Box>

                                  <Box
                                    flexGrow={1}
                                    className={classes.descriptionText}
                                  >
                                    {row.deleted ? (
                                      <Box
                                        component="span"
                                        className="font-weight-lighter"
                                      >
                                        <s>{value}</s>
                                      </Box>
                                    ) : (
                                      <Box
                                        component="span"
                                        className="font-weight-lighter"
                                      >
                                        {value}
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"Rows:"}
          className={classes.pagination}
          classes={{
            displayedRows: classes.paginationLabel,
            selectLabel: classes.paginationLabel,
          }}
        />
      </Paper>
    </div>
  );
};

export default ResourceList;
