import { Fragment, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CTX } from "./ApiDocsContext";

import { Box, Grid, Typography, CircularProgress } from "@mui/material";

import PageList from "./PageList";
import Editor from "./MDEditor";
import SectionList from "./SectionList";
import ManageButtonsComponent from "./ManageButtonsComponent";
import AlertModalComponent from "./AlertModalComponent";
import { IPage } from "./types";

const ApiDocsContentComponent = () => {
  const {
    hasAdminPrivs,
    isEditable,
    activePage,
    setActivePage,
    setActivePageBeforeChange,
    getApiDocs,
    docPages,
    loading,
    error,
    nextPath,
    openAlertModal,
    onSaveAlertModal,
    onDiscardAlertModal,
    onCloseAlertModal,
  }: any = useContext(CTX);

  const history = useHistory();

  useEffect(() => {
    if (docPages?.length > 0) {
      const pathname = "/" + (history.location.pathname.split("/")[2] ?? "");
      const newActivePage =
        docPages.find((page: IPage) => page.path === pathname) ?? docPages[0];
      setActivePage(newActivePage);
      if (isEditable) {
        setActivePageBeforeChange(newActivePage);
      }
    }
    return history.listen((location: Location) => {
      if (docPages.length > 0) {
        const pathname = "/" + (location.pathname.split("/")[2] ?? "");
        const newActivePage = docPages.find(
          (page: IPage) => page.path === pathname
        );
        setActivePage(newActivePage);
        if (isEditable) {
          setActivePageBeforeChange(newActivePage);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, docPages]);

  useEffect(() => {
    getApiDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Box pt={4} display="flex" justifyContent="center">
        <Typography color="secondary">Failed to fetch docs</Typography>
      </Box>
    );
  }
  if (loading) {
    return (
      <Box pt={4} display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!activePage) return null;

  return (
    <Fragment>
      <AlertModalComponent
        open={openAlertModal}
        nextPath={nextPath}
        onSave={onSaveAlertModal}
        onDiscard={onDiscardAlertModal}
        onClose={onCloseAlertModal}
      />
      <Grid container sx={{ overflow: "none" }}>
        <Grid item xs={2}>
          <PageList pages={docPages} />
        </Grid>
        <Grid item xs={8} sx={{ p: 2 }}>
          {hasAdminPrivs && (
            <Grid item xs={12}>
              <ManageButtonsComponent />
            </Grid>
          )}
          {activePage?.lastUpdated && (
            <Typography variant="overline" sx={{ ml: 1 }}>
              Last updated: {activePage.lastUpdated}
            </Typography>
          )}
          <Editor />
        </Grid>
        <Grid item xs={2} position="relative">
          <Box
            sx={{
              position: "fixed",
              overflowX: "hidden",
              overflowY: "auto",
              height: "calc(100vh - 84px)",
              width: "16.6%",
            }}
          >
            <SectionList />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ApiDocsContentComponent;
