import gql from "graphql-tag";
const searchResources = gql`
  mutation($freetext: String!, $cluster: String!, $language: String!) {
    searchResources(freetext: $freetext, cluster: $cluster, language: $language) {
      result {
        title
        id
        deleted
      }
    }
  }
`;
 
export default searchResources;
