import gql from "graphql-tag";
const deleteResource = gql`
  mutation(
    $id: String!
    $cluster: String!
    $language: String!
  ) {
    deleteResource(
      id: $id
      cluster: $cluster
      language: $language
    ) {
        id
        message
    }
  }
`;

export default deleteResource;
