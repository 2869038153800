import { Typography } from "@mui/material";

const NoFound = () => {
  return (
    <Typography
      display="flex"
      justifyContent="center"
      fontWeight={700}
      color="secondary"
      m={2}
    >
      Path not found
    </Typography>
  );
};

export default NoFound;
