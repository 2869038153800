import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, PaginationButton } from "./StyledComponents";

interface Props {
  page: number;
  pages: number;
  setPage: (newPage: number | ((prev: number) => number)) => void;
}

const Pagination = ({ page, pages, setPage }: Props) => {
  const [activePage, setActivePage] = useState<number>(page);
  const updatePaginationList = (newPage: number) => {
    if (
      (newPage < page && newPage > 2 && Number(adjacentPages[1]) >= newPage) ||
      (newPage > page &&
        newPage < pages - 1 &&
        Number(adjacentPages[adjacentPages.length - 2]) <= newPage) ||
      newPage === 1 ||
      newPage === pages
    )
      setAdjacentPages(getAdjacentPages(newPage));
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prev: number) => prev - 1);
      const newPage = page - 1;
      updatePaginationList(newPage);
    }
  };
  const handleNextPage = () => {
    if (page < pages) {
      setPage((prev: number) => prev + 1);
      const newPage = page + 1;
      updatePaginationList(newPage);
    }
  };

  const handleClickPage = (newPage: number) => {
    if (page !== newPage) {
      setPage(newPage);
      updatePaginationList(newPage);
    }
  };

  const getAdjacentPages = (page: number) => {
    const res: string[] = [];
    if (page !== 1 && page !== pages) res.push(page.toString());
    let i = 1;
    let range = 3;
    while (i < range && i < 5) {
      if (page - i > 1) {
        res.unshift((page - i).toString());
      } else {
        range++;
      }
      if (page + i < pages) {
        res.push((page + i).toString());
      } else {
        range++;
      }
      i++;
    }
    if (parseInt(res[0]) - 1 > 1) {
      res.unshift("...");
    }
    if (parseInt(res[res.length - 1]) + 1 < pages) {
      res.push("...");
    }
    return res;
  };

  const [adjacentPages, setAdjacentPages] = useState<string[]>(
    getAdjacentPages(page)
  );

  const history = useHistory();

  useEffect(() => {
    if (page !== activePage) {
      const newUrlParams = new URLSearchParams(history.location.search);
      newUrlParams.set("p", page.toString());
      history.push(`${history.location.pathname}?${newUrlParams.toString()}`);
    }
    setActivePage(page);
    setAdjacentPages(getAdjacentPages(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pages]);

  return (
    <Box my={4} display="flex" justifyContent="center" alignItems="center">
      <PaginationButton onClick={() => handlePrevPage()}>
        {"<"}
      </PaginationButton>
      <PaginationButton active={page === 1} onClick={() => handleClickPage(1)}>
        1
      </PaginationButton>

      {adjacentPages.map((pageString: string, i: number) => {
        if (pageString === "...")
          return (
            <PaginationButton
              key={pageString + i}
              style={{ cursor: "default" }}
            >
              {pageString}
            </PaginationButton>
          );
        return (
          <PaginationButton
            key={pageString}
            active={page === parseInt(pageString)}
            onClick={() => handleClickPage(Number(pageString))}
          >
            {pageString}
          </PaginationButton>
        );
      })}

      {pages > 1 && (
        <PaginationButton
          active={page === pages}
          onClick={() => handleClickPage(pages)}
        >
          {pages}
        </PaginationButton>
      )}
      <PaginationButton onClick={() => handleNextPage()}>
        {">"}
      </PaginationButton>
    </Box>
  );
};

export default Pagination;
