import { useEffect, useContext, useMemo, Fragment } from "react";
import { CTX } from "./Context";
import moment from "moment";
import {
  Grid,
  Typography,
  Skeleton,
  AvatarGroup,
  Avatar,
  Box,
  Paper,
} from "@mui/material";

import { useQuery } from "@apollo/client";

import RelatedArticlesComponent from "./RelatedArticlesComponent";
import ReadMoreComponent from "./ReadMoreComponent";

import TagBoxComponent from "./TagBoxComponent";
import ShareArticleComponent from "./ShareArticleComponent";
import AdPlaceholderComponent from "./AdPlaceholderComponent";

import GET_NEWSPAGE_ARTICLE from "../../queries/GET_NEWSPAGE_ARTICLE";
import ArticleImageComponent from "./ArticleImageComponent";

const allowedTags = [
  "category",
  "topic",
  "person",
  "place",
  "organisation",
  "organization",
  "event",
  "object",
];

const ArticlePage = () => {
  const { selectedArticle, setSelectedArticle, previewImage } = useContext(CTX);

  const filteredAuthors = useMemo(() => {
    const authors = selectedArticle.authors;
    if (!authors) return [];
    const uniqueAuthors: Array<string> = selectedArticle?.authors?.filter(
      (element: string, i: number) => {
        return selectedArticle?.authors?.indexOf(element) === i;
      }
    );
    return uniqueAuthors;
  }, [selectedArticle.authors]);

  const filteredTags = useMemo(() => {
    const tags = selectedArticle.concepts;
    if (!tags) return [];
    const filteredTags = tags.filter(
      (tag: any) =>
        tag &&
        !tag.uuid.includes("00000000") &&
        allowedTags.includes(tag.type.replace("x-im/", ""))
    );
    return filteredTags;
  }, [selectedArticle.concepts]);

  const { loading, error } = useQuery(GET_NEWSPAGE_ARTICLE, {
    variables: {
      uuid: selectedArticle.uuid,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSelectedArticle(data.getNewspageArticle);
    },
    onError: (e: Error) => {},
    skip: !selectedArticle?.uuid,
  });

  useEffect(() => {
    $("#imatrics-times")?.scrollTop(0);
  }, [selectedArticle]);

  if (error || !selectedArticle?.uuid) {
    return <Typography variant="h4">Article not found</Typography>;
  }

  if (loading) {
    return (
      <Paper
        sx={{
          flexGrow: 1,
          p: [4, 10],
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="50vh" />
        <Skeleton variant="circular" width={40} height={40} sx={{ my: 2 }} />
        <Typography variant="h2">
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="h6">
          <Skeleton width="90%" />
        </Typography>
        <Typography variant="body1">
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
          <Skeleton width="90%" />
        </Typography>
      </Paper>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          sx={{
            flexGrow: 1,
            p: [4, 10],
          }}
        >
          <ArticleImageComponent
            src={previewImage(selectedArticle.image, 1200)}
          />
          <Grid container spacing={2} my={4} alignItems="center">
            <Grid item>
              <AvatarGroup max={2} total={filteredAuthors.length}>
                {filteredAuthors.map((author: string, i: number) => (
                  <Avatar key={i} alt="" src="" />
                ))}
              </AvatarGroup>
            </Grid>
            <Grid item>
              <Box>
                {filteredAuthors.map((author: string, i: number) => (
                  <Fragment key={i}>
                    <Typography
                      component="a"
                      variant="subtitle1"
                      fontWeight={700}
                      pl={i !== 0 ? 1 : 0}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      {author}
                    </Typography>
                    {i < filteredAuthors.length - 1 && ","}
                  </Fragment>
                ))}
              </Box>
              <Typography variant="subtitle2">
                {`${moment(selectedArticle.publicationTimestamp).format(
                  "MMM Do YYYY, HH:mm"
                )} · ${selectedArticle.minuteRead} min read`}
              </Typography>
            </Grid>
          </Grid>

          <Typography
            variant="h3"
            sx={{ fontFamily: "Crete Round", fontWeight: 700, mb: 3 }}
          >
            {selectedArticle.headline}
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontFamily: "Crete Round", fontWeight: 700, mb: 4 }}
          >
            {selectedArticle.preamble}
          </Typography>

          {selectedArticle?.body?.map((text: string, i: number) => (
            <Typography
              key={i}
              variant="body1"
              sx={{ fontFamily: "Crete Round", fontSize: "1.2rem", mb: 3 }}
            >
              {text}
            </Typography>
          ))}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TagBoxComponent tags={filteredTags} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ShareArticleComponent uuid="" />
            </Grid>
          </Grid>

          <RelatedArticlesComponent uuid={selectedArticle.uuid} />
          <Box my={2}>
            <AdPlaceholderComponent numberOfAds={1} />
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper
          elevation={2}
          sx={{
            flexGrow: 1,
            p: [4, 10],
          }}
        >
          <ReadMoreComponent uuid={selectedArticle.uuid} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ArticlePage;
