import { TextField, MenuItem } from "@mui/material";
import {
  Description,
  Grain,
  PersonOutlineOutlined,
  AccountBalance,
  LocationOn,
  EventNote,
} from "@mui/icons-material";
import { TiThLargeOutline } from "react-icons/ti";

interface Props {
  label: string;
  value: string;
  onChange: any;
  dataList: any[];
  fullWidth?: boolean;
  disabled?: boolean;
  style?: any;
}

const Picker = (props: Props) => {
  const { label, value, onChange, dataList, fullWidth, disabled, style } =
    props;

  return (
    <TextField
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{ minWidth: 120 }}
      variant="outlined"
      size="small"
      style={{
        ...style,
        textAlign: "left",
      }}
      InputProps={{ style: { fontWeight: 700 } }}
      select
      label={label}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    >
      {dataList.map((data: any, index: number) => (
        <MenuItem
          key={index}
          value={data}
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          {data === "x-im/category" || data === "category" ? (
            <Description fontSize="small" className="mr-2" />
          ) : data === "x-im/topic" || data === "topic" ? (
            <Grain fontSize="small" className="mr-2" />
          ) : data === "x-im/person" || data === "person" ? (
            <PersonOutlineOutlined fontSize="small" className="mr-2" />
          ) : data === "x-im/organisation" || data === "organisation" ? (
            <AccountBalance fontSize="small" className="mr-2" />
          ) : data === "x-im/place" || data === "place" ? (
            <LocationOn fontSize="small" className="mr-2" />
          ) : data === "x-im/event" || data === "event" ? (
            <EventNote fontSize="small" className="mr-2" />
          ) : data === "x-im/object" || data === "object" ? (
            <TiThLargeOutline fontSize="medium" className="mr-2" />
          ) : null}
          <span>{data}</span>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Picker;
