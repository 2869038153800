import { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { CTX } from "../../utils/ContextStore";

import { Clear, Close, Delete, DeleteForever } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  Tooltip,
  CircularProgress,
  DialogActions,
  Alert,
} from "@mui/material";

import DELETE_USER from "src/mutations/DELETE_USER";

interface IProps {
  open: boolean;
  usernames: readonly string[];
  getUsers: () => void;
  onClose: () => void;
  onRemove?: (username: string) => void;
  onClear?: () => void;
}

const BulkDeleteModal = ({
  usernames,
  getUsers,
  onRemove,
  onClear,
  ...rest
}: IProps) => {
  const { setShowSnackbar, setSnackbarMessage, setSnackbarError }: any =
    useContext(CTX);
  const [deleteUser] = useMutation(DELETE_USER);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const handleDelete = () => {
    setIsLoading(true);

    const reqs = usernames.map((username) => {
      return deleteUser({
        variables: { username: username },
      });
    });

    Promise.allSettled(reqs)
      .then((data: any) => {
        data.forEach((res: any) => {
          if (res.status === "rejected") {
            setSnackbarMessage("Failed to delete users");
            setSnackbarError(true);
            setShowSnackbar(true);
          }
        });
        setSnackbarMessage(
          `Successfully deleted ${
            usernames.length > 1
              ? usernames.length + " users"
              : "user " + usernames[0]
          }`
        );
        setSnackbarError(false);
        setShowSnackbar(true);
        onClear && onClear();
        getUsers();
      })
      .catch((e) => {
        setSnackbarMessage(e.message);
        setSnackbarError(true);
        setShowSnackbar(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (usernames.length === 0) {
      setConfirmDelete(false);
      rest.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernames]);

  return (
    <Dialog fullWidth={true} maxWidth="md" {...rest}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <DialogTitle sx={{ p: 0 }}>Delete users</DialogTitle>
        <IconButton size="small" onClick={rest.onClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ py: 0 }}>
        <Typography
          sx={{ pb: 1, gap: 0.5, display: "flex", alignItems: "center" }}
        >
          {usernames.length} selected
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              onClear && onClear();
              rest.onClose();
            }}
          >
            <Clear />
          </IconButton>
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <List
              sx={{
                overflowX: "hidden",
                overflowY: "auto",
                height: "400px",
                maxHeight: "400px",
                borderRight: "1px solid rgba(0,0,0,0.125)",
              }}
            >
              {usernames.map((username) => (
                <ListItem
                  key={username}
                  sx={{
                    py: 0.5,
                    px: 0,
                    alignItems: "center",
                    gap: 1,
                    pr: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                      fontWeight: 700,
                    }}
                  >
                    {username}
                  </Typography>

                  {onRemove && (
                    <Tooltip title={`Deselect ${username}`} placement="bottom">
                      <IconButton
                        size="small"
                        onClick={() => {
                          onRemove(username);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            container
            item
            xs={6}
            direction="column"
            sx={{ gap: 2, px: 2, py: 1 }}
          >
            <Alert variant="filled" severity="warning">
              <Typography sx={{ fontWeight: 700 }}>
                Deleting a user will delete all of their data. This action
                cannot be undone.
              </Typography>
            </Alert>
            <Box sx={{ display: "flex", gap: 2, pb: 2 }}>
              <Button
                disabled={confirmDelete}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setConfirmDelete(true);
                }}
                sx={{ gap: 1 }}
              >
                <Delete />
                Delete selected ({usernames.length})
              </Button>
              {confirmDelete && (
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                  sx={{ gap: 1 }}
                >
                  <DeleteForever />
                  Confirm
                </Button>
              )}
              {isLoading && <CircularProgress size="small" />}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={rest.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkDeleteModal;
