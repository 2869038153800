import gql from "graphql-tag";
const getEndpoints = gql`
  mutation ($username: String) {
    getEndpoints(username: $username) {
      url
      name
      active
      alternativeName
      settingsName
      isDemoEnvironment
    }
  }
`;

export default getEndpoints;
