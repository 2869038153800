import { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ChevronRight, ArrowBack, Close } from "@mui/icons-material";

import SubConceptChart from "./SubConceptChart";
import SubConceptList from "./SubConceptList";

const theme = createTheme();

const SubConceptModal = (props: any) => {
  const show = props.show;
  const onHide = props.onHide;
  const subConcepts = props.subConcepts;
  const setSubConcepts = props.setSubConcepts;
  const chosenConcept = props.chosenConcept;
  const setChosenConcept = props.setChosenConcept;
  const [conceptHistory, setConceptHistory] = useState<any[]>([
    { concept: chosenConcept, subconcepts: subConcepts },
  ]);

  return (
    <>
      <Dialog open={show} onClose={onHide} maxWidth="lg" fullWidth>
        <DialogTitle
          style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs={9}>
              <Typography variant="overline">
                Subconcepts of{" "}
                {conceptHistory
                  .slice(0)
                  .reverse()
                  .map((history: any, i: number) => {
                    return history.concept.title !== chosenConcept.title ? (
                      <Typography
                        variant="button"
                        key={history.concept.uuid || i}
                      >
                        {history.concept.title}
                        <ChevronRight fontSize="small" />
                      </Typography>
                    ) : (
                      <Typography
                        variant="button"
                        key={history.concept.title || i}
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        {history.concept.title}
                      </Typography>
                    );
                  })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                color="primary"
                aria-label="close"
                style={{
                  float: "right",
                  color: theme.palette.grey[500],
                  marginLeft: "8px",
                }}
                onClick={onHide}
              >
                <Close />
              </IconButton>
              <IconButton
                disabled={conceptHistory.length <= 1}
                color="primary"
                aria-label="go-back"
                style={{
                  float: "right",
                  color: theme.palette.grey[500],
                }}
                onClick={() => {
                  //go to previous history, disable if none
                  if (conceptHistory.length > 1) {
                    setChosenConcept(conceptHistory[1].concept);
                    setSubConcepts(conceptHistory[1].subconcepts);
                    setConceptHistory(conceptHistory.slice(1));
                  }
                }}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="overflow-hidden">
          <Grid container>
            <Grid item xs={9}>
              <SubConceptChart
                chosenConcept={chosenConcept}
                subConcepts={subConcepts}
              />
            </Grid>
            <Grid item xs={3}>
              <SubConceptList
                chosenConcept={chosenConcept}
                subConcepts={subConcepts}
                setSubConcepts={setSubConcepts}
                setChosenConcept={setChosenConcept}
                conceptHistory={conceptHistory}
                setConceptHistory={setConceptHistory}
                onHide={onHide}
                startDate={props.startDate}
                endDate={props.endDate}
                newspaper={props.newspaper}
                channel={props.channel}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubConceptModal;
