import { useRef } from "react";
import { IArticle } from "src/utils/typeDefs";
import { FileUpload } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

interface IProps {
  setFile: (file: any) => void;
  setData: (data: IArticle[]) => void;
  callback?: () => void;
}

const ImportJsonButton = (props: IProps) => {
  const { setFile, setData, callback } = props;
  const fileRef = useRef(null);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length || !e.target.files[0]) return;

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent = e.target.result as string;

      // file must not be larger than 10MB, length 200 objects
      if (fileContent.length > 10000000) {
        alert("File size exceeds 10MB limit");
        return;
      }
      let jsonData = [];
      try {
        if (file.name.endsWith(".jsonl")) {
          const lines = fileContent.split("\n").filter(Boolean);
          jsonData = lines.map((line) => JSON.parse(line));
          if (jsonData.length > 200) {
            alert("Number of objects exceeds 200 limit");
            return;
          }
        } else if (file.name.endsWith(".json")) {
          jsonData = JSON.parse(fileContent);
          if (jsonData.length > 200) {
            alert("Number of objects exceeds 200 limit");
            return;
          }
        } else {
          alert("Unsupported file type");
          return;
        }
      } catch (err) {
        alert("Unsupported file type");
        return;
      }
      if (jsonData.length === 0) {
        alert("No data found in file");
        return;
      }
      setData(jsonData);
      setFile(file);
      callback && callback();
    };

    reader.readAsText(file);
  };

  return (
    <>
      <Tooltip
        arrow
        disableInteractive
        title="Upload a local file of articles into a queue. JSON and JSONL formats are supported."
        enterDelay={500}
      >
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            const link = document.getElementById("json-import-btn");
            link?.click();
          }}
        >
          <FileUpload /> Import JSON
        </Button>
      </Tooltip>
      <input
        hidden
        id="json-import-btn"
        name="json-import-btn"
        type="file"
        ref={fileRef}
        accept=".jsonl,.json,application/json"
        onChange={onChangeFile}
      />
    </>
  );
};

export default ImportJsonButton;
