import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

interface MenuOption {
  label: string;
  url: string;
  icon?: any;
  subOptions?: MenuOption[] | null | undefined;
}

interface Props {
  menuOptions: MenuOption[];
}

const BurgerMenu = (props: Props) => {
  const { menuOptions } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        id="burger-menu"
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <Tooltip title="Menu">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon fontSize="medium" className="burger-icon" />
          </IconButton>
        </Tooltip>
      </Box>
      {menuOptions?.length > 0 && (
        <Menu
          MenuListProps={{ dense: true, disablePadding: true }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          {menuOptions?.map((option: MenuOption) => (
            <div key={option.url}>
              <Link to={option.url}>
                <MenuItem>
                  {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                  <ListItemText>
                    <Typography variant="button" style={{ fontWeight: 700 }}>
                      {option.label}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Link>

              {!option.subOptions ||
                (option.subOptions?.length > 0 &&
                  option.subOptions.map((subOption: MenuOption) => (
                    <Link key={subOption.url} to={subOption.url}>
                      <MenuItem>
                        {subOption.icon && (
                          <ListItemIcon>{subOption.icon}</ListItemIcon>
                        )}
                        <ListItemText inset>
                          <Typography
                            variant="button"
                            style={{ fontSize: "90%" }}
                          >
                            {subOption.label}
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    </Link>
                  )))}
            </div>
          ))}
        </Menu>
      )}
    </Fragment>
  );
};

export default BurgerMenu;
